import React, { ReactElement } from 'react'
import { Button, Svg } from 'ui'
import NextIcon from 'images/helpPaneSteps/nextstep.svg'
import { arrowIconButtonStyle } from 'components/SetupGuide/Components/PreviousStepButton'

export default function NextStepButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <Button
      data-testid="setup-guide-next-step-arrow"
      ariaLabel="next step"
      sx={arrowIconButtonStyle}
      variant="simple"
      onClick={onClick}
    >
      <Svg
        fill="aliceBlue60"
        svg={NextIcon}
        sx={{ width: '2rem', height: '2rem' }}
        descriptiveText="Next step arrow"
      />
    </Button>
  )
}
