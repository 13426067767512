import React, { ReactElement, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { EditProfileUrlParams } from 'components/Dashboard/Profiles'
import ProfilesPagesContainer from 'components/Dashboard/Profiles/ProfilesPagesContainer'
import EditProfileLayout from 'components/Dashboard/Profiles/ProfileLayouts/EditProfileLayout'
import ServiceCategoryList from 'components/Dashboard/Profiles/Services/CategoriesList'
import ServiceList from 'components/Dashboard/Profiles/Services/ServicesList'
import ServicesSubHeader, {
  ServicesSubHeaderProps,
} from 'components/Dashboard/Profiles/Services/ServicesSubHeader'
import ServicesSubHeaderActions from 'components/Dashboard/Profiles/Services/ServicesSubHeaderActions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import ServicesPlaceholder from './Placeholder/ServicesPlaceholder'
import MobileSearchInput from 'ui/MobileSearchInput'
import { setIsSearchExpanded } from 'store/dashboard/dashboard'
import { useUpdateDocumentTitle } from 'utils/useUpdateDocumentTitle'
import useGetServicesData from 'components/Dashboard/Profiles/Services/useGetServicesData'
import differenceInDays from 'date-fns/differenceInDays'
import moment from 'moment'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import ServicesTermsAgreement from './ServicesTermsAgreement'

export enum LimitedRedirectionServiceCategory {
  GAMING = 'gaming',
  VIDEO = 'video',
}

export default function Services(params: RouteComponentProps<EditProfileUrlParams>): ReactElement {
  const dispatch = useAppDispatch()
  const categoryId = params.category
  const [searchText, setSearchText] = useState('')
  const isOrganization = useAppSelector(s => s.session.isOrganization)
  const { data: userData } = useGetUser()
  const hasShownServicesWarning = useAppSelector(s => s.services.hasShownServicesWarning)
  const isUserSignupOlderThan7Days =
    differenceInDays(moment().toDate(), moment(userData?.date).toDate()) > 7
  const shouldShowServicesTermsAgreement =
    !hasShownServicesWarning &&
    !isOrganization &&
    !isUserSignupOlderThan7Days &&
    (categoryId === LimitedRedirectionServiceCategory.GAMING ||
      categoryId === LimitedRedirectionServiceCategory.VIDEO)

  const { isLoading } = useGetServicesData()
  useUpdateDocumentTitle('Services')

  useEffect(() => {
    return () => {
      dispatch(setIsSearchExpanded?.(false))
    }
  }, [dispatch, categoryId])

  const subHeaderProps: ServicesSubHeaderProps = {
    categoryId,
    searchText,
    setSearchText,
  }

  return (
    <>
      <EditProfileLayout
        subHeader={categoryId && <ServicesSubHeader {...subHeaderProps} />}
        subHeaderRightContent={!categoryId && <ServicesSubHeaderActions {...subHeaderProps} />}
        searchInput={
          <MobileSearchInput
            name="services-mobile"
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
            clearSearch={() => setSearchText('')}
          />
        }
      >
        <ProfilesPagesContainer
          loadingPlaceholder={isLoading && <ServicesPlaceholder categoryId={categoryId} />}
        >
          {categoryId || searchText ? (
            shouldShowServicesTermsAgreement && !searchText ? (
              <ServicesTermsAgreement />
            ) : (
              <ServiceList categoryId={categoryId} searchText={searchText} />
            )
          ) : (
            <ServiceCategoryList />
          )}
        </ProfilesPagesContainer>
      </EditProfileLayout>
    </>
  )
}
