import { useGetProfilesQuery } from 'store/api/profiles'
import { useGetDevicesQuery } from 'store/api/devices'
import { ResolverStatus } from 'store/api/user/user.interface'

interface ChecklistStatus {
  isProfileCreated: boolean
  isFilterEnabled: boolean
  isEndpointAdded: boolean
  isEndpointConfigured: boolean
  firstProfilePK: string | number | undefined
  checklistPercentage: number
  areRequestsFinished: boolean
}

export function useChecklistStatus(): ChecklistStatus {
  const { data: profilesData, isLoading: isProfilesLoading } = useGetProfilesQuery('')
  const { data: allDevicesData, isLoading: isDevicesLoading } = useGetDevicesQuery('')

  const areRequestsFinished = !isProfilesLoading && !isDevicesLoading
  const isProfileCreated = (profilesData?.profiles?.length ?? 0) > 0
  const isFilterEnabled =
    profilesData?.profiles?.some(profile => profile.profile?.flt?.count ?? 0 > 0) ?? false
  const isEndpointAdded = (allDevicesData?.devices?.length ?? 0) > 0
  const isEndpointConfigured =
    isEndpointAdded &&
    (allDevicesData?.devices?.some(device => device.status === ResolverStatus.VERIFIED) ?? false)
  const firstProfilePK = profilesData?.profiles?.[0]?.PK || undefined
  const checklistPercentage =
    [true, isProfileCreated, isFilterEnabled, isEndpointAdded, isEndpointConfigured].filter(Boolean)
      .length * 20

  return {
    isProfileCreated,
    isFilterEnabled,
    isEndpointAdded,
    isEndpointConfigured,
    firstProfilePK,
    checklistPercentage,
    areRequestsFinished,
  }
}
