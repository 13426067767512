import React, { ReactElement, RefObject } from 'react'
import { Flex, Text, Image } from 'theme-ui'
import useGetColorMode from 'utils/useGetColorMode'
import { getChecklistRocket } from 'components/Dashboard/utils/getChecklistRocket'

interface RocketProgressIconProps {
  checklistPercentage: number
  onClick: () => void
  collapsedRef: RefObject<HTMLDivElement> | null
  hidePercentage?: boolean
}

export default function RocketProgressIcon({
  checklistPercentage,
  onClick,
  collapsedRef,
  hidePercentage = false,
}: RocketProgressIconProps): ReactElement {
  const { isLightMode } = useGetColorMode()
  const rocketIcon = getChecklistRocket(checklistPercentage, isLightMode)

  return (
    <Flex
      data-testid="rocket-icon"
      ref={collapsedRef}
      sx={{
        color: 'white',
        ...(hidePercentage
          ? {}
          : {
              gap: '0.6rem',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'fit-content',
              backgroundColor: 'paua',
              boxShadow: isLightMode
                ? '0px 4px 20px 0px rgba(118, 150, 224, 0.40)'
                : '0px 4px 20px 0px rgba(83, 40, 241, 0.40)',
              border: '1.5px solid',
              borderColor: 'blue600',
              borderRadius: '1rem',
              p: '0.8rem',
              cursor: ['default', 'pointer', 'pointer'],
              mt: '2.4rem',
              mb: '0.8rem',
            }),
      }}
      onClick={onClick}
    >
      <Flex
        sx={{ width: '3.2rem', height: '3.2rem', justifyContent: 'center', alignItems: 'center' }}
      >
        <Image src={rocketIcon} sx={{ width: '2.4rem', height: '3.2rem' }} />
      </Flex>
      {!hidePercentage && <Text variant="size15Weight700">{`${checklistPercentage}%`}</Text>}
    </Flex>
  )
}
