import React, { ReactElement } from 'react'
import { Flex, Text } from '@theme-ui/components'
import EncryptedIcon from 'images/dashboard/encrypted-icon.svg'
import NonEncryptedIcon from 'images/dashboard/non-encrypted-icon.svg'
import { Svg } from 'ui'

export const resolverText = {
  resolverId: 'Resolver ID',
  legacy: 'Legacy DNS',
  doh: 'DNS-over-HTTPS/3',
  dot: 'DNS-over-TLS/DoQ',
  secure: 'Secure DNS',
  dnsStamp: 'DNS Stamp',
}

type ResolverTabTitleProps = {
  type: string
  isOpen?: boolean
}
export default function ResolverTabTitle({ type }: ResolverTabTitleProps): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        variant="size12Weight700"
        sx={{
          color: 'aliceBlue',
          textTransform: 'uppercase',
        }}
      >
        {resolverText[type]}
      </Text>
      <Flex
        sx={{
          gap: '0.8rem',
        }}
      >
        <Svg
          svg={type === 'legacy' ? NonEncryptedIcon : EncryptedIcon}
          descriptiveText={type === 'legacy' ? 'Non-encrypted icon' : 'Encrypted icon'}
        />
        <Text
          variant="size12Weight700"
          sx={{
            color: 'aliceBlue60',
            textTransform: 'uppercase',
          }}
        >
          {type === 'legacy' ? 'non-encrypted' : 'encrypted'}
        </Text>
      </Flex>
    </Flex>
  )
}
