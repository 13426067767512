import React from 'react'
import { TutorialSlideData } from 'components/Dashboard/Tutorial/useTutorialTray'
import { FiltersSlide } from 'components/Dashboard/Tutorial/TutorialSlides/FiltersTutorialSlides'
import { ServicesSlide } from 'components/Dashboard/Tutorial/TutorialSlides/ServicesTutorialSlides'
import { ProfilesSlide } from 'components/Dashboard/Tutorial/TutorialSlides/ProfilesTutorialSlides'
import {
  EndpointsSlide,
  ProvisionSlide,
} from 'components/Dashboard/Tutorial/TutorialSlides/EndpointsTutorialSlides'
import { MyOrgSlide } from 'components/Dashboard/Tutorial/TutorialSlides/MyOrgTutorialSlides'
import { SubOrgSlide } from 'components/Dashboard/Tutorial/TutorialSlides/SubOrgTutorialSlides'
import { OrgBillingSlide } from 'components/Dashboard/Tutorial/TutorialSlides/OrgBillingTutorialSlides'
import {
  ActivityLogSlide,
  StatisticsSlide,
} from 'components/Dashboard/Tutorial/TutorialSlides/AnalyticsSlides'
import { CustomRulesSlide } from 'components/Dashboard/Tutorial/TutorialSlides/CustomRulesTutorialSlides'
import { ProfileOptionsSlide } from 'components/Dashboard/Tutorial/TutorialSlides/ProfileOptionsSlides'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { AdminLogsSlide } from 'components/Dashboard/Tutorial/TutorialSlides/AdminLogsSlides'
import { ReportsSlide } from 'components/Dashboard/Tutorial/TutorialSlides/ReportsTutorialSlides'
import { ClientsSlide } from 'components/Dashboard/Tutorial/TutorialSlides/ClientsSlide'
import { DomainTestSlide } from './TutorialSlides/DomainTestSlide'

export const filtersTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Filters?',
    content: <FiltersSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/filters`,
  },
]

export const servicesTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Services?',
    content: <ServicesSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/services`,
  },
]

export const customRulesTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Custom Rules?',
    content: <CustomRulesSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/custom-rules`,
  },
]

export const endpointsTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Endpoints?',
    content: <EndpointsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/devices`,
  },
]

export const provisionTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is Provisioning?',
    content: <ProvisionSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/provisioning`,
  },
]

export const myOrgTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is My Org?',
    content: <MyOrgSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/organizations#organization-details`,
  },
]

export const subOrgTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is Sub-Org?',
    content: <SubOrgSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/org-sub-organizations`,
  },
]

export const orgBillingTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is Org - Billing?',
    content: <OrgBillingSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/org-billing-products`,
  },
]

export const adminLogTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Admin Logs?',
    content: <AdminLogsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/org-admin-logs`,
  },
]

export const activityLogTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is Activity Log?',
    content: <ActivityLogSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/activity-log`,
  },
]

export const statisticsTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Statistics?',
    content: <StatisticsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/statistics`,
  },
]

export const profilesTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Profiles?',
    content: <ProfilesSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/profiles`,
  },
]

export const profileOptionsTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Profile Options?',
    content: <ProfileOptionsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/profiles#profile-options`,
  },
]

export const reportsTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Reports?',
    content: <ReportsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/reports`,
  },
]

export const clientsTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What are Clients?',
    content: <ClientsSlide />,
    url: `${DOCS_CONTROL_D_DOMAIN}/docs/device-clients`,
  },
]

export const domainTestTutorialSlides: TutorialSlideData[] = [
  {
    title: 'What is Domain Test?',
    content: <DomainTestSlide />,
  },
]
