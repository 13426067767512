import React, { ReactElement, useMemo } from 'react'
import Fuse from 'fuse.js'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import ServiceCard from 'components/Dashboard/Profiles/Services/ServicesList/Card'
import VirtualizedGrid, {
  SizePerBreakpoint,
} from 'components/Dashboard/CommonComponents/VirtualizedGrid'
import NoResults from 'components/Dashboard/CommonComponents/NoResults'
import { useServiceItemsPerRow } from 'components/Dashboard/Profiles/Services/useServiceItemsPerRow'
import { EnabledStatus } from 'store/api/rules'
import { ServiceData } from 'store/api/services'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Button } from 'ui'
import { defaultFuseOptions } from 'utils'
import { setShowActiveOnly } from 'store/services'
import { Flex, Text } from 'theme-ui'
import { WarningMessageBox } from 'components/WarningMessageBox'
import ProxyModalDialog from 'components/Dashboard/ProxyModalDialog'
import useGetServicesData from 'components/Dashboard/Profiles/Services/useGetServicesData'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import groupBy from 'lodash/groupBy'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

export const warningContent = [
  {
    id: 'gaming',
    message:
      'Due to the bespoke protocols used by many gaming applications, redirection is unlikely to work using the following Services. We encourage you to use these Services only in Block or Bypass mode.',
  },
  {
    id: 'video',
    message:
      'Redirection of Video services is offered on a best-effort basis and we provide limited support for this use case.',
  },
]

export const serviceHeightsPx: SizePerBreakpoint = [110, 120, 120]

export default function ServiceListByCategory({
  categoryId,
  searchText,
}: {
  categoryId?: string
  searchText: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const { data: userData } = useGetUser()
  const isHapticFeedbackEnabled = !!userData?.haptics
  const { userServices, isLoading } = useGetServicesData()
  const showActiveOnly = useAppSelector(s => !!s.services.showActiveOnly)
  const servicesPerRow = useServiceItemsPerRow()

  const services = useMemo(
    () =>
      (userServices || []).filter(({ category, action, global }) => {
        if (categoryId) {
          if (showActiveOnly) {
            return (
              category === categoryId &&
              ((global?.action?.status === EnabledStatus.ENABLED &&
                action?.ovr !== EnabledStatus.ENABLED) ||
                action?.status === EnabledStatus.ENABLED)
            )
          }

          return category === categoryId
        }

        return true
      }),
    [userServices, categoryId, showActiveOnly],
  )
  const fuseServiceList: Fuse<ServiceData> = useMemo(
    () =>
      new Fuse(services, {
        ...defaultFuseOptions,
        keys: ['name', 'PK'],
      }),
    [services],
  )

  if (!categoryId && searchText.length < 2) {
    return <NoResults message="Keep typing to start searching." />
  }

  const filteredServices = searchText
    ? fuseServiceList.search(searchText).map(({ item }) => item)
    : services

  let groupedServices: Record<string, ServiceData[]> = {}
  const isSearchingByAllServices = searchText && !categoryId

  if (isSearchingByAllServices) {
    groupedServices = groupBy(filteredServices, 'category')
  }

  if (!filteredServices.length && !isLoading) {
    return (
      <NoResults
        isSearchText={!!searchText}
        message="No active services to show in this category."
        content={
          !searchText ? (
            <Button
              sx={{
                minWidth: '14rem',
                maxWidth: '14rem',
                height: 'auto',
              }}
              variant="gradientButton"
              data-testid="show-all-services-button"
              ariaLabel="show all services button"
              type="submit"
              onClick={() => dispatch(setShowActiveOnly(false))}
            >
              <Text variant="size17Weight600Line138">Show All</Text>
            </Button>
          ) : (
            <Button
              data-testid="suggest-service"
              ariaLabel="Suggest Service"
              variant="primary"
              sx={{
                fontSize: ['1.6rem', '1.8rem'],
                borderRadius: '45px',
                fontWeight: 'bold',
                px: '2.4rem',
              }}
              onClick={() => {
                window.open(`${DOCS_CONTROL_D_DOMAIN}/discuss`, '_blank', 'noopener, noreferrer')
              }}
            >
              Suggest Service
            </Button>
          )
        }
      />
    )
  }

  const warningMessage = warningContent.find(({ id }) => id === (categoryId || ''))?.message

  const serviceRenderer = (index: number) => {
    const service = filteredServices[index]
    return service ? (
      <ServiceCard service={service} isHapticFeedbackEnabled={isHapticFeedbackEnabled} />
    ) : null
  }

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {warningMessage && <WarningMessageBox>{warningMessage}</WarningMessageBox>}
      {isSearchingByAllServices ? (
        <GroupedServicesResult
          groupedServices={groupedServices}
          servicesPerRow={servicesPerRow}
          isHapticFeedbackEnabled={isHapticFeedbackEnabled}
        />
      ) : (
        <VirtualizedGrid
          dataTestId="dashboard-services"
          ariaLabel="Services"
          itemCount={filteredServices.length}
          itemHeightsPx={serviceHeightsPx}
          itemRenderer={serviceRenderer}
          itemsPerRow={servicesPerRow}
          scrollingContainerRef={scrollingContainerRef}
          gap={['0.1rem', '1.2rem', '1.2rem']}
        />
      )}
      <ProxyModalDialog />
    </Flex>
  )
}

function GroupedServicesResult({
  groupedServices,
  servicesPerRow,
  isHapticFeedbackEnabled,
}: {
  groupedServices: Record<string, ServiceData[]>
  servicesPerRow: number[]
  isHapticFeedbackEnabled: boolean
}): ReactElement {
  const { categories } = useGetServicesData()
  const breakpointIndex = useBreakpointIndex()
  const servicesPerRowNumber = servicesPerRow[breakpointIndex]

  return (
    <Flex
      sx={{
        gap: ['0.8rem', '1.2rem', '2.4rem'],
        flexDirection: 'column',
      }}
    >
      {Object.entries(groupedServices).map(([categoryId, services]) => {
        const categoryName = categories?.find(category => category.PK === categoryId)?.name || ''
        return (
          <Flex
            key={categoryId}
            data-testid={categoryId}
            sx={{ flexDirection: 'column', gap: ['0.8rem', '1.2rem', '2.4rem'] }}
          >
            <Text variant="size17Weight600Line138" sx={{ pl: '1.2rem', color: 'aliceBlue' }}>
              {categoryName}
            </Text>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                gap: ['0.1rem', '1.2rem', '1.2rem'],
                flexWrap: 'wrap',
                '& > div': {
                  width: [
                    '100%',
                    `calc(100%/${servicesPerRowNumber} - 0.6rem)`,
                    `calc(100%/${servicesPerRowNumber} - 1rem)`,
                  ],
                  flexGrow: 'unset',
                  flexShrink: 0,
                },
              }}
            >
              {services.map(service => (
                <ServiceCard
                  key={service.PK}
                  service={service}
                  isHapticFeedbackEnabled={isHapticFeedbackEnabled}
                />
              ))}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
