import { useCallback } from 'react'
import { useAppDispatch } from 'store/hooks'
import { clearStateForImpersonation } from 'store/organization'
import { baseApi } from 'store/api'
import { userApi } from 'store/api/user'
import { navigate } from '@reach/router'

const useStartOrEndImpersonation = (): ((path: string) => void) => {
  const dispatch = useAppDispatch()

  const startOrEndImpersonation = useCallback(
    async (path: string) => {
      await navigate(path)
      await dispatch(clearStateForImpersonation())
      await dispatch(baseApi.util.resetApiState())
      await dispatch(userApi.endpoints.getUser.initiate('', { forceRefetch: true }))
    },
    [dispatch],
  )

  return startOrEndImpersonation
}

export default useStartOrEndImpersonation
