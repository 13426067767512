import React, { ReactElement, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import ArrowRight from 'images/freeDNS/arrow-right.svg'
import { StandardConfigurationType } from './StandardConfigurations'
import TabsContainer, { Tab } from '../HomePage/TabsContainer'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setSelectedDNSKey } from 'store/freeDNS'
import useQueryString from 'utils/useQueryString'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'
import PlusSign from 'images/freeDNS/plus-sign.svg'

const useCasesTab = 'Use Cases'
const firstRowKeysDesktop = ['unfiltered', 'blockMalware', 'blockMalwareAndAds']
const firstRowKeysTablet = ['unfiltered', 'blockMalware']

const StandardConfigurationsItemContent = ({
  item,
  itemKey,
  isOpen,
  onClickHandler,
}: {
  item: StandardConfigurationType
  itemKey: ResolverKey
  isOpen?: boolean
  onClickHandler: (link: string) => void
}): ReactElement => {
  const dispatch = useAppDispatch()
  const { firstTab, secondTab, title } = item
  const [activeTab, setActiveTab] = useState(firstTab.title)
  const tabContent = activeTab === useCasesTab ? secondTab.content : firstTab.content
  const selectedDNSKey = useAppSelector(s => s.freeDNS.selectedDNSKey)
  const isSelectedItem = itemKey === selectedDNSKey
  const { nav } = useQueryString()

  return (
    <Flex
      className="item-content-container"
      sx={{
        position: ['static', 'relative'],
        width: '100%',
        height: [
          '36rem',
          firstRowKeysTablet.includes(item.key) ? '28.2rem' : '36rem',
          firstRowKeysDesktop.includes(item.key) ? '28.2rem' : '36rem',
        ],
        px: '2.4rem',
        pb: '2.4rem',
        pt: '4.9rem',
        backgroundColor: 'blue800',
        border: '1px solid',
        borderTop: '1px solid',
        borderColor: 'wakaiwaGrey50',
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        flexDirection: 'column',
      }}
    >
      {/* Tablet & Desktop */}
      <Button
        variant="simple"
        sx={{
          display: ['none', 'flex'],
          position: 'absolute',
          border: '1px solid',
          width: '25.3rem',
          borderColor: 'cyan60',
          left: '50%',
          top: 0,
          height: 'fit-content',
          textAlign: 'center',
          p: '0.9rem 2.0rem',
          borderRadius: '5.2rem',
          color: 'white',
          backgroundColor: 'blue800',
          transform: 'translate(-50%, -50%)',
          ':hover': { transform: 'translate(-50%, -50%) scale(1.025)' },
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'lightSkyBlue10',
            borderRadius: '5.2rem',
          },
          ':hover::before': {
            backgroundColor: 'lightSkyBlue15',
          },
        }}
        onClick={() => {
          dispatch(setSelectedDNSKey(isSelectedItem ? '' : itemKey))
        }}
        ariaLabel={
          isSelectedItem ? `Deselect ${title} configuration` : `Select ${title} configuration`
        }
      >
        <Text
          variant="size17Weight600Line138"
          sx={{
            color: 'aliceBlue',
          }}
        >
          {isSelectedItem ? 'Deselect' : 'Select'}
        </Text>
      </Button>
      {/* Mobile */}
      <Button
        variant="simple"
        ariaLabel="open setup guide"
        sx={{
          display: [isOpen ? 'flex' : 'none', 'none', 'none'],
          position: 'absolute',
          width: '31.8rem',
          height: '4.7rem',
          left: '50%',
          top: 0,
          textAlign: 'center',
          p: '0.9rem 2.0rem',
          border: '1px solid',
          borderColor: 'cyan60',
          backgroundColor: 'blue800',
          borderRadius: '5.2rem',
          zIndex: 'zIndex1',
          transform: 'translate(-50%, -50%)',
          ':hover': { transform: 'translate(-50%, -50%) scale(1.025)' },
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'lightSkyBlue10',
            borderRadius: '5.2rem',
          },
          ':hover::before': {
            backgroundColor: 'lightSkyBlue15',
          },
        }}
        onClick={() => {
          nav({
            helpPane: SetupGuideIntermediateStates.DNS,
            freeResolverType: itemKey as ResolverKey,
          })
        }}
      >
        <Text
          variant="size17Weight600Line138"
          sx={{
            color: 'white',
          }}
        >
          Configure
        </Text>
      </Button>
      <Flex
        sx={{
          height: 'fit-content',
          pr: ['1.6rem', '2.4rem'],
        }}
      >
        <TabsContainer sx={{ width: 'fit-content', borderBottom: 'none' }}>
          <Tab
            onClick={(): void => setActiveTab(firstTab.title)}
            isActive={activeTab === firstTab.title}
            inactiveColor="body"
            activeColor="aliceBlue"
            sxTab={{
              width: 'fit-content',
              height: 'fit-content',
              px: 0,
              pt: 0,
              pb: '0.8rem',
              mr: '2.4rem',
            }}
          >
            <Text
              variant="size17Weight600Line138"
              sx={{
                px: 0,
              }}
            >
              {firstTab.title}
            </Text>
          </Tab>
          <Tab
            onClick={(): void => setActiveTab(secondTab.title)}
            isActive={activeTab === secondTab.title}
            inactiveColor="body"
            activeColor="aliceBlue"
            sxTab={{
              minWidth: '9rem',
              height: 'fit-content',
              px: 0,
              pt: 0,
              pb: '0.8rem',
            }}
          >
            <Text sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>{secondTab.title}</Text>
          </Tab>
        </TabsContainer>
      </Flex>
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: activeTab === useCasesTab && ['0.2rem'],
          mt: '1.6rem',
          '& > div': {
            backgroundColor: !firstTab.links && 'lightSkyBlue5',
          },
          '& > div:first-of-type': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
          '& > div:last-child': {
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          },
        }}
      >
        {tabContent.map((text, index) => (
          <Flex
            key={text}
            sx={{
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              backgroundColor:
                activeTab !== useCasesTab && firstTab.links ? 'none' : 'lightSkyBlue5',
            }}
          >
            {!!index && activeTab !== useCasesTab && (
              <Box
                sx={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'blue800',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Flex
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '3.4rem',
                    height: '3.4rem',
                    borderRadius: '50%',
                    border: '1px solid',
                    color: 'cyan60',
                    backgroundColor: 'blue800',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '2.8rem',
                    '::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'lightSkyBlue20',
                      borderRadius: '50%',
                      zIndex: 'zIndex1',
                    },
                  }}
                >
                  <Svg svg={PlusSign} descriptiveText="Additional configuration item"></Svg>
                </Flex>
              </Box>
            )}
            <Flex
              sx={{
                flex: '1 1 0',
                py: activeTab === useCasesTab ? '1rem' : '2.2rem',
                px: '1.6rem',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: activeTab !== useCasesTab && firstTab.links && 'lightSkyBlue5',
                borderRadius: '12px',
              }}
            >
              <Text
                variant="size16Weight400"
                sx={{
                  color: 'lightSteelBlue',
                }}
                tabIndex={0}
              >
                {text}
              </Text>
            </Flex>
            {activeTab !== useCasesTab && firstTab.links && (
              <>
                <Flex
                  sx={{
                    flex: '1 1 0',
                    py: '2.4rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    '& > button:first-of-type': {
                      mb: '2.4rem',
                    },
                  }}
                >
                  {firstTab.links.map(link => (
                    <Button
                      key={link}
                      ariaLabel={`open ${link} button`}
                      variant="simple"
                      sx={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mx: 0,
                        p: 0,
                        ':hover > span': {
                          color: 'aliceBlue',
                        },
                      }}
                      onClick={() => {
                        onClickHandler(link)
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: '1.8rem',
                          color: 'lightSteelBlue',
                          fontWeight: 'bold',
                        }}
                      >
                        {link}
                      </Text>
                      <Svg svg={ArrowRight} fill="body" descriptiveText={`View ${link} details`} />
                    </Button>
                  ))}
                </Flex>
              </>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default StandardConfigurationsItemContent
