import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import ArrowDownIcon from 'images/dashboard/context-menu-arrow-down.svg'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import useGetColorMode from 'utils/useGetColorMode'

export default function SettingsDropdown({
  disabled = false,
  items,
  hideRef,
  testId,
  defaultValue,
  boundaryDataTestId = '',
  buttonSx,
}: {
  disabled?: boolean
  items: SimpleActionMenuItemProps[]
  hideRef: React.MutableRefObject<(() => void) | undefined>
  testId?: string
  defaultValue?: string | ReactElement
  boundaryDataTestId?: string
  buttonSx?: ThemeUIStyleObject
}): ReactElement {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const buttonContentRef = useRef<HTMLButtonElement>(null)
  const selectedItem = items.find(item => item.isSelected)
  const boundaryElement = useBoundaryElement(boundaryDataTestId)
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null)
  const { isLightMode } = useGetColorMode()

  useEffect(() => {
    const scrollingSection = document.getElementById('advanced-settings-section')

    if (scrollingSection) {
      setScrollElement(scrollingSection as HTMLDivElement)
    }
  }, [])

  useHideTooltipOnScroll(hideRef, { current: scrollElement })

  return (
    <Dropdown
      disabled={disabled}
      buttonRef={buttonContentRef}
      data-testid={testId}
      ariaLabel="settings dropdown"
      variant="simple"
      onKeyDown={stopPropagationOnKeyPress}
      sx={{
        width: '100%',
        p: 0,
      }}
      tippyprops={{
        appendTo: boundaryElement,
        placement: 'bottom-end',
        animation: 'fade',
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onHide() {
          setIsDropdownOpened(false)
        },
        onTrigger() {
          setIsDropdownOpened(true)
        },
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        maxWidth: 'fit-content',
      }}
      dropdowncontent={
        <Flex
          className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
          data-testid={`${testId}-content`}
          sx={{
            flexDirection: 'column',
            width: '25rem',
            px: '0.4rem',
          }}
        >
          <SimpleActionMenu
            hideRef={hideRef}
            isOpen={isDropdownOpened}
            items={items}
            sxContainer={{ px: 0, position: 'relative' }}
            sxButton={{
              maxHeight: 'auto',
              m: 0,
              py: '0.4rem',
              px: '0.8rem',
              fontSize: '1.4rem',
              fontWeight: 'normal',
              alignItems: 'center',
            }}
          />
        </Flex>
      }
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          flexShrink: 0,
          svg: {
            transform: isDropdownOpened ? 'rotate(180deg)' : 'rotate(0)',
          },
          ...buttonSx,
        }}
      >
        <Text
          variant="size15Weight700"
          sx={{
            color: isDropdownOpened ? 'aliceBlue' : 'aliceBlue60',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            mr: ['0.2rem', '0.8rem'],
          }}
        >
          {selectedItem?.buttonContent || defaultValue}
        </Text>
        <Svg
          svg={ArrowDownIcon}
          fill={isDropdownOpened ? 'aliceBlue' : 'aliceBlue60'}
          sx={{ opacity: 1, flexShrink: 0 }}
          descriptiveText={isDropdownOpened ? 'Collapse dropdown' : 'Expand dropdown'}
        />
      </Flex>
    </Dropdown>
  )
}
