import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { GatsbyBrowser } from 'gatsby'
import omit from 'lodash/omit'
// https://www.npmjs.com/package/@datapunt/matomo-tracker-react
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react'

import './global.css'
import 'tippy.js/dist/tippy.css'
import './src/components/tippy-styles.scss'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './src/components/carousel-styles.scss'
import instance from './src/matomoInstance'
import { appStore } from './src/store'
import { setShowActiveOnly, setShowCardView } from './src/store/services'
import CustomThemeProvider from './src/ui/Theme/ThemeProvider'
import { POSTHOG_API_KEY, POSTHOG_API_HOST } from 'gatsby-env-variables'
import { posthog } from 'posthog-js'

if (!posthog.__loaded) {
  posthog.init(POSTHOG_API_KEY, { api_host: POSTHOG_API_HOST })
}

const Wrapper = ({ children }) => {
  const { enableLinkTracking } = useMatomo()
  enableLinkTracking()

  const isCypress =
    typeof window !== 'undefined' &&
    // @ts-ignore
    !!window?.Cypress

  return (
    // @ts-ignore
    <MatomoProvider value={instance}>
      <ReduxProvider store={appStore}>
        {/*
         When adding a CustomThemeProvider wrapper, some tests crash in gitlab.
         There are no problems with tests locally. CustomThemeProvider is important for us
         to dynamically change modes on the static pages without page reloading */}
        {isCypress ? children : <CustomThemeProvider>{children}</CustomThemeProvider>}
      </ReduxProvider>
    </MatomoProvider>
  )
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <Wrapper>{element}</Wrapper>
)

const performMigration = ({
  key,
  field,
  action,
}: {
  key: string
  field?: string
  action: (item: unknown) => void
}) => {
  const item = typeof window !== 'undefined' && window.localStorage.getItem(key)
  if (item) {
    if (field) {
      // this is a migration from one persisted slice to another
      const storedData = JSON.parse(item) as Record<string, unknown>
      if (storedData[field]) {
        action(storedData[field])
        window.localStorage.setItem(key, JSON.stringify(omit(storedData, field)))
      }
    } else {
      // this is a migration from a key to a slice
      action(item)
      window.localStorage.removeItem(key)
    }
  }
}

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  const dispatch = appStore.dispatch
  performMigration({
    key: 'serviceItemView',
    action: item => {
      dispatch(setShowCardView(item !== 'list'))
    },
  })
  performMigration({
    key: 'serviceActiveView',
    action: item => {
      dispatch(setShowActiveOnly(item === 'active'))
    },
  })
}

// shouldUpdateScroll fires on every route change, and it expects us to return a boolean:
// true - if we should scroll to top,
// false - if we should maintain the current scroll position.
// also, could return [x, y] array of coordinates to scroll to, or a string of the id or name
// by default - returns true.
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}): boolean => {
  return (
    prevRouterProps?.location?.pathname &&
    prevRouterProps?.location?.pathname !== routerProps.location.pathname
  )
}

export const onServiceWorkerUpdateReady = (): void => window.location.reload()
