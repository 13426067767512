import React, { ReactElement } from 'react'
import { Button, Svg } from 'ui'
import MenuOpenIcon from 'images/menu-open.svg'
import { Box } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'

export default function HeaderNavMenuButtonMobile({
  tabIndex = 0,
}: {
  tabIndex?: number
}): ReactElement {
  const dispatch = useAppDispatch()
  const isMobileNavMenuOpen = useAppSelector(s => s.dashboard.isMobileNavMenuOpen)
  const isConfigured = useAppSelector(s => !!s.devices.configuredDevice)

  return (
    <Button
      data-testid="toggle-navigation-menu"
      ariaLabel="open navigation menu"
      onClick={(): void => {
        dispatch(setIsMobileNavMenuOpen?.(!isMobileNavMenuOpen))
      }}
      sx={{
        display: ['flex', 'none'],
        position: 'relative',
        border: 'none',
        p: 0,
        flexShrink: 0,
      }}
      variant="simple"
      tabIndex={tabIndex}
    >
      <Svg svg={MenuOpenIcon} fill="aliceBlue60" descriptiveText="Open Navigation Menu" />
      {isConfigured && (
        <Box
          data-testid="device-configured-badge"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '1rem',
            height: '1rem',
            borderRadius: '500px',
            backgroundColor: 'primary',
            border: '1px solid black',
          }}
        />
      )}
    </Button>
  )
}
