import React, { ReactElement } from 'react'
import { Flex, Text, Image } from 'theme-ui'
import { Button, Svg } from 'ui'
import BarryIcon from 'images/barry/barry-icon.png'
import BarryIconBackground from 'images/barry/barry-background.svg'
import { useAppDispatch } from 'store/hooks'
import { Page, switchPage } from 'store/barry/barry'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function SplashScreen(): ReactElement {
  const dispatch = useAppDispatch()

  const isMobile = useBreakpointIndex() === 0

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        '@media screen and (max-height: 450px)': {
          pt: isMobile ? '21rem' : 'none',
          pb: '2.4rem',
        },
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
          '@media screen and (max-height: 450px)': {
            maxHeight: '50rem',
            overflow: 'hidden',
            width: '100%',
          },
        }}
      >
        <Svg svg={BarryIconBackground} descriptiveText="Barry chat background decoration" />
      </Flex>
      <Flex
        data-testid="barry-splash-screen"
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 'zIndex1',
        }}
      >
        <Image
          src={BarryIcon}
          aria-label="Barry assistant icon"
          sx={{ width: '14rem', height: '14rem' }}
        />
        <Text
          variant="size28Weight600"
          sx={{
            pt: '1.6rem',
            color: 'aliceBlue',
            pb: '0.8rem',
          }}
        >
          Ask Barry
        </Text>
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Flex
            sx={{
              color: 'lightSteelBlue',
              marginBottom: '3.2rem',
              flexDirection: 'column',
              alignItems: 'center',
              width: '32rem',
              textAlign: 'center',
            }}
          >
            <Text variant="size15Weight400Line138">
              I can help answer and troubleshoot any question or issue.
            </Text>
          </Flex>
          <Button
            ariaLabel="Ask me anything"
            data-testid="splash-screen-button"
            onClick={() => dispatch(switchPage(Page.DESCRIBE_ISSUE_PAGE))}
            variant="gradientButton"
            sx={{
              width: 'fit-content',
              height: '4.4rem',
              py: 0,
            }}
          >
            <Text variant="size17Weight600Line138" sx={{ color: 'commonWhite' }}>
              Ask me anything
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
