import React, { ReactElement } from 'react'
import CategoryCardLoader from './CategoryCardLoader'
import ServiceCardLoader from './ServiceCardLoader'
import { categoryHeightsPx } from 'components/Dashboard/Profiles/Services/CategoriesList'
import {
  serviceHeightsPx,
  warningContent,
} from 'components/Dashboard/Profiles/Services/ServicesList'
import { useServiceItemsPerRow } from 'components/Dashboard/Profiles/Services/useServiceItemsPerRow'
import PlaceholderGrid from 'components/PlaceholderGrid'
import ServiceCardWrapper from './CardWrapper'
import { Flex } from 'theme-ui'
import { WarningMessagePlaceholder } from 'components/Dashboard/Profiles/Placeholder/WarningMessagePlaceholder'

export default function ServicesPlaceholder({ categoryId }: { categoryId?: string }): ReactElement {
  const servicesPerRow = useServiceItemsPerRow()
  const warningMessage = warningContent.find(({ id }) => id === (categoryId || ''))?.message

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {warningMessage && <WarningMessagePlaceholder />}

      <PlaceholderGrid
        itemCount={categoryId ? 8 : 16}
        itemHeightsPx={categoryId ? serviceHeightsPx : categoryHeightsPx}
        itemsPerRow={servicesPerRow}
        placeholder={
          <ServiceCardWrapper>
            {categoryId ? <ServiceCardLoader /> : <CategoryCardLoader />}
          </ServiceCardWrapper>
        }
        gap={['0.1rem', '1.2rem', '1.2rem']}
      />
    </Flex>
  )
}
