import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { Flex, Text } from 'theme-ui'
import DashboardContainer from 'components/Dashboard/DashboardContainer'
import DashboardHeader from 'components/Dashboard/DashboardHeader'
import SubHeader from 'components/Dashboard/SubHeader'
import EditProfileIcon from 'images/dashboard/navBar/edit-profile-icon.svg'
import { useAppDispatch } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import DeviceDropdownOrAddButton from 'components/Dashboard/DeviceDropdown/DeviceDropdownOrAddButton'
import { setSelectedDeviceId as setDeviceId } from 'store/analytics'
import { setQueryFilters } from 'store/activityLog'
import useQueryString from 'utils/useQueryString'
import { useGetDevicesQuery } from 'store/api/devices'
import {
  useDeleteProfileMutation,
  useGetProfilesQuery,
  useLazyExportProfileQuery,
  usePostProfileMutation,
} from 'store/api/profiles'
import { SubHeaderPath } from 'components/Dashboard/SubHeader/SubHeaderButtonContent'
import { ResponseType } from 'store/api'
import { ProfileResponseData } from 'store/api/profiles/profiles.interface'
import DuplicateIcon from 'images/menus/duplicate-icon.svg'
import DeleteIcon from 'images/menus/delete-icon.svg'
import debounce from 'lodash/debounce'
import { downloadFile } from 'utils/downloadFile'
import ExportIcon from 'images/export-rule.svg'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import { ConfirmDeletionDialog } from 'ui/ConfirmDeletionDialog'
import { useAlertPresenter, useCustomAlerts } from 'ui'
import { Setter } from 'utils'
import { clearErrors } from 'store/errors'
import ActionIconButton from 'ui/ActionIconButton'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'

const tutorialText = {
  [SubHeaderPath.FILTERS]: {
    text: (
      <TextWithOverFlowAndTippyPopup
        ariaLabel="filters tutorial"
        content={
          <Text>
            Categories of websites and online services you can <strong>block</strong>.
          </Text>
        }
        placement="bottom-start"
        variant="size12Weight500"
      />
    ),
  },
  [SubHeaderPath.SERVICES]: {
    text: (
      <TextWithOverFlowAndTippyPopup
        ariaLabel="services tutorial"
        content={
          <Text>
            Popular websites and apps that you can <strong>block</strong>, <strong>redirect</strong>
            , or <strong>bypass</strong> (if it&apos;s blocked by a Filter).
          </Text>
        }
        placement="bottom-start"
        variant="size12Weight500"
      />
    ),
  },
  [SubHeaderPath.CUSTOM_RULES]: {
    text: (
      <TextWithOverFlowAndTippyPopup
        ariaLabel="custom rules tutorial"
        content={
          <Text>
            Rules to <strong>block</strong>, <strong>redirect</strong> or <strong>bypass</strong>{' '}
            specific domain names.
          </Text>
        }
        placement="bottom-start"
        variant="size12Weight500"
      />
    ),
  },
  [SubHeaderPath.PROFILE_OPTIONS]: {
    text: (
      <TextWithOverFlowAndTippyPopup
        ariaLabel="profile options tutorial"
        content="Advanced options and settings for this Profile."
        placement="bottom-start"
        variant="size12Weight500"
      />
    ),
  },
}

const deleteAlertId = 'profile-deleted'
const copyAlertId = 'profile-copy-created'

export default function EditProfileLayout({
  children,
  subHeaderRightContent,
  subHeader,
  searchInput,
}: {
  children?: ReactNode
  subHeaderRightContent?: ReactNode
  subHeader?: ReactNode
  searchInput?: ReactNode
}): ReactElement {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isMobile = useBreakpointIndex() === 0
  const { relocate } = useQueryString()
  const { dismissAlert } = useAlertPresenter()
  const { presentCautionOkAlert } = useCustomAlerts()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const profile = useGetSelectedProfile()
  const profileId = profile?.PK?.toString() || ''
  const profileName = profile?.name || ''

  useGetDevicesQuery('')
  const { isSuccess } = useGetProfilesQuery('')
  const [deleteProfile, { isLoading: isDeleteProfileLoading }] = useDeleteProfileMutation()

  useEffect(() => {
    if (isSuccess && !profile) {
      relocate('/dashboard/profiles')
    }
  }, [isSuccess, profile, relocate])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dismissAlert(copyAlertId)
    }
  }, [dismissAlert, dispatch])

  const onDelete = async () => {
    const resp = await deleteProfile({ pk: profileId })
    const data = (resp as ResponseType<{ message: string }>).data

    setIsModalOpen(false)

    if (data) {
      relocate('/dashboard/profiles')
      data.message && presentCautionOkAlert(data.message, deleteAlertId)
    }
  }

  const baseProfileUrl = location.pathname.split('/')[4]

  return (
    <DashboardContainer
      header={
        <DashboardHeader
          icon={EditProfileIcon}
          title={profileName}
          description={tutorialText[baseProfileUrl]?.text}
          rightContent={<ActionButtons profileId={profileId} setIsModalOpen={setIsModalOpen} />}
        >
          <DeviceDropdownOrAddButton
            isAllDevicesVisible={false}
            profileId={profileId}
            onClick={deviceId => {
              dispatch(setDeviceId(deviceId))
              dispatch(setQueryFilters({ deviceId, clientId: undefined }))
              relocate('/dashboard/statistics')
            }}
          />
        </DashboardHeader>
      }
      subHeader={
        subHeader ? (
          <Flex sx={{ width: '100%', flexDirection: 'column' }}>
            <Flex
              data-testid="dashboard-sub-header"
              sx={{ position: 'relative', width: '100%', pb: ['1.4rem', 0] }}
              className={isMobile ? 'reset-tippy-width centered-header-tippy mobile' : ''}
            >
              {subHeader}
            </Flex>
            {searchInput}
          </Flex>
        ) : (
          <Flex sx={{ width: '100%', flexDirection: 'column' }}>
            <SubHeader rightSideContent={subHeaderRightContent} />
            {searchInput}
          </Flex>
        )
      }
    >
      {children}
      <ConfirmDeletionDialog
        testId="delete-profile-dialog"
        isOpen={isModalOpen}
        element={scrollingContainerRef.current}
        title="Delete Profile"
        isRequestInFlight={isDeleteProfileLoading}
        notificationText="Are you sure you want to permanently delete this Profile? This cannot be undone."
        onClose={() => setIsModalOpen(false)}
        onDelete={onDelete}
      />
    </DashboardContainer>
  )
}

function ActionButtons({
  profileId,
  setIsModalOpen,
}: {
  profileId: string
  setIsModalOpen?: Setter<boolean>
}): ReactElement {
  const [addProfile] = usePostProfileMutation()
  const [exportProfile] = useLazyExportProfileQuery()
  const { data: profilesData } = useGetProfilesQuery('')
  const { presentCautionOkAlert } = useCustomAlerts()

  const profile = profilesData?.profiles.find(profile => profile.PK === profileId)
  const profileName = profile?.name || ''

  return (
    <Flex sx={{ gap: '0.8rem' }}>
      <ActionIconButton
        ariaLabel="duplicate profile button"
        dataTestId="duplicate-profile-button"
        onClick={async () => {
          const resp = await addProfile({
            name: profileName,
            clone_profile_id: profileId,
          })
          const data = (resp as ResponseType<ProfileResponseData>).data

          if (data) {
            presentCautionOkAlert(
              <Text>
                This profile has been cloned to a new profile -{' '}
                <strong>{data?.profiles?.[0]?.name}</strong>
              </Text>,
              copyAlertId,
            )
          }
        }}
        svgIcon={DuplicateIcon}
        tooltipText="Duplicate Profile"
      />
      <ActionIconButton
        ariaLabel="delete profile button"
        dataTestId="delete-profile-button"
        onClick={() => setIsModalOpen?.(true)}
        svgIcon={DeleteIcon}
        tooltipText="Delete Profile"
      />
      <ActionIconButton
        ariaLabel="export profile button"
        dataTestId="export-profile-button"
        onClick={debounce(async () => {
          if (profile?.PK.toString()) {
            const response = await exportProfile(profile.PK.toString())

            if (response.data?.body) {
              downloadFile({
                content: response.data?.body?.export,
                fileTitle: response.data?.body.filename,
                fileType: 'application/json',
              })
            }
          }
        }, 500)}
        svgIcon={ExportIcon}
        tooltipText="Export Profile"
      />
    </Flex>
  )
}
