import React, { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import { Flex } from 'theme-ui'
import ServiceCardWrapper from 'components/Dashboard/Profiles/Services/Placeholder/CardWrapper'
import { EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { ServiceData } from 'store/api/services'
import { ProxyLocation } from 'store/api/proxies'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/CommonComponents/ExternalSvgIcon'

interface ServiceCardContentProps {
  shouldShowWarning: boolean
  service: ServiceData
  children: ReactNode
  redirectButtonHover: boolean
  dataTestId: string
  warning: ReactNode
  redirectLocation?: ProxyLocation
}

export default function ServiceCardContent({
  shouldShowWarning = false,
  service,
  dataTestId,
  redirectButtonHover,
  children,
  warning,
  redirectLocation,
}: ServiceCardContentProps): ReactElement {
  const { PK, name } = service

  const action =
    service.action?.ovr === EnabledStatus.ENABLED
      ? service.action
      : service.global?.action ?? service.action

  /* The below code is to facilitate nice fading in and out of the warning overlay on each filters card.
     When shouldShowWarning is going from FALSE to TRUE we want the zIndex to change immediately, 
     so we can see the opacity transition when shouldShowWarning is going from TRUE to FALSE 
     we want the zIndex to change only after the opacity transition has ended */

  const [canChangeZIndexToHidden, setCanChangeZIndexToHidden] = useState(false)

  const warningZIndex: 'zIndex240' | 'zIndex-100' = useMemo(() => {
    if (shouldShowWarning) {
      return 'zIndex240'
    }
    if (canChangeZIndexToHidden) {
      setCanChangeZIndexToHidden(false)
    }
    return 'zIndex-100'
  }, [shouldShowWarning, canChangeZIndexToHidden])

  const getServiceStatus = useCallback(() => {
    if (action?.status === EnabledStatus.ENABLED) {
      switch (action?.do) {
        case RuleType.BLOCK:
          return 'Blocked'
        case RuleType.WHITELIST:
          return 'Bypassed'
        case RuleType.SPOOF_TAG:
          return `Redirected`
        case RuleType.SPOOF_IP:
          return `Redirected`
      }
    }
    return 'Off'
  }, [action])

  return (
    <ServiceCardWrapper testId={PK}>
      <Flex
        onTransitionEnd={() => {
          !shouldShowWarning && setCanChangeZIndexToHidden(true)
        }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: shouldShowWarning ? 1 : 0,
          zIndex: warningZIndex,
        }}
      >
        {warning}
      </Flex>
      <Flex
        data-testid={dataTestId}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '0.2rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'row',
              mr: 0,
              my: 0,
            }}
          >
            <ExternalSvgIcon
              testId="services-list-item-svg"
              icon={PK}
              type={IconType.SERVICES}
              descriptiveText={`${name} service icon`}
              fill="grayscale"
              sx={{
                width: '3.2rem',
                height: '3.2rem',
              }}
            />
          </Flex>

          <Flex
            sx={{
              maxWidth: '100%',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <TextWithOverFlowAndTippyPopup
              overflowCheckWithHighAccuracy
              variant="size15Weight700"
              content={name}
              ariaLabel={name}
              sxText={{
                mb: '0.4rem',
                color: 'aliceBlue',
              }}
            />
            <TextWithOverFlowAndTippyPopup
              ariaLabel="service status"
              content={
                redirectButtonHover
                  ? `Change ${
                      redirectLocation?.isSingle
                        ? redirectLocation?.country_name
                        : redirectLocation?.city ??
                          `${action?.via ? action?.via : ''}${
                            action?.via && action?.via_v6 ? ',' : ''
                          } ${action?.via_v6 ? action?.via_v6 : ''}`
                    }`
                  : getServiceStatus()
              }
              variant="size12Weight400"
              sxText={{
                color: 'aliceBlue',
                opacity: '0.6',
              }}
            />
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: 'center', minWidth: '13rem' }}>{children}</Flex>
      </Flex>
    </ServiceCardWrapper>
  )
}
