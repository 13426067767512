import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import {
  ServiceCategory,
  ServiceData,
  useGetAllServicesCategoriesQuery,
  useGetGlobalServicesQuery,
  useGetServiceCategoriesQuery,
  useGetUserServicesQuery,
} from 'store/api/services'
import useGetUserState from 'store/api/user/useGetUserState'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export default function useGetServicesData(): {
  categories?: ServiceCategory[]
  allServices?: ServiceData[]
  userServices?: ServiceData[]
  isLoading: boolean
} {
  const { noAccessToParentOrg } = useGetUserState()
  const { data } = useGetOrganization()
  const selectedProfile = useGetSelectedProfile()
  const globalProfileId = data?.organization?.parent_profile?.PK

  const { data: categoriesData, isSuccess: isCategoriesLoaded } = useGetServiceCategoriesQuery('', {
    skip: !selectedProfile,
  })
  const { data: userServicesData, isSuccess: isUserServicesLoaded } = useGetUserServicesQuery(
    {
      profileId: selectedProfile?.PK,
    },
    { skip: !selectedProfile },
  )
  const { data: allServicesData, isSuccess: isAllServicesLoaded } =
    useGetAllServicesCategoriesQuery(
      { profileId: selectedProfile?.PK },
      { skip: !selectedProfile || !isUserServicesLoaded },
    )

  useGetGlobalServicesQuery(
    { globalProfileId, profileId: selectedProfile?.PK },
    {
      skip:
        noAccessToParentOrg ||
        !globalProfileId ||
        !selectedProfile ||
        !isAllServicesLoaded ||
        !isUserServicesLoaded,
    },
  )

  return {
    categories: categoriesData?.categories,
    allServices: allServicesData?.services,
    userServices: userServicesData?.services,
    isLoading: !isAllServicesLoaded || !isUserServicesLoaded || !isCategoriesLoaded,
  }
}
