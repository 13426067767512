import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from '@theme-ui/components'
import {
  Accordion,
  SimpleActionMenuItemProps,
  Switch,
  MultiSelectInput,
  Option,
  ExternalLink,
  Input,
} from 'ui'
import { Divider, Text } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import type { Setter } from 'utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ROOT_GROUP } from 'store/api/rules'
import FocusLock from 'react-focus-lock'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useGetProfilesQuery } from 'store/api/profiles'
import { useLazyGetRulesQuery } from 'store/api/rules/rules'
import { useGetGroupsQuery } from 'store/api/groups'
import { useGetDevicesQuery } from 'store/api/devices'
import useGetUserState from 'store/api/user/useGetUserState'
import useQueryString from 'utils/useQueryString'
import type { AdvancedSettingsType } from 'components/SetupGuide/Components/ProfileDownload'
import customUnescape from 'utils/customUnescape'
import AdvancedSettingsIcon from 'images/dashboard/devices/advanced-settings.svg'

function clientIdMask(value: string): string {
  return value.replace(/\W/g, '-').toLowerCase()
}

const limitErrorMessage = 'Please add 100 domains or less at once.'

export default function AdvancedSettings({
  advancedSettings,
  setAdvancedSettings,
  excludedDomains,
  setExcludedDomains,
  excludedNetworks,
  setExcludedNetworks,
  isOpen,
  setIsOpen,
  domainLimitReached,
}: {
  advancedSettings: AdvancedSettingsType
  setAdvancedSettings: Setter<AdvancedSettingsType>
  excludedDomains?: Option[]
  setExcludedDomains?: Setter<Option[] | undefined>
  excludedNetworks?: Option[]
  setExcludedNetworks?: Setter<Option[] | undefined>
  isOpen: boolean
  setIsOpen: Setter<boolean>
  domainLimitReached?: boolean
}): ReactElement {
  const { isCommonExclude, shouldTrustRootCa, shouldPreventDisablement, clientId } =
    advancedSettings

  const dispatch = useAppDispatch()
  const hideRef = useRef<() => void>()
  const [selectedFolderPK, setSelectedFolderPK] = useState<number | undefined>(undefined)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: profilesData } = useGetProfilesQuery('', { skip: !sessionToken })
  const [getRules] = useLazyGetRulesQuery()
  const { qs } = useQueryString()
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(d => d.PK === qs.deviceId)
  const { isOrganization } = useGetUserState()
  const currentProfile = profilesData?.shared_profiles?.find(
    profile => profile.PK === selectedDevice?.profile.PK,
  )
  const isCurrentProfileShared = currentProfile?.profile?.opt.data.some(opt => opt.PK === 'shared')

  const { data: groupsData } = useGetGroupsQuery(
    {
      profileId: selectedDevice?.profile.PK ?? '',
      ignoreImpersonation: isCurrentProfileShared,
    },
    { skip: !sessionToken || !selectedDevice },
  )

  useEffect(() => {
    if (selectedFolderPK === undefined) {
      return
    }

    const setDomains = async () => {
      const response = await getRules({
        groupPk: selectedFolderPK,
        profileId: selectedDevice?.profile.PK.toString() ?? '',
      })

      setExcludedDomains?.(
        response.data?.rules.map(
          (rule): Option => ({
            label: rule.PK,
            value: rule.PK,
          }),
        ) || [],
      )
    }

    setDomains()
  }, [dispatch, getRules, selectedDevice?.profile.PK, selectedFolderPK, setExcludedDomains])

  const filteredGroupsData = useMemo(
    () =>
      groupsData?.groups?.map(x => ({
        ...x,
        text: customUnescape(x.group),
      })) || [],
    [groupsData?.groups],
  )

  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      filteredGroupsData.map(group => {
        return {
          isSelected: group.PK === selectedFolderPK,
          ariaLabel: `${group?.text} option`,
          children: <Text>{group?.text ?? ''}</Text>,
          onClick: () => {
            setSelectedFolderPK(group.PK)
            hideRef.current?.()
          },
        }
      }),
    [filteredGroupsData, selectedFolderPK],
  )

  const rootGroupOption: SimpleActionMenuItemProps = {
    ariaLabel: 'root folder',
    isSelected: selectedFolderPK === ROOT_GROUP,
    children: <Text>Root Folder</Text>,
    onClick: () => {
      setSelectedFolderPK(ROOT_GROUP)
      hideRef.current?.()
    },
  }

  return (
    <Accordion
      id="advanced-settings"
      title="Advanced Settings"
      ariaLabelButtonName="advanced settings"
      buttonVariant="simple"
      titleIcon={AdvancedSettingsIcon}
      sxTitleContainer={{
        pr: '2.4rem',
      }}
      titleStyle={{
        color: 'aliceBlue60',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        textAlign: 'left',
        pl: '1.2rem',
      }}
      openTitleStyle={{
        color: 'aliceBlue',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        textAlign: 'left',
        pl: '1.2rem',
      }}
      containerStyle={{
        width: '100%',
        '& > div:first-of-type': {
          width: '100%',
          px: '1.6rem',
          backgroundColor: isOpen ? 'blue' : 'transparent',
          borderBottom: '1px solid',
          borderColor: 'blueYonder15',
        },
        alignItems: 'center',
      }}
      buttonStyle={{
        width: '100%',
        justifyContent: 'space-between',
        py: '0.8rem',
        px: 0,
        fontSize: '1.8rem',
        fontWeight: 'bold',
        flex: 'auto',
      }}
      svgStyle={{
        backgroundColor: 'transparent',
        flexShrink: 0,
        '& svg': {
          flexShrink: 0,
        },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={setIsOpen}
    >
      <FocusLock whiteList={() => false}>
        <Flex
          sx={{
            width: ['100%', '45.2rem'],
            backgroundColor: 'transparent',
            flexDirection: 'column',
            gap: '1.2rem',
            py: '1.2rem',
          }}
        >
          <Flex
            data-testid="client-ids"
            sx={{
              width: '100%',
              flexDirection: 'column',
              px: '1.6rem',
            }}
          >
            <Input
              isCompact
              type="text"
              value={clientId}
              data-testid="client-ids-input"
              onChange={(e): void => {
                setAdvancedSettings({
                  ...advancedSettings,
                  clientId: clientIdMask(e.target.value),
                })
              }}
              tabIndex={0}
              placeholder="Optional client id ie. client-01"
              autoComplete="off"
              autoFocus={false}
              label="Client ID"
            />
            <Text variant="size10Weight400" sx={{ color: 'aliceBlue60', mt: '0.8rem' }}>
              Optionally supply a device client identifier.
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/device-clients`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                  ml: '0.5rem',
                }}
              >
                Learn more
              </ExternalLink>
            </Text>
          </Flex>
          <Flex
            data-testid="exclude-networks"
            sx={{
              width: '100%',
              flexDirection: 'column',
              px: '1.6rem',
            }}
          >
            <Text
              variant="size12Weight400"
              sx={{
                color: 'aliceBlue60',
                mb: '0.8rem',
              }}
            >
              Exclude Wi-Fi Networks
            </Text>
            <MultiSelectInput
              shouldAllowPaste
              ariaLabel="type SSID names"
              placeholder="Type SSID names here"
              value={excludedNetworks}
              setValue={setExcludedNetworks}
            />
            <Text variant="size10Weight400" sx={{ color: 'aliceBlue60', mt: '0.8rem' }}>
              Names of networks where {CONTROLDText} shouldn&apos;t be used.
            </Text>
          </Flex>
          <Divider sx={{ color: 'blueYonder30', m: 0 }} />
          <Flex
            data-testid="exclude-domains"
            sx={{
              width: '100%',
              flexDirection: 'column',
              px: '1.6rem',
            }}
          >
            <Text
              variant="size12Weight400"
              sx={{
                color: 'aliceBlue60',
                mb: '0.8rem',
              }}
            >
              Exclude Domains
            </Text>
            <MultiSelectInput
              shouldAllowPaste
              ariaLabel="type domain names"
              placeholder="Type domain names here"
              value={excludedDomains}
              setValue={setExcludedDomains}
              isError={domainLimitReached}
            />
            <Text
              variant="size10Weight400"
              sx={{ color: domainLimitReached ? 'errorOpaque' : 'aliceBlue60', mt: '0.8rem' }}
            >
              {domainLimitReached
                ? limitErrorMessage
                : `Domain names that should not be queried through ${CONTROLDText}.`}
            </Text>
            {sessionToken && (
              <SimpleDropdown
                ariaLabel="advanced settings"
                isSearchVisible
                items={[rootGroupOption, ...options]}
                hideRef={hideRef}
                isOpen={isOpen}
                defaultValue="Or choose folder"
                sx={{ mb: 0, mt: '1.2rem' }}
                contentMaxHeight="23rem"
              />
            )}
          </Flex>
          <Divider sx={{ color: 'blueYonder30', m: 0 }} />
          <SwitchBoxItem
            dataTestId="exclude-common-domains"
            checked={isCommonExclude}
            handleClick={checked => {
              setAdvancedSettings({
                ...advancedSettings,
                isCommonExclude: checked,
              })
            }}
          >
            <Text variant="size15Weight600" sx={{ color: 'aliceBlue60', mb: '1.2rem' }}>
              Exclude common domains
            </Text>
            <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
              Don&apos;t use {CONTROLDText} on common captive portals (plane wifi, etc).
            </Text>
          </SwitchBoxItem>
          {isOrganization && (
            <Flex sx={{ width: '100%', flexDirection: 'column', gap: '1.2rem' }}>
              <Divider sx={{ color: 'blueYonder30', m: 0 }} />
              <SwitchBoxItem
                dataTestId="prevent-disablement"
                checked={shouldPreventDisablement}
                handleClick={checked => {
                  setAdvancedSettings({
                    ...advancedSettings,
                    shouldPreventDisablement: checked,
                  })
                }}
              >
                <Text variant="size15Weight600" sx={{ color: 'aliceBlue60', mb: '1.2rem' }}>
                  Prevent Disablement
                </Text>
                <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
                  Prevent the DNS Profile from being removed,{' '}
                  <Text sx={{ fontWeight: 600 }}>
                    this only works in
                    <ExternalLink
                      to="https://support.apple.com/en-ca/102291"
                      sx={{
                        color: 'aliceBlue60',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        ml: '0.5rem',
                      }}
                    >
                      Supervised mode
                    </ExternalLink>
                    .
                  </Text>
                </Text>
              </SwitchBoxItem>
            </Flex>
          )}
          <Divider sx={{ color: 'blueYonder30', m: 0 }} />
          <SwitchBoxItem
            dataTestId="trust-root-ca"
            checked={shouldTrustRootCa}
            handleClick={checked => {
              setAdvancedSettings({
                ...advancedSettings,
                shouldTrustRootCa: checked,
              })
            }}
          >
            <Text variant="size15Weight600" sx={{ color: 'aliceBlue60', mb: '1.2rem' }}>
              Trust Control D Root CA
            </Text>
            <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
              Allows for custom block pages to be used.
              <ExternalLink
                to="https://support.apple.com/en-us/102390"
                sx={{
                  color: 'aliceBlue60',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                  ml: '0.5rem',
                }}
              >
                See additional steps.
              </ExternalLink>
            </Text>
          </SwitchBoxItem>
        </Flex>
      </FocusLock>
    </Accordion>
  )
}

function SwitchBoxItem({
  checked,
  handleClick,
  dataTestId,
  children,
}: {
  checked: boolean
  handleClick: (checked: boolean) => void
  dataTestId: string
  children: React.ReactNode
}): ReactElement {
  return (
    <Flex sx={{ justifyContent: 'space-between', px: '1.6rem' }}>
      <Flex sx={{ flexDirection: 'column', mr: '0.8rem' }}>{children}</Flex>
      <Switch
        isCompact
        ariaLabel={dataTestId}
        data-testid={dataTestId}
        checked={checked}
        onClick={(): void => {
          handleClick(!checked)
        }}
      />
    </Flex>
  )
}
