import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import range from 'lodash/range'
import chunk from 'lodash/chunk'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

type SizePerBreakpoint = [number, number, number]
type GapPerBreakpoint = [string, string, string]

interface PlaceholderGridProps {
  itemCount: number
  itemHeightsPx: SizePerBreakpoint
  placeholder: ReactElement | null
  itemsPerRow?: SizePerBreakpoint
  gap?: GapPerBreakpoint
}

export default function PlaceholderGrid({
  itemCount,
  itemHeightsPx,
  placeholder,
  itemsPerRow = [1, 1, 2],
  gap = ['1.6rem', '1.6rem', '2.4rem'],
}: PlaceholderGridProps): ReactElement | null {
  const breakpoint = useBreakpointIndex()

  if (breakpoint < 0) {
    return null
  }

  const columnCount = itemsPerRow[breakpoint]
  const rowHeight = itemHeightsPx[breakpoint]
  const columnGap = gap[breakpoint]

  return (
    <Flex sx={{ width: '100%', flexDirection: 'column' }}>
      {chunk([...range(itemCount)], columnCount).map((row, index) => (
        <Flex key={index} sx={{ width: '100%' }}>
          {row.map((key, index) => (
            <Flex
              key={key}
              sx={{
                width: '100%',
                height: rowHeight,
                padding: `0 ${
                  index === columnCount - 1 ? 0 : `calc(${columnGap} / 2)`
                } ${columnGap} ${index === 0 ? 0 : `calc(${columnGap} / 2)`}`,
              }}
            >
              {placeholder}
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  )
}
