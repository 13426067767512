import React, { useState } from 'react'
import { Flex, IconButton } from 'theme-ui'
import { Svg } from 'ui'
import MoreIcon from 'images/ui/more-icon.svg'
import CloseIcon from 'images/dashboard/customRules/action-menu-close-icon.svg'
import { ListItemActionMenuProps } from './ListItemActionMenu.interface'
import { useAppSelector } from 'store/hooks'
import {
  ruleLargeViewItemHeight,
  ruleSmallViewItemHeight,
} from 'components/Dashboard/Profiles/CustomRules/Views/CustomRule.desktop'

export default function ListItemActionMenu({
  children,
  menuBackgroundColor = 'darkItemBG',
  isShowMoreButtonDisabled = false,
  shouldShowListActionsMenu,
  setShouldShowListActionsMenu,
  blockReadMoreTabFocus,
}: ListItemActionMenuProps): React.ReactElement {
  const [showActionMenuInner, setShowActionMenuInner] = useState(false)
  const showActionMenu = shouldShowListActionsMenu ?? showActionMenuInner
  const setShowActionMenu = setShouldShowListActionsMenu ?? setShowActionMenuInner
  const shouldShowLargeView = useAppSelector(s => s.customRules.shouldShowLargeView)

  return (
    <Flex
      onBlur={(event): void => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowActionMenu(false)
        }
      }}
      sx={{
        display: ['flex', 'flex', 'none'],
        flexShrink: 0,
        alignItems: shouldShowLargeView ? ['flex-start', 'flex-start', 'center'] : 'center',
        height: [
          'auto',
          'auto',
          `calc(${shouldShowLargeView ? ruleLargeViewItemHeight : ruleSmallViewItemHeight})`,
        ],
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            transition: 'transform 0.4s ease-in-out',
            transform: `translateX(${showActionMenu ? 50 : 150}%)`,
            position: 'absolute',
            height: '100%',
            width: ['30rem', '50rem'],
            right: 0,
            alignItems: shouldShowLargeView ? 'flex-start' : 'center',
          }}
        >
          <Flex
            sx={{
              maskImage: `linear-gradient(
               to right,
               rgba(0, 0, 0, 1),
               rgba(0, 0, 0, 0)
           )`,
              position: 'absolute',
              zIndex: 'zIndex650',
              backgroundColor: menuBackgroundColor,
              transform: `rotate(180deg) translateX(100%)`,
              width: '25%',
              opacity: showActionMenu ? 1 : 0,
              transition: `opacity 0.2s ease`,
              alignItems: 'center',
            }}
          />
          <Flex
            sx={{
              display: showActionMenu ? 'flex' : 'none',
              width: '100%',
              mr: 'calc(50% - 4.2rem)',
              alignItems: 'center',
              outline: 'none',
              height: 'fit-content',
              backgroundColor: 'blue800',
              justifyContent: 'flex-end',
            }}
            data-testid="list-item-action-menu-children"
            aria-hidden={!showActionMenu}
          >
            <Flex sx={{ background: 'cardBg', flex: 1, justifyContent: 'flex-end' }}>
              {children}
              <IconButton
                data-testid="close-show-more-button"
                aria-label="Close Show More"
                onClick={(e): void => {
                  e.stopPropagation()
                  setShowActionMenu(false)
                }}
                tabIndex={showActionMenu ? 0 : -1}
              >
                <Svg
                  fill="aliceBlue"
                  svg={CloseIcon}
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                  }}
                  descriptiveText="Close icon"
                />
              </IconButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <IconButton
        data-testid="show-more-button"
        aria-label="Show more"
        as="button"
        onClick={(e): void => {
          e.stopPropagation()
          setShowActionMenu(true)
        }}
        sx={{
          width: 'fit-content',
          ml: '1.2rem',
          flexShrink: 0,
          ':disabled': {
            backgroundColor: 'transparent',
          },
        }}
        disabled={isShowMoreButtonDisabled}
        tabIndex={blockReadMoreTabFocus || showActionMenu ? -1 : 0}
      >
        <Svg
          fill={isShowMoreButtonDisabled ? 'aliceBlue' : 'aliceBlue60'}
          svg={MoreIcon}
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
          descriptiveText="More options icon"
        />
      </IconButton>
    </Flex>
  )
}
