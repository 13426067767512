import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import DialogTabButton from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog/DialogTabButton'

interface RedirectTypeHeaderProps {
  firstTabText: string
  firstTabIcon?: React.ElementType
  firstTabClick: () => void
  firstTabSelected: boolean
  firstTabDataTestId?: string
  secondTabText: string
  secondTabIcon?: React.ElementType
  secondTabClick: () => void
  secondTabDataTestId?: string
  secondTabSelected?: boolean
  commonInputRef?: React.MutableRefObject<HTMLInputElement | null>
}
export default function TrayTabbedHeader({
  firstTabText,
  firstTabClick,
  firstTabSelected,
  firstTabIcon,
  firstTabDataTestId,
  secondTabText,
  secondTabIcon,
  secondTabClick,
  secondTabDataTestId,
  commonInputRef,
}: RedirectTypeHeaderProps): ReactElement {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-around',
        px: '1.6rem',
        pt: '1.2rem',
        pb: !firstTabSelected && '1.2rem',
        gap: '0.8rem',
        borderBottom: !firstTabSelected && '1px solid',
        borderBottomColor: !firstTabSelected && 'blueYonder15',
      }}
    >
      <DialogTabButton
        onClick={firstTabClick}
        selected={firstTabSelected}
        icon={firstTabIcon}
        text={firstTabText}
        ariaLabel={firstTabText}
        sx={{
          flex: '1 1 50%',
          borderRadius: '0.8rem',
          border: '1px solid',
          borderColor: firstTabSelected ? 'littleBoyBlue' : 'transparent',
          p: '0.8rem',
          '&:hover': firstTabSelected ? {} : { backgroundColor: 'white6' },
        }}
        data-testid={firstTabDataTestId}
        inputRef={commonInputRef}
      />
      <DialogTabButton
        onClick={secondTabClick}
        selected={!firstTabSelected}
        text={secondTabText}
        ariaLabel={secondTabText}
        icon={secondTabIcon}
        sx={{
          flex: '1 1 50%',
          borderRadius: '0.8rem',
          border: '1px solid',
          borderColor: !firstTabSelected ? 'littleBoyBlue' : 'transparent',
          p: '0.8rem',
          '&:hover': !firstTabSelected ? {} : { backgroundColor: 'white6' },
        }}
        data-testid={secondTabDataTestId}
        inputRef={commonInputRef}
      />
    </Flex>
  )
}
