import React, { ReactElement, ReactNode } from 'react'
import { Svg } from 'ui'
import starIcon from 'images/organization/homepage/commonImages/star-3px.svg'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export const bigStarStyles = {
  width: '2.4rem',
  height: '2.4rem',
}

export default function StarsWrapper({
  children,
  starsSx,
  sx,
  containerSx,
}: {
  starsSx: ThemeUIStyleObject[]
  containerSx?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  children?: ReactNode
}): ReactElement {
  return (
    <Flex
      data-testid="stars"
      sx={{
        position: 'relative',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Flex sx={{ display: ['none', 'none', 'flex'], ...containerSx }}>
        {starsSx.map(starSx => (
          <Svg
            key={Object.entries(starSx).toString()}
            svg={starIcon}
            descriptiveText="Decorative star"
            sx={{
              position: 'absolute',
              zIndex: 'zIndex5',
              ...starSx,
            }}
          />
        ))}
      </Flex>
      {children}
    </Flex>
  )
}
