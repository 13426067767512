import React, { ReactElement } from 'react'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import OrganizationsAccordion from 'components/Organization/OrganizationNavBar/OrganizationAccordion'
import MyOrganizationIcon from 'images/organization/my-organization-icon.svg'
import SubOrganizationIcon from 'images/organization/sub-organizations-icon.svg'
import BillingIcon from 'images/dashboard/navBar/billing-icon.svg'
import ApiIcon from 'images/dashboard/navBar/api-icon.svg'
import AccountIcon from 'images/dashboard/navBar/my-account-icon.svg'
import { OrganizationNavRelativePath } from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import { useGetUnImpersonatedUserQuery, userApi } from 'store/api/user'
import customUnescape from 'utils/customUnescape'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'
import useQueryString from 'utils/useQueryString'

export default function OrganizationNavMenu(): ReactElement {
  const { isFetching, data } = useGetOrganization()
  const { qs } = useQueryString()
  const { data: userData } = userApi.endpoints.getUser.useQueryState('')
  const { data: unImpersonatedUser } = useGetUnImpersonatedUserQuery('', {
    skip: !qs.orgId && !userData?.org?.parent_org,
  })

  const isSubOrg = !!unImpersonatedUser?.org?.parent_org
  const permissionLevel = userData?.org?.permission?.level
  const isViewer = permissionLevel === OrgPermissionLevel.VIEWER

  return (
    <OrganizationsAccordion
      isFetching={isFetching}
      name={customUnescape(data?.organization.name)}
      isSubOrg={isSubOrg}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '0.1rem',
        }}
      >
        <NavMenuItem
          activeIcon={MyOrganizationIcon}
          relativePath={OrganizationNavRelativePath.MY_ORGANIZATION}
          label="My Organization"
          isSubItem
        />
        {!isSubOrg && (
          <NavMenuItem
            activeIcon={SubOrganizationIcon}
            relativePath={OrganizationNavRelativePath.SUB_ORGANIZATIONS}
            label="Sub-Organizations"
            isSubItem
          />
        )}
        {!isSubOrg && (
          <NavMenuItem
            activeIcon={BillingIcon}
            relativePath={OrganizationNavRelativePath.BILLING}
            label="Billing"
            isSubItem
          />
        )}
        {!isViewer && (
          <NavMenuItem
            activeIcon={ApiIcon}
            relativePath={OrganizationNavRelativePath.API}
            label="API"
            isSubItem
          />
        )}
        <NavMenuItem
          activeIcon={AccountIcon}
          relativePath={OrganizationNavRelativePath.ACCOUNT}
          label="Account"
          isSubItem
        />
      </Flex>
    </OrganizationsAccordion>
  )
}
