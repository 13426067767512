import React, { ReactElement, useRef } from 'react'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useAppSelector } from 'store/hooks'
import { Flex } from 'theme-ui'
import DashboardAlerts from './DashboardAlerts'

const paddingRight = ['3.2rem', '2.4rem', '2.4rem']

export default function DashboardAlertsContainer(): ReactElement | null {
  const { qs } = useQueryString()
  const breakpoints = useBreakpointIndex()
  const resizeableAlertContainerRef = useRef<HTMLDivElement>(null)
  const isModalDialogVisible = useAppSelector(s => s.dashboard.isModalDialogVisible)
  const isMobileTrayVisible = useAppSelector(s => s.dashboard.isMobileTrayVisible)
  const isSetupGuideVisible = useAppSelector(s => !!s.persistData.sessionToken) && qs.deviceId

  const shouldHideDashboardAlert =
    isSetupGuideVisible ||
    qs.profileDialog ||
    qs.deviceDialog ||
    qs.ctrldDeviceId ||
    qs.subOrgDialog ||
    qs.provisionDialog ||
    qs.billingDialog ||
    qs.reportsDialog ||
    isModalDialogVisible ||
    isMobileTrayVisible ||
    qs.memberModal

  return !shouldHideDashboardAlert ? (
    <Flex
      ref={resizeableAlertContainerRef}
      sx={{
        position: 'absolute',
        left: [`calc(${paddingRight[breakpoints]} / 2)`, 0],
        width: `calc(100% - ${paddingRight[breakpoints]})`,
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      <DashboardAlerts resizeableAlertContainerRef={resizeableAlertContainerRef} />
    </Flex>
  ) : null
}
