import React, { ReactElement, useState } from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { Flex, Text } from 'theme-ui'
import { Accordion, Svg } from 'ui'
import CopyToClipboard from 'components/CopyToClipboard'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import ExternalLinkIcon from 'images/profileManagement/external-link-icon.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { InvoiceState, OrganizationInvoice } from 'store/api/billing/payments.interface'
import { TableCell, TableRow } from 'ui/Table'
import { RowContainer } from 'components/Dashboard/Preferences/Api/TokenAccordionItem'
import { useAppSelector } from 'store/hooks'
import getFormattedPrice from 'utils/getFormattedPrice'
import { useGetProductsQuery } from 'store/api/billing'

export default function OrganizationInvoiceElement({
  invoice,
  tableHeaders,
}: {
  invoice: OrganizationInvoice
  tableHeaders: string[]
}): ReactElement {
  const breakpoint = useBreakpointIndex()

  const OrganizationInvoiceElement =
    breakpoint > 1 ? InvoiceElementDesktop : InvoiceElementMobileAndTablet

  const currentInvoiceState =
    invoice.method === 'credit card' && !invoice.iv_state ? invoice.tx_status : invoice.iv_state

  const invoiceState =
    {
      [InvoiceState.PAID]: 'Paid',
      [InvoiceState.PENDING]: 'Pending',
      [InvoiceState.CANCELLED]: 'Cancelled',
    }[currentInvoiceState] || ''

  return (
    <OrganizationInvoiceElement
      invoice={invoice}
      tableHeaders={tableHeaders}
      state={invoiceState}
    />
  )
}

const InvoiceElementDesktop = ({
  invoice,
  state,
}: {
  invoice: OrganizationInvoice
  state: string
}): ReactElement => {
  const { date, tx_id, amount, method, currency_amount, currency } = invoice
  useGetProductsQuery({})
  const currencies = useAppSelector(s => s.products.currencies)
  const invoiceCurrency = currencies.find(c => c.code === currency)

  return (
    <TableRow key={tx_id} dataTestId={`item-${date}`}>
      <TableCell>{date}</TableCell>
      <TableCell testId="invoices-cell-tid">
        <TextWithOverFlowAndTippyPopup
          content={tx_id}
          ariaLabel={tx_id}
          sxText={{ mr: '0.2rem' }}
        />
        <CopyToClipboard
          data={tx_id}
          smallIcon={true}
          ariaLabelText="Copy transaction ID to clipboard"
        />
      </TableCell>
      <TableCell testId="org-invoices-cell-method" sx={{ pl: '1.2rem' }}>
        {/* Converts payment method to Title Case so as to remain cohesive with Plan section */}
        {startCase(toLower(method))}
      </TableCell>
      <TableCell>
        {getFormattedPrice(invoiceCurrency, currency ? currency_amount : amount, true)}
      </TableCell>
      <TableCell testId="invoices-cell-status">{state}</TableCell>

      <TableCell sx={{ flex: '0 1 2.4rem' }}>
        <ViewInvoice tx_id={tx_id} />
      </TableCell>
    </TableRow>
  )
}

const InvoiceElementMobileAndTablet = ({
  invoice,
  tableHeaders,
  state,
}: {
  invoice: OrganizationInvoice
  tableHeaders: string[]
  state: string
}): ReactElement => {
  const { date, tx_id, method, currency_amount, amount, currency } = invoice
  useGetProductsQuery({})
  const currencies = useAppSelector(s => s.products.currencies)
  const invoiceCurrency = currencies.find(c => c.code === currency)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      key={tx_id}
      title={<Text>{date} &nbsp;</Text>}
      ariaLabelButtonName={`invoice - ${date} ${state}`}
      shouldUseSectionInAriaLabel={false}
      id={`item-${tx_id}`}
      openTitleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 1 }}
      titleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 0.6 }}
      buttonStyle={{
        p: '1.2rem',
        pb: isOpen ? '0.8rem' : '1.2rem',
        '& > div svg path': {
          opacity: isOpen ? 1 : 0.6,
        },
      }}
      fillColor="aliceBlue"
      containerStyle={{
        backgroundColor: 'cardBg',
        borderBottom: '1px solid',
        borderColor: 'blueYonder15',
        ':first-of-type': { borderTop: '1px solid', borderColor: 'blueYonder15' },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={isOpen => {
        setIsOpen(isOpen)
      }}
    >
      <Flex
        sx={{ width: '100%', px: '1.2rem', pb: '1.2rem', gap: '1.2rem', flexDirection: 'column' }}
      >
        <Flex sx={{ flexDirection: 'column', gap: '0.8rem', flex: 1 }}>
          <RowContainer>
            <Text
              variant="size15Weight700"
              sx={{
                display: 'inline-flex',
                flexShrink: 0,
                mr: '0.8rem',
              }}
            >
              {tableHeaders[1]}
            </Text>
            <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextWithOverFlowAndTippyPopup
                variant="size15Weight400"
                content={tx_id}
                ariaLabel={tx_id}
                sxText={{ opacity: 0.6 }}
              />
              <CopyToClipboard data={tx_id} smallIcon={true} />
            </Flex>
          </RowContainer>
          <RowContainer>
            <Text variant="size15Weight700" tabIndex={0}>
              {tableHeaders[2]}
            </Text>
            <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
              {startCase(toLower(method))}
            </Text>
          </RowContainer>
          <RowContainer sx={{ display: ['flex', 'none'] }}>
            <Text variant="size15Weight700" tabIndex={0}>
              {tableHeaders[3]}
            </Text>
            <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
              {getFormattedPrice(invoiceCurrency, currency ? currency_amount : amount, true)}
            </Text>
          </RowContainer>
          <RowContainer>
            <Text variant="size15Weight700" tabIndex={0}>
              {tableHeaders[4]}
            </Text>
            <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
              {state}
            </Text>
          </RowContainer>
          <RowContainer sx={{ display: ['flex', 'none'] }}>
            <ViewInvoice tx_id={tx_id} shouldDisplayText />
          </RowContainer>
        </Flex>
        <Flex sx={{ display: ['none', 'flex'], flexDirection: 'column', flex: 1, gap: '0.8rem' }}>
          <RowContainer>
            <Text variant="size15Weight700" tabIndex={0}>
              {tableHeaders[3]}
            </Text>
            <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
              {getFormattedPrice(invoiceCurrency, currency ? currency_amount : amount, true)}
            </Text>
          </RowContainer>
          <RowContainer
            sx={{
              justifyContent: ['center', 'flex-end'],
            }}
          >
            <ViewInvoice tx_id={tx_id} shouldDisplayText />
          </RowContainer>
        </Flex>
      </Flex>
    </Accordion>
  )
}

function ViewInvoice({
  tx_id,
  shouldDisplayText,
}: {
  tx_id: string
  shouldDisplayText?: boolean
}): ReactElement {
  return (
    // https://github.com/gatsbyjs/gatsby/issues/13825 cannot use <Link> here as we need to open in a new window
    <a
      href={`/invoice?id=${tx_id}`}
      target="_blank"
      rel="noreferrer"
      aria-label="Open invoice in new window"
      style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
      }}
    >
      {shouldDisplayText ? (
        <Text
          variant="size15Weight400"
          sx={{
            display: 'inline-flex',
            gap: '0.8rem',
            color: 'aliceBlue60',
            alignItems: 'center',
            '&:hover': {
              color: 'aliceBlue',
              'svg path': { fill: 'aliceBlue' },
            },
          }}
        >
          View Invoice
          <Svg
            svg={ExternalLinkIcon}
            fill="aliceBlue60"
            descriptiveText="Open invoice in new window"
          />
        </Text>
      ) : (
        <Svg
          svg={ExternalLinkIcon}
          fill="aliceBlue60"
          sx={{ '&:hover': { '& path': { fill: 'aliceBlue' } } }}
          descriptiveText="Open invoice in new window"
        />
      )}
    </a>
  )
}
