import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Switch } from 'ui'
import { Flex } from 'theme-ui'
import RuleActions from 'components/Dashboard/Profiles/RuleActions'
import ServiceCardContent from 'components/Dashboard/Profiles/Services/ServicesList/CardContent'
import ConfirmServiceWarning from 'components/Dashboard/Profiles/Services/ServicesList/ConfirmServiceWarning'
import { EnabledStatus, RuleType } from 'store/api/rules'
import { PutServiceActionRequest, ServiceData } from 'store/api/services'
import { useAppDispatch } from 'store/hooks'
import { SerializedError } from '@reduxjs/toolkit'
import { openProxyTray } from 'store/proxyTray'
import { ControlDError } from 'store/api/http'
import { setRuleTooltipSettings } from 'store/tutorial/tutorial'
import { useGetProxiesQuery } from 'store/api/proxies'
import { usePutServicesMutation } from 'store/api/services/services'
import ProfileOverrideIndicator from 'components/Dashboard/Profiles/ProfileOverrideIndicator'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'

export interface ServiceCardProps {
  service: ServiceData
  isHapticFeedbackEnabled: boolean
}

const ServiceCard = ({ service, isHapticFeedbackEnabled }: ServiceCardProps): ReactElement => {
  const dispatch = useAppDispatch()
  const [redirectButtonHover, setRedirectButtonHover] = useState(false)
  const activeAction =
    service.action?.ovr === EnabledStatus.ENABLED
      ? service.action
      : service.global?.action ?? service.action
  const isSwitchChecked = activeAction?.status === EnabledStatus.ENABLED
  const [confirmationPayload, setConfirmationPayload] = useState<PutServiceActionRequest | null>(
    null,
  )
  const [isToggleServiceRequestInFlight, setIsToggleServiceRequestInFlight] = useState(false)
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const [enabledServicePk, setEnabledServicePk] = useState('')
  const [putServiceAction] = usePutServicesMutation()
  const currentProfileId = useGetSelectedProfile()?.PK.toString()

  const handleServiceError = useCallback(
    (err: SerializedError) => {
      const error: ControlDError = JSON.parse(err.stack ?? '{}')
      if (error.metadata) {
        dispatch(openProxyTray({ rule: { ...service, locations: [error.metadata?.action.via] } }))
      }
    },
    [dispatch, service],
  )
  const handleActionButtonClick = useCallback(
    (ruleType: RuleType): void => {
      if (
        ruleType === service.action?.do &&
        service.action?.status === EnabledStatus.ENABLED &&
        !service.global
      ) {
        return
      }

      const dispatchPayload: PutServiceActionRequest = {
        PK: service.PK,
        do: ruleType,
        status: EnabledStatus.ENABLED,
        ovr: service.global ? EnabledStatus.ENABLED : undefined,
        // this will only be used by the redirect button if service is in off state
        ...(ruleType === RuleType.SPOOF_TAG
          ? { via: service.action?.via || service.unlock_location }
          : {}),
      }
      if (service.action?.status !== EnabledStatus.ENABLED && !!service.warning) {
        //wait for confirmation
        setConfirmationPayload(dispatchPayload)
      } else {
        setIsToggleServiceRequestInFlight(true)
        putServiceAction({ body: dispatchPayload, profileId: currentProfileId })
          .then(() => {
            dispatch(
              setRuleTooltipSettings({ userPk, selectedRuleType: ruleType ?? RuleType.BLOCK }),
            )
          })
          .catch(handleServiceError)
          .finally(() => {
            setIsToggleServiceRequestInFlight(false)
          })
      }
    },
    [
      currentProfileId,
      dispatch,
      handleServiceError,
      putServiceAction,
      service.PK,
      service.action?.do,
      service.action?.status,
      service.action?.via,
      service.global,
      service.unlock_location,
      service.warning,
      userPk,
    ],
  )

  const handleSwitchClick = useCallback((): void => {
    if (!isSwitchChecked) {
      setEnabledServicePk(service.PK)
    }

    const status = isSwitchChecked ? EnabledStatus.DISABLED : EnabledStatus.ENABLED
    let dispatchPayload: PutServiceActionRequest = {
      PK: service.PK,
      ...(isSwitchChecked ? {} : service.action ?? { do: RuleType.BLOCK }), // default for first use

      status,
    }

    if (service.global) {
      dispatchPayload = {
        PK: service.PK,
        ovr: EnabledStatus.ENABLED,
        status,
        do: activeAction?.do ?? RuleType.BLOCK,
        via: activeAction?.via,
      }
    }

    if (service.action?.status !== EnabledStatus.ENABLED && !!service.warning) {
      // wait for confirmation
      setConfirmationPayload(dispatchPayload)
    } else {
      setIsToggleServiceRequestInFlight(true)
      putServiceAction({ body: dispatchPayload, profileId: currentProfileId })
        .then(() => {
          dispatch(
            setRuleTooltipSettings({
              userPk,
              selectedRuleType: service.action?.do ?? RuleType.BLOCK,
            }),
          )
        })
        .catch(handleServiceError)
        .finally(() => {
          setIsToggleServiceRequestInFlight(false)
        })
    }
  }, [
    isSwitchChecked,
    service.PK,
    service.action,
    service.global,
    service.warning,
    activeAction?.do,
    activeAction?.via,
    putServiceAction,
    currentProfileId,
    handleServiceError,
    dispatch,
    userPk,
  ])

  const enableServiceAfterConfirm = useCallback(() => {
    if (confirmationPayload) {
      const timeout = setTimeout(() => setIsToggleServiceRequestInFlight(true), 500)
      putServiceAction({ body: confirmationPayload, profileId: currentProfileId }).finally(() => {
        setIsToggleServiceRequestInFlight(false)
        clearTimeout(timeout)
      })
      setConfirmationPayload(null)
    }
  }, [confirmationPayload, currentProfileId, putServiceAction])
  const cancelServiceActivation = useCallback(() => {
    setConfirmationPayload(null)
  }, [])

  const { data: proxiesData } = useGetProxiesQuery('')
  const proxyLocations = proxiesData?.proxies
  const serviceLocations = useMemo(() => {
    if (service.locations) {
      return proxyLocations
        ?.filter(l => !service.locations || service.locations.some(p => p === l.PK))
        .map((location, _, allLocations) => ({
          // mark the single locations in the filtered list
          ...location,
          isSingle: allLocations.filter(l => l.country === location.country).length === 1,
        }))
    }
    return proxyLocations
  }, [proxyLocations, service.locations])
  const redirectLocation = serviceLocations?.find(p => p.PK === activeAction?.via)
  const activeSwitch = (
    <Switch
      isCompact
      ariaLabel={`${isSwitchChecked ? 'disable' : 'enable'} ${service.name} service`}
      isLoading={isToggleServiceRequestInFlight}
      hasHapticFeedback={isHapticFeedbackEnabled}
      checked={isSwitchChecked}
      onClick={handleSwitchClick}
      needSaveFocus
      tabIndex={!!confirmationPayload ? -1 : 0}
      sxSwitchToggle={{
        backgroundColor: 'black',
      }}
      unCheckedColor="silverSpoon"
    />
  )
  const isOverrideEnabled = service.action?.ovr === EnabledStatus.ENABLED
  const onOverrideClick = useCallback(async (): Promise<void> => {
    if (!isOverrideEnabled) {
      return
    }
    setIsToggleServiceRequestInFlight(true)
    await putServiceAction({
      body: {
        ovr: EnabledStatus.DISABLED,
        status: EnabledStatus.DISABLED,
        do: undefined,
        PK: service.PK,
      },
      profileId: currentProfileId,
    })
    setIsToggleServiceRequestInFlight(false)
  }, [currentProfileId, isOverrideEnabled, putServiceAction, service.PK])
  const overrideWidget = service.global && (
    <ProfileOverrideIndicator
      isOverrideEnabled={isOverrideEnabled}
      onOverrideClick={onOverrideClick}
    />
  )
  const ruleActions = (
    <RuleActions
      rule={{
        ...service,
        action: activeAction ?? { do: RuleType.BLOCK, status: EnabledStatus.DISABLED },
      }}
      handleRuleTypeClick={handleActionButtonClick}
      setRedirectButtonHover={setRedirectButtonHover}
      redirectLocation={redirectLocation}
      isEnabledService={service.PK === enabledServicePk}
    />
  )
  return (
    <ServiceCardContent
      shouldShowWarning={!!confirmationPayload}
      service={service}
      dataTestId="services-list-item"
      redirectButtonHover={redirectButtonHover}
      warning={
        service.warning && (
          <ConfirmServiceWarning
            enable={enableServiceAfterConfirm}
            cancel={cancelServiceActivation}
            serviceWarningText={service.warning || ''}
            isVisible={!!confirmationPayload}
          />
        )
      }
      redirectLocation={redirectLocation}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: '1 1 auto',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            ml: '0.8rem',
            mb: '1.4rem',
          }}
        >
          {overrideWidget}
          {activeSwitch}
        </Flex>
        {ruleActions}
      </Flex>
    </ServiceCardContent>
  )
}

export default React.memo(ServiceCard)
