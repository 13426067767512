import React, { ReactElement } from 'react'
import { Flex, Text, Image } from 'theme-ui'
import useGetColorMode from 'utils/useGetColorMode'
import { Svg } from 'ui'
import SmallClose from 'images/dashboard/checklist/small-close.svg'
import WarningDark from 'images/dashboard/checklist/alert-closing-dialoge.png'
import WarningLight from 'images/dashboard/checklist/alert-closing-dialoge-light.png'
import ConfirmCheckmark from 'images/dashboard/checklist/confirm-checkmark.svg'

interface ChecklistConfirmDialogProps {
  onClose: () => void
  onConfirm: () => void
}

const ChecklistConfirmDialog = ({
  onClose,
  onConfirm,
}: ChecklistConfirmDialogProps): ReactElement => {
  const { isLightMode } = useGetColorMode()
  return (
    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
      <Flex sx={{ gap: '0.8rem' }}>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src={isLightMode ? WarningLight : WarningDark} />
        </Flex>
        <Flex sx={{ flexDirection: 'column', color: 'white', gap: '0.4rem' }}>
          <Text variant="size16Weight700">Are you sure?</Text>
          <Text variant="size11Weight400" sx={{ color: 'white70black70', whiteSpace: 'nowrap' }}>
            Closing this is permanent
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          gap: '0.8rem',
          mt: '0.4rem',
          'svg > g': {
            'g > path': {
              fill: 'white',
            },
            path: {
              fill: 'white',
            },
          },
        }}
      >
        <Svg
          descriptiveText={'keep checklist open'}
          svg={SmallClose}
          onClick={onClose}
          sx={{ cursor: 'pointer' }}
        />
        <Svg
          data-testid="confirm-closing-checklist"
          descriptiveText="confirm closing checklist"
          svg={ConfirmCheckmark}
          onClick={onConfirm}
          sx={{ cursor: 'pointer' }}
        />
      </Flex>
    </Flex>
  )
}

export default ChecklistConfirmDialog
