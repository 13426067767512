export default function isElementVisibleInViewport(element: HTMLElement | null): boolean {
  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    // rect.width is important for correct calculation on some mobile screen resolutions
    rect.right + rect.width <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
