import { useMemo } from 'react'
import differenceInDays from 'date-fns/differenceInDays'
import { useAppSelector } from 'store/hooks'

export function useShowChecklist(userDate?: Date | null): boolean {
  const hasClosedChecklist = useAppSelector(state => state.session.hasClosedChecklist)

  return useMemo(() => {
    if (!userDate) {
      return false
    }
    const daysSinceCreation = differenceInDays(new Date(), new Date(userDate))
    return daysSinceCreation <= 7 && !hasClosedChecklist
  }, [userDate, hasClosedChecklist])
}
