import React, { MutableRefObject } from 'react'
import { TextProps, Text } from 'theme-ui'
import useBreakpointIndex from './Theme/useBreakpointIndex'
import isArray from 'lodash/isArray'
import { ForwardRef } from '@theme-ui/components/src/types'

interface TextWithBreakpointVariantProps extends Omit<TextProps, 'variant'> {
  variant: string[] | string
  textRef?: MutableRefObject<HTMLDivElement | null>
}

// ForwardRef is important here, otherwise the tooltip in TextWithOverFlowAndTippyPopup is not displayed
export const TextWithBreakpointVariant: ForwardRef<HTMLDivElement, TextWithBreakpointVariantProps> =
  React.forwardRef(function TextWithBreakpointVariant(props, ref) {
    const { variant, ...rest } = props

    const breakpoint = useBreakpointIndex()
    const tabletBreakpoint = 1 // should use a tablet breakpoint when the variant array doesn't contain a 3rd element
    const breakpointVariant = isArray(variant)
      ? variant[breakpoint] || variant[tabletBreakpoint] || ''
      : variant

    return <Text {...rest} variant={breakpointVariant} ref={ref} />
  })
