import React, { ElementType, ReactElement, ReactNode, useRef, useState } from 'react'
import { TippyProps } from '@tippyjs/react'
import { ThemeUIStyleObject } from 'theme-ui'
import { ButtonVariant, Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { DropdownMenuItem } from '../DropdownMenuItem'
import FocusLock from 'react-focus-lock'
import ArrowDownIcon from 'images/organization/homepage/headerNavMenu/arrow-sm-down.svg'

export type DropdownItem = {
  header: string
  description?: string
  icon?: ElementType
  to?: string | undefined
  href?: string
}

export interface DropdownCommonProps {
  dropdownItems: DropdownItem[]
  children: ReactNode
  withArrow?: boolean
  tippyprops?: TippyProps
  sxContainer?: ThemeUIStyleObject
  testId?: string
}

export interface OrgHomepageDropdownBaseProps extends DropdownCommonProps {
  variant: ButtonVariant
  sx?: ThemeUIStyleObject
  sxContainer?: ThemeUIStyleObject
  sxButton?: ThemeUIStyleObject
  ariaLabel: string
}

export default function HomepageDropdownBase({
  dropdownItems,
  children,
  variant,
  withArrow = true,
  sx,
  sxContainer,
  sxButton,
  tippyprops,
  testId,
  ariaLabel,
}: OrgHomepageDropdownBaseProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()
  const dismiss = () => hideRef.current?.()
  const options: SimpleActionMenuItemProps[] = dropdownItems.map(
    ({ header, icon, description, to, href }) => ({
      ariaLabel: `${header} option`,
      children: (
        <DropdownMenuItem
          {...{
            header,
            icon,
            description,
            to,
            href,
            dismiss,
            testId: href,
          }}
        />
      ),
    }),
  )

  return (
    <Dropdown
      ariaLabel={ariaLabel}
      variant={variant}
      data-testid={testId}
      tippyprops={{
        arrow: true,
        placement: 'bottom',
        maxWidth: 'none',
        theme: 'orgs-homepage',
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onShow: () => setIsOpen(true),
        onHide: () => setIsOpen(false),
        ...tippyprops,
      }}
      dropdowncontent={
        isOpen ? (
          <FocusLock returnFocus={{ preventScroll: true }}>
            <SimpleActionMenu
              tabIndex={-1} // don't need to have focus on both the button and the links, just the link is enough
              hideRef={hideRef}
              items={options}
              sxContainer={{
                flexWrap: 'wrap',
                rowGap: '0',
                columnGap: '1.6rem',
                px: '0',
                ...sxContainer,
              }}
              customHoverStyles={{
                backgroundColor: 'darkItemBGHover10',
                svg: {
                  opacity: 1,
                },
                'svg path': {
                  opacity: 1,
                  strokeOpacity: 1,
                  fillOpacity: 0,
                },
              }}
              sxButton={{
                mx: '0.8rem',
                py: '1.2rem',
                px: '0.8rem',
                borderRadius: '0.8rem',
                '&:hover': {
                  ':not([disabled])': {
                    backgroundColor: 'lightSteelBlue10',
                  },
                  opacity: 1,
                  svg: {
                    opacity: 1,
                  },
                  'svg path': {
                    strokeOpacity: 1,
                  },
                },
                ...sxButton,
              }}
            />
          </FocusLock>
        ) : (
          <></>
        )
      }
      sx={{
        fontSize: '1.7rem',
        fontWeight: 500,
        gap: '0.8rem',
        ':not(.tippy-box)': {
          height: '3.9rem',
          display: 'flex',
          alignItems: 'center',
        },
        '> svg': {
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          fill: isOpen ? 'blue400' : 'lightSteelBlue',
          transition: 'transform 0.2s ease-out',
        },
        '@media screen and (max-width: 1190px)': {
          gap: '0.2rem',
        },
        ...sx,
      }}
    >
      {children}
      {withArrow && (
        <Svg
          svg={ArrowDownIcon}
          sx={{
            mb: '-0.2rem',
            ml: '0.4rem',
            flexShrink: 0,
          }}
          descriptiveText={isOpen ? 'Collapse dropdown menu' : 'Expand dropdown menu'}
        />
      )}
    </Dropdown>
  )
}
