import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'
import SubHeaderButton from 'components/Dashboard/SubHeader/SubHeaderButton'
import { SubHeaderName, SubHeaderPath } from 'components/Dashboard/SubHeader/SubHeaderButtonContent'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import FiltersIcon from 'images/subheader/FiltersIcon.svg'
import ServicesIcon from 'images/subheader/ServicesIcon.svg'
import CustomRulesIcon from 'images/subheader/CustomRulesIcon.svg'
import ProfileOptionsIcon from 'images/subheader/ProfileOptionsIcon.svg'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import { profilesApi } from 'store/api/profiles'

const SubHeader = ({ rightSideContent }: { rightSideContent?: ReactNode }): ReactElement => {
  const isMobile = useBreakpointIndex() === 0
  const profile = useGetSelectedProfile()
  const { isLoading: areProfilesLoading, isUninitialized } =
    profilesApi.endpoints.getProfiles.useQueryState('')
  const isLoading = isUninitialized || areProfilesLoading

  return (
    <Flex
      data-testid="dashboard-sub-header"
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        justifyContent: 'space-between',
      }}
      className={isMobile ? 'reset-tippy-width centered-header-tippy mobile' : ''}
    >
      <Flex
        sx={{
          flexShrink: 1,
          gap: '0.8rem',
          overflowX: 'auto',
          maxWidth: '100%',
          pb: ['0.8rem', '0.8rem', 0],
        }}
      >
        <SubHeaderButton
          text={SubHeaderName.FILTERS}
          path={SubHeaderPath.FILTERS}
          icon={FiltersIcon}
          count={profile?.profile?.flt?.count ?? 0}
          isLoading={isLoading}
        />
        <SubHeaderButton
          text={SubHeaderName.SERVICES}
          path={SubHeaderPath.SERVICES}
          icon={ServicesIcon}
          count={profile?.profile?.svc?.count ?? 0}
          isLoading={isLoading}
        />
        <SubHeaderButton
          text={SubHeaderName.CUSTOM_RULES}
          path={SubHeaderPath.CUSTOM_RULES}
          icon={CustomRulesIcon}
          count={profile?.profile?.rule?.count ?? 0}
          isLoading={isLoading}
        />
        <SubHeaderButton
          text={SubHeaderName.PROFILE_OPTIONS}
          path={SubHeaderPath.PROFILE_OPTIONS}
          icon={ProfileOptionsIcon}
          count={profile?.profile?.opt?.count ?? 0}
          isLoading={isLoading}
        />
      </Flex>
      <Flex
        sx={{
          position: ['static', 'static', 'absolute'],
          alignItems: 'center',
          right: 0,
          flexShrink: 0,
          pl: '0.8rem',
          height: 'fit-content',
        }}
      >
        {rightSideContent}
      </Flex>
    </Flex>
  )
}

export default SubHeader
