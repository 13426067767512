import React, { ReactElement } from 'react'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import { checkIfWithinInterval } from 'utils/getTimeSinceNow'

export enum DeviceAnalyticsStatus {
  ONLINE, // used in last 60 seconds
  OFFLINE, // not used in last 60 seconds
  UNAVAILABLE, // status not available
}

export const deviceStatusToColor: Record<DeviceAnalyticsStatus, string> = {
  [DeviceAnalyticsStatus.ONLINE]: 'greenApple',
  [DeviceAnalyticsStatus.OFFLINE]: 'pomegranate',
  [DeviceAnalyticsStatus.UNAVAILABLE]: 'white',
}

export default function LastUsedIndicator({
  deviceStatus,
  deviceStatusBadgeText,
  lastActiveTimeStamp,
  sx,
}: {
  deviceStatus: DeviceAnalyticsStatus
  deviceStatusBadgeText?: string
  lastActiveTimeStamp?: number
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Text
      data-testid="last-used-indicator"
      variant="size8Weight700"
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: '50%',
        top: '2rem',
        transform: 'translate(-50%, 0)',
        px: '0.4rem',
        height: '1.4rem',
        border: '1px solid',
        borderColor: 'darkBodyBG',
        color: 'darkBodyBG',
        borderRadius: '26px',
        backgroundColor: checkIfWithinInterval(lastActiveTimeStamp)
          ? 'banana'
          : deviceStatusToColor[deviceStatus],
        ...sx,
      }}
    >
      {deviceStatusBadgeText}
    </Text>
  )
}
