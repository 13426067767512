import React, { ReactElement } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { Button, Svg } from 'ui'
import LogoIcon from 'images/dashboard/billing/logo-icon.svg'
import { navigate } from 'gatsby'
import { TooltipWrapper } from 'ui/TooltipWrapper'

export default function UpgradeOrganizationPane({
  isSubscriptionActive,
  trialEnd,
  isExpired,
  isButtonDisabled,
}: {
  isSubscriptionActive: boolean
  trialEnd?: string
  isExpired?: boolean
  isButtonDisabled?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '5.4rem',
        flexDirection: ['column', 'row'],
        position: 'relative',
        borderRadius: [0, '1.6rem'],
        border: '1px solid',
        borderColor: 'white17',
        backgroundColor: 'blue600',
        overflow: 'hidden',
        alignItems: 'center',
        gap: '1.2rem',
        px: '1.2rem',
        py: '0.8rem',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
      }}
    >
      <Svg
        svg={LogoIcon}
        sx={{
          position: 'absolute',
          top: [0, 'unset'],
          bottom: ['unset', '-0.2rem'],
          left: 0,
          height: 'fit-content',
          '& path': {
            height: 'fit-content',
          },
        }}
        descriptiveText="Control D logo"
      />
      <Flex
        sx={{
          width: '100%',
          gap: [0, 0, '1.6rem'],
          alignItems: ['flex-start', 'flex-start', 'center'],
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Text variant="size15Weight700" sx={{ color: 'text' }}>
          {isExpired ? 'Your Trial Has Expired' : `Trial Expires on ${trialEnd}`}
        </Text>
        <Text
          variant="size15Weight500"
          sx={{
            color: 'muted',
          }}
        >
          {isSubscriptionActive
            ? 'You will be billed on this date.'
            : isExpired
            ? 'Please add a method of payment to continue using Control D.'
            : 'Add payment method to keep your account active.'}
        </Text>
      </Flex>
      {!isSubscriptionActive && (
        <TooltipWrapper
          text={
            <Text variant="size12Weight400">
              Please add at least 5 Connected Devices to start subscription
            </Text>
          }
          disabled={!isButtonDisabled}
        >
          <Flex sx={{ flexShrink: 0 }}>
            <Button
              data-testid="add-payment-method-button"
              disabled={isButtonDisabled}
              ariaLabel="navigate to change-payment-method page"
              variant="transparent"
              sx={{
                width: ['100%', 'fit-content'],
                flexShrink: 0,
                px: '1.6rem',
                backgroundColor: 'aliceBlue',
                borderRadius: '10rem',
                color: 'black',
                ':disabled': { color: 'commonAliceBlue30' },
              }}
              onClick={() => {
                navigate('/change-payment-method')
              }}
            >
              <Text variant="size15Weight700">Add Payment Method</Text>
            </Button>
          </Flex>
        </TooltipWrapper>
      )}
    </Flex>
  )
}
