import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'
import { Svg } from 'ui'
import ExternalLinkIcon from 'images/dashboard/external-link-icon.svg'
import ArrowIcon from 'images/dashboard/arrow-right.svg'
import ExternalLink from 'ui/ExternalLink/ExternalLink.base'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

interface MenuItemProps {
  to?: string
  content: string | ReactNode
  boundaryElement?: Element | 'parent'
  testId?: string
  startIcon?: React.ElementType
  hasExternalLinkIcon?: boolean
}
export function MenuItem({
  content,
  to,
  testId,
  startIcon,
  hasExternalLinkIcon = true,
}: MenuItemProps): ReactElement {
  return to ? (
    <ExternalLink to={to} sx={{ width: '100%' }}>
      <MenuItemContent
        hasExternalLinkIcon={hasExternalLinkIcon}
        content={content}
        testId={testId}
        startIcon={startIcon}
      />
    </ExternalLink>
  ) : (
    <MenuItemContent content={content} testId={testId} startIcon={startIcon} />
  )
}

interface MenuItemContentProps {
  content: string | ReactNode
  testId?: string
  hasExternalLinkIcon?: boolean
  startIcon?: React.ElementType
}
export function MenuItemContent({
  content,
  testId,
  hasExternalLinkIcon = false,
  startIcon,
}: MenuItemContentProps): ReactElement {
  return (
    <Flex
      data-testid={testId}
      sx={{
        position: 'relative',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
        {startIcon && (
          <Svg
            svg={startIcon}
            fill={testId?.includes('barry') ? undefined : 'aliceBlue'}
            sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
            descriptiveText="Menu Item Icon"
          />
        )}
        {typeof content === 'string' ? (
          <TextWithOverFlowAndTippyPopup
            variant="size15Weight500"
            sxText={{ maxWidth: '20rem' }}
            content={content}
            ariaLabel={content}
          />
        ) : (
          content
        )}
      </Flex>
      <Svg
        fill="aliceBlue"
        svg={hasExternalLinkIcon ? ExternalLinkIcon : ArrowIcon}
        sx={{
          width: '1.8rem',
          height: '1.8rem',
          flexShrink: 0,
          opacity: !!testId?.includes('barry') ? 0.6 : 1,
        }}
        descriptiveText={hasExternalLinkIcon ? 'External Link' : 'Navigate'}
      />
    </Flex>
  )
}
