import React, { ReactElement, ElementType } from 'react'
import { Spinner, Text } from 'theme-ui'
import { Svg } from 'ui'
import getFormattedNumber from 'utils/getFormattedNumber'
import Tippy from '@tippyjs/react'
import useGetColorMode from 'utils/useGetColorMode'

export enum SubHeaderName {
  FILTERS = 'Filters',
  SERVICES = 'Services',
  CUSTOM_RULES = 'Rules',
  PROFILE_OPTIONS = 'Profile Options',
}

export enum SubHeaderPath {
  FILTERS = 'filters',
  SERVICES = 'services',
  CUSTOM_RULES = 'custom-rules',
  PROFILE_OPTIONS = 'profile-options',
}

export const SubHeaderButtonContent = ({
  text,
  icon,
  count = 0,
  isLoading,
}: {
  text: string
  icon: ElementType
  count: number
  isLoading: boolean
}): ReactElement => {
  const { isLightMode } = useGetColorMode()

  return (
    <>
      <Svg svg={icon} descriptiveText={`${text} section icon`} fill="aliceBlue" />
      <Text variant="size15Weight600">{text}</Text>
      {isLoading ? (
        <Spinner
          color="aliceBlue"
          sx={{
            width: '1.4rem',
            height: '1.4rem',
          }}
        />
      ) : (
        <Tippy
          theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
          content={<Text sx={{ color: 'aliceBlue' }}>{count}</Text>}
          disabled={count < 1000}
          arrow={false}
          maxWidth={200}
        >
          <Text variant="size12Weight700Letter018" sx={{ mt: '0.1rem' }}>
            {getFormattedNumber(count)?.toLowerCase()}
          </Text>
        </Tippy>
      )}
    </>
  )
}
