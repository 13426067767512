import React, { ReactElement, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useAlertPresenter } from 'ui'
import { useAppDispatch } from 'store/hooks'
import {
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventName,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import useArrowNavForHelpPane from 'utils/useArrowKeyHelpPaneNavigation'
import { SetupGuidePaneTypes } from 'components/SetupGuide/SetupGuide.interface'
import useStepHelper from 'components/SetupGuide/useStepHelper'
import { ResolverStatus } from 'store/api/user/user.interface'
import { detectConfiguredDevice } from 'store/multiprofile'
import useOnClickOutside from 'utils/useOnClickOutside'
import ConfigurationPane from './Components/ConfigurationPane'
import WindowsVersionSelector from 'components/SetupGuide/Panes/WindowsVersionSelector'
import { freeDnsPagePath } from 'components/FreeDNSPage/FreeDNSPage'
import AppleTVVersionSelector from 'components/SetupGuide/Panes/AppleTVVersionSelector'
import { useLocation } from '@reach/router'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/CommonComponents/ShowAlertOnModalOrTrayWrapper'
import { useGetDevicesTypesQuery } from 'store/api/devices/devices'
const MacOSVersionSelector = React.lazy(
  () => import('components/SetupGuide/Panes/MacOSVersionSelector'),
)
const StepContainer = React.lazy(() => import('components/SetupGuide/Panes/StepContainer'))
const PlatformPane = React.lazy(() => import('components/SetupGuide/Panes/PlatformPane'))
const ShowDNS = React.lazy(() => import('components/SetupGuide/Panes/ShowDNS'))
const ShowUserDns = React.lazy(() => import('components/SetupGuide/Panes/ShowUserDns'))
export const troubleshootingAlertName = 'troubleshooting'

function SetupGuide(): ReactElement | null {
  const dispatch = useAppDispatch()
  const { trackEvent } = useTrackingHelper()
  const [shouldShowTray, setShouldShowTray] = useState(false)
  const location = useLocation()
  const { dismissAlert } = useAlertPresenter()
  const { goToPreviousStep, goToNextStep, configuredStatus, closePane, qs } = useStepHelper()

  const isFreeDNS = location.pathname.includes(freeDnsPagePath)

  useGetDevicesTypesQuery('')

  // tray should be visible only after the first render, otherwise the tray is not displaying after page reload
  useEffect(() => {
    setShouldShowTray(true)
  }, [setShouldShowTray])

  useEffect(() => {
    if (!!qs.helpPane) {
      trackEvent({
        category: TrackingEventCategory.Nav,
        action: TrackingEventAction.Tutorial,
        name: qs.setupOs?.toLowerCase() as TrackingEventName,
      })
    }
  }, [qs.helpPane, trackEvent, qs.setupOs])

  useEffect(() => {
    // only do this when the help pane is actually open to avoid duplicating configured device detection api calls
    if (!!qs.helpPane && configuredStatus === ResolverStatus.VERIFIED) {
      dispatch(detectConfiguredDevice())
      dismissAlert(troubleshootingAlertName)
    }
  }, [configuredStatus, dismissAlert, dispatch, qs.helpPane])

  useArrowNavForHelpPane({
    onLeftArrowPress: goToPreviousStep,
    onRightArrowPress: goToNextStep,
    onClose: closePane,
    isButtonVisible: !!qs.step,
    isConfigured: configuredStatus === ResolverStatus.VERIFIED,
  })

  const currentPane = useMemo(() => {
    switch (qs.helpPane) {
      case SetupGuidePaneTypes.DNS:
        return isFreeDNS ? <ShowDNS /> : <ShowUserDns />
      case SetupGuidePaneTypes.Platform:
        return <PlatformPane />
      case SetupGuidePaneTypes.MacVersion:
        return <MacOSVersionSelector />
      case SetupGuidePaneTypes.WindowsVersion:
        return <WindowsVersionSelector />
      case SetupGuidePaneTypes.AppleTVVersion:
        return <AppleTVVersionSelector />
      case SetupGuidePaneTypes.Configure:
        return <ConfigurationPane />
      default:
        return qs.helpPane && <StepContainer /> //return undefined if helppane is not defined
    }
  }, [isFreeDNS, qs.helpPane])
  const alertPresenterContainerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside([alertPresenterContainerRef], () => {
    dismissAlert(troubleshootingAlertName)
  })

  return shouldShowTray ? (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.helpPane}>
      <Suspense>{currentPane}</Suspense>
    </ShowAlertOnModalOrTrayWrapper>
  ) : null
}

export default SetupGuide
