import React, { ReactElement } from 'react'
import { SelectVersionPane } from 'components/SetupGuide/Components'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { Button } from 'ui'
import useQueryString from 'utils/useQueryString'
import SetupGuideDialog from '../Components/SetupGuideDialog'
import { Flex } from '@theme-ui/components'

export default function WindowsVersionSelector(): ReactElement {
  const { setupGuideNav, qs } = useQueryString()

  return (
    <SetupGuideDialog title={`Windows - ${qs.mode}`} isLeftContentVisible isRightContentVisible>
      <SelectVersionPane>
        <Flex sx={{ width: '18.5rem', flexDirection: 'column', gap: '2.4rem' }}>
          <Button
            variant="newSecondary"
            ariaLabel="windows 11"
            sx={{
              width: '100%',
              height: '3.8rem',
            }}
            onClick={(): void => {
              setupGuideNav({
                ...qs,
                helpPane: SetupOs.WINDOWS_11,
                setupOs: SetupOs.WINDOWS_11,
                mode: qs.mode,
                step: '0',
              })
            }}
          >
            Windows 11
          </Button>
          <Button
            variant="newSecondary"
            ariaLabel="windows 10 or older"
            sx={{
              height: '3.8rem',
            }}
            onClick={(): void => {
              setupGuideNav({
                ...qs,
                helpPane: SetupOs.WINDOWS_10_OR_OLDER,
                setupOs: SetupOs.WINDOWS_10_OR_OLDER,
                mode: qs.mode,
                step: '0',
              })
            }}
          >
            Windows 10 or older
          </Button>
        </Flex>
      </SelectVersionPane>
    </SetupGuideDialog>
  )
}
