import React, { ReactElement, useRef } from 'react'
import { ExternalLink, Svg } from 'ui'
import GlobalProfileEnforcedIcon from 'images/dashboard/global-profile-enforced.svg'
import GlobalProfileOverridenIcon from 'images/dashboard/global-profile-overriden.svg'
import Tippy from '@tippyjs/react'
import { Flex, Text } from 'theme-ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import useGetColorMode from 'utils/useGetColorMode'

export default function ProfileOverrideIndicator({
  isOverrideEnabled,
  onOverrideClick,
}: {
  isOverrideEnabled: boolean
  onOverrideClick: () => void
}): ReactElement {
  const hideRef = useRef<() => void>()
  const { isLightMode } = useGetColorMode()

  useHideTooltipOnScroll(hideRef, scrollingContainerRef)

  return (
    <Tippy
      onCreate={instance => {
        hideRef.current = () => {
          instance.hide()
        }
      }}
      content={
        <Flex sx={{ p: '0.2rem' }}>
          <Text sx={{ overflowWrap: 'break-word', flexWrap: 'wrap', color: 'aliceBlue' }}>
            {isOverrideEnabled
              ? 'You are overriding the inherited setting from the Global Profile. '
              : 'This setting is inherited from the Global Profile. '}
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/global-profile#conflicts-and-overrides`}
              sx={{
                color: 'aliceBlue60',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                ml: '0.5rem',
              }}
            >
              Learn more
            </ExternalLink>
          </Text>
        </Flex>
      }
      arrow={false}
      theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
      touch="hold"
      interactive={true}
      maxWidth={300}
    >
      <Flex>
        {isOverrideEnabled ? (
          <Svg
            key="override"
            data-testid="global-profile-overridden"
            descriptiveText="Click to stop overriding global profile setting"
            svg={GlobalProfileOverridenIcon}
            sx={{
              my: 'auto',
              mr: '1.2rem',
              cursor: 'pointer',
            }}
            onClick={onOverrideClick}
          />
        ) : (
          <Svg
            key="global"
            data-testid="global-profile-active"
            descriptiveText="Global profile setting is enforced"
            svg={GlobalProfileEnforcedIcon}
            sx={{
              my: 'auto',
              mr: '1.2rem',
            }}
          />
        )}
      </Flex>
    </Tippy>
  )
}
