import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui'

const SettingsItem = ({
  icon,
  title,
  description,
  descriptionLink,
  expandedContent,
  warningMessage,
  actionContent,
  sx,
  testId,
  isTopBorder = true,
}: {
  icon?: ElementType
  title: string
  description: string
  descriptionLink?: ReactNode
  expandedContent?: ReactNode
  warningMessage?: string
  actionContent?: ReactNode
  sx?: ThemeUIStyleObject
  testId?: string
  isTopBorder?: boolean
}): ReactElement => {
  return (
    <Flex
      data-testid={testId}
      sx={{
        width: '100%',
        py: '1.2rem',
        borderTop: '1px solid',
        borderTopColor: isTopBorder ? 'blueYonder15' : 'transparent',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {warningMessage && (
        <Flex
          sx={{
            borderRadius: '0.8rem',
            backgroundColor: 'banana25',
            color: 'banana',
            justifyContent: 'center',
            alignItems: 'center',
            py: '0.8rem',
            mx: '1.6rem',
            mb: '1.2rem',
          }}
        >
          <Text variant="size12Weight400">{warningMessage}</Text>
        </Flex>
      )}
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: '1.6rem',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            gap: '1.2rem',
          }}
        >
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Flex sx={{ alignItems: 'center' }}>
              {icon && (
                <Svg
                  fill="aliceBlue"
                  svg={icon}
                  sx={{ width: '2rem', height: '2rem', mr: '1.2rem' }}
                  descriptiveText={`${title} section icon`}
                />
              )}
              <Text variant="size15Weight700" sx={{ color: 'aliceBlue' }}>
                {title}
              </Text>
            </Flex>
            <Flex sx={{ flexShrink: 0 }}>{actionContent}</Flex>
          </Flex>
          <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
            {description} {descriptionLink}
          </Text>
        </Flex>
      </Flex>
      {expandedContent && <Flex sx={{ mt: '1.6rem', px: '1.6rem' }}>{expandedContent}</Flex>}
    </Flex>
  )
}

export default SettingsItem
