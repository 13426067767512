import React, { FC } from 'react'
import { Router } from '@reach/router'
import { AlertContextProvider, TrayContextProvider } from 'ui'
import PrivateRoute from '../PrivateRoute'
import DashboardPage from 'components/Dashboard'

/**
 * A layout component that provides a private base route for the dashboard.
 *
 * It is implemented as a layout component rather than a page to avoid full unmounting of
 * the dashboard on navigation between panes.
 */

const DashboardWrapper: FC = () => (
  <AlertContextProvider>
    <TrayContextProvider>
      <Router basepath="/dashboard">
        <PrivateRoute component={DashboardPage} path="/*" />
      </Router>
    </TrayContextProvider>
  </AlertContextProvider>
)

export default DashboardWrapper
