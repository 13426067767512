import React, { ElementType, ReactElement, useCallback, useState } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { ProfileDownloadButton } from '../index'
import useMobileConfigUrl from 'components/SetupGuide/useMobileConfigUrl'
import useQueryString from 'utils/useQueryString'
import { Divider } from 'theme-ui'
import CopyableResolverId from './CopyableResolverId'
import CopyToClipboard from 'components/CopyToClipboard'
import {
  SetupGuideIntermediateStates,
  SetupGuideMode,
  SetupOs,
} from 'components/SetupGuide/SetupGuide.interface'
import { BASE_URL, DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import DividerWithText from 'ui/DividerWithText'
import { Button, ExternalLink, Svg } from 'ui'
import BinaryVariantButtons from './BinaryVariantButtons'
import CmdIcon from 'images/helpPaneSteps/cmd.svg'
import PowershallIcon from 'images/helpPaneSteps/powershall.svg'
import AppStoreIcon from 'images/helpPaneSteps/app-store.svg'
import GooglePlayIcon from 'images/helpPaneSteps/google-play.svg'
import AutomaticQrCode from './AutomaticQrCode'

export default function AutomaticProfileDownload({
  resolverUid,
  children,
}: {
  resolverUid: string
  children?: ReactElement | ReactElement[]
}): ReactElement {
  const { setupGuideNav, qs } = useQueryString()
  const [isCmd, setIsCmd] = useState(true)
  const isMac = qs.setupOs === SetupOs.MAC_OS
  const isWindows = qs.setupOs === SetupOs.WINDOWS_OS
  const isIos = qs.setupOs === SetupOs.IOS_OS
  const isAndroid = qs.setupOs === SetupOs.ANDROID_OS
  const shouldHideTerminal = isIos || isAndroid
  const shouldHideDownloadPart = [
    SetupOs.LINUX_OS,
    SetupOs.ROUTER,
    SetupOs.PF_SENSE,
    SetupOs.OPN_SENSE,
  ].includes(qs.setupOs as SetupOs)

  const shouldShowGenerateDnsProfileButton = [SetupOs.IOS_OS, SetupOs.MAC_OS].includes(
    qs.setupOs as SetupOs,
  )
  const isRouter = qs.setupOs?.includes(SetupOs.ROUTER) && qs.setupOs !== SetupOs.WINDOWS_SERVER
  const curl =
    qs.helpPane?.includes('windows') || qs.helpPane === SetupOs.WINDOWS_SERVER
      ? qs.helpPane?.includes('windows') && isCmd
        ? `powershell -Command "(Invoke-WebRequest -Uri '${BASE_URL}/dl' -UseBasicParsing).Content | Set-Content '%TEMP%\\ctrld_install.bat'" && %TEMP%\\ctrld_install.bat ${resolverUid} forced`
        : `(Invoke-WebRequest -Uri '${BASE_URL}/dl/ps1' -UseBasicParsing).Content | Set-Content "$env:TEMP\ctrld_install.ps1"; Invoke-Expression "& '$env:TEMP\ctrld_install.ps1' '${resolverUid}' 'forced'"`
      : qs.helpPane?.includes('pfsense') || qs.helpPane?.includes('opnsense')
      ? `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -- -s ${resolverUid} forced'`
      : `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -s ${resolverUid} forced'`

  const onGenerateProfileClick = useCallback(() => {
    setupGuideNav({
      ...qs,
      helpPane: isMac ? SetupGuideIntermediateStates.MacVersion : SetupOs.IOS,
      mode: SetupGuideMode.MANUAL,
      setupOs: isMac ? SetupOs.MAC : SetupOs.IOS,
    })
  }, [isMac, qs, setupGuideNav])

  const url = useMobileConfigUrl()

  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:
          (shouldHideTerminal || shouldHideDownloadPart) && !isIos ? 'center' : 'flex-start',
        textAlign: 'center',
        gap: '4rem',
        px: ['1.2rem', 0],
      }}
    >
      {!shouldHideDownloadPart && (
        <Flex
          sx={{
            width: ['100%', '40rem'],
            flexDirection: 'column',
            gap: '4rem',
            alignItems: 'center',
          }}
        >
          <Text
            variant="size14Weight400Line138"
            sx={{
              display: 'inline-flex',
              gap: '1.2rem',
              textAlign: 'left',
              maxWidth: '32.4rem',
              color: 'aliceBlue',
            }}
          >
            <NumberBadge value={1} />{' '}
            {qs.deviceId && isIos
              ? 'Download the Control D setup App from the App store'
              : qs.deviceId && isAndroid
              ? 'Download the Control D setup App from the Google Play Store'
              : 'Click the button below to download the setup utility and open it.'}
          </Text>
          <Flex
            sx={{
              justifyContent: 'center',
            }}
          >
            {isMac || isWindows ? (
              <BinaryVariantButtons isMac={isMac} />
            ) : isIos ? (
              <ExternalLink
                data-testid="ios-badge-link"
                to="https://apps.apple.com/us/app/control-d-quick-setup/id1518799460"
              >
                <Svg svg={AppStoreIcon} descriptiveText="App Store icon" />
              </ExternalLink>
            ) : isAndroid ? (
              <ExternalLink
                data-testid="android-badge-link"
                to="https://play.google.com/store/apps/details?id=com.controld.setuputility&pli=1"
              >
                <Svg svg={GooglePlayIcon} descriptiveText="Google Play icon" />
              </ExternalLink>
            ) : (
              <ProfileDownloadButton sx={{ width: '25.8rem' }} url={url}>
                Download Setup Utility
              </ProfileDownloadButton>
            )}
          </Flex>
          <Text
            variant="size14Weight400Line138"
            sx={{
              display: 'inline-flex',
              gap: '1.2rem',
              textAlign: 'left',
              maxWidth: '32.4rem',
              color: 'aliceBlue',
            }}
          >
            <NumberBadge value={2} />{' '}
            {(isIos || isAndroid) && qs.deviceId
              ? 'Copy and paste the Resolver ID below or scan the QR code using the app.'
              : 'Copy the Resolver ID below, paste it into the utility app and press Configure.'}
          </Text>
          <CopyableResolverId text={resolverUid} />
          {(isIos || isAndroid) && qs.deviceId && <AutomaticQrCode deviceId={qs.deviceId} />}
        </Flex>
      )}

      {!shouldHideDownloadPart && !shouldHideTerminal && <DividerWithText text="OR" />}

      {!shouldHideTerminal && (
        <>
          <Text variant="size14Weight400Line138" sx={{ maxWidth: '28.2rem', color: 'aliceBlue' }}>
            Copy this command and paste it into your admin terminal.
          </Text>

          {qs.helpPane?.includes('windows') && (
            <Flex sx={{ gap: '0.8rem' }}>
              <TerminalTypeButton
                icon={CmdIcon}
                text="CMD"
                isActive={isCmd}
                onClick={() => setIsCmd(true)}
              />
              <TerminalTypeButton
                icon={PowershallIcon}
                text="Powershell"
                isActive={!isCmd}
                onClick={() => setIsCmd(false)}
              />
            </Flex>
          )}

          <Flex
            data-testid="terminal-container"
            sx={{
              width: ['100%', '39rem'],
              backgroundColor: 'white6',
              p: '1.6rem',
              borderRadius: '1.2rem',
              textAlign: 'left',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Text variant="size12Weight500" sx={{ color: 'aliceBlue' }}>
              {curl}
            </Text>
            <CopyToClipboard smallIcon data={curl} fillSvg="aliceBlue" />
          </Flex>
        </>
      )}

      {shouldShowGenerateDnsProfileButton && (
        <>
          <DividerWithText text="OR" />
          <Button
            ariaLabel="generate DNS profile"
            variant="newSecondary"
            sx={{
              height: '3.8rem',
            }}
            onClick={onGenerateProfileClick}
          >
            Generate DNS Profile
          </Button>
        </>
      )}
      {isRouter && (
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            gap: ['2.4rem', '3.2rem'],
            alignItems: 'center',
            mt: '2.4rem',
          }}
        >
          <Divider sx={{ width: '100%', color: 'white15', mt: '0.8rem' }} />
          <Text
            variant="size14Weight400Line138"
            sx={{
              color: 'aliceBlue',
            }}
          >
            Alternatively, download and deploy the binary manually.
          </Text>
          <Button
            variant="newSecondary"
            ariaLabel="open provisioning doc"
            sx={{
              height: '3.8rem',
            }}
            onClick={() => {
              window.open(`${DOCS_CONTROL_D_DOMAIN}/docs/ctrld`, '_blank', 'noopener, noreferrer')
            }}
          >
            View Documentation
          </Button>
        </Flex>
      )}

      {children}
    </Flex>
  )
}

export function NumberBadge({ value }: { value: number }): ReactElement {
  return (
    <Text
      variant="size14Weight700Line138"
      sx={{
        display: 'inline-flex',
        width: '3.2rem',
        height: '3.2rem',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderColor: 'blueYonder30',
        borderRadius: '100px',
        color: 'aliceBlue',
        flexShrink: 0,
      }}
    >
      {value}
    </Text>
  )
}

function TerminalTypeButton({
  icon,
  isActive,
  text,
  onClick,
}: {
  icon: ElementType
  isActive: boolean
  text: string
  onClick: () => void
}) {
  return (
    <Button
      ariaLabel={text}
      data-testid={`${text}-tab`}
      variant="transparent"
      sx={{
        px: '1.2rem',
        height: '3.3rem',
        border: '1px solid',
        borderColor: isActive ? 'littleBoyBlue' : 'transparent',
        borderRadius: '1.2rem',
        gap: '1.2rem',
        color: isActive ? 'aliceBlue' : 'aliceBlue60',
        svg: {
          opacity: isActive ? 1 : 0.6,
        },
        '&:hover': {
          color: 'aliceBlue',
          svg: {
            opacity: 1,
          },
        },
      }}
      onClick={onClick}
    >
      <Svg svg={icon} fill="white" descriptiveText={`${text} terminal icon`} />
      <Text variant="size15Weight700">{text}</Text>
    </Button>
  )
}
