import React, {
  ElementType,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Dropdown, InputSearch, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import { Label } from '@theme-ui/components'
import InfoIcon from 'images/dashboard/info-icon.svg'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import useBoundaryElement from 'utils/useBoundaryElement'
import Fuse from 'fuse.js'
import { defaultFuseOptions, Setter } from 'utils'
import DropdownIcon from 'images/profileManagement/context-menu-arrow-down.svg'
import useGetColorMode from 'utils/useGetColorMode'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function SimpleDropdown({
  disabled = false,
  label,
  items,
  hideRef,
  isOpen,
  sx,
  sxDropdown,
  sxDropdownButton,
  sxDropdownContent,
  sxArrowIcon,
  testId,
  defaultValue = '-',
  tooltipText,
  contentMaxHeight,
  mobileContentWidth,
  arrowIcon,
  arrowFill,
  shouldRotateArrow = true,
  theme,
  ariaLabel,
  boundaryElementTestId,
  isSearchVisible,
  sxLabelAndTooltip,
  isOptional,
  sxTooltipContainer,
}: {
  disabled?: boolean
  label?: string | ReactElement
  items: SimpleActionMenuItemProps[]
  sx?: ThemeUIStyleObject
  sxDropdown?: ThemeUIStyleObject
  sxDropdownButton?: ThemeUIStyleObject
  sxDropdownContent?: ThemeUIStyleObject
  sxArrowIcon?: ThemeUIStyleObject
  hideRef: React.MutableRefObject<(() => void) | undefined>
  isOpen?: boolean
  testId?: string
  defaultValue?: string | ReactElement
  tooltipText?: string
  contentMaxHeight?: string
  mobileContentWidth?: string
  arrowIcon?: ElementType
  arrowFill?: string
  shouldRotateArrow?: boolean
  theme?: string
  ariaLabel: string
  boundaryElementTestId?: string
  isSearchVisible?: boolean
  sxLabelAndTooltip?: ThemeUIStyleObject
  isOptional?: ReactNode
  sxTooltipContainer?: ThemeUIStyleObject
}): ReactElement {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const buttonContentRef = useRef<HTMLButtonElement>(null)
  const selectedItem = items.find(item => item.isSelected)
  const boundaryElement = useBoundaryElement(boundaryElementTestId || 'parent')
  const [searchText, setSearchText] = useState('')
  const [hoverIndex, setHoverIndex] = useState(0)
  const hasInputFocus = hoverIndex === -1
  const { isLightMode } = useGetColorMode()

  const searchOptions = useMemo((): SimpleActionMenuItemProps[] => {
    const fuzzySearchList = new Fuse(items, {
      ...defaultFuseOptions,
      keys: ['searchBy'],
    })
    if (!searchText) {
      return items
    }
    return fuzzySearchList.search(searchText).map(matches => matches.item)
  }, [items, searchText])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {(label || tooltipText || isOptional) && (
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: '0.8rem',
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              gap: '0.8rem',
              ...sxLabelAndTooltip,
            }}
          >
            {label && (
              <Label
                sx={{
                  fontSize: '1.2rem',
                  color: isDropdownOpened ? 'aliceBlue' : 'aliceBlue60',
                }}
              >
                {label}
              </Label>
            )}
            {tooltipText && (
              <IconWithTippyPopup
                placement="top"
                content={
                  <Flex
                    sx={{
                      alignSelf: 'center',
                      textAlign: 'center',
                      fontSize: '1.2rem',
                      ...sxTooltipContainer,
                    }}
                  >
                    {tooltipText}
                  </Flex>
                }
                ariaLabel={tooltipText}
                svg={InfoIcon}
                fill="aliceBlue60"
                sx={{
                  width: '1.2rem',
                  height: '1.2rem',
                }}
                descriptiveText={tooltipText}
              />
            )}
          </Flex>
          {isOptional && (
            <Text variant="size12Weight400" sx={{ color: 'aliceBlue30' }}>
              Optional
            </Text>
          )}
        </Flex>
      )}
      <Dropdown
        ariaLabel={ariaLabel}
        disabled={disabled}
        buttonRef={buttonContentRef}
        data-testid={testId}
        variant="simple"
        onKeyDown={stopPropagationOnKeyPress}
        sx={{
          minWidth: '6.8rem',
          minHeight: '3.8rem',
          width: '100%',
          height: 'auto',
          border: '1px solid',
          borderColor: 'blueYonder40',
          borderRadius: '0.8rem',
          background: 'blue',
          '&:not(.tippy-box):hover': {
            ':not([disabled])': {
              ':not(:focus)': {
                borderColor: 'blueYonder80',
              },
            },
          },
          ':disabled': {
            cursor: 'auto',
            borderColor: 'blueYonder15',
            backgroundColor: 'blueYonder10',
            color: 'aliceBlue30',
            span: {
              color: 'aliceBlue30',
            },
          },
          ...sxDropdown,
        }}
        tippyprops={{
          zIndex: 240, // same as ModalDialogHeader
          appendTo: boundaryElement,
          placement: 'bottom-start',
          animation: 'fade',
          onCreate: instance => {
            hideRef.current = () => {
              instance.hide()
            }
          },
          onHide() {
            setIsDropdownOpened(false)
            setSearchText('')
          },
          onTrigger() {
            setIsDropdownOpened(true)
          },
          theme: theme || isLightMode ? 'light-org-dropdown' : 'org-dropdown',
          maxWidth: '100%',
        }}
        dropdowncontent={
          <Flex
            className="hide-scrollbar"
            data-testid={`${testId}-content`}
            sx={{
              position: 'relative',
              flexDirection: 'column',
              width: [
                mobileContentWidth || buttonContentRef.current?.clientWidth,
                buttonContentRef.current?.clientWidth,
              ],
              maxHeight: contentMaxHeight || 'calc(50vh)',
              my: '1rem',
              borderRadius: '1.2rem',
              backgroundColor: 'transparent',
              px: '0.4rem',
              m: 0,
              ...sxDropdownContent,
            }}
          >
            {isSearchVisible && (
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                hasInputFocus={hasInputFocus}
                setHoverIndex={setHoverIndex}
                isOpen={isOpen || isDropdownOpened}
                onKeyDown={e => {
                  if (e.key === 'Enter' && searchOptions.length === 1) {
                    searchOptions[0]?.onClick?.()
                  }
                }}
              />
            )}
            <SimpleActionMenu
              hideRef={hideRef}
              isOpen={isOpen || isDropdownOpened}
              items={searchOptions}
              className={`hide-scrollbar ${isLightMode ? 'light' : 'dark'}`}
              sxContainer={{
                px: 0,
                py: '0.4rem',
                position: 'relative',
                gap: '0.1rem',
                overflowY: 'auto',
                overflowX: 'hidden',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
              }}
              sxButton={{
                maxHeight: 'auto',
                ml: 0,
                px: '0.8rem',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                '& > div': {
                  alignItems: 'center',
                },
              }}
            />
          </Flex>
        }
      >
        <Flex sx={{ width: '100%', gap: '0.8rem' }}>
          <Flex
            sx={{
              width: '100%',
              flex: 1,
              alignItems: 'center',
              color:
                theme === 'light-org-dropdown'
                  ? isDropdownOpened
                    ? 'black'
                    : 'black60'
                  : isDropdownOpened
                  ? 'aliceBlue'
                  : 'aliceBlue60',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: 400,
              fontSize: '1.4rem',
              lineHeight: '138%',
              letterSpacing: '0.56px',
              ...sxDropdownButton,
            }}
          >
            {selectedItem?.buttonContent || selectedItem?.children || defaultValue}
          </Flex>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              svg: shouldRotateArrow
                ? {
                    transform: isDropdownOpened ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.1s ease',
                  }
                : {},
            }}
          >
            <Svg
              svg={arrowIcon || DropdownIcon}
              fill={
                arrowFill ||
                (theme === 'light-org-dropdown'
                  ? isDropdownOpened
                    ? 'black'
                    : 'black30'
                  : isDropdownOpened
                  ? 'aliceBlue'
                  : 'aliceBlue30')
              }
              sx={{ width: '1.6rem', height: '1.6rem', ...sxArrowIcon }}
              descriptiveText="Dropdown arrow icon"
            />
          </Flex>
        </Flex>
      </Dropdown>
    </Flex>
  )
}

function SearchInput({
  searchText,
  setSearchText,
  hasInputFocus,
  setHoverIndex,
  isOpen,
  onKeyDown,
}: {
  searchText: string
  setSearchText: Setter<string>
  hasInputFocus: boolean
  setHoverIndex: Setter<number>
  isOpen?: boolean
  onKeyDown?: KeyboardEventHandler
}) {
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const isDesktop = useBreakpointIndex() === 2

  useEffect(() => {
    // no need for autofocus for mobile/tablet device as keyboard is displayed immediately
    if (isDesktop && isOpen) {
      searchInputRef.current?.focus()
    }
  }, [isDesktop, isOpen])

  return (
    <Flex
      sx={{
        position: 'sticky',
        width: '100%',
        flexDirection: 'column',
        zIndex: 'zIndex50',
        backgroundColor: 'darkJungleGreen',
      }}
    >
      <InputSearch
        isCompact
        data-testid="devices-search"
        ref={searchInputRef}
        containerStyle={{
          alignItems: 'center',
          py: '0.4rem',
          border: 'none',
        }}
        svgContainerStyle={{
          left: '0.8rem',
        }}
        closeContainerStyle={{
          right: '1.2rem',
        }}
        placeholder="Search"
        value={searchText}
        clearInput={() => {
          setSearchText('')
          searchInputRef.current?.focus()
        }}
        onChange={e => setSearchText(e.target.value)}
        tabIndex={0}
        hasFocus={hasInputFocus}
        onFocus={() => setHoverIndex(-1)}
        onKeyDown={onKeyDown}
      />
    </Flex>
  )
}
