import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg, Button } from 'ui'
import ChevronRight from 'images/dashboard/checklist/very-small-chevron-right.svg'
import Check from 'images/dashboard/checklist/green-checkmark.svg'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'
import { useAppDispatch } from 'store/hooks'
const firstChecklistItem = 1

interface CheckListItemProps {
  number: number
  text: string
  isChecked?: boolean
  navigateTo?: string
  ariaLabel: string
  isDisabled?: boolean
}

export default function CheckListItem({
  number,
  text,
  isChecked = false,
  navigateTo,
  ariaLabel,
  isDisabled,
}: CheckListItemProps): ReactElement {
  const { relocate } = useQueryString()
  const isMobile = useBreakpointIndex() === 0
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (navigateTo && !isChecked && !isDisabled) {
      relocate(navigateTo)
    }
    if (isMobile) {
      dispatch(setIsMobileNavMenuOpen(false))
    }
  }

  return (
    <Button
      ariaLabel={ariaLabel}
      variant="transparent"
      data-testid={text.toLowerCase().replace(/\s+/g, '-')}
      onClick={handleClick}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 0,
        cursor: isChecked || isDisabled ? 'default' : 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        'svg > g > path': {
          fill: 'white',
        },
        ':hover svg > g': {
          opacity: isChecked || isDisabled ? 0.3 : 0.8,
        },
      }}
    >
      <Flex
        sx={{
          gap: '0.8rem',
          alignItems: 'center',
          color: isChecked || isDisabled ? 'white30black30' : 'white',
        }}
      >
        <Flex
          sx={{
            height: '1.6rem',
            width: '1.6rem',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            color: isDisabled ? 'white30black30' : 'white',
            borderColor: 'white',
            border: '1px solid',
            ...(isChecked && {
              border: 'none',
              backgroundColor: 'greenApple',
            }),
          }}
        >
          {isChecked ? (
            <Svg descriptiveText="checklist item completed" svg={Check} />
          ) : (
            <Text variant="size11Weight700">{number}</Text>
          )}
        </Flex>
        <Text
          variant="size14Weight400"
          sx={{
            textDecoration: isChecked && !isDisabled ? 'line-through' : 'none',
          }}
        >
          {text}
        </Text>
      </Flex>
      {number !== firstChecklistItem && <Svg descriptiveText={ariaLabel} svg={ChevronRight} />}
    </Button>
  )
}
