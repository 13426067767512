import React, { ReactElement, useEffect, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { Card, Button } from 'ui'

interface ConfirmEnablesAdsProps {
  enable: () => void
  cancel: () => void
  serviceWarningText: string
  isVisible?: boolean
}
export default function ConfirmServiceWarning({
  enable,
  cancel,
  serviceWarningText,
  isVisible,
}: ConfirmEnablesAdsProps): ReactElement {
  const enableBtnRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (isVisible) {
      enableBtnRef.current?.focus()
    }
  }, [isVisible])
  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: 'blue800',
        flex: '1 0 auto',
        borderRadius: [0, '1.2rem'],
        '>.card-children': {
          flex: '1 1 auto',
        },
        p: 0,
      }}
    >
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
          backgroundColor: 'white6',
          p: ['1.2rem', '0.8rem'],
          borderRadius: [0, '1.2rem'],
          gap: '0.4rem',
        }}
      >
        <Text
          variant="size11Weight400"
          sx={{
            color: 'aliceBlue60',
            flex: 1,
            maxHeight: '6rem',
            cursor: isVisible ? 'text' : 'default',
            overflow: 'auto',
          }}
          className="hide-scrollbar"
        >
          {serviceWarningText}
        </Text>
        <Flex
          sx={{
            justifyContent: 'flex-end',
            flexShrink: 0,
            gap: '1.2rem',
          }}
        >
          <Button
            variant="simple"
            sx={{
              border: 'none',
              whiteSpace: 'nowrap',
              cursor: isVisible ? 'pointer' : 'default',
              p: 0,
            }}
            onClick={enable}
            data-testid="confirm-enables-ads"
            ariaLabel="confirm enables ads"
            tabIndex={isVisible ? 0 : -1}
            ref={enableBtnRef}
          >
            <Text
              variant="size11Weight700"
              sx={{
                color: 'greenApple',
              }}
            >
              Enable Anyway
            </Text>
          </Button>
          <Button
            variant="simple"
            sx={{
              border: 'none',
              cursor: isVisible ? 'pointer' : 'default',
              p: 0,
            }}
            onClick={cancel}
            data-testid="cancel-enables-ads"
            ariaLabel="cancel enables ads"
            tabIndex={isVisible ? 0 : -1}
          >
            <Text
              variant="size11Weight700"
              sx={{
                color: 'aliceBlue60',
              }}
            >
              Cancel
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Card>
  )
}
