import moment from 'moment'
import { Flex } from '@theme-ui/components'
import React, { ReactElement } from 'react'
import CustomTimeInput from './CustomComponents/CustomTimeInput'
import useGetColorMode from 'utils/useGetColorMode'

export interface TimePickerProps {
  isDropdown?: boolean
  isDateRange?: boolean
  date?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  onDateChange?: (date: Date) => void
  onDateRangeChange?: (date: [Date | null, Date | null] | Date[]) => void
}
export default function TimePicker({
  isDropdown,
  isDateRange,
  date,
  startDate,
  endDate,
  onDateChange,
  onDateRangeChange,
}: TimePickerProps): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        ...(!isDateRange
          ? {
              borderLeft: '1px solid',
              borderRight: '1px solid',
              borderBottom: '1px solid',
              borderColor: 'blueYonder40',
              borderBottomLeftRadius: '0.8rem',
              borderBottomRightRadius: '0.8rem',
            }
          : {}),
      }}
    >
      <CustomTimeInput
        key="start-date"
        isDropdown={isDropdown}
        date={startDate || date}
        onChange={date => {
          if (date) {
            isDateRange && endDate ? onDateRangeChange?.([date, endDate]) : onDateChange?.(date)
          }
        }}
        dayValue={
          startDate
            ? moment(startDate).format('MMM DD')
            : date
            ? moment(date).format('MMM DD')
            : undefined
        }
        sx={{
          borderTop: '1px solid',
          borderTopColor: 'blueYonder40',
          borderBottom: '1px solid',
          borderBottomColor: !endDate ? 'blueYonder40' : 'transparent',
          ...(isLightMode
            ? {
                borderColor: endDate ? 'blueYonder40' : 'transparent',
                borderBottomRadius: endDate ? 'none' : '0.8rem',
              }
            : {}),
        }}
      />
      {endDate && (
        <CustomTimeInput
          key="end-date"
          isDropdown={isDropdown}
          date={endDate}
          onChange={date => {
            if (date && startDate) {
              isDateRange ? onDateRangeChange?.([startDate, date]) : onDateChange?.(date)
            }
          }}
          dayValue={endDate && moment(endDate).format('MMM DD')}
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: 'blueYonder40',
            ...(isLightMode
              ? {
                  borderRadius: 'none',
                }
              : {}),
          }}
        />
      )}
    </Flex>
  )
}
