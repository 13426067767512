import React, { ReactElement, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

type BadgeColor = 'success' | 'warning' | 'error' | 'info'

const badgeBgColors: Record<BadgeColor, string> = {
  error: 'pomegranate',
  success: 'greenApple',
  warning: 'banana',
  info: 'plumpPurple',
}

const badgeTextColors: Record<BadgeColor, string> = {
  error: 'black',
  success: 'darkBodyBG',
  warning: 'black',
  info: 'white',
}

interface BadgeProps {
  children: ReactNode
  colorTheme: BadgeColor
  sx?: ThemeUIStyleObject
}

const Badge = ({ colorTheme, sx, children }: BadgeProps): ReactElement => (
  <Flex
    sx={{
      backgroundColor: badgeBgColors[colorTheme],
      borderRadius: '10rem',
      color: badgeTextColors[colorTheme],
      flexShrink: 0,
      fontSize: '1rem',
      fontWeight: 'bold',
      height: '1.4rem',
      padding: '0 0.4rem',
      width: 'auto',
      ...sx,
    }}
  >
    {children}
  </Flex>
)

export default Badge
