import { useEffect, useState } from 'react'

const servicesPerRow: [number, number, number] = [1, 2, 2]
const servicesPerRowFullDesktop: [number, number, number] = [1, 2, 4]

const customBreakpointWidth = 1240
const getItemsPerRow = () =>
  window.innerWidth < customBreakpointWidth ? servicesPerRow : servicesPerRowFullDesktop

export function useServiceItemsPerRow(): [number, number, number] {
  const [value, setValue] = useState(getItemsPerRow())

  useEffect(() => {
    const onResize = () => {
      setValue(getItemsPerRow())
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return value
}
