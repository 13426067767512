import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex } from 'theme-ui'
import { getOnKeyPressWith, stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import { Button, Svg } from 'ui'
import CopyIcon from 'images/copy-icon.svg'
import MoveIcon from 'images/move-icon.svg'
import EditIcon from 'images/edit-icon.svg'
import DeleteIcon from 'images/delete-icon.svg'
import { CustomRuleProps } from './CustomRule'
import { useGetGroupsQuery } from 'store/api/groups'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import { CustomRulesConfirmDeletion } from '../SubHeaderRightContent/DesktopHeaderContent'
import { deselectAllRules, toggleSelectionMode } from 'store/customRules'
import { EnabledStatus, useDeleteGroupRulesMutation } from 'store/api/rules'

export const ruleLargeViewItemHeight = ['8rem', '8.8rem', '5.6rem']
export const ruleSmallViewItemHeight = '4.8rem'

export function CustomRuleDesktop({
  onEditButtonClicked,
  onMoveButtonClicked,
  onCopyButtonClicked,
  isSelected,
  shouldShowCheckbox,
  onSelectRule,
  switcheroo,
  handleDelete,
  blockActionButtonsTabFocus,
  isConfirmDeletionOpen,
  setIsConfirmDeletionOpen,
  hostname,
  children,
  status,
}: CustomRuleProps): ReactElement {
  const dispatch = useAppDispatch()
  const hasNoNonRootFolders = useAppSelector(s => s.groups.data?.groups.length === 0)
  const shouldShowLargeView = useAppSelector(s => s.customRules.shouldShowLargeView)
  const rulesProfileId = useGetSelectedProfile()?.PK.toString() ?? ''
  const { data: groupsData } = useGetGroupsQuery(
    { profileId: rulesProfileId },
    { skip: !rulesProfileId },
  )
  const [deleteRules] = useDeleteGroupRulesMutation()
  const { groupUserIsIn } = useAppSelector(s => s.customRules)

  return (
    <Flex
      data-testid="custom-rule-list-item"
      sx={{
        width: '100%',
        px: '1.2rem',
        height: shouldShowLargeView ? ruleLargeViewItemHeight : ruleSmallViewItemHeight,
        alignItems: 'center',
        backgroundColor: shouldShowCheckbox && isSelected ? 'greenApple15' : 'cardBg',
        cursor: 'pointer',
        justifyContent: 'space-between',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: isSelected ? 'greenApple' : 'blueYonder15',
        borderRadius: '1.2rem',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
        '.rule-info': {
          display: isConfirmDeletionOpen ? 'none' : 'flex',
        },
        ':hover': {
          backgroundColor: !(shouldShowCheckbox && isSelected) && 'white8',
          '.rule-options': {
            opacity: shouldShowCheckbox || isSelected ? 0 : 100,
            width: !shouldShowCheckbox && !isSelected && 'fit-content',
          },
          '.rule-info': {
            display: !shouldShowCheckbox && !isSelected && 'none',
          },
          '.custom-rule-hostname-container': {
            width: ['100%', 'auto', 'calc(100% - 17rem)'],
          },
        },
        ':focus-within': {
          backgroundColor: !(shouldShowCheckbox && isSelected) && 'white8',
          '.rule-options': {
            opacity: shouldShowCheckbox || isSelected ? 0 : 100,
            width: !shouldShowCheckbox && !isSelected && 'fit-content',
          },
          '.rule-info': {
            display: !shouldShowCheckbox && !isSelected && 'none',
          },
        },
      }}
      tabIndex={isConfirmDeletionOpen ? -1 : 0}
      aria-label={`rule ${isSelected ? 'checked' : 'unchecked'}`}
      onKeyDown={getOnKeyPressWith({
        callback: onSelectRule,
      })}
    >
      {children}
      <Flex
        sx={{
          position: 'relative',
          width: 'fit-content',
          display: shouldShowCheckbox || isSelected ? 'none' : 'flex',
          mt: '1.2rem',
          alignSelf: 'flex-start',
          transform: shouldShowCheckbox ? 'translateX(22rem)' : 'translate(0)',
          transition: 'transform 0.5s ease',
          zIndex: 'zIndex2',
          alignItems: 'center',
        }}
      >
        {!isConfirmDeletionOpen ? (
          <>
            <Flex
              className="rule-options"
              sx={{
                position: 'absolute',
                right: '5rem',
                alignItems: 'center',
                opacity: 0,
                gap: '1.6rem',
                ml: '0.8rem',
                width: 0,
                overflow: 'hidden',
                py: '0.4rem',
              }}
            >
              <Button
                data-testid="copy-rule"
                ariaLabel="open copy rule tray"
                onClick={onCopyButtonClicked}
                variant="simple"
                onKeyDown={stopPropagationOnKeyPress}
                tabIndex={blockActionButtonsTabFocus ? -1 : 0}
                sx={{
                  p: 0,
                }}
              >
                <Svg
                  svg={CopyIcon}
                  fill="aliceBlue60Black60"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                  }}
                  descriptiveText="Copy Rule"
                />
              </Button>
              <Button
                disabled={!groupsData?.groups.length}
                data-testid="move-rule"
                ariaLabel="open move rule tray"
                onClick={onMoveButtonClicked}
                variant="simple"
                onKeyDown={stopPropagationOnKeyPress}
                tabIndex={blockActionButtonsTabFocus ? -1 : 0}
                sx={{
                  p: 0,
                  ':disabled': {
                    backgroundColor: 'transparent',
                    opacity: '0.25',
                  },
                }}
              >
                <Svg
                  svg={MoveIcon}
                  fill="aliceBlue"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                    opacity: 0.5,
                    ':hover': {
                      opacity: hasNoNonRootFolders ? 0.5 : 1,
                    },
                  }}
                  descriptiveText="Move Rule"
                />
              </Button>
              <Button
                data-testid="edit-rule"
                ariaLabel="open edit rule tray"
                onClick={onEditButtonClicked}
                onKeyDown={stopPropagationOnKeyPress}
                tabIndex={blockActionButtonsTabFocus ? -1 : 0}
                variant="simple"
                sx={{
                  p: 0,
                }}
              >
                <Svg
                  svg={EditIcon}
                  fill="aliceBlue"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                    opacity: 0.5,
                    ':hover': {
                      opacity: 1,
                    },
                  }}
                  descriptiveText="Edit Rule"
                />
              </Button>
              <Button
                data-testid="delete-rule"
                ariaLabel="delete rule"
                onClick={handleDelete}
                onKeyDown={stopPropagationOnKeyPress}
                tabIndex={blockActionButtonsTabFocus ? -1 : 0}
                variant="simple"
                sx={{ mr: '1.6rem', p: 0 }}
              >
                <Svg
                  svg={DeleteIcon}
                  fill="aliceBlue"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                    opacity: 0.5,
                    ':hover': {
                      opacity: 1,
                    },
                  }}
                  descriptiveText="Delete Rule"
                />
              </Button>
            </Flex>
            <Flex
              data-testid={`custom-rule-list-item-${
                status === EnabledStatus.ENABLED ? 'enabled' : 'disabled'
              }-switch`}
            >
              {switcheroo}
            </Flex>
          </>
        ) : (
          <CustomRulesConfirmDeletion
            sx={{
              position: 'absolute',
              right: 0,
              zIndex: 'zIndex2',
              mt: '3.2rem',
              ml: '0.8rem',
              '& > button': { height: '3.3rem' },
            }}
            onCancel={() => {
              dispatch(toggleSelectionMode(false))
              dispatch(deselectAllRules())
              setIsConfirmDeletionOpen?.(false)
            }}
            onDelete={() => {
              deleteRules({
                body: { hostnames: [hostname] },
                profileId: rulesProfileId,
                group: groupUserIsIn,
              })
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}
