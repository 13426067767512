import React, { ReactElement } from 'react'
import { ExternalLink, Svg } from 'ui'

interface SocialMediaButtonProps {
  to: string
  icon: React.ElementType
  hasSmallIcon?: boolean
}
export default function SocialMediaButton({
  to,
  icon,
  hasSmallIcon,
}: SocialMediaButtonProps): ReactElement {
  return (
    <ExternalLink
      aria-label={to}
      to={to}
      sx={{
        width: hasSmallIcon ? '3.2rem' : '4.8rem',
        height: hasSmallIcon ? '3.2rem' : '4.8rem',
        backgroundColor: 'white15',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: 'white25',
          '& svg path': {
            fill: 'white',
          },
        },
      }}
    >
      <Svg
        svg={icon}
        descriptiveText={`Social media link to ${to}`}
        fill="white50"
        width={hasSmallIcon ? '2.4rem' : '3.2rem'}
        height={hasSmallIcon ? '2.4rem' : '3.2rem'}
      />
    </ExternalLink>
  )
}
