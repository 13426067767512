import React, { ReactElement, useMemo, useRef, useState } from 'react'
import MoreIcon from 'images/ui/more-icon.svg'
import AddIcon from 'images/add-icon.svg'
import { Button, Dropdown, IconButtonContent, IconMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import {
  EnforcingStatus,
  useDeleteScheduleMutation,
  usePutSchedulesMutation,
} from 'store/api/schedules'
import { useScheduleState } from 'components/Dashboard/Devices/DeviceListItem/ScheduleContextProvider'
import { Flex, Text } from 'theme-ui'
import Tippy from '@tippyjs/react'
import DeleteScheduleIcon from 'images/menus/profilesAndDevices/delete-schedule-icon.svg'
import EditScheduleIcon from 'images/menus/profilesAndDevices/edit-schedule-icon.svg'
import StartNowIcon from 'images/menus/profilesAndDevices/start-now-icon.svg'
import StopNowIcon from 'images/menus/profilesAndDevices/stop-now-icon.svg'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useGetProfilesQuery } from 'store/api/profiles'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from '../DeviceModalDialog'
import useGetColorMode from 'utils/useGetColorMode'

export default function ScheduleMenu(): ReactElement {
  const { qs, nav } = useQueryString()
  const [isScheduleMenuOpen, setIsScheduleMenuOpen] = useState(false)
  const scheduleMenuRef = useRef<() => void>()
  const { existingSchedule } = useScheduleState()
  const { data: profilesData } = useGetProfilesQuery('')
  const [putSchedule] = usePutSchedulesMutation()
  const [deleteSchedule] = useDeleteScheduleMutation()
  const boundaryElement = useBoundaryElement('device-dialog')

  const startStopMenuItem: SimpleActionMenuItemProps[] = useMemo(() => {
    if (existingSchedule?.status) {
      if (existingSchedule.enforcing) {
        return [
          {
            ariaLabel: 'stop schedule',
            children: <IconButtonContent startIcon={StopNowIcon}>Stop Schedule</IconButtonContent>,
            onClick: () =>
              putSchedule({
                pk: existingSchedule?.PK ?? '',
                body: { enforce_override: EnforcingStatus.FALSE },
              }).then(() => {
                scheduleMenuRef.current?.()
                setIsScheduleMenuOpen(false)
              }),
          },
        ]
      } else {
        return [
          {
            ariaLabel: 'start schedule',
            children: (
              <IconButtonContent startIcon={StartNowIcon}>Start Schedule</IconButtonContent>
            ),
            onClick: () =>
              putSchedule({
                pk: existingSchedule?.PK ?? '',
                body: { enforce_override: EnforcingStatus.TRUE },
              }).then(() => {
                scheduleMenuRef.current?.()
                setIsScheduleMenuOpen(false)
              }),
          },
        ]
      }
    }
    return []
  }, [existingSchedule?.PK, existingSchedule?.enforcing, existingSchedule?.status, putSchedule])

  const scheduleMenuItems: SimpleActionMenuItemProps[] = useMemo(
    () => [
      ...startStopMenuItem,
      {
        ariaLabel: 'edit schedule option',
        children: <IconButtonContent startIcon={EditScheduleIcon}>Edit Schedule</IconButtonContent>,
        onClick: () => {
          nav({ ...qs, deviceDialog: DeviceDialogType.SCHEDULE })
        },
      },
      {
        ariaLabel: 'delete schedule option',
        children: (
          <IconButtonContent startIcon={DeleteScheduleIcon}>Delete Schedule</IconButtonContent>
        ),
        onClick: () => deleteSchedule({ pk: existingSchedule?.PK ?? '' }),
      },
    ],
    [deleteSchedule, existingSchedule?.PK, nav, qs, startStopMenuItem],
  )
  const isCreateScheduleEnabled = (profilesData?.profiles?.length ?? 0) > 1
  const { isLightMode } = useGetColorMode()

  return existingSchedule ? (
    <Dropdown
      variant="simple"
      ariaLabel="account details"
      data-testid="schedule-menu-button"
      sx={{
        ml: '0.8rem',
        p: 0,
        borderRadius: '50%',
        opacity: isScheduleMenuOpen ? 1 : 0.5,
        ':hover': { opacity: 1 },
        '&:hover > svg path': {
          fill: 'aliceBlue',
        },
      }}
      tippyprops={{
        appendTo: boundaryElement || 'parent',
        animation: 'fade',
        duration: 300,
        placement: 'bottom-end',
        offset: [0, 8],
        onCreate: instance => {
          scheduleMenuRef.current = () => {
            instance.hide()
          }
        },
        onHide: () => {
          setIsScheduleMenuOpen(false)
        },
        onShow: () => {
          setIsScheduleMenuOpen(true)
        },
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
      }}
      dropdowncontent={
        <IconMenu
          hideRef={scheduleMenuRef}
          items={scheduleMenuItems}
          sxContainer={{
            p: '0.4rem',
            m: 0,
            backgroundColor: 'transparent',
            maxHeight: '20rem',
            width: '26.4rem',
          }}
        />
      }
    >
      <Flex>
        <Svg
          svg={MoreIcon}
          fill="aliceBlue"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
          descriptiveText="Schedule options menu"
        />
      </Flex>
    </Dropdown>
  ) : (
    <Tippy
      onShow={(): void | false => {
        if (isCreateScheduleEnabled) {
          // returning false here stops the tooltip from showing
          return false
        }
      }}
      content={
        <Text sx={{ overflowWrap: 'break-word' }}>
          More than 1 profile is required to create a schedule. Please add a profile and try again.
        </Text>
      }
      arrow={false}
      theme="screen"
      touch="hold"
      interactive={true}
      appendTo={document.body}
      maxWidth="30rem"
    >
      <Flex>
        <Button
          data-testid="create-schedule-button"
          ariaLabel="create schedule button"
          variant="transparent"
          sx={{
            opacity: 0.5,
            p: 0,
            ':hover': { opacity: 1 },
            ':disabled': {
              backgroundColor: 'transparent',
              opacity: 0.3,
            },
          }}
          onClick={() => {
            nav({ ...qs, deviceDialog: DeviceDialogType.SCHEDULE })
          }}
          disabled={!isCreateScheduleEnabled}
        >
          <Svg
            fill="white"
            svg={AddIcon}
            sx={{ height: '2.4rem', width: '2.4rem' }}
            descriptiveText="Create new schedule"
          />
        </Button>
      </Flex>
    </Tippy>
  )
}
