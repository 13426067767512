import React, { createRef, ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useAppSelector } from 'store/hooks'
import { AlertContextProvider, TrayPresenter } from 'ui'
import useTutorialSlides from 'components/Dashboard/utils/useTutorialSlides'
import useQueryString from 'utils/useQueryString'
import { useLocation, useMatch } from '@reach/router'
import ConfirmEmailScreen from 'components/Organization/ConfirmEmailScreen'
import TwoFaModalDialog from 'components/Organization/TwoFa/TwoFaModalDialog'
import { OrganizationNavRelativePath } from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { useTraysVisibility } from 'ui/Tray/TrayContextProvider'
import { userApi } from 'store/api/user'
import DashboardTutorial from 'components/Dashboard/Tutorial/DashboardTutorial'
import NavBar from 'components/Dashboard/NavBar'
import NotificationsDialog from 'components/Dashboard/NavBar/NotificationsDialog'
import SetupGuide from 'components/SetupGuide/SetupGuide'
import { useIsTwoFaVisible } from 'utils/useIsTwoFaVisible'
import BarryDialog from 'components/Barry/BarryDialog'
import CreatePortalWrapper from 'components/CreatePortalWrapper'
import useGetColorMode from 'utils/useGetColorMode'

export const scrollingContainerRef = createRef<HTMLDivElement>()
export const trayRef = createRef<HTMLDivElement>()

export default function DashboardScrollingContainer({
  children,
}: {
  children: ReactNode
}): ReactElement {
  const breakpoints = useBreakpointIndex()
  const isMobile = breakpoints === 0

  const isInMaintenanceMode = useMatch('maintenance-mode')
  const is404Page = useMatch('404')
  const { isLightMode } = useGetColorMode()

  const { isAnyTrayOpen } = useTraysVisibility()
  const isMobileNavMenuOpen = useAppSelector(s => s.dashboard.isMobileNavMenuOpen)

  return (
    <Flex
      ref={scrollingContainerRef}
      data-testid="dashboard-scrolling-container"
      sx={{
        position: 'fixed',
        width: '100vw',
        height: '100%',
        backgroundColor: 'blue800',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
        fontFamily: 'body',
        // scrolling container should not affect the tray
        overflowY: isAnyTrayOpen || isMobileNavMenuOpen ? 'hidden' : 'scroll',
      }}
      className={`${isLightMode ? 'light' : 'dark'} show-scrollbar`}
    >
      <Flex
        sx={{
          maxWidth: '1512px',
          width: '100%',
          height: '100%',
          opacity: breakpoints === -1 ? 0 : 1,
        }}
      >
        {!isMobile && !is404Page && !isInMaintenanceMode && <NavBar />}
        <Flex
          sx={{
            position: 'relative',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {children}
        </Flex>
      </Flex>

      <DashboardModalDialogs />
    </Flex>
  )
}

function DashboardModalDialogs(): ReactElement {
  const { qs } = useQueryString()
  const location = useLocation()
  const { currentTutorial } = useTutorialSlides()
  const isSetupGuideVisible = useAppSelector(s => !!s.persistData.sessionToken) && qs.deviceId
  const { data: userData } = userApi.endpoints.getUser.useQueryState('')
  const isConfirmEmailVisible = !!userData?.org?.PK && !userData?.email_status
  const isTwoFaVisible = useIsTwoFaVisible()

  return (
    <>
      {isConfirmEmailVisible &&
        !location.pathname.includes(OrganizationNavRelativePath.ACCOUNT) && <ConfirmEmailScreen />}
      {isTwoFaVisible && <TwoFaModalDialog />}

      {isSetupGuideVisible && (
        <AlertContextProvider>
          <SetupGuide />
        </AlertContextProvider>
      )}

      {!isConfirmEmailVisible && !isTwoFaVisible && (
        <DashboardTutorial tutorialType={currentTutorial} />
      )}

      <NotificationsDialog />

      <TrayPresenter ref={trayRef} />

      <CreatePortalWrapper>
        <BarryDialog />
      </CreatePortalWrapper>
    </>
  )
}
