import TrayTabbedHeader from 'components/Dashboard/ProxyModalDialog/TrayTabbedHeader'
import ProxyList from 'components/ProxyList/ProxyList'
import { EnabledStatus, RuleType } from 'store/api/rules'
import IpOrHostnameForm from 'components/Dashboard/ProxyModalDialog/IpOrHostnameForm'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { Flex } from '@theme-ui/components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openProxyTray, ProxyTrayState } from 'store/proxyTray'
import { useGetProxiesQuery } from 'store/api/proxies'
import { ServiceData, usePutServicesMutation } from 'store/api/services'
import { isCustomRule, isServiceRule } from 'utils/uniteRules'
import { updateCustomRuleDraft, updateGroupDraft } from 'store/customRules'
import omit from 'lodash/omit'
import { Setter } from 'utils'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'

export default function DestinationsView({
  isVisible,
  type,
  shouldShowProxy,
  setShouldShowProxy,
}: {
  isVisible: boolean
  type: string
  shouldShowProxy: boolean
  setShouldShowProxy: Setter<boolean>
}): ReactElement {
  const dispatch = useAppDispatch()
  const proxyTrayState: ProxyTrayState = useAppSelector(s => s.proxyTray)
  const { data: proxiesData } = useGetProxiesQuery('')
  const proxyLocations = proxiesData?.proxies
  const currentProfileId = useGetSelectedProfile()?.PK.toString() ?? ''
  const [putServices] = usePutServicesMutation()

  const filteredProxyLocations = useMemo(() => {
    if (!isServiceRule(proxyTrayState.rule)) {
      // custom rules
      return proxyLocations?.filter(x => !x.hidden)
    }
    // CAUTION: pulling in services from the store here led to #948
    // #900 if the service associated with the rule action has a locations array that is not empty, return only the proxy locations that match the locations in that array
    if (!!proxyTrayState.rule?.locations) {
      return proxyLocations
        ?.filter(proxy => (proxyTrayState.rule as ServiceData).locations?.includes(proxy.PK))
        .map((location, _, allLocations) => ({
          // mark the single locations in the filtered list
          ...location,
          isSingle: allLocations.filter(l => l.country === location.country).length === 1,
        }))
    }

    return proxyLocations
  }, [proxyTrayState.rule, proxyLocations])

  useEffect(() => {
    setShouldShowProxy(proxyTrayState?.rule?.action?.do !== RuleType.SPOOF_IP)
  }, [proxyTrayState, setShouldShowProxy])

  const handleProxyClick = useCallback(
    (ruleType: RuleType) =>
      async (via?: string, viaV6?: string): Promise<void> => {
        const viaData = {
          via: via || '',
          via_v6: viaV6 || undefined,
        }

        if (isServiceRule(proxyTrayState.rule)) {
          // service always posts
          if (
            via !== proxyTrayState.rule?.action?.via ||
            viaV6 !== proxyTrayState.rule?.action?.via_v6
          ) {
            await putServices({
              profileId: currentProfileId,
              body: {
                PK: proxyTrayState.rule?.PK,
                do: ruleType,
                status: EnabledStatus.ENABLED,
                ...viaData,
              },
            })
          }
        } else if (isCustomRule(proxyTrayState.rule)) {
          // update custom rules rule action draft
          dispatch(
            updateCustomRuleDraft({
              ...omit(proxyTrayState.rule?.action, 'via'),
              action: {
                do: ruleType,
                ...viaData,
              },
            }),
          )
        } else {
          // update group action draft
          dispatch(
            updateGroupDraft({
              ...omit(proxyTrayState.rule?.action, 'via'),
              action: {
                do: ruleType,
                ...viaData,
              },
            }),
          )
        }
        dispatch(openProxyTray(''))
      },
    [currentProfileId, dispatch, proxyTrayState.rule, putServices],
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <TrayTabbedHeader
        firstTabSelected={shouldShowProxy}
        firstTabText="Proxies"
        firstTabClick={(): void => setShouldShowProxy(true)}
        firstTabDataTestId="proxy-tray-proxies-button"
        secondTabText="IP or Hostname"
        secondTabClick={(): void => setShouldShowProxy(false)}
        secondTabDataTestId="proxy-tray-hostname-button"
      />
      {shouldShowProxy ? (
        <>
          <ProxyList
            isOpen={shouldShowProxy}
            proxyLocations={filteredProxyLocations ?? []}
            handleProxyClick={handleProxyClick(RuleType.SPOOF_TAG)}
            currentLocationPK={proxyTrayState.rule?.action?.via}
            proxyLevel={type}
            allowDeselect={false}
            isVisible={!!isVisible}
          />
        </>
      ) : (
        <IpOrHostnameForm handleProxyClick={handleProxyClick(RuleType.SPOOF_IP)} />
      )}
    </Flex>
  )
}
