import { Setter } from 'utils'
import { toggleTwoFa, TwoFactorStep } from 'store/twofa'
import React, { FormEvent, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import { Input, Svg } from 'ui'
import { LoadingState } from 'store/fetchingLogic'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import ContinueButton from '../ContinueButton'
import WarningIcon from 'images/dashboard/warning-icon.svg'

export default function AccountPasswordStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const dispatch = useAppDispatch()
  const twofa = useAppSelector(s => s.twofa)
  const [password, setPassword] = useState('')
  const { data: userData } = useGetUser()

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault()

    dispatch(
      toggleTwoFa({
        password,
        setTwofaStep: setStep,
        closeEditing: () => {},
        sessionTwofa: userData?.twofa,
      }),
    )
  }

  return (
    <Flex
      as="form"
      onSubmit={onSubmit}
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Svg svg={WarningIcon} descriptiveText="Two-factor authentication requirement warning" />
      <Text
        variant="size15Weight400Line138"
        sx={{ maxWidth: ['100%', '30.1rem'], color: 'aliceBlue60' }}
      >
        2FA is required to join this organization. To enable, please enter your password first.
      </Text>
      <Input
        isCompact
        data-testid="confirm-two-factor-password"
        placeholder="Current Password"
        aria-label="current password"
        autoComplete="current-password"
        type="password"
        id="confirm-two-factor-password"
        label="Password"
        name="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        containerStyle={{
          width: ['100%', '100%', 'auto'],
        }}
      />
      <ContinueButton
        isRequestInFlight={twofa.loading === LoadingState.PENDING}
        ariaLabel="confirm password button"
        dataTestId="confirm-password-button"
        onClick={onSubmit}
      />
    </Flex>
  )
}
