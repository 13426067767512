import React, { ReactElement } from 'react'
import { Divider, Flex, Spinner, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import KeyIcon from 'images/key-icon.svg'
import { Svg } from 'ui'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function CopyableResolverId({ text }: { text: string }): ReactElement {
  const { isLoading } = useGetUser()
  const splitText = text?.split('\n')

  return (
    <Flex
      sx={{
        width: '22.5rem',
        height: '5.6rem',
        flexShrink: 0,
        backgroundColor: 'white6',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        borderRadius: '1.2rem',
        px: '1.6rem',
      }}
    >
      {isLoading ? (
        <Spinner color="aliceBlue" />
      ) : (
        <Flex sx={{ height: '100%', alignItems: 'center' }}>
          <Svg svg={KeyIcon} fill="aliceBlue" descriptiveText="Resolver ID key" />
          <Divider
            sx={{
              width: '1px',
              height: '5.6rem',
              backgroundColor: 'blue800',
              color: 'blue800',
              mx: '1.6rem',
              my: 0,
              flexShrink: 0,
            }}
          />
          {splitText?.map(text => (
            <Text
              key={text}
              variant="size12Weight500"
              sx={{
                color: 'aliceBlue',
              }}
            >
              {text}
            </Text>
          ))}
        </Flex>
      )}
      <CopyToClipboard smallIcon data={text.trim()} fillSvg="aliceBlue" />
    </Flex>
  )
}
