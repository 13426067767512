import React, { ReactElement, useEffect, useRef } from 'react'
import { Tray } from 'ui'
import { Box, Flex } from 'theme-ui'

import TrayHeader from 'components/TrayHeader'
import { MobileNavMenuTrayProps } from './MobileNavMenuTray.interface'
import { FreeFocusInside } from 'react-focus-lock'
import useGetColorMode from 'utils/useGetColorMode'

const MobileNavMenuTray = ({
  children,
  isOpen,
  dismiss,
  trayHeaderLeftComponent,
  trayHeaderRightComponent,
  trayHeaderChildren,
  footer,
  traySx = {},
  trayHeaderSx = {},
}: MobileNavMenuTrayProps): ReactElement => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const trayHeaderRef = useRef<HTMLDivElement>(null)
  const trayFooterRef = useRef<HTMLDivElement>(null)
  const { isLightMode } = useGetColorMode()
  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <FreeFocusInside>
      <Tray
        trayRef={scrollContainerRef}
        dataTestId="nav-menu-tray"
        entersFrom="right"
        isOpen={isOpen}
        dismiss={dismiss}
        slideDimensionLength={['100%', '54.8rem']}
        showOverlay
        sx={{
          height: '100%',
          overflowY: 'hidden',
          ...traySx,
        }}
      >
        <TrayHeader
          headerRef={trayHeaderRef}
          sx={{
            py: '1.6rem',
            px: '1.6rem',
            alignItems: 'center',
            ...trayHeaderSx,
          }}
          leftComponent={isOpen ? trayHeaderLeftComponent : <></>}
          rightComponent={isOpen ? trayHeaderRightComponent : <></>}
        >
          {trayHeaderChildren}
        </TrayHeader>

        <Flex
          sx={{
            flexDirection: 'column',
            height: `calc(100% - ${trayHeaderRef.current?.clientHeight || 0}px - ${
              trayFooterRef.current?.clientHeight || 0
            }px)`,
            overflowY: 'auto',
          }}
          className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
        >
          {isOpen ? children : null}
        </Flex>
        {footer && (
          <Box
            ref={trayFooterRef}
            sx={{ position: 'fixed', width: '100%', backgroundColor: 'white5', bottom: 0 }}
          >
            {footer}
          </Box>
        )}
      </Tray>
    </FreeFocusInside>
  )
}

export default MobileNavMenuTray
