import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ProxyTrayState } from 'store/proxyTray'
import SpoofIpRuleInput from 'components/Dashboard/ProxyModalDialog/SpoofIpRuleInput'
import { useAppSelector } from 'store/hooks'
import { RuleType } from 'store/api/rules'
import { isHostnameValid } from 'utils/isIpOrHostnameValid'
import { v4 } from 'is-ip'
import { Setter } from 'utils'
import { BadgeType, SaveButton } from './IpOrHostnameForm'

export default function HostnameForm({
  selectedFirstType,
  setSelectedFirstType,
  handleProxyClick,
}: {
  selectedFirstType: BadgeType
  setSelectedFirstType: Setter<BadgeType>
  handleProxyClick: (via?: string, viaV6?: string) => void
}): ReactElement {
  const [via, setVia] = useState('')
  const proxyTrayState: ProxyTrayState = useAppSelector(s => s.proxyTray)
  const inputRef = useRef<HTMLInputElement>(null)
  const proxyTrayVia = proxyTrayState.rule?.action?.via || ''

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (proxyTrayState.rule?.action?.do === RuleType.SPOOF_IP) {
      setVia(!v4(proxyTrayVia) ? proxyTrayVia : '')
    }
  }, [proxyTrayState.rule, proxyTrayVia])

  return (
    <>
      <SpoofIpRuleInput
        types={Object.values(BadgeType).filter(type => type !== BadgeType.COMMENT)}
        via={via}
        placeholder="Enter Hostname"
        selectedType={selectedFirstType}
        setSelectedType={type => {
          setSelectedFirstType(type)
        }}
        setVia={setVia}
        inputRef={inputRef}
        testId="rule-tray-spoof-hostname-input"
        id="rule-tray-spoof-hostname-input"
        label={'Hostname'}
        ariaLabel={`enter hostname`}
      />
      <SaveButton
        isButtonDisabled={!isHostnameValid(via)}
        testId="hostname-submit-button"
        ariaLabel="save rule"
        onClick={() => handleProxyClick(via)}
      />
    </>
  )
}
