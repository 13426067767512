import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Button, IconButtonContent, IconMenu, Input, SimpleActionMenuItemProps, Svg } from 'ui'
import { setQueryFilters } from 'store/activityLog'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import Fuse from 'fuse.js'
import { defaultFuseOptions } from 'utils'
import CheckIcon from 'images/analytics/check.svg'
import CloseIcon from 'images/bold-close-icon.svg'
import SearchIcon from 'images/search-icon-bold.svg'
import { Filter } from 'store/api/filters/filters.interface'
import { ServiceData } from 'store/api/services'
import { ProxyLocation } from 'store/api/proxies'
import { verdictTypeFilters, verdictTypesToDropdownItemData } from './ActionsDropdown'
import { protocolToDropdownItemData, protocolTypeFilters } from './ProtocolsDropdown'
import actionIcons from 'components/Dashboard/IconMap/ActionIcons'
import protocolIcons from 'components/Dashboard/IconMap/ProtocolIcons'
import deviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import { hideQueryFilterDropdown } from './QueryFilterDropdown'
import ProxyList from 'components/ProxyList/ProxyList'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { DeviceClient, DeviceInfo } from 'store/api/devices/devices.interface'
import { RCODE, RCODEMap } from './CodesDropdown'
import { RTYPE } from './TypesDropdown'
import { useDeviceClientsData } from 'components/Dashboard/Analytics/DeviceClients/useDeviceClientsData'
import { IconType } from 'components/Dashboard/CommonComponents/ExternalSvgIcon'

interface QueryFilterDropdownContentProps {
  type: QueriesFilterMenuType
  endpoints?: DeviceInfo[] | null
  clients?: DeviceClient[] | null
  codes?: RCODE[] | null
  types?: RTYPE[] | null
  filters?: Filter[] | null
  services?: ServiceData[] | null
  locations?: ProxyLocation[] | null
  actions?: ProxyLocation[] | null
  headerRef?: MutableRefObject<HTMLDivElement | null>
  dismiss?: () => void
  searchTestId?: string
  isSearchVisible?: boolean
  maxHeight?: string
}

interface QueriesFilterSubmenuItemMap {
  title: string
  items: {
    alias: string
    name: string
    uid: string
    country?: string
    /**
     * this is the name of the icon to use for the filter level. For filter levels
     * the PK is the name of the level e.g. "ads_small" but the iconName is "ads"
     */
    iconName?: string
  }[]
}

export default function QueryFilterDropdownContent({
  type,
  endpoints = [],
  clients = [],
  codes = [],
  types = [],
  filters = [],
  services = [],
  locations = [],
  dismiss,
  searchTestId,
  isSearchVisible = true,
  maxHeight,
}: QueryFilterDropdownContentProps): ReactElement {
  const dispatch = useAppDispatch()
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const { setSelectedDeviceId, setSelectedClientId } = useDeviceClientsData()
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const visibleProxyLocations = locations?.filter(x => !x.hidden) ?? []
  const isMobile = useBreakpointIndex() === 0

  useEffect(() => {
    if (isMobile) {
      scrollingContainerRef.current && (scrollingContainerRef.current.style.overflowY = 'hidden')
    }

    return () => {
      scrollingContainerRef.current && (scrollingContainerRef.current.style.overflowY = 'scroll')
    }
  }, [isMobile])

  const filterTypeMap: QueriesFilterSubmenuItemMap = {
    [QueriesFilterMenuType.ENDPOINT]: {
      title: 'Endpoints',
      items:
        endpoints?.map(endpoint => {
          return { name: endpoint.name, uid: endpoint.PK, iconName: endpoint.icon }
        }) || [],
    },
    [QueriesFilterMenuType.CLIENT]: {
      title: 'Clients',
      items:
        clients?.map(client => {
          return { name: client.host, uid: client.id, alias: client?.alias }
        }) || [],
    },
    [QueriesFilterMenuType.RCODE]: {
      title: 'RCODEs',
      items:
        codes?.map(code => {
          return { name: code, uid: RCODEMap[code] }
        }) || [],
    },
    [QueriesFilterMenuType.RTYPE]: {
      title: 'RTYPEs',
      items:
        types?.map(type => {
          return { name: type, uid: type }
        }) || [],
    },
    [QueriesFilterMenuType.FILTERS]: {
      title: 'Filters',
      items:
        filters?.map(filter => {
          return { name: filter.name, uid: filter.PK, iconName: filter.iconName }
        }) || [],
    },
    [QueriesFilterMenuType.SERVICES]: {
      title: 'Services',
      items:
        services?.map(service => {
          return { name: service.name, uid: service.PK }
        }) || [],
    },
    [QueriesFilterMenuType.ACTION]: {
      title: 'Action',
      items:
        verdictTypeFilters?.map(type => {
          return {
            name: verdictTypesToDropdownItemData[type].label,
            uid: `${type}`,
          }
        }) || [],
    },
    [QueriesFilterMenuType.PROTOCOL]: {
      title: 'Protocol',
      items:
        protocolTypeFilters?.map(type => {
          return {
            name: protocolToDropdownItemData[type].label,
            uid: `${type}`,
          }
        }) || [],
    },
  }[type]

  const searchQueryFilterParameters = useCallback(() => {
    if (!filterTypeMap?.items) {
      return []
    }

    const fuzzySearchList = new Fuse(filterTypeMap.items, {
      ...defaultFuseOptions,
      keys: ['name', 'alias'],
    })
    if (!searchText) {
      return filterTypeMap.items
    }
    return fuzzySearchList.search(searchText).map(matches => matches.item)
  }, [filterTypeMap, searchText])

  const submenuOptions: SimpleActionMenuItemProps[] = useMemo(() => {
    const sortedOptions = [...searchQueryFilterParameters()]

    const options: SimpleActionMenuItemProps[] =
      sortedOptions.map(item => {
        const icon =
          type === QueriesFilterMenuType.ACTION
            ? actionIcons[item.uid || '']
            : type === QueriesFilterMenuType.PROTOCOL
            ? protocolIcons[item.uid || '']
            : type === QueriesFilterMenuType.ENDPOINT
            ? deviceIcons[item.iconName || defaultIconName]
            : undefined
        const externalIcon =
          type === QueriesFilterMenuType.FILTERS
            ? { icon: item.iconName ?? item.uid, type: IconType.FILTERS }
            : type === QueriesFilterMenuType.SERVICES
            ? { icon: item.uid, type: IconType.SERVICES }
            : undefined

        const isSelected = filterParameters[type] === item.uid

        return {
          ariaLabel: `${item.name} option`,
          dataTestId:
            type === QueriesFilterMenuType.ENDPOINT
              ? `${item.name}-device-dropdown-item`
              : `activity-log-filter-item-${item.uid}`,
          isSelected,
          tabIndex: 0,
          isBackgroundHighlighted: true,
          children: (
            <IconButtonContent
              startIcon={icon}
              startExternalIcon={externalIcon}
              endIcon={isSelected ? CheckIcon : undefined}
            >
              <TextWithOverFlowAndTippyPopup
                sxText={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: ['100%', '26.4rem'],
                }}
                content={item.alias ? `${item.name} (${item.alias})` : item.name}
                ariaLabel={item.alias ? `${item.name} (${item.alias})` : item.name}
              />
            </IconButtonContent>
          ),
          onClick: () => {
            const isActionOrProtocolType = [
              QueriesFilterMenuType.ACTION,
              QueriesFilterMenuType.ENDPOINT,
              QueriesFilterMenuType.PROTOCOL,
              QueriesFilterMenuType.LOCATIONS,
            ].includes(type)

            const queryFilters = {
              [type]:
                filterParameters[type] === item.uid && !isActionOrProtocolType ? '' : item.uid,
            }

            if (type === QueriesFilterMenuType.FILTERS) {
              queryFilters[QueriesFilterMenuType.SERVICES] = ''
            }

            if (type === QueriesFilterMenuType.SERVICES) {
              queryFilters[QueriesFilterMenuType.FILTERS] = ''
            }

            dispatch(setQueryFilters(queryFilters))

            if (type === QueriesFilterMenuType.CLIENT) {
              setSelectedClientId(item.uid)
            }

            if (type === QueriesFilterMenuType.ENDPOINT) {
              setSelectedDeviceId(item.uid)
            }
            hideQueryFilterDropdown?.[type]?.()
            dismiss?.()
          },
        }
      }) ?? []

    return options
  }, [
    searchQueryFilterParameters,
    type,
    filterParameters,
    dispatch,
    dismiss,
    setSelectedDeviceId,
    setSelectedClientId,
  ])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%', '26.8rem'],
        overflow: 'hidden',
        maxHeight: type === QueriesFilterMenuType.LOCATIONS ? '100%' : maxHeight || '30rem',
      }}
    >
      {type === QueriesFilterMenuType.LOCATIONS ? (
        <ProxyList
          isDropdown
          currentLocationPK={filterParameters[QueriesFilterMenuType.LOCATIONS]}
          proxyLocations={visibleProxyLocations}
          handleProxyClick={locationPK => {
            const queryFilters = {
              [QueriesFilterMenuType.LOCATIONS]:
                filterParameters[QueriesFilterMenuType.LOCATIONS] === locationPK ? '' : locationPK,
            }

            dispatch(setQueryFilters(queryFilters))
            hideQueryFilterDropdown?.[QueriesFilterMenuType.LOCATIONS]?.()
            dismiss?.()
          }}
          isOpen={true}
          isVisible={true}
          placeholder="Search"
          isBackgroundHighlighted
          sxMenu={{
            '& div[data-testid^=proxy-country]': {
              border: 'none',
              px: '1.2rem',
              '& div span': {
                fontSize: '1.4rem',
                ml: '0.8rem',
              },
            },
            '& [data-testid^=default-redirect-country]': {
              border: 'none',
              '& > div > div:nth-of-type(2)': {
                px: '0.4rem',
              },
            },
            '& [data-testid^=default-redirect-location]': {
              borderRadius: '0.8rem',
              py: '0.4rem',
              px: '0.8rem',
              height: 'auto',
              '&:not(:last-child)': {
                border: 'none',
              },
              '& div span': {
                fontWeight: 500,
              },
            },
          }}
          sxList={{ maxHeight: '28.5rem' }}
        />
      ) : (
        <>
          {isSearchVisible &&
            type !== QueriesFilterMenuType.ACTION &&
            type !== QueriesFilterMenuType.PROTOCOL && (
              <Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                <Flex
                  sx={{
                    position: 'relative',
                    width: '100%',
                    right: 0,
                    zIndex: 'zIndex240',
                    alignItems: 'center',
                    p: '0.4rem',
                  }}
                >
                  <Svg
                    svg={SearchIcon}
                    fill="aliceBlue60Black60"
                    sx={{
                      position: 'absolute',
                      left: '1.2rem',
                      width: '2.4rem',
                      height: '2.4rem',
                      flexShrink: 0,
                    }}
                    descriptiveText="Search icon"
                  />

                  <Box sx={{ width: '100%' }}>
                    <Input
                      isCompact
                      type="text"
                      ref={searchInputRef}
                      value={searchText}
                      variant="newPrimary"
                      data-testid={searchTestId || 'search-activity-log-filters'}
                      onChange={(e): void => {
                        setSearchText(e.target.value)
                      }}
                      tabIndex={0}
                      placeholder="Search"
                      sx={{ pl: '3.8rem', pr: '3.2rem' }}
                      autoComplete="off"
                      autoFocus={false}
                    />
                  </Box>

                  {searchText && (
                    <Button
                      data-testid="clear-search"
                      ariaLabel="clear search button"
                      variant="simple"
                      sx={{
                        p: 0,
                        ml: '0.6rem',
                        position: 'absolute',
                        right: '1.2rem',
                      }}
                      onClick={(): void => setSearchText('')}
                    >
                      <Svg
                        svg={CloseIcon}
                        fill="aliceBlue60Black60"
                        sx={{
                          cursor: 'pointer',
                          width: '2.4rem',
                          height: '2.4rem',
                          flexShrink: 0,
                        }}
                        descriptiveText="Clear search button"
                      />
                    </Button>
                  )}
                </Flex>
              </Flex>
            )}
          <Flex
            data-testid="queries-filter-submenu-content"
            sx={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              fontSize: '1.6rem',
              overflow: 'auto',
              maxHeight: '23.5rem',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
            }}
            className="hide-scrollbar"
          >
            {submenuOptions.length ? (
              <IconMenu
                hideRef={{ current: hideQueryFilterDropdown?.[type] }}
                isOpen={!!type}
                items={submenuOptions}
                sxButton={{
                  maxHeight: '4rem',
                  ':hover': {
                    fill: 'none',
                    opacity: 1,
                  },
                }}
                sxContainer={{
                  border: 0,
                  width: '100%',
                  pt: 0,
                }}
              />
            ) : (
              <Text
                variant="size15Weight500"
                sx={{ py: '1.6rem', color: 'aliceBlue60Black60' }}
              >{`No such ${
                type === QueriesFilterMenuType.FILTERS ? 'filter' : 'service'
              }...`}</Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
