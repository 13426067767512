import React, { ReactElement } from 'react'
import { Flex, Text, Box, useThemeUI } from 'theme-ui'
import navMenuLinks, { StaticPageNavMenuLink } from 'components/StaticPage/staticPageNavMenuLinks'
import { Link } from 'gatsby'
import SocialMediaButton from 'components/StaticPage/SocialMediaButton'
import { ExternalLink, Svg } from 'ui'
import TwitterIcon from 'images/homePage/footer/twitter-icon.svg'
import RedditIcon from 'images/homePage/footer/reddit-icon.svg'
import DiscordIcon from 'images/homePage/footer/dscrdicon.svg'
import ControldIcon from 'images/homePage/footer-logo.svg'
import CONTROLDLogo from 'ui/CONTROLD'

export const copyrightYear = new Date().getFullYear()

export default function StaticPageFooter(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        py: '2.4rem',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          mb: '1.6rem',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <FooterSubsection links={navMenuLinks.useCasesLinks} />
        <FooterSubsection links={navMenuLinks.supportLinks} />
        <FooterSubsection links={navMenuLinks.companyLinks} />
        <FooterSubsection />
      </Flex>
    </Flex>
  )
}

function FooterSubsection({ links }: { links?: StaticPageNavMenuLink[] }): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['22.6rem', '20rem', '20rem'],
        mb: '4rem',
        a: {
          textDecoration: 'none',
          color: 'white50',
          fontSize: '1.4rem',
          lineHeight: '3.2rem',
        },
      }}
    >
      {links ? <LinksSubsection links={links} /> : <IconSubsection />}
    </Flex>
  )
}

function LinksSubsection({ links }: { links: StaticPageNavMenuLink[] }): ReactElement {
  const { theme } = useThemeUI()
  return (
    <>
      {links.map(({ to: linkTo, text, isExternal }) => {
        if (!linkTo) {
          return (
            <Text
              key={text}
              sx={{
                fontWeight: 'bold',
                fontSize: '1.8rem',
                mb: '2.4rem',
                color: 'white',
              }}
            >
              {text}
            </Text>
          )
        } else {
          if (isExternal) {
            return (
              <ExternalLink
                key={text}
                to={linkTo}
                sx={{
                  ':hover': {
                    color: 'white',
                  },
                }}
              >
                {text}
              </ExternalLink>
            )
          } else {
            return (
              <Link
                key={text}
                to={linkTo}
                partiallyActive
                activeStyle={{ color: theme.colors?.text }}
              >
                <Text
                  sx={{
                    ':hover': {
                      color: 'white',
                    },
                  }}
                >
                  {text}
                </Text>
              </Link>
            )
          }
        }
      })}
    </>
  )
}

function IconSubsection(): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '2.4rem',
      }}
    >
      <Svg svg={ControldIcon} descriptiveText="ControlD logo" fill="white" width={'4.8rem'} />
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: '1.6rem',
          color: 'white50',
        }}
      >
        © {copyrightYear}{' '}
        <CONTROLDLogo
          CONTROLsx={{
            alignSelf: 'center',
            fontSize: '1.6rem',
            color: 'white50',
            letterSpacing: '-0.11em',
            lineHeight: '2.7rem',
            height: '100%',
          }}
          Dsx={{ color: 'white50', ml: '0.4rem' }}
        />
        , Inc.
      </Text>
      <Flex>
        <SocialMediaButton to="https://twitter.com/controldns" icon={TwitterIcon} />
        <Box sx={{ mr: '2.4rem' }} />
        <SocialMediaButton to="https://www.reddit.com/r/ControlD/" icon={RedditIcon} />
        <Box sx={{ mr: '2.4rem' }} />
        <SocialMediaButton to="https://discord.gg/dns" icon={DiscordIcon} />
      </Flex>
    </Flex>
  )
}
