import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import ArrowIcon from 'images/freeDNS/arrow-down.svg'

interface StandardConfigurationsItemHeaderProps {
  icon?: React.ElementType
  title?: string
  color?: string
  isOpen?: boolean
}

const StandardConfigurationsItemHeader = ({
  icon,
  title,
  color,
  isOpen,
}: StandardConfigurationsItemHeaderProps): ReactElement => {
  return (
    <Flex
      sx={{
        width: '100%',
        pt: '2.4rem',
        pb: [isOpen ? '5.6rem' : '2.4rem', '4.8rem'],
        px: '2.4rem',
        color: 'aliceBlue',
        backgroundColor: 'blue800',
        borderTopLeftRadius: '1.6rem',
        borderTopRightRadius: '1.6rem',
        borderBottomLeftRadius: [isOpen ? 0 : '1.6rem', 0],
        borderBottomRightRadius: [isOpen ? 0 : '1.6rem', 0],
        border: '1px solid',
        borderBottom: !isOpen ? '1px solid' : 'none',
        borderColor: 'wakaiwaGrey50',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {icon && <Svg svg={icon} descriptiveText={`${title} configuration icon`}></Svg>}
        <Flex
          sx={{
            alignItems: 'center',
            '> svg': {
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 0.2s ease-out',
            },
          }}
        >
          <Text tabIndex={0} sx={{ mr: ['1.6rem', '0'] }} variant="size21Weight600Line138">
            {title}
          </Text>
          <Svg
            svg={ArrowIcon}
            fill={isOpen ? 'aliceBlue' : color}
            descriptiveText={isOpen ? 'Collapse configuration' : 'Expand configuration'}
            sx={{
              display: ['inline-block', 'none'],
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StandardConfigurationsItemHeader
