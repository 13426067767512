import React, { ReactElement } from 'react'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'
import HomepageDropdownBase, { DropdownCommonProps } from './HomepageDropdownBase'

export function MainMenuDropdown({
  dropdownItems,
  children,
  withArrow,
  tippyprops,
  sxContainer,
  testId,
}: DropdownCommonProps): ReactElement {
  const { isPersonalHeader } = useDetermineTypeOfHeader()

  return (
    <HomepageDropdownBase
      ariaLabel="homepage dropdown"
      testId={testId}
      variant="roundedSimple"
      sxContainer={{
        flexDirection: isPersonalHeader ? 'column' : 'row',
        width: isPersonalHeader ? '38rem' : '77.6rem',
        py: '2.4rem',
        ...sxContainer,
      }}
      sxButton={{
        width: '36.4rem',
        minHeight: '8.2rem',
        opacity: 1,
        svg: {
          opacity: 0.7,
        },
      }}
      sx={{
        ':not(.tippy-box)': {
          py: '0.8rem',
          px: '1.6rem',
          '@media screen and (max-width: 1190px)': {
            px: '0.4rem',
          },
          '@media screen and (min-width: 1180px) and (max-width: 1400px)': {
            px: '0.8rem',
          },
        },
      }}
      {...{ dropdownItems, children, withArrow, tippyprops }}
    />
  )
}
