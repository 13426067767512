import React, { ReactElement } from 'react'
import HomepageDropdownBase, { DropdownCommonProps } from './HomepageDropdownBase'

export function GetStartedDropdown({
  dropdownItems,
  children,
  withArrow,
  tippyprops,
  sxContainer,
}: DropdownCommonProps): ReactElement {
  return (
    <HomepageDropdownBase
      testId="org-get-started-button"
      ariaLabel="Get Started"
      variant="gradientButton"
      sxContainer={{
        flexDirection: 'column',
        py: '0.8rem',
        width: ['calc(100vw - 1rem)', '28rem'],
        ...sxContainer,
      }}
      sxButton={{
        width: 'auto',
        minHeight: 'auto',
        flexWrap: 'nowrap',
        opacity: 1,
        svg: {
          opacity: 0.7,
        },
      }}
      sx={{
        ':not(.tippy-box)': {
          height: ['3.3rem', '4.1rem'],
          py: ['0.6rem', '0.6rem', '0.9rem'],
          px: ['1.2rem', '1.2rem', '2rem'],
          fontSize: ['1.5rem', '1.5rem', '1.7rem'],
          fontWeight: [600, 600, 500],
        },
      }}
      {...{ dropdownItems, children, withArrow, tippyprops }}
    />
  )
}
