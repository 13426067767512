import React, { MutableRefObject, ReactElement, useState } from 'react'
import { Flex, Spinner } from 'theme-ui'
import { Dropdown, IconMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import BalancedIcon from 'images/dashboard/filters/balanced-icon.svg'
import RelaxedIcon from 'images/dashboard/filters/relaxed-icon.svg'
import StrictIcon from 'images/dashboard/filters/strict-icon.svg'
import DropdownIcon from 'images/profileManagement/filter-caret-dropdown.svg'
import { FilterLevel } from 'store/api/filters/filters.interface'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import useGetColorMode from 'utils/useGetColorMode'

export const filterLevelIcons = {
  [FilterLevel.RELAXED]: RelaxedIcon,
  [FilterLevel.BALANCED]: BalancedIcon,
  [FilterLevel.STRICT]: StrictIcon,
}

export default function FilterLevelsDropdown({
  selectedTitle,
  items,
  hideRef,
  boundaryElement,
  isLoading = false,
}: {
  selectedTitle: string
  items: SimpleActionMenuItemProps[]
  hideRef: MutableRefObject<(() => void) | undefined>
  boundaryElement: Element | 'parent'
  isLoading?: boolean
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const { isLightMode } = useGetColorMode()

  if (isLoading) {
    return <Spinner sx={{ width: '1.5rem', height: '1.5rem' }} />
  }
  return (
    <Dropdown
      ariaLabel="filter levels dropdown"
      data-testid="filter-levels-dropdown"
      sx={{
        p: 0,
      }}
      tippyprops={{
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        appendTo: boundaryElement ?? 'parent',
        placement: 'bottom-start',
        offset: [0, 8],
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          setIsOpen(true)
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        zIndex: 20,
      }}
      dropdowncontent={
        <IconMenu
          dataTestId="toggles-dropdown-content"
          hideRef={hideRef}
          isOpen={isOpen}
          items={items}
          sxButton={{
            color: 'aliceBlue',
            ':hover': {
              fill: 'none',
              span: {
                opacity: 1,
              },
              svg: {
                opacity: 1,
              },
            },
          }}
          sxContainer={{
            width: '22rem',
            gap: '0.1rem',
            p: '0.4rem',
            borderRadius: '1.2rem',
            background: 'transparent',
            boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
          }}
        />
      }
      variant="transparent"
    >
      <Flex
        sx={{
          width: ['100%', 'fit-content'],
          alignItems: 'center',
          justifyContent: ['space-between', 'flex-start'],
          '> svg': {
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.2s ease-out',
          },
          ':hover': {
            '& > span': {
              opacity: 1,
            },
            'svg > g': {
              opacity: 1,
            },
          },
          ...(isOpen && {
            '& > span': {
              opacity: 1,
            },
            'svg > g': {
              opacity: 1,
            },
          }),
        }}
      >
        <TextWithOverFlowAndTippyPopup
          sxText={{
            color: 'aliceBlue',
            opacity: 0.6,
            mr: '0.4rem',
          }}
          variant="size12Weight400"
          content={selectedTitle}
          ariaLabel={selectedTitle}
        />
        <Svg
          svg={DropdownIcon}
          fill="aliceBlue"
          sx={{
            flexShrink: 0,
          }}
          descriptiveText="Dropdown icon"
        />
      </Flex>
    </Dropdown>
  )
}
