import { ThemeUIStyleObject } from 'theme-ui'

export const timeInputContainer = '.react-datepicker__input-time-container'
export const timeInput = '.react-datepicker-time__input'

export const timeInputStyles = (background: string): ThemeUIStyleObject => ({
  [timeInputContainer]: {
    display: 'flex',
    width: '100%',
    backgroundColor: background,
    justifyContent: 'center',
    borderTop: '1px solid',
    borderTopColor: 'blueYonder40',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    margin: 0,

    [timeInput]: {
      display: 'flex',
      width: '100%',
      margin: 0,
    },
  },
})
