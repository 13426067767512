import React, { ReactElement } from 'react'
import { WarningMessageWrapper } from 'components/WarningMessage'
import { orgStaticPageHeaderHeight } from './StaticPageHeader'
import { Text } from 'theme-ui'

export default function NoScriptWarningMessage(): ReactElement {
  return (
    <>
      <noscript
        style={{
          width: '100%',
        }}
      >
        <WarningMessageWrapper
          sx={{
            height: orgStaticPageHeaderHeight,
          }}
        >
          <Text variant={'size14Weight700'}>
            Warning: It looks like JavaScript is disabled in your browser. For the best experience,
            please enable JavaScript.
          </Text>
        </WarningMessageWrapper>
      </noscript>
    </>
  )
}
