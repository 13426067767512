import React, { ReactElement } from 'react'
import { Button, ButtonProps } from 'ui'
import { Flex, Text } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { useAppSelector } from 'store/hooks'
import { RuleType } from 'store/api/rules'
import { RedirectMode } from 'store/defaultRule'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { LimitedRedirectionServiceCategory } from '../Services'
import { useLocation } from '@reach/router'

export const tooltipVisibility = {
  [RuleType.BLOCK]: 'hasSeenBlockTooltip',
  [RuleType.WHITELIST]: 'hasSeenBypassTooltip',
  [RuleType.SPOOF_IP | RuleType.SPOOF_TAG]: 'hasSeenRedirectTooltip',
  [RedirectMode.AUTO]: 'hasSeenDefaultRuleRedirectAutoTooltip',
  [RedirectMode.MANUAL]: 'hasSeenDefaultRuleRedirectManualTooltip',
}

export const tooltipContent = {
  [RuleType.BLOCK]: {
    title: 'Block',
    description: (serviceName: string): string =>
      `You are about to make your first BLOCK rule. This will prevent ${serviceName} from loading on your device or network.`,
  },
  [RuleType.WHITELIST]: {
    title: 'Bypass',
    description: (serviceName: string): string =>
      `You are about to make your first BYPASS rule. This will make ${serviceName} load as it normally would without Control D.`,
  },
  [RuleType.SPOOF_IP | RuleType.SPOOF_TAG]: {
    title: 'Redirect',
    description: (
      serviceName: string,
      cityName: string,
      isVideoOrGamingCategory?: boolean,
    ): string =>
      `You are about to make your first REDIRECT rule. ${
        isVideoOrGamingCategory
          ? 'This service is offered on a best effort basis.'
          : `This will make ${serviceName} see you as if you're in ${cityName}`
      }`,
  },
  [RedirectMode.AUTO]: {
    title: 'Auto Redirect',
    description: (): string =>
      `You are about to make your first REDIRECT rule. This will mask your IP from all sites you visit.`,
  },
  [RedirectMode.MANUAL]: {
    title: 'Manual Redirect',
    description: (_: string, cityName: string): string =>
      `You are about to make your first REDIRECT rule. This will mask your IP and make you appear in ${cityName} from all sites you visit.`,
  },
}

interface ActionButtonTooltipProps extends ButtonProps {
  appendTo?: 'parent' | Element | ((ref: Element) => Element)
  isVisible: boolean
  cityName?: string
  serviceName?: string
  children: ReactElement
  onHideOrClick?: () => void
  ariaLabel: string
}
export default function ActionButtonTooltip({
  appendTo,
  isVisible,
  cityName,
  serviceName,
  children,
  onHideOrClick,
}: ActionButtonTooltipProps): ReactElement {
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const viewedStateByUserPk = useAppSelector(s => s.tutorial.viewedStateByUserPk[userPk ?? ''])
  const selectedRuleType = viewedStateByUserPk?.selectedRuleType
  const location = useLocation()
  const isVideoOrGamingCategory = [
    LimitedRedirectionServiceCategory.GAMING,
    LimitedRedirectionServiceCategory.VIDEO,
  ].some(category => location.pathname?.endsWith(category))

  return (
    <Tippy
      appendTo={appendTo || 'parent'}
      visible={isVisible}
      disabled={
        !!viewedStateByUserPk?.[tooltipVisibility[selectedRuleType]] ||
        !tooltipContent[selectedRuleType]?.title
      }
      className="reset-tippy-content-paddings"
      onHide={onHideOrClick}
      offset={[0, selectedRuleType === RedirectMode.MANUAL ? 60 : 10]}
      placement="bottom"
      content={
        <ActionButtonTooltipContent
          title={tooltipContent[selectedRuleType]?.title}
          description={tooltipContent[selectedRuleType]?.description(
            serviceName || 'the domain',
            cityName || 'the location you choose',
            isVideoOrGamingCategory,
          )}
          onClick={onHideOrClick}
        />
      }
      arrow={false}
      theme="rule"
      interactive={true}
      maxWidth="24rem"
    >
      {children}
    </Tippy>
  )
}

const ActionButtonTooltipContent = ({ title, description, onClick }): ReactElement => {
  return (
    <Flex
      data-testid="action-button-tooltip-content"
      sx={{ flexDirection: 'column', zIndex: 'zIndex50', gap: '0.8rem' }}
    >
      <Text variant="size15Weight700" sx={{ color: 'commonWhite' }}>
        {title}
      </Text>
      <Text variant="size12Weight400" sx={{ color: 'commonWhite60' }}>
        {description}
      </Text>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button ariaLabel="ok button" variant="transparent" sx={{ p: 0 }} onClick={onClick}>
          <Text variant="size15Weight700" sx={{ color: 'commonWhite' }}>
            Okay
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}
