import React, { useState, ReactElement, useEffect } from 'react'
import { Svg } from 'ui'
import { Flex, ThemeUIStyleObject, Text, Spinner } from 'theme-ui'
import { Button } from 'ui/Buttons'
import CheckmarkIcon from 'images/checkmark.svg'
import { PromoMode } from 'store/api/billing'
import { clearError, setPromoCode, setPromoMode } from 'store/billing'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { resetTrackingData } from 'store/tracking'
import CloseButton from 'components/Plans/SelectPlan/PromoCode/CloseButton'
import PromoCodeForm from 'components/Plans/SelectPlan/PromoCode/PromoCodeForm'
import { LoadingState } from 'store/fetchingLogic'
import { useGetProductsQuery, useLazyGetProductsQuery } from 'store/api/billing/products'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export interface PromoCodeContainerProps {
  tabsContainerSx?: ThemeUIStyleObject
  inputSx?: ThemeUIStyleObject
}

const ApplyPromoCodeButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="simple"
    data-testid="open-promo-code-form"
    ariaLabel="apply promo code button"
    sx={{
      border: 'none',
      p: 0,
      color: 'cyan800',
      '&:hover': {
        color: 'white',
        '& svg path': { fill: 'white' },
      },
    }}
    onClick={onClick}
  >
    <Text variant="size17Weight500Line138">Apply Promo Code</Text>
  </Button>
)

const PromoCodeApplied = ({
  promoCode,
  resetPromo,
}: {
  promoCode: string
  resetPromo: () => Promise<void>
}) => (
  <Flex
    sx={{
      width: ['100%', '100%', '46.8rem'],
      alignItems: 'center',
      justifyContent: 'space-between',
      px: '1.2rem',
      py: '0.8rem',
      border: '1px solid',
      borderColor: 'blueYonder40',
      borderRadius: '0.8rem',
      flex: '1 0 auto',
      height: '4.6rem',
    }}
  >
    <Flex sx={{ alignItems: 'center' }}>
      <Flex sx={{ gap: '1.2rem', alignItems: 'center', flexShrink: 0 }}>
        <Svg
          svg={CheckmarkIcon}
          fill="greenApple"
          width="1.6rem"
          height="1.6rem"
          descriptiveText="Promo code applied checkmark"
        />
        <Text
          variant="size14Weight400Line138Letter056"
          sx={{
            whiteSpace: 'nowrap',
            color: 'greenApple',
            mr: '0.2rem',
          }}
        >
          Code Applied:
        </Text>
      </Flex>
      <TextWithOverFlowAndTippyPopup
        ariaLabel="promo code"
        content={promoCode}
        variant="size14Weight400Line138Letter056"
        sxText={{
          color: 'greenApple',
        }}
      />
    </Flex>
    <CloseButton
      data-testid="promo-code-close-button"
      onClick={resetPromo}
      sx={{
        width: '2.4rem',
        height: '2.4rem',
        '&>svg': {
          width: '1.2rem',
          height: '1.2rem',
        },
      }}
    />
  </Flex>
)
export default function PromoCodeContainer({
  tabsContainerSx,
  inputSx,
}: PromoCodeContainerProps): ReactElement {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const {
    promoMode,
    promoCode,
    error: promoCodeError,
    data: productData,
  } = useAppSelector(s => s.products)

  const { loading } = useAppSelector(s => s.products)
  const trackingPromoCode = useAppSelector(s => s.tracking.trackingPromoCode)
  const [getProducts] = useLazyGetProductsQuery()
  useGetProductsQuery({ promoMode, promoCode: trackingPromoCode }, { skip: !trackingPromoCode })

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (trackingPromoCode) {
      dispatch(setPromoCode(trackingPromoCode))
      dispatch(resetTrackingData())
    }
  }, [trackingPromoCode, dispatch, promoMode])

  if (loading === LoadingState.PENDING) {
    return <Spinner color="greenApple" />
  }

  const isPromoCodeApplied =
    productData?.[0].price_points?.[0].type === 'promo' && promoCode && !promoCodeError

  return (
    <Flex sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {!isOpen && !isPromoCodeApplied && (
        <ApplyPromoCodeButton
          onClick={() => {
            setIsOpen(true)
            dispatch(setPromoMode(PromoMode.PROMO))
          }}
        />
      )}
      {isPromoCodeApplied && (
        <PromoCodeApplied
          promoCode={promoCode}
          resetPromo={async () => {
            dispatch(setPromoCode(''))
            dispatch(setPromoMode(PromoMode.PROMO))
            setIsOpen(false)
            await getProducts({})
          }}
        />
      )}
      {(isOpen || promoCodeError) && !isPromoCodeApplied && (
        <PromoCodeForm
          tabsContainerSx={tabsContainerSx}
          promoMode={promoMode}
          inputSx={inputSx}
          promoCodeError={promoCodeError}
          closeForm={() => {
            setIsOpen(false)
          }}
        />
      )}
    </Flex>
  )
}
