import React, { ElementType, MutableRefObject, ReactElement, useMemo } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import { Button, IconButtonContent, IconMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import CheckIcon from 'images/analytics/check.svg'
import { Setter } from 'utils'
import RedirectIcon from 'images/small-globe-icon.svg'
import BackIconSvg from 'images/back-icon-big.svg'
import BlockedQueriesIcon from 'images/menus/analytics/blocked-queries-icon.svg'
import BypassedQueriesIcon from 'images/menus/analytics/bypassed-queries-icon.svg'
import { AllActionType, RuleActionFilter, setRuleActionFilter } from 'store/customRules'
import { useAppDispatch } from 'store/hooks'
import { RuleType } from 'store/api/rules/rules.interface'

interface LineDisplayConfig {
  label: string
  icon: ElementType
}

export const displayConfigByType: Record<
  Exclude<RuleActionFilter, typeof AllActionType>,
  LineDisplayConfig
> = {
  [RuleType.BLOCK]: { label: 'Blocked', icon: BlockedQueriesIcon },
  [RuleType.WHITELIST]: { label: 'Bypassed', icon: BypassedQueriesIcon },
  [RuleType.SPOOF_TAG]: {
    label: 'Redirected',
    icon: RedirectIcon,
  },
}

const ruleActionFilters: RuleActionFilter[] = [
  AllActionType,
  RuleType.BLOCK,
  RuleType.WHITELIST,
  RuleType.SPOOF_TAG,
]

const ruleTypesToDropdownItemData: Record<RuleActionFilter, { label: string; icon?: ElementType }> =
  {
    all: { label: 'All Actions' },
    ...displayConfigByType,
  }

export default function ActionsMenu({
  hideRef,
  selectedType,
  setIsAllActionsMenuVisible,
  sx,
}: {
  hideRef: MutableRefObject<(() => void) | undefined>
  selectedType: RuleActionFilter
  setIsAllActionsMenuVisible: Setter<boolean>
  sx?: ThemeUIStyleObject
}): ReactElement {
  const dispatch = useAppDispatch()

  const queriesOptions: SimpleActionMenuItemProps[] = useMemo(() => {
    const options: SimpleActionMenuItemProps[] =
      ruleActionFilters.map((type): SimpleActionMenuItemProps => {
        const isSelected = type === selectedType
        return {
          dataTestId: `${type}-rule-action-menu-item`,
          ariaLabel: `${type} option`,
          isSelected,
          isBackgroundHighlighted: true,
          children: (
            <IconButtonContent
              startIcon={ruleTypesToDropdownItemData[type].icon}
              endIcon={isSelected ? CheckIcon : undefined}
            >
              <Text variant="size15Weight600">{ruleTypesToDropdownItemData[type].label}</Text>
            </IconButtonContent>
          ),

          onClick: () => {
            dispatch(setRuleActionFilter(type))
            hideRef.current?.()
          },
        }
      }) ?? []

    return options
  }, [dispatch, hideRef, selectedType])

  return (
    <Flex
      sx={{
        width: ['100%', '26.4rem'],
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          textAlign: 'center',
          color: 'aliceBlue',
          justifyContent: 'center',
          alignItems: 'center',
          pt: '0.4rem',
          pb: '0.4rem',
          pointerEvents: 'visible',
        }}
      >
        <Button
          ariaLabel="back button"
          variant="simple"
          sx={{
            position: 'absolute',
            top: '0.8rem',
            left: '0.4rem',
            p: 0,
            pointerEvents: 'visible',
            opacity: 0.6,
            ':hover:not([disabled])': {
              opacity: 1,
              svg: {
                opacity: 1,
                path: {
                  opacity: 1,
                  color: 'aliceBlue',
                },
              },
            },
          }}
          onClick={() => setIsAllActionsMenuVisible(false)}
        >
          <Svg
            svg={BackIconSvg}
            fill="aliceBlue"
            sx={{ width: '1.6rem', height: '1.6rem' }}
            descriptiveText="Back to Menu"
          />
        </Button>
        <Text variant="size15Weight700" sx={{ opacity: 0.6 }}>
          Actions
        </Text>
      </Flex>
      <IconMenu
        dataTestId="rule-action-menu-content"
        hideRef={hideRef}
        items={queriesOptions}
        sxButton={{
          pr: '0.8rem',
          minWidth: '22rem',
          ':hover': {
            fill: 'none',
            opacity: 1,
          },
        }}
        sxContainer={{
          border: 0,
          p: '0.4rem',
        }}
      />
    </Flex>
  )
}
