import FreeDnsIcon from 'images/organization/homepage/headerNavMenu/freeTools/free-dns.svg'
import WebsiteLinkCheckerIcon from 'images/organization/homepage/headerNavMenu/freeTools/website-link-checker.svg'

export const freeTools = [
  {
    header: 'Free DNS',
    icon: FreeDnsIcon,
    description: 'Free and Public DNS Servers From Control D.',
    href: '/free-dns',
  },
  {
    header: 'Website Link Checker',
    icon: WebsiteLinkCheckerIcon,
    description: 'Determine if a link is safe or if it should be avoided.',
    href: '/tools/website-link-checker',
  },
]
