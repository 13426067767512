import React, { ReactElement, useEffect } from 'react'
import { Flex, Link, Spinner, Text } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setPriceInterval, setSelectedProductPk } from 'store/billing'
import FullControlIcon from 'images/plans/fcicon.svg'
import SomeControlIcon from 'images/plans/smicon.svg'
import LinkOutIcon from 'images/plans/linkout.svg'
import { Svg } from 'ui'
import Feature from './Feature'
import Features from './Features'
import Plan from './Plan'
import PromoCodeContainer from './PromoCode/PromoCodeContainer'
import PriceElement from './PriceElement'
import useQueryString from 'utils/useQueryString'
import { PlansSteps } from 'components/Plans/usePlanWizardSteps'
import { LoadingState } from 'store/fetchingLogic'
import { useGetProductsQuery } from 'store/api/billing/products'
import { CommonPriceInterval, Product, ProductType } from 'store/api/billing/payments.interface'
import { EnabledStatus } from 'store/api/rules'

const freeTrialBannerHeight = [0, '8rem']

export const numberOfServices = '1000+'

function SomeControlFeaturesList(): ReactElement {
  return (
    <>
      <Feature
        name={
          <Text>
            <b>Block</b> {numberOfServices} Services
          </Text>
        }
        tooltip="If you want to block access to an entire Service, like Minecraft, TikTok or YouTube, this plan has you covered."
      />
      <Feature
        name="18 Blocking Categories"
        tooltip="Filter out things you don't want to see while browsing that waste your precious bandwidth, like Ads & Trackers, Porn or Social sites."
      />
      <Feature
        name="10,000 Custom Rules"
        tooltip="With Control D, you can make rules to Block or Bypass any domain. Fine-tune to your heart's desire up to 10,000 rules."
      />
      <Feature
        name="Advanced Malware Filter"
        tooltip="3 tier malware filter blocks most malicious domains using domain level and IP level threat intel feeds, as well as machine learning. "
      />
      <Feature
        name="Multiple Configs"
        tooltip="Protect your whole family on all their gadgets. Your account comes with 5 sets of Legacy resolvers as well as multiple DoH and DoT DNS resolvers."
      />
      <Feature
        name="Unlimited Usage"
        tooltip="No data restrictions. Browse as you always have, with the added protection of Control D."
      />
      <Feature
        name="Analytics"
        tooltip="See all kinds of insights about your browsing activity and how Control D has helped, with our Analytics feature (opt-in)."
      />
    </>
  )
}

function FullControlFeaturesList(): ReactElement {
  return (
    <>
      <Feature
        type="extra"
        name={
          <Text>
            <b>Redirect</b> {numberOfServices} Services
          </Text>
        }
        tooltip={
          'Spoof various web services, apps and platforms to geo-distributed proxy locations and appear to be in a different country.'
        }
      />
      <Feature
        type="extra"
        name="Default Rule"
        tooltip="Need to appear elsewhere all the time? Default Rule: Location is for you. With this feature enabled, all traffic will appear to originate from the Location of your choosing. You can also choose Block or Bypass as your Default Rule."
      />
      <Feature
        name="18 Blocking Categories"
        tooltip="Filter out things you don't want to see while browsing that waste your precious bandwidth, like Ads & Trackers, Adult Content or Social sites."
      />
      <Feature
        name="10,000 Custom Rules"
        tooltip="With Control D, you can make rules to Block, Redirect (proxy) or Bypass any domain. Fine-tune to your heart's desire up to 10,000 rules."
      />
      <Feature
        name="Advanced Malware Filter"
        tooltip="3 tier malware filter blocks most malicious domains using domain level and IP level threat intel feeds, as well as machine learning. "
      />
      <Feature
        name="Multiple Configs"
        tooltip="Protect your whole family on all their gadgets. Your account comes with 5 sets of Legacy resolvers, as well as multiple DoH and DoT DNS resolvers."
      />
      <Feature
        name="Unlimited Usage"
        tooltip="No data restrictions. Browse as you always have, with the added protection of Control D."
      />
      <Feature
        name="Analytics"
        tooltip="See all kinds of insights about your browsing activity and how Control D has helped, with our Analytics feature (opt-in)."
      />
    </>
  )
}

export const getPriceInterval = (control: Product): CommonPriceInterval => {
  if (control.price_points.length === 1) {
    return control.price_points[0].duration
  }

  return CommonPriceInterval.YEARLY
}

function SelectPlan({ onNextClick }: { onNextClick: () => void }): ReactElement {
  const dispatch = useAppDispatch()
  const {
    data: products,
    currentProduct,
    selectedCurrency,
    loading,
    promoCode,
    error: promoCodeError,
  } = useAppSelector(s => s.products)
  const { nav, qs } = useQueryString()
  const { isLoading: areProductsLoading, data: trialData } = useGetProductsQuery(
    {},
    { refetchOnMountOrArgChange: true },
  )

  const someControl = products?.find(x => x.name === ProductType.SOME)
  const fullControl = products?.find(x => x.name === ProductType.FULL)
  const isLoading = useAppSelector(s => LoadingState.PENDING === s.trialEligibility.loading)
  const isEligibleForTrial = useAppSelector(s => s.trialEligibility.isEligibleForTrial)
  const isPromoCodeApplied =
    products?.[0].price_points?.[0].type === 'promo' && promoCode && !promoCodeError
  const isTrial =
    isEligibleForTrial &&
    !isLoading &&
    !isPromoCodeApplied &&
    trialData?.do_trial === EnabledStatus.ENABLED

  const shouldForceTrial = useAppSelector(s => s.tracking.shouldForceTrial)

  const shouldShowPromoCodeForm = !currentProduct?.subscription?.status && !shouldForceTrial

  useEffect(() => {
    if (shouldForceTrial) {
      dispatch(setPriceInterval(CommonPriceInterval.TRIAL))
    }
  }, [dispatch, shouldForceTrial])

  if ((loading === LoadingState.PENDING || areProductsLoading) && !products) {
    return <Spinner color="greenApple" />
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mb: ['15rem', '6rem'],
        mx: ['1.6rem', 0],
        textAlign: 'center',
      }}
    >
      <Text
        as="h1"
        sx={{
          fontSize: '4rem',
          fontWeight: 600,
          color: 'aliceBlue',
        }}
        tabIndex={0}
      >
        {currentProduct ? 'Change Plan' : 'Select Plan'}
      </Text>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyItems: 'center',
            width: ['100%', '72rem', '72rem'],
            height: '100%',
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column-reverse', 'row'],
              mt: ['4rem', isTrial ? '3.2rem' : 0],
              flexWrap: 'nowrap',
              justifyContent: 'space-around',
              width: '100%',
              gap: ['4rem', '4.8rem'],
            }}
          >
            {someControl?.price_points && !shouldForceTrial && (
              <Flex
                sx={{
                  width: ['100%', '34rem'],
                  background: isTrial
                    ? 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)'
                    : 'transparent',
                  flexDirection: 'column',
                  borderRadius: '1.2rem',
                  mt: isTrial ? 0 : freeTrialBannerHeight,
                  px: isTrial ? '0.8rem' : 0,
                  py: isTrial ? '1.2rem' : 0,
                }}
              >
                {isTrial && (
                  <Text
                    variant="size17Weight700Line138"
                    data-testid="free-trial-banner"
                    sx={{
                      color: 'white',
                      justifyContent: 'center',
                      textAlign: 'center',
                      mb: '1.2rem',
                    }}
                  >
                    Free Trial - No Credit
                    <br />
                    Card Required
                  </Text>
                )}
                <Plan
                  name="Some Control"
                  color="banana"
                  onSelect={() => {
                    dispatch(setSelectedProductPk(someControl.PK))
                    dispatch(setPriceInterval(getPriceInterval(someControl)))
                    nav({ ...qs, step: PlansSteps.CREATE_ACCOUNT })
                  }}
                  priceElement={
                    <PriceElement
                      pricePoints={someControl?.price_points}
                      currency={selectedCurrency}
                    />
                  }
                  features={
                    <Features>
                      <SomeControlFeaturesList />
                    </Features>
                  }
                  icon={SomeControlIcon}
                  isCurrentPlan={currentProduct?.name === ProductType.SOME}
                />
              </Flex>
            )}
            {fullControl?.price_points && (
              <Flex
                sx={{
                  flexDirection: 'column',
                  width: ['100%', '34rem'],
                  height: 'fit-content',
                  mt: [0, isTrial ? '7.2rem' : freeTrialBannerHeight],
                }}
              >
                <Plan
                  name="Full Control"
                  color="berryBlue"
                  onSelect={() => {
                    dispatch(setSelectedProductPk(fullControl.PK))
                    dispatch(
                      setPriceInterval(
                        shouldForceTrial
                          ? CommonPriceInterval.TRIAL
                          : getPriceInterval(fullControl),
                      ),
                    )
                    onNextClick()
                  }}
                  priceElement={
                    shouldForceTrial ? (
                      <Text sx={{ fontWeight: 'normal' }}>30 day free trial</Text>
                    ) : (
                      <PriceElement
                        pricePoints={fullControl?.price_points}
                        currency={selectedCurrency}
                      />
                    )
                  }
                  features={
                    <Features>
                      <FullControlFeaturesList />
                    </Features>
                  }
                  icon={FullControlIcon}
                  isCurrentPlan={currentProduct?.name === ProductType.FULL}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        {shouldShowPromoCodeForm && (
          <Flex
            sx={{
              width: '100%',
              mt: '3.2rem',
              justifyContent: 'center',
              maxWidth: ['100%', '46.8rem'],
            }}
          >
            <PromoCodeContainer />
          </Flex>
        )}
      </Flex>
      <Flex
        sx={{
          mt: '3.2rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Link
          href={'/terms#refunds'}
          target="_blank"
          sx={{
            color: 'aliceBlue70',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            ':hover': {
              color: 'aliceBlue',
              'svg path': {
                fill: 'aliceBlue',
              },
            },
          }}
        >
          <Text variant="size17Weight500Line138">Refund Policy</Text>
          <Svg
            sx={{ ml: '0.8rem' }}
            fill="aliceBlue70"
            svg={LinkOutIcon}
            descriptiveText="External link"
          />
        </Link>
      </Flex>
    </Flex>
  )
}

export default SelectPlan
