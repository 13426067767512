import React, { ElementType, createRef, useEffect, useState } from 'react'
import AccordionProps from './Accordion.interface'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import ArrowIcon from 'images/ui/chevron.svg'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'

export default function Accordion(props: AccordionProps): React.ReactElement {
  const {
    children,
    title,
    id,
    isOpenControlled,
    setIsOpenControlled,
    titleStyle = {},
    openTitleStyle = {},
    buttonStyle = {},
    svgStyle = {},
    containerStyle = {},
    contentContainerStyle = {},
    fillColor,
    leftIconFillColor,
    icon,
    titleIcon,
    sxTitleIcon,
    initialIsOpen = false,
    sxTitleContainer,
    leftContent,
    tabIndex,
    buttonVariant = 'transparent',
    testId,
    ariaLabelButtonName,
    shouldUseSectionInAriaLabel = true,
  } = props
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  // if you prefer to control component state from the outside..
  const customSetIsOpen = setIsOpenControlled || setIsOpen
  const customIsOpen = isOpenControlled || isOpen

  const panelRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (panelRef.current) {
      const panelStyle = panelRef.current.style
      if (customIsOpen) {
        panelStyle.maxHeight = contentContainerStyle['maxHeight'] || 'fit-content'
        panelStyle.display = 'block'
      } else {
        panelStyle.maxHeight = ''
        panelStyle.display = 'none'
      }
    }
  }, [contentContainerStyle, customIsOpen, panelRef])

  const buttonIcon = icon ?? ArrowIcon

  const finalTitleStyle = customIsOpen && openTitleStyle ? openTitleStyle : titleStyle

  return (
    <Flex sx={{ flexDirection: 'column', ...containerStyle }} data-testid={id}>
      <Flex sx={{ alignItems: 'center' }}>
        {leftContent}
        <Button
          aria-controls={`${id}-section`}
          aria-expanded={customIsOpen}
          ariaLabel={
            customIsOpen
              ? `Collapse ${ariaLabelButtonName}${shouldUseSectionInAriaLabel ? ' section' : ''}`
              : `Expand ${ariaLabelButtonName}${shouldUseSectionInAriaLabel ? ' section' : ''}`
          }
          tabIndex={tabIndex}
          role="button"
          id={id}
          data-testid={testId || 'accordion-button'}
          onClick={(event): void => {
            event.stopPropagation()
            customSetIsOpen(!customIsOpen)
          }}
          variant={buttonVariant}
          onKeyDown={stopPropagationOnKeyPress}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            p: '1.6rem',
            flex: 1,
            justifyContent: 'space-between',
            cursor: 'pointer',
            ...buttonStyle,
          }}
        >
          <Flex
            sx={{
              minWidth: 'calc(100% - 2.4rem)',
              alignItems: 'center',
              pr: [0, '2.4rem'],
              ...sxTitleContainer,
            }}
          >
            {React.isValidElement(titleIcon) ? (
              titleIcon
            ) : (
              <Svg
                svg={titleIcon as ElementType}
                fill={leftIconFillColor || (customIsOpen ? 'aliceBlue' : 'aliceBlue60Black60')}
                sx={{
                  ...sxTitleIcon,
                }}
                descriptiveText={`${title} icon`}
              />
            )}
            <Text
              sx={{ color: customIsOpen ? 'aliceBlue' : 'aliceBlue60Black60', ...finalTitleStyle }}
            >
              {title}
            </Text>
          </Flex>
          <Flex
            sx={{
              transition: 'transform 0.1s',
              transform: `rotate(${customIsOpen ? '0deg' : '180deg'})`,
              flexShrink: 0,
              ...svgStyle,
            }}
          >
            <Svg
              svg={buttonIcon}
              fill={fillColor || (customIsOpen ? 'aliceBlue' : 'aliceBlue60Black60')}
              descriptiveText="Accordion toggle icon"
            />
          </Flex>
        </Button>
      </Flex>
      <Flex sx={{ width: '100%', position: 'relative', flexShrink: 0 }}>
        <Flex
          data-testid="accordion-content"
          ref={panelRef}
          aria-labelledby={id}
          id={`${id}-section`}
          role="region"
          sx={{
            width: '100%',
            maxHeight: 0,
            overflow: 'hidden',
            transition: 'max-height 0.2s ease-out',
            flexShrink: 0,
            ...contentContainerStyle,
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
