import React, { ReactElement, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import CustomRulesTitle from 'components/Dashboard/Profiles/CustomRules/CustomRulesTitle'
import BackButton from 'components/Dashboard/Profiles/CustomRules/Buttons/BackButton'
import { useAppSelector } from 'store/hooks'
import { RuleType } from 'store/api/rules/rules.interface'
import BlockFolder from 'images/blockfolder.svg'
import RedirectFolder from 'images/redirectfolder.svg'
import BypassFolder from 'images/bypassfolder.svg'
import NoRuleFolder from 'images/folder-icon-small.svg'
import { Svg } from 'ui'
import { useGetProxiesQuery } from 'store/api/proxies'
import MagicFolder from 'images/magic-folder.svg'
import customUnescape from 'utils/customUnescape'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/CommonComponents/ExternalSvgIcon'

export default function GroupMenu(): ReactElement {
  const currentGroupData = useAppSelector(s => s.groups.currentGroup)

  const { data: proxiesData } = useGetProxiesQuery('')
  const redirectLocation = proxiesData?.proxies?.find(p => p.PK === currentGroupData?.action.via)

  const groupDetails = useMemo(() => {
    const { action, count = 0, is_magic } = currentGroupData ?? {}
    switch (action?.do) {
      case RuleType.BLOCK:
        return { icon: BlockFolder, description: `${count} Block Rule${count !== 1 ? 's' : ''}` }
      case RuleType.SPOOF_IP:
        return {
          icon: RedirectFolder,
          description: `${count} Redirect Rule${count !== 1 ? 's' : ''}`,
        }
      case RuleType.SPOOF_TAG:
        return {
          icon: RedirectFolder,
          description: `${count} Redirect (${
            redirectLocation?.city ||
            (action?.via === '?'
              ? 'a random location'
              : action?.via === 'LOCAL'
              ? 'Auto'
              : action?.via)
          }) Rule${count !== 1 ? 's' : ''}`,
        }
      case RuleType.WHITELIST:
        return {
          icon: BypassFolder,
          description: `${count} Bypass Rule${count !== 1 ? 's' : ''} `,
        }
      default:
        return {
          icon: !!is_magic ? MagicFolder : NoRuleFolder,
          description: `${count} rule${count !== 1 ? 's' : ''}`,
        }
    }
  }, [currentGroupData, redirectLocation])
  return (
    <Flex
      sx={{
        position: ['static', 'relative'],
        width: ['100%', 'inherit'],
        alignItems: 'center',
      }}
    >
      <BackButton />
      <CustomRulesTitle>
        {customUnescape(currentGroupData?.group) && (
          <Flex sx={{ alignItems: 'center' }}>
            <Flex sx={{ position: 'relative', mr: '1.2rem', alignItems: 'center' }}>
              <Svg
                svg={groupDetails.icon}
                fill="aliceBlue"
                sx={{ width: '3.2rem', height: '3.2rem' }}
                descriptiveText={`${currentGroupData?.action?.do || 'Empty'} Folder Icon`}
              />
              {redirectLocation?.country && (
                <ExternalSvgIcon
                  icon={redirectLocation?.country || ''}
                  type={IconType.LOCATIONS}
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                    width: '1.8rem',
                    height: '1.8rem',
                  }}
                  descriptiveText={`${redirectLocation?.city}, ${redirectLocation?.country} Flag`}
                />
              )}
            </Flex>
            <Flex sx={{ flexDirection: 'column' }}>
              <Text variant="size15Weight700" sx={{ color: 'aliceBlue', mb: '0.2rem' }}>
                {customUnescape(currentGroupData?.group)}
              </Text>
              <Text
                variant="size12Weight400"
                className="fill-available"
                data-testid="group-description"
                sx={{
                  color: 'aliceBlue60',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {groupDetails.description}
              </Text>
            </Flex>
          </Flex>
        )}
      </CustomRulesTitle>
    </Flex>
  )
}
