import React, { ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUICSSObject } from 'theme-ui'
import { Svg } from 'ui'
import InfoIcon from 'images/dashboard/info-icon.svg'

export const WarningMessageBox = ({
  isCompact = true,
  children,
  sxContent,
}: {
  isCompact?: boolean
  children: ReactNode
  sxContent?: ThemeUICSSObject
}): ReactElement => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: ['auto', '5.4rem'],
        flexDirection: 'row',
        borderWidth: '1px',
        borderStyle: ['none', 'solid'],
        borderColor: 'blueYonder15',
        borderRadius: '1.2rem',
        mb: ['0rem', '1.2rem'],
        pt: ['0.4rem', '1.2rem'],
        pb: '1.2rem',
        px: '1.2rem',
        alignItems: 'center',
        gap: '1.2rem',
      }}
    >
      <Svg
        svg={InfoIcon}
        descriptiveText="Information icon"
        fill="aliceBlue60"
        sx={{
          width: '2.4rem',
          height: '2.4rem',
          flexShrink: 0,
          alignSelf: ['flex-start', 'inherit'],
          mt: 0,
        }}
      />
      <Text
        tabIndex={0}
        variant={isCompact ? 'size14Weight600' : 'size15Weight700'}
        sx={{ color: 'aliceBlue60', ...sxContent }}
      >
        {children}
      </Text>
    </Flex>
  )
}
