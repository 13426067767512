import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export function MenuItem({
  text,
  to,
  linkState,
  sx,
}: {
  text: string
  to: string
  linkState?: Record<string, string>
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexShrink: 0,
        height: '3.9rem',
        borderRadius: '10rem',
        py: '0.8rem',
        px: '1.6rem',
        '@media screen and (max-width: 1400px)': {
          px: '0.8rem',
        },
        ':hover': {
          backgroundColor: 'wakaiwaGrey20',
        },
        '& > a': {
          color: 'lightSteelBlue',
          textDecoration: 'none',
          fontSize: '1.7rem',
          fontWeight: 500,
        },
        ...sx,
      }}
    >
      <Link key={`${text}-Link`} to={to} state={linkState}>
        {text}
      </Link>
    </Flex>
  )
}
