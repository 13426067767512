import React, { ReactElement, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Accordion, Svg } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import Options from './Options'
import { Provision, StatusType } from 'store/api/provision'
import moment from 'moment'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import PreventDeactivationIcon from 'images/profileManagement/settings/prevent-deactivation.svg'
import { TableCell, TableRow } from 'ui/Table'
import { RowContainer } from 'components/Dashboard/Preferences/Api/TokenAccordionItem'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export default function ProvisionElement({
  provision,
  tableHeaders,
}: {
  provision: Provision
  tableHeaders: string[]
}): ReactElement {
  const breakpoint = useBreakpointIndex()

  const ProvisionElement =
    breakpoint > 1 ? ProvisionElementDesktop : ProvisionElementMobileAndTablet

  return <ProvisionElement provision={provision} tableHeaders={tableHeaders} />
}

const ProvisionElementDesktop = ({ provision }: { provision: Provision }): ReactElement => {
  const { PK, status, ts_exp, profile, max, ts, icon, count, expired, deactivation_pin } = provision

  return (
    <TableRow dataTestId="provision-row">
      <TableCell sx={{ flex: '0 1 20rem' }} testId="provision-created-on">
        {moment(new Date(ts * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
      </TableCell>
      <TableCell testId="provision-device-icon">
        <Flex sx={{ pl: deactivation_pin ? '1.2rem' : '2.8rem', gap: '0.8rem' }}>
          <Svg
            data-testid="provision-device-icon"
            svg={DeviceIcons[icon ?? defaultIconName]}
            sx={{ width: '2.4rem', height: '2.4rem' }}
            fill="aliceBlue60"
            descriptiveText={`Device type icon: ${icon ?? defaultIconName}`}
          />
          {deactivation_pin && (
            <IconWithTippyPopup
              content={deactivation_pin}
              ariaLabel={deactivation_pin.toString()}
              svg={PreventDeactivationIcon}
              fill="aliceBlue60"
              sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
              descriptiveText="Deactivation pin protection indicator"
            />
          )}
        </Flex>
      </TableCell>
      <TableCell sx={{ mr: '0.8rem' }} testId="provision-profile-name">
        <TextWithOverFlowAndTippyPopup content={profile.name} ariaLabel={profile.name} />
      </TableCell>
      <TableCell testId="provision-status">
        {!!expired
          ? 'Expired'
          : status === StatusType.INVALIDATED
          ? 'Invalidated'
          : status === StatusType.LIMIT_REACHED
          ? 'Limit Reached'
          : 'Active'}
      </TableCell>
      <TableCell sx={{ flex: '0 1 20rem' }} testId="provision-expired-date">
        {ts_exp === 0 ? 'Never' : moment(new Date(ts_exp * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
      </TableCell>
      <TableCell sx={{ flex: '0 1 10rem' }} testId="provision-limit">{`${count}/${max}`}</TableCell>
      <TableCell sx={{ flex: '0 1 2.4rem' }}>
        <Options provisionPk={PK} />
      </TableCell>
    </TableRow>
  )
}

const ProvisionElementMobileAndTablet = ({
  provision,
  tableHeaders,
}: {
  provision: Provision
  tableHeaders: string[]
}): ReactElement => {
  const { PK, status, ts_exp, profile, max, ts, icon, count, expired, deactivation_pin } = provision
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box>
      <Accordion
        testId="provision-accordion"
        ariaLabelButtonName="provision"
        id={PK}
        title={
          <Flex sx={{ alignItems: 'center', mr: '0.8rem' }}>
            {moment(new Date(ts * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
          </Flex>
        }
        openTitleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 1 }}
        titleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 0.6 }}
        buttonStyle={{
          p: '1.2rem',
          pb: isOpen ? '0.8rem' : '1.2rem',
          '& > div svg path': {
            opacity: isOpen ? 1 : 0.6,
          },
        }}
        fillColor="aliceBlue"
        containerStyle={{
          backgroundColor: 'cardBg',
          borderBottom: '1px solid',
          borderColor: 'blueYonder15',
          ':first-of-type': { borderTop: '1px solid', borderColor: 'blueYonder15' },
        }}
        isOpenControlled={isOpen}
        setIsOpenControlled={isOpen => {
          setIsOpen(isOpen)
        }}
      >
        <Flex
          sx={{
            width: '100%',
            px: '1.2rem',
            pb: '1.2rem',
            gap: ['0.8rem', '1.2rem'],
            flexDirection: ['column', 'row'],
          }}
        >
          <Flex sx={{ flexDirection: 'column', gap: '0.8rem', flex: 1 }}>
            <RowContainer>
              <Text variant="size15Weight700">{tableHeaders[1]}</Text>
              <Svg
                data-testid="provision-device-icon"
                svg={DeviceIcons[icon ?? defaultIconName]}
                sx={{ width: '2.4rem', height: '2.4rem' }}
                fill="white50"
                descriptiveText={`Device type icon: ${icon ?? defaultIconName}`}
              />
              {deactivation_pin && (
                <IconWithTippyPopup
                  content={deactivation_pin}
                  ariaLabel={deactivation_pin.toString()}
                  svg={PreventDeactivationIcon}
                  fill="aliceBlue60"
                  sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0, color: 'aliceBlue60' }}
                  descriptiveText="Deactivation pin protection indicator"
                />
              )}
            </RowContainer>
            <RowContainer>
              <Text variant="size15Weight700">{tableHeaders[2]}</Text>
              <Text
                variant="size15Weight400"
                sx={{
                  color: 'aliceBlue60',
                }}
              >
                {profile.name}
              </Text>
            </RowContainer>
            <RowContainer>
              <Text variant="size15Weight700">{tableHeaders[3]}</Text>
              <Text
                variant="size15Weight400"
                sx={{
                  color: 'aliceBlue60',
                }}
              >
                {!!expired
                  ? 'Expired'
                  : status === StatusType.INVALIDATED
                  ? 'Invalidated'
                  : status === StatusType.LIMIT_REACHED
                  ? 'Limit Reached'
                  : 'Active'}
              </Text>
            </RowContainer>
          </Flex>
          <Flex sx={{ flexDirection: 'column', gap: '0.8rem', flex: 1 }}>
            <RowContainer>
              <Text variant="size15Weight700">{tableHeaders[4]}</Text>
              <Text
                variant="size15Weight400"
                sx={{
                  color: 'aliceBlue60',
                }}
              >
                {ts_exp === 0
                  ? 'Never'
                  : moment(new Date(ts_exp * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
              </Text>
            </RowContainer>{' '}
            <RowContainer>
              <Text variant="size15Weight700">{tableHeaders[5]}</Text>
              <Text
                variant="size15Weight400"
                sx={{
                  color: 'aliceBlue60',
                }}
              >{`${count}/${max}`}</Text>
            </RowContainer>
            <RowContainer>
              <Text variant="size15Weight700">Options</Text>
              <Options provisionPk={PK} />
            </RowContainer>
          </Flex>
        </Flex>
      </Accordion>
    </Box>
  )
}
