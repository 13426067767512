import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import StandardConfigurationsItem from 'components/FreeDNSPage/StandardConfigurationsItem'
import { ResolverKey, standardConfigurationsData } from 'components/FreeDNSPage/constants/freeDNS'
import { Svg, TrayRenderProps, useTrayPresenter } from 'ui'
import UncensoredDomainsTrayContent from './TrayContents/UncensoredDomainsTrayContent'
import LimitationsTrayContent from './TrayContents/LimitationsTrayContent'
import ConfigIcon from 'images/organization/freeDns/standard-configuration.svg'
import { TextWithBreakpointVariant } from 'ui/TextWithBreakpointVariant'

export interface StandardConfigurationType {
  key: ResolverKey
  icon: React.ElementType
  title: string
  isRecommended?: boolean
  firstTab: {
    title: string
    content: string[]
    links?: string[]
  }
  secondTab: {
    title: string
    content: string[]
  }
}

const StandardConfigurations = (): ReactElement => {
  /*Directly accessing third item in standardConfigurationsData so "Ads & Tracking" card will be open on default*/
  const [activeType, setActiveType] = useState<ResolverKey | undefined>(
    standardConfigurationsData?.[2].key || '',
  )
  const uncensoredDomainsTray = useMemo(
    () => ({
      id: 'UncensoredDomainsTray',
      renderTray: (props: TrayRenderProps) => <UncensoredDomainsTrayContent {...props} />,
      zIndexName: 'zIndex550',
    }),
    [],
  )
  const { showTray: showUncensoredDomainsTray } = useTrayPresenter(uncensoredDomainsTray)
  const limitationsTray = useMemo(
    () => ({
      id: 'LimitationsTray',
      renderTray: (props: TrayRenderProps) => <LimitationsTrayContent {...props} />,
      zIndexName: 'zIndex550',
    }),
    [],
  )
  const { showTray: showLimitationsTray } = useTrayPresenter(limitationsTray)

  const onClickHandler = useCallback(
    linkName => (linkName === 'Limitations' ? showLimitationsTray() : showUncensoredDomainsTray()),
    [showLimitationsTray, showUncensoredDomainsTray],
  )

  return (
    <Flex
      sx={{
        width: '100%',
        px: ['1.2rem', 0, 0],
        mt: ['6.4rem', '0'],
        mb: ['6.4rem', '10rem'],
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Flex
        className="ios-scrolling-glitches-fix"
        sx={{
          position: 'absolute',
          display: ['none', 'none', 'flex'],
          width: '100%',
          height: '32rem',
          top: '4.8rem',
          background: 'hotPink10',
          filter: 'blur(150px)',
          zIndex: 'zIndex-50',
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          height: 'fit-content',
          mb: ['4.2rem', '8rem'],
          mx: ['0.4rem'],
        }}
      >
        <Flex
          sx={{
            position: 'relative',
            width: '8rem',
            height: '8rem',
            py: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Svg svg={ConfigIcon} descriptiveText="Standard configurations icon" />
        </Flex>
        <TextWithBreakpointVariant
          variant={['size28Weight600', 'size40Weight600']}
          as="h2"
          sx={{
            color: 'aliceBlue',
            mt: '1.6rem',
          }}
          tabIndex={0}
        >
          Standard Configurations
        </TextWithBreakpointVariant>
        <Box
          sx={{
            display: ['block', 'block', 'none'],
            position: 'absolute',
            width: '100%',
            height: '10rem',
            backgroundColor: 'rgba(255, 102, 180, 0.20)',
            filter: 'blur(75px)',
          }}
        />
        <Text
          variant="size16Weight400"
          sx={{
            position: 'relative',
            width: ['100%', '48rem'],
            maxWidth: '48rem',
            color: 'lightSteelBlue',
            lineHeight: ['138%', '138%', '140%'],
            mt: ['0.8rem', '0.4rem'],
          }}
          tabIndex={0}
        >
          Pick a configuration that best suits your requirements, or use the custom builder below to
          fine tune exactly what you need.
        </Text>
      </Flex>
      <Flex
        sx={{
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
          gap: ['1.6rem', '4.8rem'],
          maxWidth: '99.9rem',
          zIndex: 'zIndex1',
        }}
      >
        {standardConfigurationsData?.map(item => (
          <StandardConfigurationsItem
            key={item.key}
            item={item}
            activeType={activeType}
            setActiveType={type => {
              setActiveType(activeType === type ? undefined : type)
            }}
            onClickHandler={onClickHandler}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default StandardConfigurations
