import { Flex } from 'theme-ui'
import React, { ReactElement, ReactNode } from 'react'

export default function ProfilesPagesContainer({
  children,
  loadingPlaceholder,
}: {
  children?: ReactNode
  loadingPlaceholder?: ReactNode
}): ReactElement {
  return (
    <Flex
      sx={{
        position: 'relative',
        pl: 0,
        flexDirection: 'column',
        minHeight: 'inherit',
      }}
    >
      <Flex
        sx={{
          // should be absolute for proper rendering of the virtualized grid/list
          position: 'absolute',
          top: 0,
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {loadingPlaceholder}
      </Flex>
      <Flex
        sx={{
          opacity: loadingPlaceholder ? 0 : 1,
          flexDirection: 'column',
          minHeight: 'inherit',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
