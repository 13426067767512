import React, { ElementType, ReactChild, ReactElement, useState } from 'react'
import { Flex } from 'theme-ui'
import { Dropdown, Svg } from 'ui'
import DropdownIcon from 'images/ui/chevron-up.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { Instance } from 'tippy.js'
import ExternalSvgIcon, {
  ExternalIcon,
} from 'components/Dashboard/CommonComponents/ExternalSvgIcon'
import useGetColorMode from 'utils/useGetColorMode'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useLocation } from '@reach/router'

export default function LogFilterDropdown({
  children,
  isDisabled,
  value,
  isValueSelected,
  icon,
  externalIcon,
  testId,
  onShow,
  onCreate,
  isLocation,
  ariaLabel,
}: {
  children: ReactChild | ReactChild[]
  isDisabled?: boolean
  value: string
  isValueSelected?: boolean
  icon?: ElementType
  externalIcon?: ExternalIcon
  testId: string
  onShow?: () => void
  onCreate?: (instance: Instance) => void
  isLocation?: boolean
  ariaLabel: string
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { isLightMode } = useGetColorMode()
  const boundaryElement = useBoundaryElement('filters-container')
  const location = useLocation()
  const isAdminLogs = location.pathname.endsWith('admin-logs')

  return (
    <Dropdown
      data-testid={testId}
      ariaLabel={ariaLabel}
      tippyprops={{
        appendTo: isAdminLogs ? 'parent' : boundaryElement || 'parent',
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        offset: [0, 8],
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          onShow?.()
          setIsOpen(true)
        },
        onCreate,
        maxWidth: '100%',
        zIndex: 300,
        placement: 'bottom-start',
      }}
      sx={{
        p: 0,
        flexShrink: 0,
        ':disabled': {
          cursor: 'auto',
          backgroundColor: 'transparent',
          color: 'white6',
          '& span': {
            color: 'white25',
          },
          '& div svg path': {
            fill: 'white25',
          },
          '& > svg path': {
            fill: 'white25',
          },
          ':hover': {
            '& span': {
              color: 'white25',
            },
          },
        },
      }}
      variant="transparent"
      dropdowncontent={isOpen ? children : <></>}
      disabled={isDisabled}
    >
      <Flex
        sx={{
          width: 'fit-content',
          pr: '0.8rem',
          pl: 0,
          py: '0.8rem',
          justifyContent: 'space-between',
          fontSize: ['1.6rem', '1.6rem', '1.8rem'],
          color: isOpen ? 'aliceBlue' : 'aliceBlue60Black60',
          alignItems: 'center',
          '& > svg:last-of-type': {
            transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
          },
          ':hover': {
            '& span': {
              color: 'aliceBlue',
            },
            '& div svg path': {
              fill: !isLocation && 'aliceBlue',
            },
            '& > svg path': {
              fill: 'aliceBlue',
            },
          },
        }}
      >
        <Flex sx={{ alignItems: 'center', gap: '0.8rem' }}>
          {externalIcon && (
            <ExternalSvgIcon
              icon={externalIcon.icon}
              type={externalIcon.type}
              fill={
                isLocation ? undefined : isOpen || isValueSelected ? 'aliceBlue' : 'aliceBlue60'
              }
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
              descriptiveText={`${externalIcon.icon} icon`}
            />
          )}
          {icon && (
            <Svg
              svg={icon}
              fill={
                isLocation
                  ? undefined
                  : isOpen || isValueSelected
                  ? 'aliceBlue'
                  : 'aliceBlue60Black60'
              }
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
              descriptiveText="Filter icon"
            />
          )}
          <TextWithOverFlowAndTippyPopup
            variant="size14Weight700"
            content={value ?? ''}
            ariaLabel={value ?? ''}
            sxText={{
              textAlign: 'left',
              maxWidth: '20rem',
              color: isOpen || isValueSelected ? 'aliceBlue' : 'aliceBlue60Black60',
            }}
          />
        </Flex>
        <Svg
          svg={DropdownIcon}
          fill={isOpen ? 'aliceBlue' : 'aliceBlue60Black60'}
          sx={{
            width: '1.6rem',
            height: '1.6rem',
            ml: '0.4rem',
            flexShrink: 0,
          }}
          descriptiveText="Dropdown arrow icon"
        />
      </Flex>
    </Dropdown>
  )
}
