import { Button, Svg, TrayRenderProps } from 'ui'
import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import TrayHeader from 'components/TrayHeader'
import CloseIcon from 'images/profileManagement/close-icon.svg'
import { useAppSelector } from 'store/hooks'

export default function FilterDescriptionTrayContent({ dismiss }: TrayRenderProps): ReactElement {
  const selectedFilter = useAppSelector(s => s.filters.selectedFilter)
  const { name: title, description } = selectedFilter || {}
  return (
    <Box data-testid="filter-description-tray">
      <TrayHeader
        sx={{
          pb: '1.6rem',
          px: '1.6rem',
          paddingTop: '1.6rem',
        }}
        leftComponent={
          <Button
            data-testid="close-filter-description-tray"
            ariaLabel="close tray"
            variant="simple"
            sx={{ p: 0 }}
            onClick={dismiss}
          >
            <Svg svg={CloseIcon} descriptiveText="Close filter description tray" fill="white50" />
          </Button>
        }
      >
        <Text tabIndex={0} variant="size15Weight700" sx={{ color: 'labelColor' }}>
          {title}
        </Text>
      </TrayHeader>
      <Flex
        sx={{
          flexDirection: 'column',
          pt: '2.4rem',
          px: '1.6rem',
          height: '100%',
        }}
      >
        <Text
          variant="size16Weight400"
          tabIndex={0}
          data-testid="filter-description-text"
          sx={{ color: 'textColor' }}
        >
          {description}
        </Text>
      </Flex>
    </Box>
  )
}
