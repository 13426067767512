import React, { ReactElement, useRef, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import RocketProgressIcon from './RocketProgressIcon'
import Checklist from './Checklist'
import { Flex } from 'theme-ui'
import { useChecklistStatus } from 'components/Dashboard/utils/useCheckListStatus'
import { ChecklistItems } from './ChecklistItems'

export default function ChecklistContainer(): ReactElement {
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const collapsedRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    isProfileCreated,
    isFilterEnabled,
    isEndpointAdded,
    isEndpointConfigured,
    firstProfilePK,
    checklistPercentage,
    areRequestsFinished,
  } = useChecklistStatus()

  return (
    <>
      {areRequestsFinished && (
        <>
          <Flex
            sx={{ display: ['none', 'flex', !isNavBarExpanded ? 'flex' : 'none'], width: '100%' }}
          >
            <RocketProgressIcon
              collapsedRef={collapsedRef}
              checklistPercentage={checklistPercentage}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Flex>
          <Flex
            sx={{
              display: [
                'flex',
                isExpanded ? 'flex' : 'none',
                isNavBarExpanded ? 'flex' : isExpanded ? 'flex' : 'none',
              ],
            }}
          >
            <Checklist
              collapsedRef={collapsedRef}
              checklistPercentage={checklistPercentage}
              onClose={() => setIsExpanded(false)}
            >
              <ChecklistItems
                isProfileCreated={isProfileCreated}
                isFilterEnabled={isFilterEnabled}
                isEndpointAdded={isEndpointAdded}
                isEndpointConfigured={isEndpointConfigured}
                firstProfilePK={firstProfilePK}
              />
            </Checklist>
          </Flex>
        </>
      )}
    </>
  )
}
