import React, { ReactElement, useRef } from 'react'
import { IconButtonContent, SimpleActionMenuItemProps } from 'ui'
import { EnabledStatus } from 'store/api/rules'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowActiveOnly } from 'store/services'
import CheckmarkIcon from 'images/checkmark.svg'
import ShowAllIcon from 'images/show-all-icon.svg'
import ShowOnlyActiveIcon from 'images/onlyactiveicon.svg'
import MoreButton from 'ui/MoreButton'
import useGetServicesData from 'components/Dashboard/Profiles/Services/useGetServicesData'

interface ServicesOptionsMenuProps {
  categoryId?: string
}

export default function ServicesOptionsMenu({
  categoryId,
}: ServicesOptionsMenuProps): ReactElement {
  const dispatch = useAppDispatch()
  const hideRef = useRef<() => void>()
  const showActiveOnly = useAppSelector(s => !!s.services.showActiveOnly)
  const { userServices } = useGetServicesData()
  const serviceCount = (userServices ?? []).filter(({ category }) => category === categoryId).length

  const activeServiceCount = (userServices ?? []).filter(
    ({ category, action, global }) =>
      category === categoryId &&
      ((global?.action?.status === EnabledStatus.ENABLED &&
        action?.ovr !== EnabledStatus.ENABLED) ||
        action?.status === EnabledStatus.ENABLED),
  ).length

  const listOptions: SimpleActionMenuItemProps[] = [
    {
      dataTestId: 'services-option-all',
      ariaLabel: 'show all option',
      children: (
        <IconButtonContent startIcon={ShowAllIcon} endIcon={!showActiveOnly && CheckmarkIcon}>
          Show All ({serviceCount})
        </IconButtonContent>
      ),
      isSelected: !showActiveOnly,
      onClick: () => {
        dispatch(setShowActiveOnly(false))
        hideRef.current?.()
      },
    },
    {
      dataTestId: 'services-option-only-active',
      ariaLabel: 'show only active option',
      children: (
        <IconButtonContent startIcon={ShowOnlyActiveIcon} endIcon={showActiveOnly && CheckmarkIcon}>
          Show Only Active ({activeServiceCount})
        </IconButtonContent>
      ),
      isSelected: showActiveOnly,
      onClick: () => {
        dispatch(setShowActiveOnly(true))
        hideRef.current?.()
      },
    },
  ]

  return (
    <MoreButton
      options={listOptions}
      ariaLabel="services options"
      dataTestId="services-options-button"
      hideRef={hideRef}
      appendToBoundaryElement="dashboard-sub-header"
      shouldUseNewDropdownDesign={true}
      shouldHighlightOnHover
    />
  )
}
