import { ApiResponse } from 'store/api/http'
import { EnabledStatus, RuleType } from 'store/api/rules'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import { RuleAction } from 'utils/uniteRules'
import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { replaceEmptyViaInRequestBody } from 'store/api/groups'
import { profilesApi, ProfileSectionType, updateProfileSectionCount } from '../profiles'

export interface ServiceData {
  category: string
  name: string
  unlock_location: string
  PK: string
  warning?: string
  locations?: string[]
  action?: RuleAction
  global?: ServiceData // service from global profile
}

export interface ServiceCategory {
  PK: string
  name: string
  description: string
  count: number
  enabledCount?: number
}

export type getServiceCategoriesResponse = ApiResponse<{ categories: ServiceCategory[] }>

export type GetServicesResponse = ApiResponse<{
  services: ServiceData[]
}>

export type PutServiceActionResponse = ApiResponse<{
  services: RuleAction[]
}>

export interface PutServiceActionRequest {
  PK: string | undefined
  do?: RuleType
  via?: string
  via_v6?: string
  status: EnabledStatus
  ovr?: EnabledStatus
}

export const servicesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getServiceCategories: builder.query({
      query: () => getQueryArgs('/services/categories'),
      transformResponse: (response: getServiceCategoriesResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Services'],
    }),
    getCategoryServices: builder.query({
      query: ({ categoryPk }: { categoryPk?: string }) =>
        getQueryArgs(`/services/categories/${categoryPk}`),
      transformResponse: (response: GetServicesResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Services'],
    }),
    /**
     * Get all services categories and services. If a profile is passed, it will merge the services
     * with UserServices. If not, it will return all services & categories.
     */
    getAllServicesCategories: builder.query({
      query: ({}: { profileId?: string | number }) => getQueryArgs('/services/categories/all'),
      transformResponse: (response: GetServicesResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          if (profileId) {
            dispatch(
              servicesApi.util.updateQueryData('getUserServices', { profileId }, draft => {
                const userServices = draft
                if (data.services && userServices.services) {
                  userServices.services = sortBy(
                    unionBy(draft.services, data.services, 'PK'),
                    s => s.name,
                  )
                }
              }),
            )
          }
        } catch {}
      },
      providesTags: ['Services'],
    }),
    getUserServices: builder.query({
      query: ({ profileId }: { profileId?: string | number }) =>
        getQueryArgs(`/profiles/${profileId}/services`),
      transformResponse: (response: GetServicesResponse) => response.body,
      transformErrorResponse,

      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            servicesApi.util.updateQueryData('getServiceCategories', '', draft => {
              draft.categories.forEach(category => {
                category.enabledCount = data.services.filter(
                  service =>
                    service.category === category.PK &&
                    service.action?.status === EnabledStatus.ENABLED,
                ).length
              })
            }),
          )

          dispatch(
            servicesApi.util.updateQueryData('getAllServicesCategories', { profileId }, draft => {
              const allServices = draft

              if (allServices.services && data.services) {
                allServices.services = sortBy(
                  unionBy(data.services, allServices.services, 'PK'),
                  s => s.name,
                )
              }
            }),
          )
        } catch {}
      },
      providesTags: ['Services'],
    }),
    getGlobalServices: builder.query({
      query: ({
        globalProfileId,
      }: {
        globalProfileId: string | number | undefined
        profileId: string | number | undefined
      }) => ({
        url: `/profiles/${globalProfileId}/services`,
      }),
      transformResponse: (response: GetServicesResponse) => {
        return response.body
      },
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          const categoryEnabledCountMap = new Map<string, number>()
          /**
           * Get all services which are active in the global profile and
           * merge them to the local profile services
           */
          dispatch(
            servicesApi.util.updateQueryData('getUserServices', { profileId }, draft => {
              const globalServices = data.services
              const userServices = draft.services
              if (globalServices && userServices) {
                globalServices.forEach(globalService => {
                  if (globalService.action?.status === EnabledStatus.DISABLED) {
                    return
                  }
                  const service = userServices.find(s => s.PK === globalService.PK)
                  if (service) {
                    service.global = globalService
                  } else {
                    userServices.push({
                      ...globalService,
                      action: undefined,
                      global: globalService,
                    })
                  }
                })

                //create a category enabled count map which will be used to update the category count

                userServices.map(service => {
                  if (service.global) {
                    if (
                      service.global.action?.status === EnabledStatus.ENABLED &&
                      service.action?.ovr !== EnabledStatus.ENABLED
                    ) {
                      const count = categoryEnabledCountMap.get(service.category) ?? 0
                      categoryEnabledCountMap.set(service.category, count + 1)
                    }
                  } else if (service.action?.status === EnabledStatus.ENABLED) {
                    const count = categoryEnabledCountMap.get(service.category) ?? 0
                    categoryEnabledCountMap.set(service.category, count + 1)
                  }
                })
              }
            }),
          )

          // Update the category count. This should run after UserServices has already updated the counts
          dispatch(
            servicesApi.util.updateQueryData('getServiceCategories', '', draft => {
              draft.categories.forEach(category => {
                category.enabledCount = categoryEnabledCountMap.get(category.PK) ?? 0
              })
            }),
          )
        } catch {}
      },
      providesTags: ['Services'],
    }),
    putServices: builder.mutation({
      query: ({
        profileId,
        body,
      }: {
        profileId: string | number | undefined
        body: PutServiceActionRequest
      }) =>
        getQueryArgs(
          `/profiles/${profileId}/services/${body.PK}`,
          'PUT',
          replaceEmptyViaInRequestBody<PutServiceActionRequest>(body),
        ),
      transformResponse: (response: PutServiceActionResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ profileId, body }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            servicesApi.util.updateQueryData('getUserServices', { profileId: profileId }, draft => {
              const userServices = draft
              const service = userServices.services.find(x => x.PK === body.PK)

              if (!!service) {
                if (service.action?.status !== body.status) {
                  dispatch(
                    profilesApi.util.updateQueryData('getProfiles', '', draft => {
                      updateProfileSectionCount({
                        draft,
                        profileId,
                        type: ProfileSectionType.SERVICES,
                        isIncrease: !!body.status,
                      })
                    }),
                  )
                }

                service.action = data.services[0]

                dispatch(
                  servicesApi.util.updateQueryData('getServiceCategories', '', draft => {
                    const category = draft.categories.find(
                      category => category.PK === service.category,
                    )
                    if (category) {
                      category.enabledCount = userServices.services
                        .filter(service => service.category === category?.PK)
                        .reduce((acc, service) => {
                          if (
                            (service.global &&
                              service.global.action?.status === EnabledStatus.ENABLED &&
                              service.action?.ovr !== EnabledStatus.ENABLED) ||
                            service.action?.status === EnabledStatus.ENABLED
                          ) {
                            return acc + 1
                          }
                          return acc
                        }, 0)
                    }
                  }),
                )
              }
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  endpoints,
  useGetServiceCategoriesQuery,
  useGetAllServicesCategoriesQuery,
  usePutServicesMutation,
  useGetUserServicesQuery,
  useGetCategoryServicesQuery,
  useGetGlobalServicesQuery,
} = servicesApi
