import React, { ReactElement } from 'react'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import VirtualizedGrid, {
  SizePerBreakpoint,
} from 'components/Dashboard/CommonComponents/VirtualizedGrid'
import ServiceCategoryCard from 'components/Dashboard/Profiles/Services/CategoriesList/Card'
import { useServiceItemsPerRow } from 'components/Dashboard/Profiles/Services/useServiceItemsPerRow'
import useGetServicesData from 'components/Dashboard/Profiles/Services/useGetServicesData'

export const categoryHeightsPx: SizePerBreakpoint = [94, 135, 135]

export default function ServiceCategoryList(): ReactElement {
  const serviceCategoriesPerRow = useServiceItemsPerRow()
  const { categories } = useGetServicesData()

  const categoryRenderer = (index: number) => {
    const category = categories?.[index]
    return category ? <ServiceCategoryCard category={category} /> : null
  }

  return (
    <VirtualizedGrid
      dataTestId="dashboard-service-categories"
      ariaLabel="Service categories"
      itemCount={categories?.length || 0}
      itemHeightsPx={categoryHeightsPx}
      itemRenderer={categoryRenderer}
      itemsPerRow={serviceCategoriesPerRow}
      scrollingContainerRef={scrollingContainerRef}
      gap={['0.1rem', '1.2rem', '1.2rem']}
    />
  )
}
