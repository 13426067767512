import React, { ReactElement, useRef, useState } from 'react'
import ModalDialog from 'ui/NewModalDialog'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Box, Flex } from 'theme-ui'
import useMobileInputFocus from 'utils/useMobileInputFocus'
import { RuleType } from 'store/api/rules'
import { isServiceRule } from 'utils/uniteRules'
import { openProxyTray } from 'store/proxyTray'
import DestinationsView from '../Profiles/CustomRules/RuleOrFolderDialog/DestinationsView'
import useGetColorMode from 'utils/useGetColorMode'

export default function ProxyModalDialog(): ReactElement {
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const rule = useAppSelector(s => s.proxyTray.rule)
  const [shouldShowProxy, setShouldShowProxy] = useState(rule?.action?.do !== RuleType.SPOOF_IP)
  const { isLightMode } = useGetColorMode()

  const shouldDisplayDestinations = isServiceRule(rule)
  useMobileInputFocus(inputRef, shouldDisplayDestinations)

  return (
    <ModalDialog
      title="Destinations"
      isLeftContentVisible={shouldDisplayDestinations}
      onCloseClick={() => {
        dispatch(openProxyTray(''))
      }}
      onBackClick={() => {
        dispatch(openProxyTray(''))
      }}
      dataTestId="destinations-dialog"
      isOpen={shouldDisplayDestinations}
      dismiss={() => {
        dispatch(openProxyTray(''))
      }}
      footer={
        shouldShowProxy ? undefined : (
          <Flex
            sx={{
              width: 'fit-content',
              height: '100%',
            }}
          />
        )
      }
    >
      <Box
        sx={{
          width: ['100%', '43.5rem'],
          pb: '1.2rem',
          overflow: 'auto',
          maxHeight: '70rem',
        }}
        className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
      >
        <DestinationsView
          isVisible={shouldDisplayDestinations}
          type="services"
          shouldShowProxy={shouldShowProxy}
          setShouldShowProxy={setShouldShowProxy}
        />
      </Box>
    </ModalDialog>
  )
}
