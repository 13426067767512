import { Divider, Flex, Text } from 'theme-ui'
import {
  AllActionType,
  AllStatusesType,
  setRuleActionFilter,
  setRuleStatusFilter,
} from 'store/customRules'
import { Button, Svg } from 'ui'
import { verdictTypesToDropdownItemData } from 'components/Dashboard/Analytics/ActivityLog/Dropdowns/ActionsDropdown'
import ArrowRightIcon from 'images/dashboard/arrow-right.svg'
import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Setter } from 'utils'
import { ruleStatusToDropdownItemData } from './StatusMenu'

export default function FilterByRuleItem({
  setIsAllActionsMenuVisible,
  setIsAllStatusesMenuVisible,
}: {
  setIsAllActionsMenuVisible: Setter<boolean>
  setIsAllStatusesMenuVisible: Setter<boolean>
}): ReactElement {
  const dispatch = useAppDispatch()
  const ruleActionFilter = useAppSelector(s => s.customRules.ruleActionFilter)
  const ruleStatusFilter = useAppSelector(s => s.customRules.ruleStatusFilter)

  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '0.4rem',
        px: '0.4rem',
        pb: '0.4rem',
        pt: '0rem',
      }}
    >
      <Divider
        key="filter-by-divider"
        sx={{
          backgroundColor: 'blueYonder30',
          border: 'none',
          width: '100%',
          height: '1px',
          my: '0rem',
        }}
      />
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          letterSpacing: '0.018rem',
          pl: '0.2rem',
        }}
      >
        <Text variant="size12Weight700Uppercase" sx={{ color: 'aliceBlue60' }}>
          Filter by
        </Text>
        {(ruleActionFilter !== AllActionType || ruleStatusFilter !== AllStatusesType) && (
          <Button
            ariaLabel="clear filters"
            variant="simple"
            sx={{
              p: 0,
              letterSpacing: '0.018rem',
              opacity: 0.6,
              ':hover:not([disabled])': { opacity: 1, color: 'aliceBlue' },
            }}
            onClick={() => {
              dispatch(setRuleActionFilter(AllActionType))
              dispatch(setRuleStatusFilter(AllStatusesType))
            }}
          >
            <Text variant="size12Weight700Uppercase">clear</Text>
          </Button>
        )}
      </Flex>
      <Flex sx={{ width: '100%', flexDirection: 'column', gap: '0.4rem' }}>
        <Button
          data-testid={ruleStatusToDropdownItemData[ruleStatusFilter].label}
          ariaLabel={`${ruleStatusToDropdownItemData[ruleStatusFilter].label}`}
          variant="simple"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            py: '0.4rem',
            px: '0.8rem',
            color: 'aliceBlue',
            borderRadius: '0.8rem',
            opacity: 0.6,
            ':hover': {
              opacity: 1,
              backgroundColor: 'white6',
            },
          }}
          onClick={() => setIsAllStatusesMenuVisible(true)}
        >
          <Text variant="size15Weight600">
            {ruleStatusToDropdownItemData[ruleStatusFilter].label}
          </Text>
          <Svg fill="aliceBlue" svg={ArrowRightIcon} descriptiveText="Open Status Filter Menu" />
        </Button>
        <Button
          data-testid={verdictTypesToDropdownItemData[ruleActionFilter].label}
          ariaLabel={`${verdictTypesToDropdownItemData[ruleActionFilter].label}`}
          variant="simple"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            py: '0.4rem',
            px: '0.8rem',
            color: 'aliceBlue',
            borderRadius: '0.8rem',
            opacity: 0.6,
            ':hover': {
              opacity: 1,
              backgroundColor: 'white6',
            },
          }}
          onClick={() => setIsAllActionsMenuVisible(true)}
        >
          <Text variant="size15Weight600">
            {verdictTypesToDropdownItemData[ruleActionFilter].label}
          </Text>
          <Svg fill="aliceBlue" svg={ArrowRightIcon} descriptiveText="Open Actions Filter Menu" />
        </Button>
      </Flex>
    </Flex>
  )
}
