import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import CloseIcon from 'images/profileManagement/close-icon.svg'
import LinkIcon from 'images/combined-shape.svg'
import { Button, TrayRenderProps, Svg, CircleIconWrapperButton } from 'ui'
import TrayHeader from 'components/TrayHeader'
import { useAppSelector } from 'store/hooks'
import useGetColorMode from 'utils/useGetColorMode'

const BlockListLayout = ({ dismiss }: TrayRenderProps): ReactElement => {
  const selectedFilter = useAppSelector(s => s.filters.selectedFilter)
  const { name: title, description, sources, additional } = selectedFilter || {}
  const { isLightMode } = useGetColorMode()

  const iconClick = (source: string): void => {
    typeof window !== `undefined` && window.open(source)
  }

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column' }}>
      <TrayHeader
        sx={{
          p: '1.6rem',
          alignItems: 'center',
        }}
        leftComponent={
          <CircleIconWrapperButton
            data-testid="blocklist-close-button"
            ariaLabel="close tray"
            onClick={dismiss}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              backgroundColor: 'white10',
              p: '0.4rem',
              '&:hover': {
                ':not([disabled])': {
                  backgroundColor: 'transparent',
                },
              },
            }}
          >
            <Svg
              svg={CloseIcon}
              descriptiveText="Close block list"
              fill="aliceBlue60"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
          </CircleIconWrapperButton>
        }
      >
        <Text
          variant="size15Weight700"
          tabIndex={0}
          sx={{
            textAlign: 'center',
            width: '100%',
            color: 'labelColor',
          }}
        >
          {title}
        </Text>
      </TrayHeader>
      <Flex
        className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
        sx={{
          overflowY: 'auto',
          flexDirection: 'column',
          maxWidth: '100vw',
        }}
      >
        <Text
          variant="size16Weight400"
          tabIndex={0}
          sx={{
            color: 'textColor',
            px: '1.6rem',
            pt: '1.6rem',
          }}
        >
          {description}
        </Text>
        {additional && (
          <Text
            variant="size16Weight400"
            tabIndex={0}
            sx={{
              color: 'textColor',
              fontSize: '1.6rem',
              px: '1.6rem',
              '& strong': {
                color: 'labelColor',
              },
            }}
          >
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <span dangerouslySetInnerHTML={{ __html: additional }} />
          </Text>
        )}
        {!!sources?.length && (
          <Box sx={{ mt: '1.6rem', px: '1.6rem' }}>
            <Text
              variant="size17Weight600Line138"
              sx={{
                mt: '2.4rem',
                mb: '0.8rem',
                color: 'labelColor',
              }}
            >
              Sources
            </Text>
            <Box
              as="ul"
              sx={{
                listStyle: 'none',
                pl: 0,
                backgroundColor: 'lightSkyBlue5',
              }}
            >
              {sources?.map((source, index) => {
                return (
                  <Box
                    as="li"
                    sx={{
                      pt: '1.6rem',
                      pb: '1.6rem',
                      borderBottom: (theme): string => `1px solid ${theme.colors?.white10}`,
                    }}
                    key={`${source}${index}`}
                  >
                    <Flex>
                      <Text sx={{ wordWrap: 'break-word', maxWidth: '90%', color: 'textColor' }}>
                        {source}
                      </Text>
                      <Box sx={{ flexGrow: 1 }} />
                      <Button
                        variant="simple"
                        ariaLabel={`open ${new URL(source).hostname} in the new tab`}
                        sx={{ p: 0 }}
                        onClick={(): void => iconClick(source)}
                      >
                        <Svg
                          svg={LinkIcon}
                          descriptiveText={`Open ${new URL(source).hostname} in new tab`}
                          fill=""
                          sx={{
                            width: '2.4rem',
                            height: '2.4rem',
                          }}
                        />
                      </Button>
                    </Flex>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
        <Box sx={{ mb: '2.4rem' }} />
      </Flex>
    </Flex>
  )
}

export default BlockListLayout
