import React, { ElementType, ReactElement, useEffect, useRef } from 'react'
import { Button } from 'ui/Buttons'
import { useLocation } from '@reach/router'
import {
  SubHeaderButtonContent,
  SubHeaderName,
  SubHeaderPath,
} from 'components/Dashboard/SubHeader/SubHeaderButtonContent'
import useQueryString from 'utils/useQueryString'
import { setEditingProfileSection } from 'store/multiprofile'
import { useAppDispatch } from 'store/hooks'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import isElementVisibleInViewport from 'components/Dashboard/utils/isElementVisibleInViewport'

function scrollToSubHeaderButton(buttonElement: HTMLButtonElement | null, shouldScroll: boolean) {
  if (!buttonElement) {
    return
  }

  if (shouldScroll && !isElementVisibleInViewport(buttonElement)) {
    buttonElement?.scrollIntoView({
      inline: 'start',
    })
  }
}

interface SubHeaderButtonProps {
  text: SubHeaderName
  path: SubHeaderPath
  icon: ElementType
  count: number
  isLoading: boolean
}
const SubHeaderButton = ({
  text,
  path,
  icon,
  count = 0,
  isLoading,
}: SubHeaderButtonProps): ReactElement => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { relocate } = useQueryString()
  const baseProfileUrl = location.pathname.split('/').slice(0, 4).join('/')
  const isActive = location.pathname.includes(path)
  const currentProfileId = useGetSelectedProfile()?.PK.toString()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isDesktop = useBreakpointIndex() === 2

  useEffect(() => {
    if (isActive) {
      scrollToSubHeaderButton(buttonRef.current, !isDesktop && !isLoading)

      // page should scroll to top when location is changed
      // it is important to do this after scrollIntoView to avoid visual flickering or slight scrolling
      scrollingContainerRef.current && (scrollingContainerRef.current.scrollTop = 0)
    }
  }, [isActive, isDesktop, isLoading])

  return (
    <Button
      ref={buttonRef}
      className={`sub-header-${path}`}
      data-testid={`sub-header-${path}`}
      ariaLabel={`sub header ${text} button`}
      variant={isActive ? 'transparent' : 'simple'}
      sx={{
        width: 'fit-content',
        height: '3.8rem',
        alignItems: 'center',
        p: '0.8rem',
        border: '1px solid',
        borderRadius: '1.2rem',
        borderColor: isActive ? 'littleBoyBlue' : 'transparent',
        backgroundColor: isActive ? 'littleBoyBlue5' : 'transparent',
        color: 'aliceBlue',
        flexShrink: 0,
        opacity: isActive ? 1 : 0.6,
        gap: '0.8rem',
        ':hover': {
          opacity: 1,
        },
      }}
      onClick={() => {
        if (currentProfileId) {
          dispatch(setEditingProfileSection(path))
        }
        relocate(`${baseProfileUrl}/${path}`)
      }}
    >
      <SubHeaderButtonContent text={text} icon={icon} count={count} isLoading={isLoading} />
    </Button>
  )
}

export default SubHeaderButton
