import React, { useCallback, useEffect, useRef, useState } from 'react'
import useCopyToClipboard from './copyToClipboard'

export default function useCopyToClipboardHook({
  isButton,
  data,
}: {
  isButton?: boolean
  data: string
}): {
  isCopied: boolean
  isVisible: boolean
  handleClick: (event?: React.MouseEvent<HTMLButtonElement>) => void
  hideTooltip: () => void
  resetCopiedState: () => void
  showTooltip: () => void
} {
  const { isCopied, copyToClipboard, resetCopiedState } = useCopyToClipboard(data)
  const [isVisible, setIsVisible] = useState(false)
  const hideTooltip = (): void => setIsVisible(false)
  const showTooltip = (): void => {
    setIsVisible(true)
  }

  const timerRef = useRef<number>()

  useEffect(() => {
    return (): void => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const handleClick = useCallback(
    (e): void => {
      e.preventDefault()
      e.stopPropagation()
      !isButton && showTooltip()
      copyToClipboard()
      clearTimeout(timerRef.current)
      // https://github.com/Microsoft/TypeScript/issues/30128#issuecomment-807394387
      timerRef.current = +setTimeout(() =>
        setTimeout(
          isButton
            ? () => {
                resetCopiedState()
              }
            : hideTooltip,
          1000,
        ),
      )
    },
    [isButton, copyToClipboard, resetCopiedState],
  )

  return {
    isCopied,
    isVisible,
    handleClick,
    hideTooltip,
    resetCopiedState,
    showTooltip,
  }
}
