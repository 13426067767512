import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import foregroundStyle from 'components/Dashboard/Profiles/Placeholder/foregroundStyle'

export default function CategoryCardLoader(): ReactElement {
  return (
    <>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Flex
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              ...foregroundStyle,
              width: '3.2rem',
              height: '3.2rem',
              mr: '1.6rem',
            }}
          />
          <Flex sx={{ flexDirection: 'column' }}>
            <Box
              sx={{
                ...foregroundStyle,
                width: '8.6rem',
                height: '1.2rem',
              }}
            />
            <Box
              sx={{
                ...foregroundStyle,
                width: '10rem',
                height: '1.2rem',
                mt: '0.8rem',
              }}
            />
          </Flex>
        </Flex>

        <Box
          sx={{
            ...foregroundStyle,
            width: '3.2rem',
            height: '3.2rem',
          }}
        />
      </Flex>
      <Flex sx={{ display: ['none', 'flex'], justifyContent: 'center', mt: '1.8rem' }}>
        <Box
          sx={{
            ...foregroundStyle,
            width: '100%',
            height: '5rem',
            borderRadius: '1.8rem',
          }}
        />
      </Flex>
    </>
  )
}
