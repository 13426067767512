import React, { ReactElement, ReactNode, Suspense, useState } from 'react'
const DatePicker = React.lazy(() => import('react-datepicker'))
import { Flex, Text } from '@theme-ui/components'
import { Button } from 'ui'
import { DatePickerShortcut } from './FutureDateTimePicker'
import 'react-datepicker/dist/react-datepicker.css'
import isArray from 'lodash/isArray'
import moment from 'moment/moment'
import useGetColorMode from 'utils/useGetColorMode'
import useTimeRangeSelector from 'components/Dashboard/Analytics/TimeRangeSelector/useTimeRangeSelector'
import TimePicker from './TimePicker'
import CustomHeader from './CustomComponents/CustomHeader'
import CustomDay from './CustomComponents/CustomDay'
import CustomContainer from './CustomComponents/CustomContainer'

export interface DateTimePickerProps {
  isDropdown?: boolean
  isDateRange?: boolean
  date?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  onDateChange?: (date: Date) => void
  onDateRangeChange?: (date: [Date | null, Date | null] | Date[]) => void
  minDate?: Date
  maxDate?: Date
  shortcuts?: DatePickerShortcut[]
  footer?: ReactNode
}
export default function DateTimePicker({
  isDropdown,
  isDateRange,
  date,
  startDate,
  endDate,
  onDateChange,
  onDateRangeChange,
  minDate,
  maxDate,
  shortcuts,
  footer,
}: DateTimePickerProps): ReactElement {
  const [activeShortcutLabel, setActiveShortcutLabel] = useState('')
  const { isLightMode } = useGetColorMode()
  const { isStatistics } = useTimeRangeSelector()

  return (
    <Flex sx={{ flexDirection: 'column', mt: isDropdown ? 0 : '1.2rem' }}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        {shortcuts?.map(shortcut => (
          <ShortcutButton
            key={shortcut.label}
            label={shortcut.label}
            activeLabel={activeShortcutLabel}
            onClick={() => {
              onDateChange?.(shortcut.date)
              setActiveShortcutLabel(shortcut.label)
            }}
          />
        ))}
      </Flex>

      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          ...(isLightMode && endDate
            ? {
                backgroundColor: 'black',
                borderRadius: '0.8rem',
                border: '1px solid',
                borderColor: 'blueYonder30',
              }
            : {}),
        }}
      >
        <Suspense>
          <DatePicker
            selected={startDate || date}
            startDate={startDate}
            endDate={endDate}
            onChange={date => {
              if (date) {
                if (isDateRange && isArray(date)) {
                  const [startDate, endDate] = date
                  const currentDate = moment().toDate()

                  if (startDate !== endDate) {
                    startDate?.setHours(
                      currentDate.getHours(),
                      currentDate.getMinutes(),
                      currentDate.getSeconds(),
                    )
                  }

                  onDateRangeChange?.(date)
                } else {
                  onDateChange?.(date as Date)
                }

                setActiveShortcutLabel('')
              }
            }}
            inline
            timeInputLabel=""
            minDate={minDate}
            maxDate={maxDate}
            selectsRange={isDateRange}
            renderCustomHeader={CustomHeader}
            renderDayContents={(dayOfMonth: number) => <CustomDay dayOfMonth={dayOfMonth} />}
            calendarContainer={({ className, children }) => (
              <CustomContainer className={className} isDropdown={isDropdown}>
                {children}
              </CustomContainer>
            )}
          />
        </Suspense>
        {/* don't use a default showTimeInput, it supports only one input */}
        {/* don't show custom time input on statistics page */}
        {!isStatistics && (
          <TimePicker
            isDropdown={isDropdown}
            isDateRange={isDateRange}
            date={date}
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateRangeChange}
          />
        )}
        {footer}
      </Flex>
    </Flex>
  )
}

function ShortcutButton({
  label,
  activeLabel,
  onClick,
}: {
  label: string
  activeLabel: string
  onClick?: () => void
}): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <Button
      ariaLabel={label}
      key={label}
      variant="simple"
      sx={{
        py: 0,
        height: '3.2rem',
        px: '1.2rem',
        border: '1px solid',
        borderColor: activeLabel === label ? 'littleBoyBlue' : 'transparent',
        borderRadius: '0.8rem',
        color: activeLabel === label ? 'aliceBlue' : 'aliceBlue60',
        '&:hover': {
          color: 'aliceBlue',
        },
        ...(isLightMode && activeLabel === label ? { backgroundColor: 'littleBoyBlue6' } : {}),
      }}
      onClick={onClick}
    >
      <Text variant="size12Weight700">{label}</Text>
    </Button>
  )
}
