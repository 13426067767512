import React, { RefObject, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import RuleInput from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog/RuleInput'
import { isHostnameValid, isIpValid } from 'utils/isIpOrHostnameValid'
import { Setter } from 'utils'
import { v4, v6 } from 'is-ip'
import SpoofIpRuleDropdown from './SpoofIpRuleDropdown'
import { BadgeType } from './IpOrHostnameForm'
import { Button } from 'ui'

const getErrorMessage = type => {
  if (type === BadgeType.HOSTNAME) {
    return 'Please enter a valid hostname'
  }

  return `Please enter a valid ${type === BadgeType.IPV4 ? 'IPv4' : 'IPv6'} address`
}

const ipValidation = (type, via): boolean => {
  if (type === BadgeType.HOSTNAME) {
    return isHostnameValid(via)
  }

  if (type === BadgeType.IPV4) {
    return isIpValid(via) && v4(via)
  }

  return isIpValid(via) && v6(via)
}

interface SpoofIpRuleInputProps {
  id?: string
  via?: string
  setVia: (x: string) => void
  types?: BadgeType[]
  selectedType: string
  setSelectedType?: Setter<BadgeType>
  inputRef?: RefObject<HTMLInputElement>
  label?: string
  testId?: string
  placeholder?: string
  ariaLabel?: string
  isDropdownVisible?: boolean
}
export default function SpoofIpRuleInput({
  id,
  via,
  setVia,
  inputRef,
  types,
  selectedType,
  setSelectedType,
  label,
  testId,
  placeholder,
  ariaLabel,
  isDropdownVisible = true,
}: SpoofIpRuleInputProps): ReactElement {
  const isViaValid = ipValidation(selectedType, via)

  return (
    <Flex
      sx={{
        mt: '1.2rem',
        flexDirection: 'column',
      }}
    >
      <Flex data-testid="spoof-ip-rule-container" sx={{ gap: '1.2rem', alignItems: 'flex-start' }}>
        {isDropdownVisible ? (
          <SpoofIpRuleDropdown
            testId={testId}
            types={types}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexShrink: 0,
              gap: '0.8rem',
            }}
          >
            <Text
              variant="size12Weight400"
              sx={{
                color: 'aliceBlue70',
              }}
            >
              Type
            </Text>
            <Button
              ariaLabel={selectedType}
              variant="newPrimary"
              disabled={true}
              sx={{
                width: '8.1rem',
                height: '3.8rem',
                borderRadius: '0.8rem',
                px: '1.2rem',
                textAlign: 'left',
                color: 'aliceBlue30',
              }}
            >
              <Text variant="size14Weight400Line138">{selectedType}</Text>
            </Button>
          </Flex>
        )}
        <RuleInput
          isCompact
          name={id}
          id={id}
          label={label}
          data-testid={testId}
          onChange={(event): void => {
            setVia(event.target.value.toLowerCase().trim())
          }}
          value={via || ''}
          placeholder={placeholder}
          aria-label={ariaLabel}
          sx={{
            width: ['100%', '31rem'],
          }}
          containerStyle={{
            '& > div > label': {
              fontSize: '1.2rem',
            },
          }}
          errorMessage={!!via && !isViaValid ? getErrorMessage(selectedType) : undefined}
          ref={inputRef}
        />
      </Flex>
    </Flex>
  )
}
