import React, { createRef, ReactElement, ReactNode, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'
import { Button, Svg } from 'ui'
import ArrowRightIcon from 'images/dashboard/arrow-right.svg'
import { useAppSelector } from 'store/hooks'
import { useLocation } from '@reach/router'
import useQueryString from 'utils/useQueryString'
import useShouldRenderOnClient from 'utils/useShouldRenderOnClient'
import useGetUserState from 'store/api/user/useGetUserState'
import { Setter } from 'utils'
import CloseIcon from 'images/menu-close.svg'
import { useGetBillingProductsQuery } from 'store/api/billing'
import { navigate } from 'gatsby'
import { differenceInDays } from 'date-fns'
import moment from 'moment/moment'
import useGetBillingAndSubscriptions from 'components/Organization/OrganizationBilling/useGetBillingAndSubscriptions'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import {
  BillingMethod,
  isBillingMethodValidToDisplayBanner,
} from './Organization/OrganizationBilling/BillingPreferences'
import { organizationApi } from 'store/api/organization'
import { userApi } from 'store/api/user'
import { useIsTwoFaVisible } from 'utils/useIsTwoFaVisible'
import { TooltipWrapper } from 'ui/TooltipWrapper'

export const warningMessageRef = createRef<HTMLDivElement>()

const supportMessageHeight = ['6.2rem', '3.9rem']

const warningMessage = `The version of iOS/Safari you are using is outdated and may cause problems viewing the
      website. Please upgrade them to ensure optimal experience.`

export function WarningMessage(): ReactElement | null {
  const isOnboardingCompleted = useAppSelector(s => s.session.isOnboardingCompleted)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const warningError = useAppSelector(s => s.products.warningError)
  const { isOrganization } = useGetUserState()
  const { data: userData, isLoading: isUserDataLoading } =
    userApi.endpoints.getUser.useQueryState('')
  const permissionLevel = userData?.org?.permission?.level
  const isViewer = permissionLevel === OrgPermissionLevel.VIEWER
  const [showWarningMessage, setShowWarningMessage] = useState(true)
  const { areSubscriptionsLoading, billingSubscription } = useGetBillingAndSubscriptions(
    !sessionToken || !isOrganization,
  )
  const {
    data: orgData,
    isLoading: isOrganizationLoading,
    isUninitialized,
  } = organizationApi.endpoints.getOrganization.useQueryState('')

  const isAddPaymentButtonDisabled =
    orgData?.organization?.billing_method === BillingMethod.NONE &&
    (orgData?.organization?.users?.max || 0) < 5

  useGetBillingProductsQuery('', { skip: !sessionToken })

  const shouldRenderOnClient = useShouldRenderOnClient()
  const isTwoFaVisible = useIsTwoFaVisible()

  if (!showWarningMessage) {
    return null
  }

  if (!shouldRenderOnClient) {
    return null
  }

  if (isTwoFaVisible) {
    return null
  }

  if (
    !areSubscriptionsLoading &&
    !isOrganizationLoading &&
    !isUserDataLoading &&
    !isUninitialized &&
    userData &&
    isOrganization &&
    // no banner for sub-orgs
    !orgData?.organization?.parent_org?.PK &&
    !isViewer &&
    isBillingMethodValidToDisplayBanner(orgData?.organization?.billing_method) &&
    orgData?.organization?.status_printed === 'Trial' &&
    !['cancelled', 'active', 'failed'].includes(billingSubscription?.state || '') &&
    differenceInDays(moment(orgData?.organization?.trial_end).toDate(), moment().toDate()) <= 23
  ) {
    return <TrialExpiresContent isButtonDisabled={isAddPaymentButtonDisabled} />
  }

  if (
    isOrganization &&
    !isViewer &&
    billingSubscription?.state === 'failed' &&
    !areSubscriptionsLoading &&
    !isOrganizationLoading
  ) {
    return <RebillFailedMessageContent />
  }

  if (isOrganization) {
    return null
  }

  if (warningError) {
    return (
      <FailedBillingMessageContent
        message={warningError.message}
        path={warningError?.actions?.[0].path}
        setShowWarningMessage={setShowWarningMessage}
      />
    )
  }

  return isOnboardingCompleted === undefined || isOnboardingCompleted ? (
    <SupportMessageContent />
  ) : (
    <ConfigureDeviceContent />
  )
}

export function WarningMessageWrapper({
  children,
  sx,
}: {
  children: ReactNode
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      data-testid="warning-message"
      ref={warningMessageRef}
      sx={{
        position: 'sticky',
        top: 0,
        width: '100%',
        height: 0,
        px: '1.2rem',
        fontSize: '1.2rem',
        fontWeight: 700,
        background:
          'linear-gradient(180deg, rgba(1, 8, 24, 0.00) 0%, rgba(1, 8, 24, 0.30) 100%), #E93349',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        zIndex: 'zIndex0',
        color: 'commonWhite',
        ...sx,
      }}
      className="support-message"
    >
      {children}
    </Flex>
  )
}

function ConfigureDeviceContent(): ReactElement | null {
  const location = useLocation()
  const { relocate } = useQueryString()
  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper sx={{ height: '3.9rem' }}>
      <Button
        ariaLabel="navigate to endpoints"
        variant="transparent"
        sx={{
          width: ['100%', 'auto'],
          p: 0,
          my: '0.8rem',
          justifyContent: ['space-between', 'center'],
        }}
        onClick={() => relocate('/dashboard/endpoints')}
      >
        <Text variant="size12Weight700" sx={{ mr: '0.4rem', color: 'commonWhite' }}>
          Configure Endpoint to activate {CONTROLDText}
        </Text>
        <Svg
          svg={ArrowRightIcon}
          descriptiveText="Right arrow"
          fill="commonWhite"
          sx={{ width: '1.2rem', height: '1.2rem' }}
        />
      </Button>
    </WarningMessageWrapper>
  )
}

function SupportMessageContent(): ReactElement {
  return (
    <WarningMessageWrapper
      sx={{
        height: supportMessageHeight,
        //  * (gap: 1rem) will be a false positive if the browser supports gap with grid
        //  * @supports selector() entered browsers at roughly the same point as flexbox gap,
        //  *  which means it can be used as a rough proxy for support for flex gap.
        //  * */
        '@supports selector(:first-child)': {
          display: 'none',
        },
      }}
    >
      {warningMessage}
    </WarningMessageWrapper>
  )
}

function FailedBillingMessageContent({
  message,
  path,
  setShowWarningMessage,
}: {
  message: string
  path: string
  setShowWarningMessage: Setter<boolean>
}): ReactElement | null {
  const { relocate } = useQueryString()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        height: supportMessageHeight,
      }}
    >
      <Button
        ariaLabel={`navigate to ${path}`}
        variant="transparent"
        sx={{
          width: ['94%', 'auto'],
          p: 0,
          my: '0.8rem',
          justifyContent: ['space-between', 'center'],
        }}
        onClick={() => relocate(path)}
      >
        <Text variant="size12Weight700" sx={{ mr: '0.8rem', color: 'commonWhite' }}>
          {message}
        </Text>
        <Svg svg={ArrowRightIcon} descriptiveText="Right arrow" fill="commonWhite" />
      </Button>
      <Button
        ariaLabel="close warning message"
        data-testid="close-warning-message"
        variant="simple"
        sx={{
          position: 'absolute',
          right: ['0.2rem', '1.6rem'],
          alignSelf: 'center',
          p: 0,
          m: 0,
        }}
        onClick={() => setShowWarningMessage(false)}
      >
        <Svg svg={CloseIcon} descriptiveText="Close warning" fill="commonWhite" />
      </Button>
    </WarningMessageWrapper>
  )
}

function TrialExpiresContent({
  isButtonDisabled,
}: {
  isButtonDisabled?: boolean
}): ReactElement | null {
  const { qs } = useQueryString()
  const location = useLocation()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        display: qs.orgId ? 'none' : 'flex',
        height: ['4.8rem', '3.9rem'],
      }}
    >
      <Text variant="size12Weight700" sx={{ mr: '0.4rem', color: 'commonWhite' }}>
        You are using a {CONTROLDText} trial. Please add a payment method to keep using{' '}
        {CONTROLDText}.{' '}
        <TooltipWrapper
          text={
            <Text variant="size12Weight400">
              Please add at least 5 Connected Devices to start subscription
            </Text>
          }
          disabled={!isButtonDisabled}
        >
          <Flex sx={{ display: 'inline-flex', flexShrink: 0 }}>
            <Button
              disabled={isButtonDisabled}
              ariaLabel="navigato to change payment method"
              variant="transparent"
              sx={{
                display: 'inline-flex',
                textDecoration: 'underline',
                color: 'commonWhite',
                p: 0,
                ':disabled': {
                  backgroundColor: 'transparent',
                  color: 'commonAliceBlue30',
                },
              }}
              onClick={() => navigate('/change-payment-method')}
            >
              <Text variant="size12Weight700">Add Payment Method</Text>
            </Button>
          </Flex>
        </TooltipWrapper>
      </Text>
    </WarningMessageWrapper>
  )
}

function RebillFailedMessageContent(): ReactElement | null {
  const { qs } = useQueryString()
  const location = useLocation()

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <WarningMessageWrapper
      sx={{
        display: qs.orgId ? 'none' : 'flex',
        height: ['5.2rem', '3.9rem'],
      }}
    >
      <Text variant="size12Weight700" sx={{ mr: '0.4rem', color: 'commonWhite' }}>
        We were unable to process your payment. Please update your credit card information.{' '}
        <Button
          ariaLabel="navigate to change payment method"
          variant="transparent"
          sx={{
            display: 'inline-flex',
            textDecoration: 'underline',
            p: 0,
            color: 'commonWhite',
          }}
          onClick={() => navigate('/change-payment-method')}
        >
          <Text variant="size12Weight700" sx={{ mr: '0.4rem' }}>
            Change Credit Card
          </Text>
        </Button>
      </Text>
    </WarningMessageWrapper>
  )
}
