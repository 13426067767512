import React, { ReactElement } from 'react'
import ProfilesIcon from 'images/dashboard/navBar/profiles-icon.svg'
import DevicesIcon from 'images/dashboard/navBar/devices-icon.svg'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import { AnalyticsNavMenu } from 'components/Dashboard/NavBar/AnalyticsNavMenu'
import { devicesApi } from 'store/api/devices'
import { profilesApi } from 'store/api/profiles'

export enum TopNavRelativePath {
  PROFILES = 'profiles',
  ENDPOINTS = 'endpoints',
  ACTIVITY_LOG = 'activity-log',
  STATISTICS = 'statistics',
}
// Nav menu items that are common to organizations and non-organizations, like Profiles, Devices and Analytics
export function MainNavMenuItems(): ReactElement {
  const { data: profilesData, isLoading: profilesLoading } =
    profilesApi.endpoints.getProfiles.useQueryState('')
  const { data: endpointsData, isLoading: endpointsLoading } =
    devicesApi.endpoints.getDevices.useQueryState('')

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '0.1rem',
      }}
    >
      <NavMenuItem
        activeIcon={ProfilesIcon}
        relativePath={TopNavRelativePath.PROFILES}
        label="Profiles"
        count={profilesData?.profiles?.length}
        isLoading={!profilesData?.profiles?.length && profilesLoading}
      />
      <NavMenuItem
        activeIcon={DevicesIcon}
        relativePath={TopNavRelativePath.ENDPOINTS}
        label="Endpoints"
        count={endpointsData?.devices.length}
        isLoading={!endpointsData?.devices.length && endpointsLoading}
      />
      <AnalyticsNavMenu />
    </Flex>
  )
}
