import React, { ReactElement, useState, useRef, RefObject } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import SmallClose from 'images/dashboard/checklist/small-close.svg'
import useOnClickOutside from 'utils/useOnClickOutside'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { setHasClosedChecklist } from 'store/session'
import { navBarTabletWidth } from 'components/Dashboard/NavBar'
import RocketProgressIcon from 'components/Dashboard/NavBar/Checklist/RocketProgressIcon'
import useGetColorMode from 'utils/useGetColorMode'
import ChecklistConfirmDialog from 'components/Dashboard/NavBar/Checklist/ChecklistConfirmDialog'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

interface ChecklistProps {
  onClose: () => void
  collapsedRef: RefObject<HTMLDivElement>
  checklistPercentage: number
  children: ReactElement | ReactElement[]
}

const Checklist = ({
  onClose,
  collapsedRef,
  checklistPercentage,
  children,
}: ChecklistProps): ReactElement => {
  const componentRef = useRef<HTMLDivElement>(null)
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const dispatch = useAppDispatch()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { isLightMode } = useGetColorMode()
  const isTablet = useBreakpointIndex() === 1

  useOnClickOutside([componentRef, collapsedRef], onClose)

  return (
    <Flex
      data-testid="checklist-container"
      ref={componentRef}
      sx={{
        height: 'fit-content',
        backgroundColor: 'paua',
        border: '1.5px solid',
        borderColor: 'blue600ToLittleBoyBlue',
        borderRadius: '1rem',
        p: '1.6rem',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        boxShadow: isLightMode
          ? '0px 4px 20px 0px rgba(118, 150, 224, 0.40)'
          : '0px 4px 20px 0px rgba(83, 40, 241, 0.40)',
        mx: ['1.2rem', 0],
        my: ['1.6rem', '2.4rem'],
        mt: '2.4rem',
        ...(!isNavBarExpanded || isTablet
          ? {
              width: '24rem',
              position: 'absolute',
              left: navBarTabletWidth,
              top: '8.8rem',
              my: 0,
              mt: 0,
            }
          : {}),
      }}
    >
      <Flex sx={{ flexDirection: 'column', width: '100%' }}>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {showConfirmDialog ? (
            <ChecklistConfirmDialog
              onClose={() => setShowConfirmDialog(false)}
              onConfirm={() => {
                dispatch(setHasClosedChecklist(true))
                onClose()
              }}
            />
          ) : (
            <Flex sx={{ width: '100%' }}>
              <Flex
                sx={{
                  display: ['flex', 'none', isNavBarExpanded ? 'flex' : 'none'],
                  flexShrink: 0,
                  alignItems: 'center',
                }}
              >
                <RocketProgressIcon
                  checklistPercentage={checklistPercentage}
                  onClick={() => {}}
                  collapsedRef={null}
                  hidePercentage
                />
              </Flex>
              <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    color: 'white',
                    gap: '0.4rem',
                    ml: ['1.2rem', '0', isNavBarExpanded ? '1.2rem' : '0'],
                  }}
                >
                  <Text variant="size16Weight700">Let&apos;s get started</Text>
                  <Text
                    data-testid="checklist-percentage"
                    variant="size11Weight400"
                    sx={{ color: 'white70black70', whiteSpace: 'nowrap' }}
                  >
                    {`${checklistPercentage}% completed`}
                  </Text>
                </Flex>
                <Flex
                  sx={{
                    gap: '0.8rem',
                    mt: '0.4rem',
                    'svg > g': {
                      'g > path': {
                        fill: 'white',
                      },
                      path: {
                        fill: 'white',
                      },
                    },
                  }}
                >
                  <Svg
                    data-testid="close-checklist"
                    descriptiveText={'close checklist'}
                    svg={SmallClose}
                    onClick={() => setShowConfirmDialog(true)}
                    sx={{ cursor: 'pointer' }}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1.2rem',
            pt: '2.4rem',
            filter: showConfirmDialog ? 'blur(2px)' : 'none',
            pointerEvents: showConfirmDialog ? 'none' : 'auto',
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Checklist
