import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearStateForImpersonation } from 'store/organization'
import { initialResponseState } from '../fetchingLogic'

export interface AccessState {
  selectedDeviceId?: string
  ipsToDelete: string[]
}

const initialState: AccessState = {
  ...initialResponseState,
  selectedDeviceId: undefined,
  ipsToDelete: [],
}

export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setSelectedDeviceId(state, { payload }: PayloadAction<string | undefined>): void {
      state.selectedDeviceId = payload
    },
    setIpsToDelete(state, { payload }: PayloadAction<string>): void {
      if (state.ipsToDelete?.includes(payload)) {
        state.ipsToDelete = state.ipsToDelete.filter(ip => ip !== payload)
      } else {
        state.ipsToDelete = [...state.ipsToDelete, payload]
      }
    },
    selectAllIps(state, { payload }: PayloadAction<string[]>): void {
      state.ipsToDelete = payload.map(ip => ip)
    },
    resetIpsToDelete(state): void {
      state.ipsToDelete = []
    },
  },
  extraReducers: builder => {
    builder.addCase(clearStateForImpersonation.fulfilled, () => initialState)
  },
})

export const { setSelectedDeviceId, setIpsToDelete, selectAllIps, resetIpsToDelete } =
  accessSlice.actions
