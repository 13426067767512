import React, { ReactElement, ReactNode } from 'react'
import AnalyticsIcon from 'images/dashboard/navBar/analytics-icon.svg'
import { AnalyticsNavRelativePath } from './AnalyticsNavMenu'
import NavBarAccordion, { NavBarAccordionType } from './NavBarAccordion'

export default function AnalyticsAccordion({ children }: { children: ReactNode }): ReactElement {
  return (
    <NavBarAccordion
      type={NavBarAccordionType.ANALYTICS}
      title="Analytics"
      ariaLabelButtonName="analytics"
      titleIcon={AnalyticsIcon}
      relativePath={AnalyticsNavRelativePath}
    >
      {children}
    </NavBarAccordion>
  )
}
