import { MutableRefObject, useEffect } from 'react'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { scrollingContainerRef } from 'components/Dashboard/DashboardScrollingContainer'
import { useLocation } from '@reach/router'

export const useHideTooltipOnScroll = (
  hideRef: MutableRefObject<(() => void) | undefined>,
  dropdownScrollingContainerRef?: MutableRefObject<HTMLDivElement | null>,
): void => {
  const isDesktop = useBreakpointIndex() === 2
  const location = useLocation()
  const isDashboard = location.pathname.includes('dashboard')

  useEffect(() => {
    if (isDesktop && !dropdownScrollingContainerRef?.current) {
      return
    }

    const handleScroll = () => {
      hideRef.current?.()
    }

    const scrollingElement =
      dropdownScrollingContainerRef?.current ||
      (isDashboard ? scrollingContainerRef.current : document)

    scrollingElement?.addEventListener('scroll', handleScroll)

    return () => {
      scrollingElement?.removeEventListener('scroll', handleScroll)
    }
  }, [hideRef, isDesktop, isDashboard, dropdownScrollingContainerRef])
}
