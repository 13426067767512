import React, { ReactElement } from 'react'
import DashboardInitialization from './DashboardInitialization'
import DashboardAlertsContainer from './DashboardAlertsContainer'
import DashboardScrollingContainer from './DashboardScrollingContainer'
import { RouteComponentProps } from '@reach/router'

const DashboardPages = React.lazy(() => import('components/Dashboard/DashboardPages'))

function DashboardPage({}: RouteComponentProps): ReactElement {
  return (
    <>
      <DashboardInitialization />
      <DashboardScrollingContainer>
        <DashboardPages />
        <DashboardAlertsContainer />
      </DashboardScrollingContainer>
    </>
  )
}

export default React.memo(DashboardPage, (prevProps, nextProps) => {
  return prevProps.uri === nextProps.uri
})
