import React, { ReactElement } from 'react'
import { Button, Svg } from 'ui'
import CheckMarkIcon from 'images/helpPaneSteps/donestepicon.svg'
import { arrowIconButtonStyle } from 'components/SetupGuide/Components/PreviousStepButton'
import { Flex, Spinner } from 'theme-ui'
import { useAppSelector } from 'store/hooks'

export default function ConfiguredButton({
  onClick,
  isResolved,
}: {
  onClick: () => void
  isResolved: boolean
}): ReactElement {
  const isDeviceStatusEnabled = useAppSelector(s => s.devices.isDeviceStatusVerified)

  return (
    <Button
      data-testid="footer-done-button"
      sx={arrowIconButtonStyle}
      variant="simple"
      onClick={onClick}
      ariaLabel="continue to dashboard"
    >
      {!isResolved && !isDeviceStatusEnabled ? (
        <Flex
          sx={{
            width: '3.2rem',
            height: '3.2rem',
            borderRadius: '50%',
            backgroundColor: 'greenApple',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner color="black" size={24} />
        </Flex>
      ) : (
        <Flex
          sx={{
            width: '3.2rem',
            height: '3.2rem',
            borderRadius: '50%',
            backgroundColor: 'greenApple',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Svg
            svg={CheckMarkIcon}
            sx={{ width: '2.4rem', height: '2.4rem' }}
            descriptiveText="Configuration complete checkmark"
          />
        </Flex>
      )}
    </Button>
  )
}
