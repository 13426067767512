import React, { useState, useEffect, ReactElement, useRef } from 'react'
import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg, InputSearch } from 'ui'
import Fuse from 'fuse.js'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { ProxyLocation } from 'store/api/proxies'
import ProxyCountry from 'components/ProxyList/ProxyCountry'
import ProxyMenuItem from 'components/ProxyList/ProxyMenuItem'
import AutoLocationIcon from 'images/customRules/auto-loc-icon.svg'
import RandomLocationIcon from 'images/customRules/random-loc-icon.svg'
import { defaultFuseOptions } from 'utils'
import useMobileInputFocus from 'utils/useMobileInputFocus'
import CloseIcon from 'images/bold-close-icon.svg'
import DownArrow from 'images/profileManagement/context-menu-arrow-down.svg'
import CheckIcon from 'images/analytics/check.svg'
import useGetColorMode from 'utils/useGetColorMode'
const getSortedDataObject = (obj, sortingChildByValue) =>
  Object.keys(obj)
    .sort()
    .reduce(
      (prevValue, currentValue) => (
        (prevValue[currentValue] = sortBy(obj[currentValue], item => item[sortingChildByValue])),
        prevValue
      ),
      {},
    )

const locationsSortingOptions = {
  ...defaultFuseOptions,
  includeScore: true,
  useExtendedSearch: true,
  threshold: 0.3,
  maxPatternLength: 32,
  keys: ['city', { name: 'country_name', weight: 2 }, { name: 'country', weight: 2 }],
}

// construct Random Location
export const randomLocation: ProxyLocation = {
  city: 'Random Location',
  country: '?',
  country_name: '?',
  uid: '?',
  PK: '?',
  gps_lat: 0,
  gps_long: 0,
}

export const autoLocation: ProxyLocation = {
  city: 'Auto Location',
  country: '?',
  country_name: '?',
  uid: '?',
  PK: 'LOCAL',
  gps_lat: 0,
  gps_long: 0,
}

interface ProxyListProps {
  handleProxyClick: (location?: string) => Promise<void> | void
  currentLocationPK?: string
  proxyLocations: ProxyLocation[]
  proxyLevel?: string
  isOpen: boolean
  selectedItemColor?: string
  allowDeselect?: boolean
  isVisible: boolean
  placeholder?: string
  sxMenu?: ThemeUIStyleObject
  sxSearch?: ThemeUIStyleObject
  sxList?: ThemeUIStyleObject
  isBackgroundHighlighted?: boolean
  isDropdown?: boolean
}

export default function ProxyList({
  handleProxyClick,
  currentLocationPK,
  proxyLocations,
  proxyLevel = 'default-redirect',
  isOpen,
  selectedItemColor = 'greenAple',
  allowDeselect = true,
  isVisible,
  placeholder,
  sxMenu,
  sxSearch,
  sxList,
  isBackgroundHighlighted,
  isDropdown,
}: ProxyListProps): ReactElement {
  const [locations, setLocations] = useState<ProxyLocation[]>(proxyLocations)
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<HTMLInputElement>(null)
  const breakpointIndex = useBreakpointIndex()

  useMobileInputFocus(searchInput, isOpen && isVisible)

  const [locationsWithConnectedAtStart, setLocationsWithConnectedAtStart] = useState(locations)

  useEffect(() => {
    if (isOpen) {
      if (currentLocationPK) {
        const maybeConnectedLocation = locations.filter(
          location => location.PK === currentLocationPK,
        )
        setLocationsWithConnectedAtStart([
          ...maybeConnectedLocation,
          ...locations.filter(location => location.PK !== currentLocationPK),
        ])
      } else {
        setLocationsWithConnectedAtStart(locations)
      }
    } else {
      searchInput?.current?.blur?.()
      setLocationsWithConnectedAtStart(locations)
    }
  }, [breakpointIndex, currentLocationPK, isOpen, isVisible, locations])

  const fuseInstanceRef = useRef<Fuse<ProxyLocation>>()

  useEffect(() => {
    fuseInstanceRef.current = new Fuse(proxyLocations, locationsSortingOptions)
  }, [proxyLocations])

  useEffect(() => {
    const fuseInstance = fuseInstanceRef.current

    if (searchText && fuseInstance) {
      const isLocationSearch = searchText.length < 2
      const filteredLocations = fuseInstance
        .search(isLocationSearch ? `${searchText}$` : searchText)
        .map(result => {
          return { ...result.item, matchKey: result.matches?.[0]?.key }
        })

      setLocations(filteredLocations as ProxyLocation[])
    } else {
      setLocations(proxyLocations)
    }
  }, [proxyLocations, searchText])

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        {searchText && (
          <Button
            onClick={(): void => setSearchText('')}
            variant="simple"
            ariaLabel="clear search text"
            sx={{
              p: 0,
              position: 'absolute',
              right: '1.2rem',
              top: '1.1rem',
              zIndex: 'zIndex50',
            }}
          >
            <Svg svg={CloseIcon} fill="aliceBlue60Black60" descriptiveText="Clear search text" />
          </Button>
        )}
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            width: '100%',
            flexShrink: 0,
            p: isDropdown ? '0.4rem' : '0.8rem',
          }}
        >
          <InputSearch
            isCompact
            ref={searchInput}
            id={`${proxyLevel}-search`}
            name={`${proxyLevel}-search`}
            data-testid={`${proxyLevel}-search`}
            value={searchText}
            containerStyle={{ border: 'none' }}
            svgContainerStyle={{
              left: '0.8rem',
            }}
            sx={{
              ...sxSearch,
            }}
            placeholder={placeholder || 'Search Destinations'}
            aria-label="search location"
            onChange={({ target }): void => setSearchText(target.value)}
          />
        </Flex>
        <Flex sx={{ overflow: 'auto', ...sxList }}>
          {locations && (
            <MenuItems
              locations={locationsWithConnectedAtStart}
              handleProxyClick={handleProxyClick}
              proxyLevel={proxyLevel}
              currentLocationPK={currentLocationPK}
              selectedItemColor={selectedItemColor}
              allowDeselect={allowDeselect}
              sxMenu={sxMenu}
              isBackgroundHighlighted={isBackgroundHighlighted}
              isDropdown={isDropdown}
            />
          )}
        </Flex>
      </Box>
    </Box>
  )
}

function MenuItems({
  locations,
  handleProxyClick,
  proxyLevel = 'default-redirect',
  currentLocationPK,
  selectedItemColor,
  allowDeselect,
  sxMenu,
  isBackgroundHighlighted,
  isDropdown,
}: {
  currentLocationPK?: string
  locations: ProxyLocation[]
  handleProxyClick: (pk?: string) => void
  proxyLevel?: string
  selectedItemColor: string
  allowDeselect: boolean
  sxMenu?: ThemeUIStyleObject
  isBackgroundHighlighted?: boolean
  isDropdown?: boolean
}): ReactElement {
  const [groupedLocations, setGroupedLocations] = useState<Record<string, ProxyLocation[]>>({})
  useEffect(() => {
    const groupByCountry = groupBy(locations, l => l.country_name)

    setGroupedLocations(getSortedDataObject(groupByCountry, 'city'))
  }, [locations])
  const { isLightMode } = useGetColorMode()
  return !!locations?.length ? (
    <Box
      sx={{
        flex: '1',
        overflowY: 'auto',
        color: selectedItemColor,
        ...sxMenu,
      }}
      className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
    >
      {proxyLevel === 'customrules' && (
        <>
          <ProxyMenuItem
            sx={{ width: '100%', px: '1.6rem', mx: 0, height: '3.8rem' }}
            shouldShowCountryName={false}
            onClick={handleProxyClick}
            location={autoLocation}
            isSelected={autoLocation.PK === currentLocationPK}
            leftSvg={AutoLocationIcon}
            rightSvg={
              currentLocationPK === autoLocation.PK ? (
                <Svg
                  key={currentLocationPK}
                  svg={CheckIcon}
                  fill="greenApple"
                  descriptiveText="Auto location selected"
                />
              ) : (
                <Svg
                  key={currentLocationPK}
                  svg={DownArrow}
                  fill="aliceBlue30"
                  sx={{ transform: 'rotate(-90deg)' }}
                  descriptiveText="View auto location options"
                />
              )
            }
            selectedItemColor={selectedItemColor}
          />
          <ProxyMenuItem
            sx={{
              width: '100%',
              px: isDropdown ? '0.4rem' : '1.6rem',
              mx: 0,
              height: '3.8rem',
            }}
            shouldShowCountryName={false}
            onClick={handleProxyClick}
            location={randomLocation}
            isSelected={randomLocation.PK === currentLocationPK}
            leftSvg={RandomLocationIcon}
            rightSvg={
              currentLocationPK === randomLocation.PK ? (
                <Svg
                  key={currentLocationPK}
                  svg={CheckIcon}
                  fill="greenApple"
                  descriptiveText="Random location selected"
                />
              ) : (
                <Svg
                  key={currentLocationPK}
                  svg={DownArrow}
                  fill="aliceBlue30"
                  sx={{ transform: 'rotate(-90deg)' }}
                  descriptiveText="View random location options"
                />
              )
            }
            selectedItemColor={selectedItemColor}
          />
        </>
      )}

      {Object.entries(groupedLocations).map(([countryName, locations], index) => {
        return (
          <ProxyCountry
            index={index}
            key={countryName}
            name={countryName}
            locations={locations}
            proxyLevel={proxyLevel}
            data-testid={`${proxyLevel}-country-${countryName}`}
            currentLocationPK={currentLocationPK}
            selectedItemColor={selectedItemColor}
            handleProxyClick={handleProxyClick}
            allowDeselect={allowDeselect}
            shouldNotShowSingle={proxyLevel === 'services'}
            isBackgroundHighlighted={isBackgroundHighlighted}
            isDropdown={isDropdown}
          />
        )
      })}
    </Box>
  ) : (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        py: '1.6rem',
      }}
    >
      <Text variant="size15Weight500" sx={{ color: 'aliceBlue60Black60' }}>
        No such location...
      </Text>
    </Flex>
  )
}
