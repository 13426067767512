import ChecklistRocket20Dark from 'images/dashboard/checklist/rocket-20-dark.png'
import ChecklistRocket40Dark from 'images/dashboard/checklist/rocket-40-dark.png'
import ChecklistRocket60Dark from 'images/dashboard/checklist/rocket-60-dark.png'
import ChecklistRocket100Dark from 'images/dashboard/checklist/rocket-100-dark.png'
import ChecklistRocket20Light from 'images/dashboard/checklist/rocket-20-light.png'
import ChecklistRocket40Light from 'images/dashboard/checklist/rocket-40-light.png'
import ChecklistRocket60Light from 'images/dashboard/checklist/rocket-60-light.png'
import ChecklistRocket100Light from 'images/dashboard/checklist/rocket-100-light.png'

export const getChecklistRocket = (percentage: number, isLightMode: boolean): string => {
  if (percentage === 100) {
    return isLightMode ? ChecklistRocket100Light : ChecklistRocket100Dark
  }
  if (percentage >= 60) {
    return isLightMode ? ChecklistRocket60Light : ChecklistRocket60Dark
  }
  if (percentage >= 40) {
    return isLightMode ? ChecklistRocket40Light : ChecklistRocket40Dark
  }
  return isLightMode ? ChecklistRocket20Light : ChecklistRocket20Dark
}
