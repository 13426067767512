import AttachedIcon from 'images/barry/attach-icon.svg'
import React, { ReactElement, useState } from 'react'
import { usePreAuthTokenMutation, useSendSupportTicketMutation } from 'store/api/support/support'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Page, switchPage } from 'store/barry/barry'
import { Flex, Spinner, Text } from 'theme-ui'
import SupportTicketInput from './BarryChatComponents/SupportTicketInput'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { Button, Svg } from 'ui'
import useGetColorMode from 'utils/useGetColorMode'

export default function SubmitTicketView(): ReactElement {
  const dispatch = useAppDispatch()
  const { messages } = useAppSelector(x => x.barry.message)
  const { data: userData } = useGetUser()
  const [email, setEmail] = useState(userData?.email || '')
  const { isLightMode } = useGetColorMode()
  const [comments, setComments] = useState('')
  const [
    sendSupportTicket,
    {
      isError: isSendSupportTicketError,
      isUninitialized: isSendSupportTicketUninitialized,
      isLoading: isSendSupportTicketLoading,
    },
  ] = useSendSupportTicketMutation()
  const [
    getPreAuthToken,
    { isError: isPreAuthError, isLoading: isPreAuthLoading, isSuccess: isPreAuthSuccess },
  ] = usePreAuthTokenMutation()

  const shouldDisplayErrorMessage = isSendSupportTicketError || isPreAuthError
  const isRequestLoading =
    isSendSupportTicketLoading ||
    isPreAuthLoading ||
    ((isPreAuthSuccess || isPreAuthError) && isSendSupportTicketUninitialized)

  return (
    <Flex
      className="hide-scrollbar"
      sx={{
        flexDirection: 'column',
        height: '100%',
        width: ' 100%',
      }}
      as="form"
      onSubmit={e => {
        e.preventDefault()
        if (!email) {
          return
        }

        getPreAuthToken({ userEmail: email })
          .unwrap()
          .then(payload => {
            if (!!payload) {
              sendSupportTicket({
                email: email,
                subject: `Barry Support Ticket from: ${email}`,
                chatlog: messages,
                ...(comments && { message: comments }),
                channel: 'chatbot',
                token: payload.token,
              })
            }
          })
          .catch(() => {})
      }}
    >
      <Flex
        data-testid="form-ticket-view"
        className="hide-scrollbar"
        sx={{
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          position: 'relative',
          top: 0,
          '@media screen and (max-height: 530px)': {
            position: 'fixed',
            top: '7.2rem',
            overflowY: 'scroll',
            pb: '18rem',
          },
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            px: '2.4rem',
          }}
        >
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'row',
              backgroundColor: 'darkJungleGreen2',
              minHeight: '4.8rem',
              border: '1px solid',
              borderColor: 'blueYonder20',
              borderRadius: '1.2rem',
              pointerEvents: 'none',
              mt: '2.4rem',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              gap: '0.8rem',
            }}
            data-testid="chatlog-attached-button"
          >
            <Svg svg={AttachedIcon} fill="lightCyan" descriptiveText="Attachment icon" />
            <Text variant="size14Weight400Line138Letter056" sx={{ color: 'lightCyan' }}>
              Chatlog Attached
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: '7rem' }}>
            <Text
              variant="size28Weight600"
              sx={{
                color: 'aliceBlue',
                mb: 0,
              }}
            >
              Talk to a human
            </Text>
            <Text
              variant="size14Weight400Line138Letter056"
              sx={{
                color: shouldDisplayErrorMessage ? 'orangeRed' : 'lightSteelBlue',
                mt: '0.8rem',
                mb: 0,
                maxWidth: '29.6rem',
                textAlign: 'center',
              }}
            >
              {shouldDisplayErrorMessage
                ? 'Something went wrong, please try sending it again.'
                : "Fill this form out and we'll get back to you as soon as possible by email"}
            </Text>
          </Flex>
          <Flex
            sx={{ mt: '3.2rem', width: '100%', gap: '2rem', flexDirection: 'column', px: '1.6rem' }}
          >
            {' '}
            {/**
             * TODO: Using autofill for the email on chrome at least fills in string with a fontSize of 17px
             */}
            <SupportTicketInput
              label="Email"
              inputTestId="ticket_flow_email"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              name="email"
              required={true}
              type="email"
            />
            <SupportTicketInput
              label="Comments (optional)"
              required={false}
              inputTestId="ticket_flow_comments"
              onChange={({ target }) => setComments(target.value)}
              value={comments}
              name="comments"
              type="text"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        data-testid="submit-ticket"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isLightMode ? 'commonWhite' : 'white6',
          height: '7.3rem',
          borderTop: '1px solid',
          borderColor: 'blueYonder40',
          mt: ['2rem', 'auto'],
          width: ['100%', '100%'],
          px: '1.6rem',
          position: ['fixed', 'relative'],
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Button
          ariaLabel="go back"
          variant="transparent"
          sx={{
            fontWeight: '500',
            color: 'lightSteelBlue',
            minWidth: '50%',
            fontSize: '1.7rem',
          }}
          onClick={() => dispatch(switchPage(Page.CHAT_PAGE))}
        >
          <Text variant="size17Weight500Line138">Back</Text>
        </Button>
        <Button
          ariaLabel="submit ticket"
          variant="gradientButton"
          color="title"
          sx={{
            color: 'white',
            minWidth: '50%',
            width: ['fit-content', 'fit-content'],
            height: '3.9rem',
            py: 0,
            zIndex: 'zIndex2',
            '& svg': {
              mt: '0.4rem',
            },
          }}
          type="submit"
          data-testid="ticket_flow_submit"
          disabled={isRequestLoading}
        >
          {isRequestLoading ? (
            <Spinner size={24} duration={900} color="aliceBlue" />
          ) : (
            <Text
              variant="size17Weight500Line138"
              sx={{
                pb: '0.2rem',
                color: 'commonWhite',
                fontSize: '1.7rem',
              }}
            >
              Send
            </Text>
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
