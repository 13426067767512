import React, { ReactElement } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/close-icon.svg'
interface CloseButtonProps {
  sx?: ThemeUIStyleObject
  onClick: () => void
}
export default function CloseButton({
  onClick,
  sx = {},
  ...props
}: CloseButtonProps): ReactElement {
  return (
    <Button
      variant="simple"
      ariaLabel="close button"
      sx={{
        flexShrink: 0,
        borderRadius: '100%',
        backgroundColor: 'blue',
        border: '1px solid',
        borderColor: 'blueYonder40',
        color: 'white',
        width: '4.6rem',
        height: '4.6rem',
        fontSize: '2.4rem',
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      <Svg
        svg={CloseIcon}
        fill="aliceBlue"
        width="2.4rem"
        height="2.24em"
        descriptiveText="Close button"
      />
    </Button>
  )
}
