import React, { ReactElement, ReactChild } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import ModalDialog from 'ui/NewModalDialog'
import { navigate } from 'gatsby'
import useStepHelper from '../useStepHelper'
import { troubleshootingAlertName } from '../SetupGuide'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import { SetupGuideIntermediateStates } from '../SetupGuide.interface'
import useQueryString from 'utils/useQueryString'
import { useAlertPresenter } from 'ui'
import { useLocation } from '@reach/router'
import { useAppSelector } from 'store/hooks'
import { useGetDevicesQuery } from 'store/api/devices'
import useGetProvisioningState from 'store/provisioning/useGetProvisioningState'
import { useGetUserQuery } from 'store/api/user'
import { freeDnsPagePath } from 'components/FreeDNSPage/FreeDNSPage'
import useGetColorMode from 'utils/useGetColorMode'

export default function SetupGuideDialog({
  title,
  isOpen,
  isLeftContentVisible,
  isRightContentVisible,
  footer,
  children,
  onClose,
  onBackClick,
  sxContainer,
  sxHeaderContainer,
  sxFooterContainer,
}: {
  title?: string
  isOpen?: boolean
  isRightContentVisible?: boolean
  isLeftContentVisible?: boolean
  footer?: ReactElement
  children: ReactChild
  onClose?: () => void
  onBackClick?: () => void
  sxContainer?: ThemeUIStyleObject
  sxHeaderContainer?: ThemeUIStyleObject
  sxFooterContainer?: ThemeUIStyleObject
}): ReactElement {
  const { closePane } = useStepHelper()
  const { setupGuideNav } = useQueryString()
  const { dismissAlert } = useAlertPresenter()
  const { isLightMode } = useGetColorMode()
  const { qs, nav } = useQueryString()
  const location = useLocation()
  const { icon } = useGetProvisioningState()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: userData } = useGetUserQuery('', { skip: !sessionToken })
  const isHapticsEnabled = !!userData?.haptics
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  /**
   * Allow the user to change the platform when the device does not have a platform selected.
   */
  const isPlatformTypePresent =
    icon || devicesData?.devices.find(device => device.PK === qs?.deviceId)?.icon

  const isFreeDNS = location.pathname.includes(freeDnsPagePath)

  return (
    <ModalDialog
      dataTestId="setup-guide"
      shouldCreatePortal={false}
      isOpen={isOpen || true}
      title={title}
      isLeftContentVisible={isLeftContentVisible}
      isRightContentVisible={isRightContentVisible || !isLeftContentVisible}
      onCloseClick={onClose || (() => closePane())}
      shouldVibrateOnPresent={isHapticsEnabled}
      onBackClick={
        isLeftContentVisible
          ? onBackClick ||
            ((): void => {
              dismissAlert(troubleshootingAlertName)

              if (location.key === 'initial') {
                nav({ ...pick(qs, ['overlay']) })
                return
              }

              if (qs.mode) {
                return setupGuideNav({
                  ...omit(qs, 'mode', 'initialSetupOs', 'step'),
                  setupOs: qs.initialSetupOs,
                  helpPane: SetupGuideIntermediateStates.Configure,
                })
              }

              qs.step
                ? setupGuideNav({
                    ...(isPlatformTypePresent ? qs : omit(qs, ['setupOs', 'step'])),
                    helpPane: SetupGuideIntermediateStates.DNS,
                  })
                : navigate(-1)
            })
          : undefined
      }
      dismiss={onClose || (() => closePane())}
      footer={footer}
      sx={{
        maxHeight: 'none',
      }}
      sxHeaderContainer={sxHeaderContainer}
      sxFooterContainer={sxFooterContainer}
    >
      <Flex
        sx={{
          minWidth: ['100%', '45.2rem'],
          flexDirection: 'column',
          alignItems: 'flex-start',
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: ['100%', '80dvh'],
          width: ['100%', isFreeDNS ? '100%' : '45.2rem'],
          ...sxContainer,
        }}
        className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
      >
        {children}
      </Flex>
    </ModalDialog>
  )
}
