import React, { ReactElement, ReactNode } from 'react'
import Tippy from '@tippyjs/react'
import { IconButton, ThemeUIStyleObject } from 'theme-ui'
import CheckMarkIcon from 'images/whitelist-icon.svg'
import CopyIcon from 'images/copy-icon.svg'
import { Button, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import CopyIconRedesign from 'images/copy-icon-clipboard.svg'
import { actionIconButtonStyles } from 'ui/ActionIconButton'
import useGetColorMode from 'utils/useGetColorMode'
import useCopyToClipboardHook from 'utils/useCopyToClipboard'

const mediumIconSizeSx = {
  minHeight: '3.2rem',
  minWidth: '3.2rem',
  height: '3.2rem',
  width: '3.2rem',
}

const smallIconSizeSx = {
  minHeight: '2.4rem',
  minWidth: '2.4rem',
  height: '2.4rem',
  width: '2.4rem',
}

export default function CopyToClipboard({
  data,
  ariaLabelText,
  smallIcon,
  iconCustomStyle,
  children,
  fillSvg,
  sx,
  theme,
  variant,
  isButton,
  isHeader,
}: {
  data: string
  ariaLabelText?: string
  smallIcon?: boolean
  iconCustomStyle?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  variant?: string
  children?: ReactNode
  fillSvg?: string
  theme?: string
  isButton?: boolean
  isHeader?: boolean
}): ReactElement {
  const { isLightMode } = useGetColorMode()

  const { isCopied, isVisible, handleClick, hideTooltip, resetCopiedState, showTooltip } =
    useCopyToClipboardHook({ isButton, data })

  return isButton ? (
    <Button variant="action" onClick={handleClick} ariaLabel="copy button">
      {isCopied ? 'Copied' : 'Copy'}
    </Button>
  ) : (
    <Tippy
      content={isCopied ? 'Copied' : 'Copy to clipboard'}
      theme={theme || isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
      arrow={false}
      onHidden={(): void => {
        resetCopiedState()
        hideTooltip()
      }}
      visible={isVisible}
    >
      <IconButton
        aria-label={ariaLabelText}
        className="copy-icon-wrapper"
        data-testid="copy-icon-wrapper"
        variant={variant ? variant : 'none'}
        sx={{
          height: 'auto',
          width: 'auto',
          cursor: 'pointer',
          ':hover svg path': {
            fill: fillSvg?.includes('greenApple') ? 'greenApple' : 'aliceBlue',
          },
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          p: 0,
          ...(isHeader ? actionIconButtonStyles : {}),
          ...sx,
        }}
        onClick={handleClick}
        onMouseEnter={showTooltip}
        onMouseLeave={isCopied ? undefined : hideTooltip}
        onKeyDown={stopPropagationOnKeyPress}
      >
        {children}
        {isCopied ? (
          <Svg
            svg={CheckMarkIcon}
            fill={isHeader ? 'aliceBlue' : fillSvg || 'aliceBlue60'}
            sx={iconCustomStyle || (smallIcon ? smallIconSizeSx : mediumIconSizeSx)}
            descriptiveText="Content copied checkmark"
          />
        ) : (
          <Svg
            svg={variant ? CopyIconRedesign : CopyIcon}
            fill={isHeader ? 'aliceBlue' : fillSvg || 'aliceBlue60'}
            sx={iconCustomStyle || (smallIcon ? smallIconSizeSx : mediumIconSizeSx)}
            descriptiveText="Copy to clipboard button"
          />
        )}
      </IconButton>
    </Tippy>
  )
}
