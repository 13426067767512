import React, { ReactElement, useEffect } from 'react'
import { Flex } from 'theme-ui'
import { Svg, Button } from 'ui'
import CollapseIcon from 'images/dashboard/collapse-icon.svg'
import NavBarContent from 'components/Dashboard/NavBar/NavBarContent'
import NavBarHeader from 'components/Dashboard/NavBar/NavBarHeader'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsNavBarExpanded } from 'store/dashboard/dashboard'
import Checklist from './Checklist'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { useShowChecklist } from 'components/Dashboard/utils/useShowChecklist'

export const navBarTabletWidth = '11.8rem'
const navBarDesktopCollapsedWidth = '11.8rem'

export default function NavBar(): ReactElement {
  const dispatch = useAppDispatch()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const { data: userData } = useGetUser({ refetchOnMountOrArgChange: true })
  const shouldShowChecklist = useShowChecklist(userData?.date)

  useEffect(() => {
    return () => {
      dispatch(setIsNavBarExpanded?.(true))
    }
  }, [dispatch])

  return (
    <Flex
      as="nav"
      data-testid="nav-bar"
      sx={{
        position: 'relative',
        zIndex: 'zIndex300',
        width: [0, navBarTabletWidth, isNavBarExpanded ? '29.8rem' : navBarDesktopCollapsedWidth],
        transition: 'width 0.3s',
        flexShrink: 0,
      }}
    >
      <Flex
        sx={{
          position: 'fixed',
          width: 'fit-content',
          height: '100%',
          flexShrink: 0,
          pl: '1.6rem',
          zIndex: 'zIndex240',
        }}
      >
        <Flex
          data-testid="nav-menu-left"
          sx={{
            width: ['10rem', '7.2rem', isNavBarExpanded ? '24.8rem' : '7.2rem'],
            pt: '1.6rem',
            flexDirection: 'column',
            transition: 'width 0.3s',
          }}
        >
          <NavBarHeader />
          {shouldShowChecklist && <Checklist />}
          <NavBarContent />
        </Flex>
        <Flex
          sx={{
            position: 'relative',
            ml: ['2.4rem', '1.6rem', '1.6rem'],
            mr: ['2.4rem', '1.6rem', '1.6rem'],
            width: '1px',
            height: '100%',
            backgroundColor: 'white10',
            ':hover': {
              '.divider-button': {
                opacity: 1,
              },
            },
            transition: 'all 100ms ease-in-out',
          }}
        >
          <Flex
            sx={{
              height: '100%',
              width: isNavBarExpanded ? '2.8rem' : '2rem',
              right: isNavBarExpanded ? '-1.6rem' : '-1rem',
              position: 'absolute',
              display: ['none', 'none', 'flex'],
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(setIsNavBarExpanded?.(!isNavBarExpanded))
            }}
          >
            <Button
              variant="simple"
              data-testid="divider-button"
              ariaLabel={isNavBarExpanded ? 'collapse nav bar' : 'expand nav bar'}
              className="divider-button"
              sx={{
                opacity: 0,
                position: 'absolute',
                top: '50%',
                right: 0,
                width: '3.2rem',
                height: '3.2rem',
                p: 0,
                borderRadius: '50%',
                transform: `rotate(${isNavBarExpanded ? 0 : '180deg'})`,
                backgroundColor: 'darkBodyBG',
              }}
            >
              <Svg
                svg={CollapseIcon}
                fill="white"
                sx={{ flexShrink: 0 }}
                descriptiveText="Toggle Navigation Bar"
              />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
