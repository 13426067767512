import NoResults from 'components/Dashboard/CommonComponents/NoResults'
import React, { ReactElement } from 'react'
import { useAppDispatch } from 'store/hooks'
import { setHasShownServicesWarning } from 'store/services'
import { Text } from 'theme-ui'
import { Button } from 'ui'

export default function ServicesTersmAgreement(): ReactElement {
  const dispatch = useAppDispatch()

  return (
    <NoResults
      message="Primary purpose of this section is to BLOCK services. Redirection of any services listed here is offered on a best effort basis and is excluded from the refund policy."
      content={
        <Button
          variant="newPrimary"
          data-testid="agree-to-services-conditions-button"
          ariaLabel="I agree to the services usage conditions"
          type="submit"
          onClick={() => dispatch(setHasShownServicesWarning(true))}
        >
          <Text variant="size17Weight600Line138">I Agree</Text>
        </Button>
      }
    />
  )
}
