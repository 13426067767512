import React, { ReactElement, useEffect, useState } from 'react'
import { Image, Spinner } from '@theme-ui/components'
import { getDeviceQrCode } from 'store/api/devices'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import { Flex } from 'theme-ui'

const errorMessage = 'Failed to generate QR code. Please try again.'

export default function AutomaticQrCode({ deviceId }: { deviceId: string }): ReactElement {
  const [image, setImage] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const errorHandler = () => {
      setIsLoading(false)
      setError(errorMessage)
    }

    setIsLoading(true)
    getDeviceQrCode(deviceId)
      .then(res => {
        if (!res.ok) {
          errorHandler()
          return Promise.reject()
        }
        return res.blob()
      })
      .then(blob => setImage(URL.createObjectURL(blob)))
      .catch(() => {
        errorHandler()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [deviceId])

  return error ? (
    <ErrorBox errorMessage={error} sx={{ mb: '2.4rem', borderRadius: '1.2rem' }} />
  ) : (
    <Flex
      sx={{
        width: '17.5rem',
        height: '17.5rem',
        borderRadius: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Image
          data-testid="automatic-qr-code"
          src={image}
          alt="qr-code"
          sx={{ borderRadius: '1rem' }}
        />
      )}
    </Flex>
  )
}
