import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { Text } from 'theme-ui'
import { useParams } from '@reach/router'
import { Flex } from '@theme-ui/components'
import categoryIconMap from 'components/Dashboard/Profiles/Services/CategoriesList/icons'
import { EditProfileUrlParams } from 'components/Dashboard/Profiles'
import { ServiceCategory } from 'store/api/services'
import { Svg } from 'ui'
import ArrowIcon from 'images/big-chevron-right.svg'
import PlaceholderIcon from 'images/serviceIcon.svg'

interface CategoryCardProps {
  category: ServiceCategory
}

export default function ServiceCategoryCard({ category }: CategoryCardProps): ReactElement {
  const { profileId } = useParams<EditProfileUrlParams>()
  const { name, PK, description, count, enabledCount } = category

  return (
    <Flex
      sx={{
        width: '100%',
        backgroundColor: 'cardBg',
        border: ['none', '1px solid'],
        borderColor: ['none', 'blueYonder15'],
        flexDirection: 'column',
        borderRadius: ['none', '16px'],
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
        ':hover': {
          backgroundColor: 'white8',
          'svg > g path': {
            fill: 'white',
          },
          'svg > g ': {
            opacity: 1,
          },
        },
      }}
      data-testid={`service-category-item-${category.PK}`}
    >
      <Link
        style={{ textDecoration: 'none', color: 'white' }}
        to={`/dashboard/profiles/${profileId}/services/${PK}${location.search}`}
      >
        <Flex sx={{ width: '100%', flexDirection: 'column' }}>
          <Flex
            sx={{
              flexDirection: 'column',
              p: ['0.8rem 1.2rem', '1.2rem'],
            }}
          >
            <Flex
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                pb: '1.2rem',
              }}
            >
              <Svg
                svg={categoryIconMap[PK] ?? PlaceholderIcon}
                descriptiveText={`${name} category icon`}
                fill="aliceBlue"
                sx={{
                  mr: '1.2rem',
                  flexShrink: 0,
                }}
                data-testid="services-list-item-svg"
              />
              <Flex sx={{ flexDirection: 'column', flex: 1 }}>
                <Text variant="size15Weight700" sx={{ color: 'aliceBlue' }}>
                  {name}
                </Text>
                <Text
                  variant="size12Weight400"
                  sx={{ opacity: 0.5, whiteSpace: 'nowrap', color: 'aliceBlue' }}
                >
                  {enabledCount ? `${enabledCount} out of ${count} Enabled` : `${count} Disabled`}
                </Text>
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'row',
                  gap: '0.8rem',
                  pt: '0.7rem',
                }}
              >
                <Svg
                  svg={ArrowIcon}
                  descriptiveText={`View ${name} category services`}
                  fill="aliceBlue"
                  sx={{ width: '2.4rem', height: '2.4rem' }}
                />
              </Flex>
            </Flex>
            <Flex
              sx={{
                height: ['3.1rem', '5.4rem'],
                p: '1.2rem',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white6',
                border: '1px solid',
                borderColor: 'wakaiwaGrey20',
                borderRadius: '8px',
              }}
            >
              <Text
                variant="size12Weight400"
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  opacity: '0.6',
                  color: 'aliceBlue',
                  maxWidth: ['none', 'none', '15rem'],
                }}
              >
                {description}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}
