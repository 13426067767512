import { baseApi, getQueryArgs, throwErrorFromResponseData, transformErrorResponse } from '../index'
import {
  ImportJsonBody,
  ModifyProfile,
  NewProfile,
  Profile,
  ProfileJsonResponse,
  ProfileOptionsMetadataResponse,
  ProfileResponse,
  PutProfileOptionArgs,
  PutProfileOptionResponse,
  ProfileOptionsResponse,
  ProfileResponseData,
} from './profiles.interface'
import { EnabledStatus } from 'store/api/rules'
import sortBy from 'lodash/sortBy'
import { ApiResponse, EmptyBodyResponse } from '../http'
import { filtersApi } from 'store/api/filters/filters'
import { current } from '@reduxjs/toolkit'
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks'

/**
 * This function converts the PK returned by the api to a string
 */
const scrubProfiles = (profiles: Profile[]): Profile[] =>
  profiles?.map(profile => ({ ...profile, PK: profile?.PK.toString() }))

export const profilesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProfiles: builder.query({
      query: () => getQueryArgs(`/profiles`),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => response.body,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              data.profiles = scrubProfiles(data.profiles)
              draft.profiles = data.profiles
              draft.shared_profiles = data.shared_profiles
            }),
          )
        } catch {}
      },
      providesTags: ['Profiles'],
    }),
    putProfile: builder.mutation({
      query: ({ pk, body }: { pk: string; body: Partial<ModifyProfile>; editProfileId?: string }) =>
        getQueryArgs(`/profiles/${pk}`, 'PUT', body),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => response.body,
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              const scrubbedProfiles = scrubProfiles(data.profiles)
              if (data.shared_profiles) {
                draft.shared_profiles = data.shared_profiles
              }

              const index = draft.profiles.findIndex(d => d.PK === scrubbedProfiles[0].PK)
              draft.profiles[index] = scrubbedProfiles[0]
            }),
          )
        } catch {}
      },
    }),
    postProfile: builder.mutation({
      query: (body: NewProfile) => getQueryArgs('/profiles', 'POST', body),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              draft.profiles = sortBy(
                [...draft.profiles, ...scrubProfiles(data.profiles)],
                profile => `${profile.name}`,
              )
              if (data.shared_profiles) {
                draft.shared_profiles = data.shared_profiles
              }
            }),
          )
        } catch {}
      },
    }),
    deleteProfile: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/profiles/${pk}`, 'DELETE'),
      transformErrorResponse,
      transformResponse: (response: EmptyBodyResponse) => {
        throwErrorFromResponseData(response)

        return {
          message: response.message,
        }
      },
      async onQueryStarted({ pk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              draft.profiles = draft.profiles.filter(d => d.PK !== pk.toString())
            }),
          )
        } catch {}
      },
    }),
    getProfileOptionsMetadata: builder.query({
      query: () => getQueryArgs('/profiles/options'),
      transformErrorResponse,
      transformResponse: (response: ProfileOptionsMetadataResponse) => {
        response.body.options = response.body.options.map(o => ({ ...o, id: o.PK }))
        return response.body
      },
      providesTags: ['ProfileOptions'],
    }),
    getProfileOptions: builder.query({
      query: (profileId: string | number | undefined) =>
        getQueryArgs(`/profiles/${profileId}/options`),
      transformErrorResponse,
      transformResponse: (response: ProfileOptionsResponse) => response.body,
    }),
    getGlobalProfileOptions: builder.query({
      query: ({
        globalProfileId,
      }: {
        globalProfileId: string | number | undefined
        profileId: string | number | undefined
      }) => ({
        url: `/profiles/${globalProfileId}/options`,
      }),
      transformErrorResponse,
      transformResponse: (response: ProfileOptionsResponse) => response.body,
      providesTags: ['ProfileOptions'],
    }),
    putProfileOptions: builder.mutation({
      query: ({ profileId, status, PK, value, custom_value, cbp, ovr }: PutProfileOptionArgs) =>
        getQueryArgs(`/profiles/${profileId}/options/${PK}`, 'PUT', {
          status,
          value,
          custom_value,
          ...(cbp ?? {}),
          ovr,
        }),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<PutProfileOptionResponse>) => response.body,
      async onQueryStarted(
        { profileId, status, PK: optionId, value, custom_value, cbp, ovr },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled

          if (profileId) {
            dispatch(
              profilesApi.util.updateQueryData('getProfileOptions', profileId, draft => {
                const options = current(draft.options)
                // check if it exists
                const existingOption = options.find(o => o.PK === optionId)

                if (status === EnabledStatus.ENABLED) {
                  // if it exists, update it, otherwise add it
                  if (existingOption) {
                    draft.options = [
                      ...options.filter(o => o.PK !== optionId),
                      {
                        PK: optionId,
                        value: data.options?.[0].value.toString() ?? value,
                        custom_value,
                        cbp,
                        global: existingOption.global,
                      },
                    ]
                  } else {
                    draft.options.push({
                      PK: optionId,
                      value,
                      custom_value,
                      cbp,
                    })

                    dispatch(
                      profilesApi.util.updateQueryData('getProfiles', '', draft => {
                        updateProfileSectionCount({
                          draft,
                          profileId,
                          type: ProfileSectionType.PROFILE_OPTIONS,
                          count: 1,
                          isIncrease: true,
                        })
                      }),
                    )
                  }
                } else {
                  // if status is disabled,
                  if (existingOption?.global) {
                    //handle override
                    draft.options = [
                      ...options.filter(o => o.PK !== optionId),
                      {
                        PK: optionId,
                        value: undefined,
                        custom_value: undefined,
                        cbp: undefined,
                        global: existingOption.global,
                        ovr,
                      },
                    ]
                  } else {
                    // and not global, remove it
                    draft.options = options.filter(o => o.PK !== optionId)

                    if (draft.options?.length !== options?.length) {
                      dispatch(
                        profilesApi.util.updateQueryData('getProfiles', '', draft => {
                          updateProfileSectionCount({
                            draft,
                            profileId,
                            type: ProfileSectionType.PROFILE_OPTIONS,
                            count: 1,
                            isIncrease: false,
                          })
                        }),
                      )
                    }
                  }
                }
              }),
            )
          }

          dispatch(
            filtersApi.util.updateQueryData('getFilters', { profileId }, draft => {
              draft.filters = draft.filters.map(filter => {
                if (filter.PK === 'malware') {
                  const level = filter.levels?.find(level => level.name === optionId)

                  if (level) {
                    level.status = status

                    if (status === EnabledStatus.ENABLED) {
                      level.opt = data.options
                    } else {
                      level.opt = undefined
                    }
                    if (filter.action) {
                      filter.action.status = filter.levels?.some(
                        l => l.status === EnabledStatus.ENABLED,
                      )
                        ? EnabledStatus.ENABLED
                        : EnabledStatus.DISABLED
                    }
                  }
                }
                return filter
              })
            }),
          )
        } catch {}
      },
    }),
    exportProfile: builder.query({
      query: (profileId: string) => getQueryArgs(`/profiles/${profileId}/export/json`),
      transformErrorResponse,
      transformResponse: (response: ProfileJsonResponse) => response,
    }),
    importProfile: builder.mutation({
      query: (body: ImportJsonBody) => getQueryArgs(`/profiles/import`, 'POST', body),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<ImportJsonBody>) => response.body,
    }),
  }),
})

export enum ProfileSectionType {
  FILTERS = 'flt',
  SERVICES = 'svc',
  CUSTOM_RULES = 'rule',
  PROFILE_OPTIONS = 'opt',
}

export function updateProfileSectionCount({
  draft,
  profileId,
  type,
  count = 1,
  isIncrease,
}: {
  draft: MaybeDrafted<ProfileResponseData>
  profileId: string | number | undefined
  type: ProfileSectionType
  count?: number
  isIncrease: boolean
}): void {
  const profile = [...(draft?.profiles || []), ...(draft?.shared_profiles || [])].find(
    profile => profileId === profile.PK,
  )

  if (profile && profile.profile) {
    if (isIncrease) {
      profile.profile[type].count = profile.profile[type].count + count
    } else {
      const countResult = profile.profile[type].count - count
      profile.profile[type].count = countResult < 0 ? 0 : countResult
    }
  }
}

export const {
  endpoints,
  useGetProfilesQuery,
  useGetProfileOptionsMetadataQuery,
  useGetProfileOptionsQuery,
  useDeleteProfileMutation,
  usePutProfileMutation,
  usePutProfileOptionsMutation,
  usePostProfileMutation,
  useLazyExportProfileQuery,
  useImportProfileMutation,
  useGetGlobalProfileOptionsQuery,
} = profilesApi
