import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ProxyTrayState } from 'store/proxyTray'
import SpoofIpRuleInput from 'components/Dashboard/ProxyModalDialog/SpoofIpRuleInput'
import { useAppSelector } from 'store/hooks'
import { RuleType } from 'store/api/rules'
import omit from 'lodash/omit'
import { isIpValid } from 'utils/isIpOrHostnameValid'
import { v6, v4 } from 'is-ip'
import { Setter } from 'utils'
import { BadgeType, SaveButton } from './IpOrHostnameForm'

export default function Ipv4AndIpv6Form({
  selectedFirstType,
  setSelectedFirstType,
  handleProxyClick,
}: {
  selectedFirstType: BadgeType
  setSelectedFirstType: Setter<BadgeType>
  handleProxyClick: (via?: string, viaV6?: string) => void
}): ReactElement {
  const [via, setVia] = useState('')
  const [viaV6, setViaV6] = useState('')
  const proxyTrayState: ProxyTrayState = useAppSelector(s => s.proxyTray)
  const inputRef = useRef<HTMLInputElement>(null)
  const proxyTrayVia = proxyTrayState.rule?.action?.via || ''
  const proxyTrayViaV6 = proxyTrayState.rule?.action?.via_v6 || ''

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (proxyTrayState.rule?.action?.do === RuleType.SPOOF_IP) {
      setVia(v4(proxyTrayVia) ? proxyTrayVia : '')
      setViaV6(proxyTrayViaV6 ?? '')
    }
  }, [proxyTrayState.rule, proxyTrayVia, proxyTrayViaV6])

  return (
    <>
      <SpoofIpRuleInput
        types={Object.values(omit(BadgeType, ['IPV6', 'COMMENT']))}
        via={via}
        placeholder="Enter IPv4 Address"
        selectedType={selectedFirstType}
        setSelectedType={type => {
          setSelectedFirstType(type)
        }}
        setVia={setVia}
        inputRef={inputRef}
        id="rule-tray-spoof-ipv4-input"
        testId="rule-tray-spoof-ipv4-input"
        label="IPv4 Address"
        ariaLabel="enter ipv4"
      />
      <SpoofIpRuleInput
        isDropdownVisible={false}
        via={viaV6}
        placeholder="Enter IPv6 Address"
        id="rule-tray-spoof-ipv6-input"
        testId="rule-tray-spoof-ipv6-input"
        selectedType={BadgeType.IPV6}
        setVia={setViaV6}
        label="IPv6 Address"
        ariaLabel="enter ipv6"
      />
      <SaveButton
        isButtonDisabled={viaV6 ? !isIpValid(viaV6) || !v6(viaV6) : !isIpValid(via) || !v4(via)}
        testId="hostname-submit-button"
        ariaLabel="save IP addresses"
        onClick={() => handleProxyClick(via, viaV6)}
      />
    </>
  )
}
