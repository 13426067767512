import { ProxyLocation } from 'store/api/proxies'
import React, { memo, ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { Button, Svg } from 'ui'
import DownArrow from 'images/profileManagement/context-menu-arrow-down.svg'
import ProxyMenuItem from 'components/ProxyList/ProxyMenuItem'
import CheckIcon from 'images/analytics/check.svg'
import TextWithOverFlowAndTippyPopup from '../TextWithOverFlowAndTippyPopup'
import { scrollingContainerRef } from '../Dashboard/DashboardScrollingContainer'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/CommonComponents/ExternalSvgIcon'

interface ProxyCountryProps {
  index: number
  name: string
  locations: ProxyLocation[]
  proxyLevel?: string
  selectedItemColor: string
  currentLocationPK?: string
  handleProxyClick: (location?: string) => void
  allowDeselect?: boolean
  shouldNotShowSingle?: boolean
  isBackgroundHighlighted?: boolean
  isDropdown?: boolean
}

const MemoizedProxyMenuItem = memo(ProxyMenuItem)
export default function ProxyCountry({
  index,
  name,
  locations,
  proxyLevel,
  selectedItemColor,
  currentLocationPK,
  handleProxyClick,
  shouldNotShowSingle = false,
  isBackgroundHighlighted,
  isDropdown,
  ...attr
}: ProxyCountryProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const isSelected = !!locations.find(l => l.PK === currentLocationPK)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isSingleLocation = locations.length === 1 && locations[0].isSingle && shouldNotShowSingle
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  const onProxyCountryAction = () => {
    clearTimeout(timeoutId.current)

    if (isSingleLocation) {
      handleProxyClick(locations[0].PK)
    } else {
      setIsOpen(prev => !prev)
    }

    if (!isOpen) {
      timeoutId.current = setTimeout(() => {
        // should not scroll container when show the cities
        scrollingContainerRef.current?.scrollTo({ top: scrollingContainerRef.current.scrollTop })
        buttonRef.current?.scrollIntoView({ block: 'nearest' })
      }, 0)
    }
  }

  useEffect(() => {
    if (!isSingleLocation && !!locations.find(l => l.matchKey || isSelected)) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isSelected, isSingleLocation, locations])

  return (
    <Button
      ref={buttonRef}
      variant="transparent"
      onClick={onProxyCountryAction}
      ariaLabel={`select ${name}`}
      sx={{
        p: 0,
        flexShrink: 0,
        width: '100%',
        minHeight: isDropdown ? 'auto' : '3.8rem',
        color: 'aliceBlue60',
        ':hover': {
          '.right-svg': {
            fill: isSelected ? 'greenApple' : 'aliceBlue',
          },
          '.down-svg path': {
            fill: 'aliceBlue',
          },
        },
      }}
      {...attr}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column' }}>
        <Flex
          data-testid={`proxy-country-${name}`}
          sx={{
            width: '100%',
            height: isDropdown ? 'auto' : '3.8rem',
            py: isDropdown ? '0.4rem' : '0.8rem',
            px: isDropdown ? '1.2rem' : '1.6rem',
            alignItems: 'center',
            borderTopWidth: !!index ? '1px' : '0',
            borderRightWidth: '0',
            borderLeftWidth: '0',
            borderBottomWidth: isOpen ? '1px' : '0',
            borderStyle: 'solid',
            borderColor: 'white15',
            '& div': {
              color: isSelected ? selectedItemColor : isOpen ? 'aliceBlue' : 'aliceBlue60',
            },
            '& span': {
              color: isSelected ? selectedItemColor : isOpen ? 'aliceBlue' : 'aliceBlue60',
            },
            '& svg': {
              fill: selectedItemColor,
            },
            '& > svg': {
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
            },
            ':hover': {
              '& div': {
                color: isSelected ? selectedItemColor : 'aliceBlue',
              },
              '& span': {
                color: isSelected ? selectedItemColor : 'aliceBlue',
              },
              '& svg': {
                fill: isSelected ? selectedItemColor : 'aliceBlue',
              },
            },
          }}
        >
          <Flex sx={{ width: '100%', alignItems: 'center' }}>
            <ExternalSvgIcon
              icon={locations[0].country}
              type={IconType.LOCATIONS}
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                flexShrink: 0,
              }}
              descriptiveText={`${locations[0].country} country flag`}
            />
            <TextWithOverFlowAndTippyPopup
              variant="size15Weight600"
              sxText={{
                ml: '0.8rem',
                color: 'aliceBlue60',
              }}
              content={name}
              ariaLabel={name}
            />
          </Flex>
          {!isSingleLocation && (
            <Svg
              className="down-svg"
              svg={DownArrow}
              fill={isOpen ? 'aliceBlue' : 'aliceBlue30'}
              sx={{ flexShrink: 0 }}
              data-testid={isOpen ? 'proxy-country-open' : 'proxy-country-close'}
              descriptiveText={
                isOpen ? `Collapse ${name} proxy locations` : `Expand ${name} proxy locations`
              }
            />
          )}
          {isSingleLocation && isSelected && (
            <Box className="right-svg">
              <Svg
                svg={CheckIcon}
                fill="greenApple"
                descriptiveText={`${name} proxy location selected`}
              />
            </Box>
          )}
        </Flex>
        {isOpen && (
          <Flex
            sx={{
              width: '100%',
              pl: '1.2rem',
              flexDirection: 'column',
            }}
          >
            {locations.map(location => {
              return (
                <MemoizedProxyMenuItem
                  key={location.PK}
                  sx={{ width: '100%', pl: '0.4rem', pr: '1.6rem', mx: 0 }}
                  isSelected={location.PK === currentLocationPK}
                  data-testid={`${proxyLevel}-location-${location.city}`}
                  location={location}
                  onClick={handleProxyClick}
                  rightSvg={
                    currentLocationPK === location.PK ? (
                      <Svg
                        svg={CheckIcon}
                        fill="greenApple"
                        descriptiveText={`${location.city} proxy location selected`}
                      />
                    ) : (
                      <Svg
                        svg={DownArrow}
                        fill="aliceBlue30"
                        sx={{ transform: 'rotate(-90deg)' }}
                        descriptiveText={`View ${location.city} proxy location options`}
                      />
                    )
                  }
                  selectedItemColor={selectedItemColor}
                  isBackgroundHighlighted={isBackgroundHighlighted}
                />
              )
            })}
          </Flex>
        )}
      </Flex>
    </Button>
  )
}
