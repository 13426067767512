import React, { ReactElement, useMemo, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg, TrayRenderProps, useTrayPresenter } from 'ui'
import { useAppDispatch } from 'store/hooks'
import ThirdPartyFilterItem from './ThirdPartyFilterItem'
import BlockListLayout from 'components/Dashboard/Profiles/Filters/BlockListLayout'
import { setSelectedFilter } from 'store/filters'
import { set3rdPartyFilterPK } from 'store/freeDNS'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import useQueryString from 'utils/useQueryString'
import { useGetFreeDNSFiltersQuery } from 'store/api/freeDns'
import ArrowDown from 'images/dashboard/context-menu-arrow-down.svg'

export const filterItemHeight = ['11.2rem', '8.2rem', '8.2rem']
export const filterItemGap = ['1.6rem', '2.4rem', '2.4rem']
const defaultVisibleFilterNumbers = [2, 3, 3]

const ThirdPartyFilters = (): ReactElement => {
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpointIndex()
  const { nav } = useQueryString()
  const { data: freeDnsData } = useGetFreeDNSFiltersQuery('')
  const [isCollapsed, setIsCollapsed] = useState(true)
  const isMobile = useBreakpointIndex() === 0

  const blocklistTrayInfo = useMemo(
    () => ({
      id: `BlockListTray`,
      renderTray: (props: TrayRenderProps) => <BlockListLayout {...props} />,
      zIndexName: 'zIndex550',
    }),
    [],
  )
  const { showTray: showBlocklistTray } = useTrayPresenter(blocklistTrayInfo)

  const onConfigureClick = filterPK => {
    dispatch(set3rdPartyFilterPK(filterPK))

    if (isMobile) {
      nav({
        helpPane: SetupGuideIntermediateStates.DNS,
        freeResolverType: filterPK,
      })
    }
  }

  return (
    <Flex
      data-testid="third-party-filters"
      sx={{
        maxWidth: '99.9rem',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        mt: ['4.8rem', '8rem'],
      }}
    >
      <Text
        tabIndex={0}
        as="h2"
        variant="size40Weight600"
        sx={{ mb: '3.2rem', color: 'aliceBlue' }}
      >
        3rd Party Filters
      </Text>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          maxHeight: `calc((${filterItemHeight[breakpoint]} + ${filterItemGap[breakpoint]}) * ${
            isCollapsed
              ? defaultVisibleFilterNumbers[breakpoint]
              : freeDnsData?.external_filters.length
          })`,
          px: ['1.2rem', '1.6rem', 0],
          overflow: 'hidden',
          transition: '0.3s max-height ease',
          gap: ['1.6rem', '2.4rem'],
        }}
      >
        {freeDnsData?.external_filters.map(filter => (
          <ThirdPartyFilterItem
            key={filter.PK}
            title={filter.name}
            onInfoIconClick={() => {
              dispatch(setSelectedFilter(filter))
              showBlocklistTray()
            }}
            onConfigureClick={() => {
              onConfigureClick(filter.PK)
            }}
          />
        ))}
      </Flex>
      <Button
        ariaLabel={isCollapsed ? 'See all 3rd party filters' : 'Hide 3rd party filters'}
        variant="roundedSecondary"
        sx={{
          mt: [isCollapsed ? '0.8rem' : '2.4rem', !isCollapsed && '2.4rem'],
          gap: '0.8rem',
          '& svg': {
            transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
          },
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Text variant="size17Weight600Line138" sx={{ color: 'aliceBlue' }}>
          {isCollapsed ? 'See all 3rd party filters' : 'Hide 3rd party filters'}
        </Text>
        <Svg svg={ArrowDown} fill="aliceBlue" descriptiveText="Toggle filter list visibility" />
      </Button>
    </Flex>
  )
}

export default ThirdPartyFilters
