import { baseApi, getQueryArgs, throwErrorFromResponseData } from 'store/api'
import { ApiResponse } from '../http'
import { BillingProduct, Product } from './payments.interface'
import { PaymentError } from 'store/billing'
import { EnabledStatus } from 'store/api/rules'

export enum PromoMode {
  PROMO = 'promo_code',
  USERNAME = 'windscribe_user',
}

export interface Currency {
  name: string
  symbol: string
  country_code?: string
  code: string
}

export const defaultCurrencyCode = 'usd'

export type GetProductsResponse = ApiResponse<{
  products: Product[]
  currencies: Currency[]
  do_trial?: EnabledStatus
}>

export type GetBillingProductsResponse = ApiResponse<{
  products: BillingProduct[]
  error?: PaymentError
}>

export interface GetProductsRequest {
  promoMode?: PromoMode
  promoCode?: string
}

export const productsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getBillingProducts: builder.query({
      query: () => getQueryArgs('/billing/products'),
      transformResponse: (response: GetBillingProductsResponse) => response.body,
      providesTags: () => ['Product'],
    }),
    getProducts: builder.query({
      query: ({ promoMode, promoCode }: GetProductsRequest) =>
        getQueryArgs(`/products${promoMode && promoCode ? `?${promoMode}=${promoCode}` : ''}`),
      transformResponse: (response: GetProductsResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      providesTags: () => ['Product'],
    }),
  }),
})

export const { useGetBillingProductsQuery, useGetProductsQuery, useLazyGetProductsQuery } =
  productsApi
