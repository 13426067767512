import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileOptionsMetaData, Profile } from 'store/api/profiles/profiles.interface'
import { initialResponseState, ResponseState } from 'store/fetchingLogic'
import { SubHeaderPath } from 'components/Dashboard/SubHeader/SubHeaderButtonContent'

import { sessionLogout } from '../session'
import { profilesApi } from 'store/api/profiles'

export interface ProfileState extends ResponseState<{ profiles: Profile[] }> {
  editProfileId?: string
  profileOptions?: ProfileOptionsMetaData[]
  shouldSortProfilesAlphabetically: boolean
  editingProfileSection?: SubHeaderPath
  newlyAddedProfileId?: string
}

const initialState: ProfileState = {
  ...initialResponseState,
  shouldSortProfilesAlphabetically: true,
}

export const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    setProfileForEdit(state, action: PayloadAction<string | undefined>): void {
      state.editProfileId = action.payload
    },
    setShouldSortProfilesAlphabetically(state, action: PayloadAction<boolean>): void {
      state.shouldSortProfilesAlphabetically = action.payload
    },
    setEditingProfileSection(state, action: PayloadAction<SubHeaderPath>): void {
      state.editingProfileSection = action.payload
    },
    clearNewlyAddedProfileId(state): void {
      state.newlyAddedProfileId = undefined
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => initialState)
      .addMatcher(profilesApi.endpoints.postProfile.matchFulfilled, (state, action) => {
        state.newlyAddedProfileId = action.payload?.profiles?.[0]?.PK.toString()
      })
  },
})

export const {
  setProfileForEdit,
  setShouldSortProfilesAlphabetically,
  setEditingProfileSection,
  clearNewlyAddedProfileId,
} = profileSlice.actions
