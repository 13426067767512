import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import useGetUserState from 'store/api/user/useGetUserState'
import { userApi } from 'store/api/user'
import { useIsTwoFaVisible } from 'utils/useIsTwoFaVisible'
import useRedirects from 'utils/useRedirects'
import { useSuccessfulRegistrationTracking } from 'utils/campaignTracking'
import useMaintenanceMode from './utils/useMaintenanceMode'
import { useDetectConfiguredDevice } from './utils/useDetectConfiguredDevice'
import { useGetNotificationsQuery } from 'store/api/notifications'
import { profilesApi, useGetProfilesQuery } from 'store/api/profiles'
import { useGetStatEndpointsQuery } from 'store/api/analytics'
import { organizationApi } from 'store/api/organization'
import { provisionApi } from 'store/api/provision'
import { devicesApi } from 'store/api/devices'
import { reportsApi } from 'store/api/reports'
import { TrayRenderProps, useTrayPresenter } from 'ui'
import NavMenuMobile from './NavBar/NavMenu.mobile'
import OrganizationNavBarContent from '../Organization/OrganizationNavBar/OrganizationNavBarContent'
import NavBarContent from './NavBar/NavBarContent'

export default function DashboardInitialization(): null {
  const userPk = useAppSelector(s => s.session.userPk)
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const { isOrganization, startPollingUser } = useGetUserState()
  const { data: userData } = userApi.endpoints.getUser.useQueryState('')
  const isConfirmEmailVisible = !!userData?.org?.PK && !userData?.email_status
  const isTwoFaVisible = useIsTwoFaVisible()
  const isMobileNavMenuOpen = useAppSelector(s => s.dashboard.isMobileNavMenuOpen)

  //handle redirects from old paths
  useRedirects()
  useSuccessfulRegistrationTracking()
  useMaintenanceMode()
  useDetectConfiguredDevice()

  useGetNotificationsQuery('', { skip: !userPk })
  useGetProfilesQuery('')
  useGetStatEndpointsQuery('', {
    skip: !userPk,
    refetchOnMountOrArgChange: true,
  })

  /** This effect invalidates the org data in the store and forces a refetch
   * when the orgId in the query string changes due to a sub-org being impersonated.
   * When a sub-org ends impersonation, the whole api state is reset in the
   * Exit impersonation button.
   * Invalidation of org tags should be done before rendering to get rid of cached
   * org data in child components.
   */
  useLayoutEffect(() => {
    if (isOrganization) {
      dispatch(organizationApi.util.invalidateTags(['Organization', 'Member']))
      dispatch(provisionApi.util.invalidateTags(['Provision']))
      dispatch(devicesApi.util.invalidateTags(['Devices']))
      dispatch(profilesApi.util.invalidateTags(['Profiles', 'ProfileOptions']))
      dispatch(reportsApi.util.invalidateTags(['Reports']))
    }
  }, [dispatch, qs.orgId, isOrganization])

  useEffect(() => {
    if (isConfirmEmailVisible && userData && !isTwoFaVisible) {
      startPollingUser(true)
    }

    return () => {
      startPollingUser(false)
    }
  }, [userData, isConfirmEmailVisible, startPollingUser, isTwoFaVisible])

  const navMobileTray = useMemo(
    () => ({
      id: 'NavMobileTray',
      renderTray: (props: TrayRenderProps) => (
        <NavMenuMobile {...props}>
          {isOrganization ? <OrganizationNavBarContent /> : <NavBarContent />}
        </NavMenuMobile>
      ),
      zIndexName: 'zIndex550',
    }),
    [isOrganization],
  )
  const { showTray: showMobileTray, hideTray: hideMobileTray } = useTrayPresenter(navMobileTray)

  useEffect(() => {
    isMobileNavMenuOpen ? showMobileTray() : hideMobileTray()
  }, [hideMobileTray, isMobileNavMenuOpen, showMobileTray])

  return null
}
