import { ApiResponse, ControlDError } from '../http'
import { EnabledStatus } from '../rules'
import { Organization } from '../organization/organization.interface'
import { CaptchaImages } from 'ui/Captcha'
import { SessionDuration } from 'components/LoginSignUpForm/LoginSignUpForm.interface'

export enum NukeAnalyticsType {
  ORG = 'nuke_analytics',
  USER = 'user_nuke_analytics',
}

export enum CAN_I_HAZ_TRIAL {
  NO = 0,
  YES = 1,
}
export enum CAN_I_HAZ_ACCOUNT {
  NO = 0,
  YES = 1,
}

export interface UsersRegisterArgs {
  email: string
  password: string
  cid?: string
  beta_token?: string
  can_i_haz_account?: CAN_I_HAZ_ACCOUNT
  clone_profile_id?: string
  solution_answer?: number
  solution_trail?: { x: number[]; y: number[] }
  org_name?: string
  org_signup_password?: string
  org_invite_hash?: string
  contact_first_name?: string
  contact_last_name?: string
}

export enum ResolverStatus {
  UNVERIFIED = 0,
  VERIFIED = 1,
  SOFT_DISABLED = 2,
  HARD_DISABLED = 3,
}

export enum ProxyAccess {
  ALLOWED = 1,
  DISALLOWED = 0,
}

export enum HapticsStatus {
  ENABLED = 1,
  DISABLED = 0,
}

export interface UserData {
  PK: string
  date: Date
  debug: {
    source_ip: string
    new_ip?: string
  }
  email: string
  email_status: number
  last_active: Date
  proxy_access: ProxyAccess
  session: string
  rule_profile: string
  status: number
  twofa: number
  haptics?: HapticsStatus
  error?: ControlDError
  stats_endpoint?: string
  tutorials?: EnabledStatus
  /**
   * This will always show the id of the org the user belongs to
   */
  org_id?: string
  /**
   * This will be the organization the user belongs to or is impersonating
   */
  org?: Organization
  learned?: number
  /**
   * The SSO provider the user is logged in with
   */
  sso?: SSOProvider
}

export interface GoogleSsoUserData extends UserData {
  is_new: boolean
}

export interface PreAuthData {
  token: string
  captcha?: CaptchaImages
  sig: string
}

export type UserDataResponse = ApiResponse<UserData>
export type GoogleSsoUserDataResponse = ApiResponse<GoogleSsoUserData>
export type PreAuthDataResponse = ApiResponse<PreAuthData>

export interface LoginOktaData {
  okta_auth_url: string
}

export type LoginOktaDataResponse = ApiResponse<LoginOktaData>

export interface GoogleAuthorizationUrlData {
  url: string
}
export type GoogleAuthorizationUrlResponse = ApiResponse<GoogleAuthorizationUrlData>

export interface PutUserArgs {
  password?: string
  current_password?: string
  force_logout?: EnabledStatus
  haptics?: EnabledStatus
  email?: string
  stats_endpoint?: string
  [NukeAnalyticsType.USER]?: EnabledStatus
  tutorials?: EnabledStatus
}

export interface DeleteUserArgs {
  password: string
}

export interface LoginRequestArgs {
  email: string
  password: string
  code?: string
}

export enum SSOProvider {
  OKTA = 'okta',
  GOOGLE = 'google',
}
export interface LoginOktaRequestArgs {
  email: string
  sso: SSOProvider
  ttl?: SessionDuration
}

export interface ResetPwdArgs {
  password: string
  hash: string | null
}
