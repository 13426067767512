import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex } from 'theme-ui'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import CheckIcon from 'images/analytics/check.svg'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { useGetDevicesTypesQuery } from 'store/api/devices/devices'
import { DeviceGroup } from 'store/api/devices/devices.interface'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'

const DeviceTypeDropdown = ({
  selectedType,
  setSelectedType,
}: {
  selectedType?: string
  setSelectedType: (type: string) => void
}): ReactElement => {
  const hideRef = useRef<() => void>()
  const { data: typesData } = useGetDevicesTypesQuery('')

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    const icons = typesData?.types?.find(type => type.group === DeviceGroup.OS)?.icons || []

    return (
      icons
        // should be only windows, macOS and linux
        .filter(icon => icon.type !== SetupOs.IOS && icon.type !== SetupOs.ANDROID)
        .map(icon => ({
          isSelected: icon.type === selectedType,
          isBackgroundHighlighted: true,
          ariaLabel: `${icon.name} option`,
          searchBy: icon.name,
          buttonContent: (
            <Svg
              svg={DeviceIcons[icon.type || defaultIconName]}
              fill="aliceBlue"
              sx={{ flexShrink: 0, width: '1.6rem', height: '1.6rem' }}
              descriptiveText={`${icon.name} device type icon`}
            />
          ),
          children: (
            <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <Flex sx={{ alignItems: 'center', gap: '1.2rem' }}>
                <Svg
                  svg={DeviceIcons[icon.type || defaultIconName]}
                  fill="aliceBlue"
                  sx={{ flexShrink: 0, width: '2.4rem', height: '2.4rem' }}
                  descriptiveText={`${icon.name} device type icon`}
                />
                <Flex
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                  }}
                >
                  {icon.name}
                </Flex>
              </Flex>
              {icon.type === selectedType && (
                <Svg
                  svg={CheckIcon}
                  fill="aliceBlue60"
                  sx={{ flexShrink: 0 }}
                  descriptiveText="Selected device type indicator"
                />
              )}
            </Flex>
          ),
          onClick: () => {
            setSelectedType(icon.type)
            hideRef.current?.()
          },
        }))
    )
  }, [typesData?.types, setSelectedType, selectedType])

  return (
    <SimpleDropdown
      isSearchVisible
      testId="device-type-dropdown"
      ariaLabel="device type dropdown"
      items={options}
      hideRef={hideRef}
      contentMaxHeight="23rem"
      label="Type"
      sxDropdownContent={{
        width: '26.4rem',
      }}
    />
  )
}

export default DeviceTypeDropdown
