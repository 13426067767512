import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'

export default function ServiceCardWrapper({
  testId,
  children,
}: {
  testId?: string
  children: ReactNode
}): ReactElement {
  return (
    <Flex
      sx={{
        backgroundColor: 'cardBg',
        flexDirection: 'column',
        flexGrow: 1,
        borderRadius: [0, '1.2rem'],
        p: '1.2rem',
        position: 'relative',
        border: ['none', '1px solid'],
        borderColor: ['none', 'blueYonder15'],
        justifyContent: 'center',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
      }}
      data-testid={testId ? `service-list-item-${testId}` : undefined}
    >
      {children}
    </Flex>
  )
}
