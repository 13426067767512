import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'
import { Flex } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setSelectProvisionPk } from 'store/organization'
import { Input } from 'ui'
import { usePutProvisionEmailMutation } from 'store/api/provision'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { ApiResponse } from 'store/api/http'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/CommonComponents/ShowAlertOnModalOrTrayWrapper'
import ModalDialog from 'ui/NewModalDialog'
import { Setter } from 'utils'

const emailRegex = RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)

export default function EmailProvisioningTrayOrModalDialog(): ReactElement | null {
  const { qs, nav } = useQueryString()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [putProvisionEmail, { isLoading }] = usePutProvisionEmailMutation()
  const selectProvisionPk = useAppSelector(s => s.organization.selectProvisionPk || '')

  const onClose = useCallback(() => {
    nav({ ...omit(qs, 'provisionDialog') })
  }, [qs, nav])

  useEffect(() => {
    return () => {
      dispatch(setSelectProvisionPk(''))
    }
  }, [dispatch, onClose])

  return (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.provisionDialog}>
      <ModalDialog
        shouldCreatePortal={false}
        isOpen={qs.provisionDialog === ProvisionDialogType.EMAIL}
        title="Email Code"
        dataTestId="email-provisioning-dialog"
        onCloseClick={onClose}
        dismiss={onClose}
        footer={
          <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <ButtonWithLoadingState
              variant="newPrimary"
              disabled={!isEmailValid}
              data-testid="send-email-code-button"
              ariaLabel="send email code button"
              sx={{
                width: ['100%', 'auto'],
                justifyContent: 'center',
                height: '3.2rem',
                alignItems: 'center',
                px: '1.6rem',
              }}
              isLoading={isLoading}
              onClick={async () => {
                const response = (await putProvisionEmail({
                  pk: selectProvisionPk,
                  body: { email },
                })) as ApiResponse

                if (!response.error) {
                  onClose()
                }
              }}
            >
              Send Code
            </ButtonWithLoadingState>
          </Flex>
        }
      >
        <EmailProvisioningView setEmail={setEmail} setIsEmailValid={setIsEmailValid} />
      </ModalDialog>
    </ShowAlertOnModalOrTrayWrapper>
  )
}

function EmailProvisioningView({
  setEmail,
  setIsEmailValid,
}: {
  setEmail: Setter<string>
  setIsEmailValid: Setter<boolean>
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '43.5rem',
        flexDirection: 'column',
        px: '1.6rem',
        py: '1.2rem',
      }}
    >
      <Input
        isCompact
        color="aliceBlue"
        label="Email"
        id="email"
        type="email"
        name="email"
        data-testid="email-provisioning-input"
        onChange={(e): void => {
          setEmail(e.target.value)

          if (e.target.value.match(emailRegex)?.length) {
            setIsEmailValid(true)
          } else {
            setIsEmailValid(false)
          }
        }}
      />
    </Flex>
  )
}
