import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useLocation } from '@reach/router'
import ServicesSubHeaderActions from 'components/Dashboard/Profiles/Services/ServicesSubHeaderActions'
import BackIcon from 'images/dashboard/back-icon.svg'
import { CircleIconWrapperButton, Svg } from 'ui'
import useQueryString from 'utils/useQueryString'
import categoryIconMap from './CategoriesList/icons'
import PlaceholderIcon from 'images/serviceIcon.svg'
import useGetServicesData from 'components/Dashboard/Profiles/Services/useGetServicesData'
import { foregroundStyle } from '../../PlaceholderElements'

export interface ServicesSubHeaderProps {
  categoryId?: string
  searchText: string
  setSearchText: (text: string) => void
}

export default function ServicesSubHeader({
  categoryId,
  searchText,
  setSearchText,
}: ServicesSubHeaderProps): ReactElement {
  const { relocate } = useQueryString()
  const location = useLocation()
  const { categories, isLoading } = useGetServicesData()
  const category = categories?.find(({ PK }) => PK === categoryId)

  return (
    <Flex
      data-testid="dashboard-sub-header-category"
      sx={{
        width: '100%',
        gap: '1.6rem',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Flex sx={{ gap: ['0.8rem', '1.2rem'] }}>
        <Flex sx={{ flexGrow: 0, height: ['3.2rem', 'auto'], alignItems: 'center' }}>
          <CircleIconWrapperButton
            data-testid="dashboard-back-button"
            ariaLabel="dashboard back button"
            sx={{
              width: ['2.4rem', '4rem'],
              height: ['2.4rem', '4rem'],
              p: '0.8rem',
              backgroundColor: ['transparent', 'white6'],
              '&:hover': {
                ':not([disabled])': {
                  backgroundColor: ['transparent', 'white8'],
                },
              },
            }}
            onClick={() => {
              relocate(location.pathname.replace(`/${categoryId}`, ''))
            }}
          >
            <Svg
              svg={BackIcon}
              descriptiveText="Return to services categories"
              fill="aliceBlue60"
              sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
            ></Svg>
          </CircleIconWrapperButton>
        </Flex>

        <Flex sx={{ alignItems: 'center' }}>
          {isLoading ? (
            <Box sx={{ width: '3.2rem', height: '3.2rem', mr: '1.2rem', ...foregroundStyle }}></Box>
          ) : (
            <Svg
              svg={categoryIconMap[category?.PK || 0] ?? PlaceholderIcon}
              descriptiveText={`${category?.name || 'Service'} category icon`}
              fill="aliceBlue"
              sx={{
                mr: '1.2rem',
                flexShrink: 0,
              }}
              data-testid="services-list-item-svg"
            />
          )}
          <Flex sx={{ flexDirection: 'column', flex: 1 }}>
            {isLoading ? (
              <>
                <Box
                  sx={{
                    width: '5rem',
                    height: '1.5rem',
                    ...foregroundStyle,
                    mb: '0.6rem',
                  }}
                />
                <Box sx={{ width: '7.2rem', height: '1.2rem', ...foregroundStyle }} />
              </>
            ) : (
              <>
                <Text variant="size15Weight700" sx={{ color: 'aliceBlue' }}>
                  {category?.name}
                </Text>
                <Text
                  variant="size12Weight400"
                  sx={{ opacity: 0.5, whiteSpace: 'nowrap', color: 'aliceBlue' }}
                >
                  {category?.enabledCount
                    ? `${category?.enabledCount} out of ${category?.count} Enabled`
                    : `${category?.count} Disabled`}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <ServicesSubHeaderActions
          categoryId={categoryId}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </Flex>
    </Flex>
  )
}
