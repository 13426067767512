import React, { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react'
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/CommonComponents/ShowAlertOnModalOrTrayWrapper'
import WarningIcon from 'images/dashboard/warning-icon.svg'
import { Svg } from 'ui'
import { Flex, Text } from '@theme-ui/components'
import ModalDialog from 'ui/NewModalDialog/ModalDialog.base'
import { Link } from 'theme-ui'
import { CONTROL_D_DOMAIN } from 'gatsby-env-variables'

export enum BillingDialogType {
  CANCEL_SUBSCRIPTION = 'cancel',
}

const BillingModalDialog = (): ReactElement | null => {
  const { qs, nav } = useQueryString()

  const onClose = useCallback(() => {
    nav({ ...omit(qs, 'billingDialog') })
  }, [nav, qs])

  const currentView = useMemo(() => {
    switch (qs.billingDialog) {
      case BillingDialogType.CANCEL_SUBSCRIPTION:
        return {
          title: 'Cancel Subscription',
          content: (
            <DialogContent>
              Please contact{' '}
              <Link
                href={`mailto:business@${CONTROL_D_DOMAIN}`}
                sx={{
                  alignItems: 'center',
                  color: 'aliceBlue60',
                  textDecoration: 'underline',
                  '&:hover': {
                    color: 'aliceBlue',
                  },
                }}
              >
                business@controld.com
              </Link>{' '}
              to make changes to your subscription.
            </DialogContent>
          ),
        }

      default:
        return
    }
  }, [qs.billingDialog])

  return qs.billingDialog ? (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.billingDialog}>
      <ModalDialog
        shouldCreatePortal={false}
        title={currentView?.title}
        onCloseClick={onClose}
        dataTestId="billing-dialog"
        isOpen={!!qs.billingDialog}
        dismiss={onClose}
      >
        {currentView?.content}
      </ModalDialog>
    </ShowAlertOnModalOrTrayWrapper>
  ) : null
}

function DialogContent({ children }: PropsWithChildren): ReactElement {
  return (
    <Flex
      sx={{
        width: ['100%', '42rem'],
        px: '2.4rem',
        py: '4rem',
        flexDirection: 'column',
        gap: '1.2rem',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Svg svg={WarningIcon} descriptiveText="Warning icon for subscription cancellation" />
      <Text variant="size16Weight400" sx={{ color: 'aliceBlue60' }}>
        {children}
      </Text>
    </Flex>
  )
}

export default BillingModalDialog
