import React, { createRef, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import FiltersDropdown from './Dropdowns/FiltersDropdown'
import ServicesDropdown from './Dropdowns/ServicesDropdown'
import LocationsDropdown from './Dropdowns/LocationsDropdown'
import ActionsDropdown from './Dropdowns/ActionsDropdown'
import { Button, Svg } from 'ui'
import { resetFilterParameters } from 'store/activityLog'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import EndpointsDropdown from './Dropdowns/EndpointsDropdown'
import ClearIcon from 'images/dashboard/clear-icon.svg'
import CodesDropdown from './Dropdowns/CodesDropdown'
import TypesDropdown from './Dropdowns/TypesDropdown'
import ClientsDropdown from './Dropdowns/ClientsDropdown'
import ProtocolsDropdown from './Dropdowns/ProtocolsDropdown'
import { useDeviceClientsData } from '../DeviceClients/useDeviceClientsData'
import omit from 'lodash/omit'
import Tippy from '@tippyjs/react'
import useGetColorMode from 'utils/useGetColorMode'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export const scrollingFiltersContainerRef = createRef<HTMLDivElement>()

export enum QueriesFilterMenuType {
  ENDPOINT = 'deviceId',
  CLIENT = 'clientId',
  ACTION = 'action',
  RCODE = 'statusCode',
  RTYPE = 'rrType',
  PROTOCOL = 'protocol',
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
}

export default function FiltersContainer(): ReactElement {
  const dispatch = useAppDispatch()
  const { setSelectedDeviceId } = useDeviceClientsData()
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const isClearFiltersIconVisible = Object.values(omit(filterParameters, 'endTs', 'startTs')).some(
    param => param && param !== 'all',
  )
  const { isLightMode } = useGetColorMode()
  const isMobile = useBreakpointIndex() === 0

  return (
    <Flex
      data-testid="filters-container"
      sx={{
        width: ['100%', 'fit-content'],
        alignItems: 'center',
      }}
      className={isMobile ? 'reset-tippy-width-subheader' : ''}
    >
      <Flex
        ref={scrollingFiltersContainerRef}
        sx={{
          position: ['relative', 'static'],
          alignItems: 'center',
          flexWrap: ['nowrap', 'wrap'],
          overflowX: 'auto',
          overflowY: 'hidden',
          pb: ['0.6rem', 0],
        }}
        className={`show-scrollbar ${isLightMode ? 'light' : 'dark'}`}
      >
        <EndpointsDropdown />
        <ClientsDropdown />
        <ActionsDropdown />
        <ProtocolsDropdown />
        <CodesDropdown />
        <TypesDropdown />
        <FiltersDropdown />
        <ServicesDropdown />
        <LocationsDropdown />

        {isClearFiltersIconVisible && (
          <Tippy
            disabled={!isMobile}
            content={'Clear filters'}
            arrow={false}
            offset={[0, 4]}
            theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
          >
            <Button
              data-testid="clear-filters"
              ariaLabel="clear filters button"
              variant="simple"
              sx={{
                p: 0,
                flexShrink: 0,
                position: ['sticky', 'static'],
                right: 0,
                width: ['4.2rem', 'auto'],
                height: ['3.2rem', 'auto'],
                background: ({ colors }) => [
                  `linear-gradient(270deg, ${colors?.blue800} 76.27%, rgba(1, 8, 24, 0.00) 100%)`,
                  'transparent',
                ],
                justifyContent: 'flex-end',
              }}
              onClick={() => {
                setSelectedDeviceId('')
                dispatch(resetFilterParameters())
              }}
            >
              {isMobile ? (
                <Svg
                  svg={ClearIcon}
                  fill="aliceBlue60Black60"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                    mr: [0, '0.4rem'],
                  }}
                  descriptiveText="Clear all filters"
                />
              ) : (
                <Text variant="size14Weight700" sx={{ color: 'aliceBlue60Black60' }}>
                  Clear Filters
                </Text>
              )}
            </Button>
          </Tippy>
        )}
      </Flex>
    </Flex>
  )
}
