import { ThemeUIStyleObject } from 'theme-ui'

export const header = '.react-datepicker__header'
export const dayNames = '.react-datepicker__day-names'

export const headerStyles = (background: string): ThemeUIStyleObject => ({
  [header]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '5.6rem',
    backgroundColor: background,
    borderBottomColor: 'blueYonder40',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px !important',
    paddingTop: 0,

    [dayNames]: {
      display: 'flex',
      height: '2.4rem',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px solid',
      borderColor: 'blueYonder40',
      '& > div': {
        display: 'flex',
        flex: 1,
        color: 'aliceBlue60',
        fontSize: '1.2rem',
        justifyContent: 'center',
      },
    },
  },
})
