import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { troubleshootingAlertName } from 'components/SetupGuide/SetupGuide'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { PlatformSteps, usePlatformSteps } from 'components/SetupGuide/usePlatformSteps'
import { useAlertPresenter } from 'ui'
import useQueryString, { LocationSearch } from 'utils/useQueryString'
import pick from 'lodash/pick'
import { ResolverStatus } from 'store/api/user/user.interface'
import { detectConfiguredDevice } from 'store/multiprofile'
import { useLocation } from '@reach/router'
import {
  remove3rdPartyFilterPK,
  removeSelectedDNSKey,
  removeSelectedFiltersPK,
} from 'store/freeDNS'

export default function useStepHelper(): {
  goToPreviousStep: () => void
  goToNextStep: () => void
  configuredStatus: ResolverStatus
  platformSteps?: PlatformSteps
  qs: LocationSearch
  closePane: () => void
} {
  const { qs, setupGuideNav, nav } = useQueryString()
  const { dismissAlert } = useAlertPresenter()
  const currentStep = +(qs.step ?? 0)
  const setupOs = qs.setupOs
  const selectedDevicePk = qs.deviceId
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isFreeDNS = location.pathname.includes('free-dns')

  const platformSteps = usePlatformSteps({
    setupOs: setupOs as SetupOs,
    selectedDevicePk,
    shouldRemoveXPadding: !isFreeDNS,
  })

  // if there is a selected device, use its status
  const configuredStatus = useAppSelector(s =>
    selectedDevicePk === s.devices.configuredDevice?.PK
      ? s.devices.configuredDevice?.status ?? ResolverStatus.UNVERIFIED
      : ResolverStatus.UNVERIFIED,
  )

  const goToPreviousStep = useCallback((): void => {
    setupGuideNav({
      ...qs,
      step: Math.max(currentStep - 1, 0).toString(),
    })
    dismissAlert(troubleshootingAlertName)
  }, [currentStep, dismissAlert, qs, setupGuideNav])

  const goToNextStep = useCallback((): void => {
    setupGuideNav({
      ...qs,
      step: Math.min(currentStep + 1, (platformSteps?.steps.length ?? 1) - 1).toString(),
    })
    dismissAlert(troubleshootingAlertName)
  }, [currentStep, dismissAlert, platformSteps, qs, setupGuideNav])

  const closePane = useCallback((): void => {
    if (!!qs.helpPane) {
      if (qs.helpPane === SetupGuideIntermediateStates.DNS && qs.deviceId) {
        dispatch(detectConfiguredDevice())
      }

      if (isFreeDNS) {
        dispatch(removeSelectedFiltersPK())
        dispatch(removeSelectedDNSKey())
        dispatch(remove3rdPartyFilterPK())
      }

      /**
       * keep the resolverUid in the url as it is used in the provisioning flow
       * keep the orgid in the url to maintain impersonation
       */
      nav(pick(qs, ['resolverUid', 'orgId']), {
        shouldMaintainState: true,
        // don't push it to the browser session history when free dns
        shouldReplaceHistoryEntry: isFreeDNS,
      })
    } //close button sometimes uses a different method.
  }, [dispatch, isFreeDNS, nav, qs])
  return {
    goToPreviousStep,
    goToNextStep,
    configuredStatus,
    platformSteps,
    qs,
    closePane,
  }
}
