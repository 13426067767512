import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'
import { Accordion } from 'ui'
import pick from 'lodash/pick'
import { StandardConfigurationType } from 'components/FreeDNSPage/StandardConfigurations'
import StandardConfigurationsItemHeader from 'components/FreeDNSPage/StandardConfigurationsItemHeader'
import StandardConfigurationsItemContent from 'components/FreeDNSPage/StandardConfigurationsItemContent'
import { commonGap } from 'components/FreeDNSPage/FreeDNSPage'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'

const StandardConfigurationsItem = ({
  item,
  activeType,
  setActiveType,
  onClickHandler,
}: {
  item: StandardConfigurationType
  activeType: string | undefined
  setActiveType: (card: ResolverKey | undefined) => void
  onClickHandler: (link: string) => void
}): ReactElement => {
  return (
    <Flex
      data-testid={`configuration-item-${item.key}`}
      sx={{
        flexDirection: 'column',
        // In the tablet width calculation, gap is divided by 2 because there are 2 elements and only one gap.
        // IMO this would have been best done as a passed in prop, but this doesn't need
        // to happen immediately.
        width: ['100%', `calc(50% - ${commonGap[1]})`, '29.9rem'],
        mb: 0,
      }}
    >
      <AccordionDecorator
        type={item.key}
        isOpen={activeType === item.key}
        setActiveType={setActiveType}
        title={
          <StandardConfigurationsItemHeader
            isOpen={activeType === item.key}
            {...pick(item, 'icon', 'title')}
          />
        }
        content={
          <StandardConfigurationsItemContent
            isOpen={activeType === item.key}
            item={item}
            itemKey={item.key}
            onClickHandler={onClickHandler}
          />
        }
      />
      <Flex
        data-testid={`desktop-standard-item-${item.key}`}
        sx={{ display: ['none', 'flex'], flexDirection: 'column' }}
      >
        <StandardConfigurationsItemHeader {...pick(item, 'icon', 'title')} />
        <StandardConfigurationsItemContent
          item={item}
          itemKey={item.key}
          onClickHandler={onClickHandler}
        />
      </Flex>
    </Flex>
  )
}

const AccordionDecorator = ({
  title,
  content,
  isOpen,
  setActiveType,
  type,
}: {
  title: ReactNode
  content: ReactNode
  isOpen: boolean
  setActiveType: (card: ResolverKey | undefined) => void
  type: ResolverKey
}): ReactElement => {
  return (
    <Accordion
      data-testid="help-accordion"
      id={type}
      title={title}
      ariaLabelButtonName="help"
      containerStyle={{
        display: ['flex', 'none'],
        minWidth: '100%',
      }}
      buttonStyle={{
        justifyContent: 'space-between',
        px: 0,
        py: 0,
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: ['transparent', 'white25'],
      }}
      titleStyle={{ minWidth: '100%' }}
      openTitleStyle={{ minWidth: '100%' }}
      svgStyle={{ display: 'none' }}
      sxTitleContainer={{ minWidth: '100%' }}
      isOpenControlled={isOpen}
      setIsOpenControlled={(): void => setActiveType(type)}
    >
      {content}
    </Accordion>
  )
}

export default StandardConfigurationsItem
