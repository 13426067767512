import React, { ReactElement, useEffect, useRef, useState } from 'react'
import Ipv4AndIpv6Form from './Ipv4AndIpv6Form'
import { Box, Flex } from 'theme-ui'
import HostnameForm from './HostnameForm'
import { useAppSelector } from 'store/hooks'
import { ProxyTrayState } from 'store/proxyTray'
import { isHostnameValid, isIpValid } from 'utils/isIpOrHostnameValid'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'
import { Button } from 'ui'

export enum BadgeType {
  IPV4 = 'A',
  IPV6 = 'AAAA',
  HOSTNAME = 'CNAME',
  COMMENT = 'Comment',
}

export default function IpOrHostnameForm({
  handleProxyClick,
}: {
  handleProxyClick: (via?: string, viaV6?: string) => void
}): ReactElement {
  const [selectedFirstType, setSelectedFirstType] = useState(BadgeType.IPV4)
  const inputRef = useRef<HTMLInputElement>(null)
  const isHostname = selectedFirstType === BadgeType.HOSTNAME
  const proxyTrayState: ProxyTrayState = useAppSelector(s => s.proxyTray)
  const proxyTrayVia = proxyTrayState.rule?.action?.via || ''
  const proxyTrayViaV6 = proxyTrayState.rule?.action?.via_v6 || ''

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (
      isHostnameValid(proxyTrayVia) &&
      proxyTrayVia !== 'LOCAL' &&
      proxyTrayVia !== 'RANDOM' &&
      !isIpValid(proxyTrayVia) &&
      !isIpValid(proxyTrayViaV6)
    ) {
      setSelectedFirstType(BadgeType.HOSTNAME)
    } else {
      setSelectedFirstType(BadgeType.IPV4)
    }
  }, [proxyTrayVia, proxyTrayViaV6])

  return (
    <Box sx={{ px: '1.6rem' }}>
      {isHostname ? (
        <HostnameForm
          selectedFirstType={selectedFirstType}
          setSelectedFirstType={setSelectedFirstType}
          handleProxyClick={handleProxyClick}
        />
      ) : (
        <Ipv4AndIpv6Form
          selectedFirstType={selectedFirstType}
          setSelectedFirstType={setSelectedFirstType}
          handleProxyClick={handleProxyClick}
        />
      )}
    </Box>
  )
}

export function SaveButton({
  isButtonDisabled,
  testId,
  ariaLabel,
  onClick,
}: {
  isButtonDisabled: boolean
  testId?: string
  ariaLabel: string
  onClick?: () => void
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="newPrimary"
            data-testid={testId}
            ariaLabel={ariaLabel}
            onClick={onClick}
            disabled={isButtonDisabled}
            sx={{
              width: ['100%', 'auto'],
              justifyContent: 'center',
              height: '3.2rem',
              alignItems: 'center',
              px: '1.6rem',
            }}
          >
            Save
          </Button>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
