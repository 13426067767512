import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import foregroundStyle from 'components/Dashboard/Profiles/Placeholder/foregroundStyle'

export default function ServiceCardLoader(): ReactElement {
  return (
    <Flex sx={{ height: '100%', justifyContent: 'space-between' }}>
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            ...foregroundStyle,
            width: '3.2rem',
            height: '3.2rem',
            flexShrink: 0,
          }}
        />
        <Flex sx={{ flexDirection: 'column' }}>
          <Box
            sx={{
              ...foregroundStyle,
              width: '8.6rem',
              height: '1.2rem',
            }}
          />
          <Box
            sx={{
              ...foregroundStyle,
              width: '8.6rem',
              height: '1.2rem',
              mt: '0.8rem',
            }}
          />
        </Flex>
      </Flex>

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            ...foregroundStyle,
            width: '5.8rem',
            height: '2.4rem',
          }}
        />
        <Box
          sx={{
            ...foregroundStyle,
            width: '8rem',
            height: '3.2rem',
          }}
        />
      </Flex>
    </Flex>
  )
}
