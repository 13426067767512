import React, { ReactElement } from 'react'
import {
  Highlight,
  TutorialBaseSlide,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { Box, Text } from 'theme-ui'
import DomainTestDarkImage from 'images/tutorialFlows/DomainTest/domain-test-dark.svg'
import DomainTestLightLight from 'images/tutorialFlows/DomainTest/domain-test-light.svg'
import useGetColorMode from 'utils/useGetColorMode'

export function DomainTestSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? DomainTestLightLight : DomainTestDarkImage}
      description={
        <Box>
          <Text>
            The Domain Test Page lets you check what happens to a domain when it goes through your
            Control D DNS resolver. <br /> <br />
            You can enter the domain name, pick an{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight>, select the DNS
            record type and click &quot;Check&quot; to see how that domain gets resolved by Control
            D. <br /> <br />
            If it gets blocked, the page will tell you exactly why. If you feel it&apos;s a mistake
            you can use the Report False Positive button to flag it and help us improve our network
            filtering.
          </Text>
        </Box>
      }
    />
  )
}
