import React, { ReactElement } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import { useAppSelector } from 'store/hooks'
import DeviceDropdown, {
  DevicesDropdownProps,
} from 'components/Dashboard/DeviceDropdown/DeviceDropdown'
import AddIcon from 'images/add-icon.svg'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceModalDialog'
import omit from 'lodash/omit'
import useQueryString from 'utils/useQueryString'
import { useGetDevicesQuery } from 'store/api/devices'
import { navigate } from '@reach/router'

export interface DevicesDropdownOrAddButtonProps
  extends Omit<DevicesDropdownProps, 'dropdownDevices'> {
  profileId?: string
  shouldShowAddButton?: boolean
  shouldShowSmallView?: boolean
  sx?: ThemeUIStyleObject
}
export default function DeviceDropdownOrAddButton(
  props: DevicesDropdownOrAddButtonProps,
): ReactElement | null {
  const { qs } = useQueryString()
  const { shouldShowAddButton = true, sx, shouldShowSmallView } = props
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData, isLoading: devicesLoading } = useGetDevicesQuery('', {
    skip: !sessionToken,
  })

  const dropdownDevices = props.profileId
    ? devicesData?.devices.filter(
        device => device.profile.PK === props.profileId || device.profile2?.PK === props.profileId,
      )
    : devicesData?.devices

  if (devicesLoading) {
    return null
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        ...sx,
      }}
      onClick={e => e.stopPropagation()}
    >
      {(dropdownDevices || []).length ? (
        <DeviceDropdown
          dropdownDevices={dropdownDevices || []}
          isLoading={devicesLoading}
          shouldShowSmallView={shouldShowSmallView}
          {...omit(props, 'profileId', 'shouldShowAddButton')}
        />
      ) : shouldShowAddButton ? (
        <Button
          ariaLabel="navigate to endpoints"
          variant="simple"
          sx={{
            p: 0,
            fontSize: ['1.2rem', '1.7rem'],
            fontWeight: 400,
            color: 'aliceBlue60',
            flexShrink: 0,
            alignItems: 'center',
            gap: '0.4rem',
          }}
          onClick={() =>
            navigate(
              `/dashboard/endpoints?deviceDialog=${DeviceDialogType.ADD}${
                qs.orgId ? `&orgId=${qs.orgId}` : ''
              }`,
            )
          }
        >
          Add Endpoint
          <Svg
            fill="aliceBlue60"
            svg={AddIcon}
            sx={{
              width: ['1.4rem', '1.6rem'],
              height: ['1.4rem', '1.6rem'],
            }}
            descriptiveText="Add endpoint icon"
          />
        </Button>
      ) : (
        <Text variant="size12Weight400" sx={{ color: 'aliceBlue60', cursor: 'default' }}>
          No Endpoints
        </Text>
      )}
    </Flex>
  )
}
