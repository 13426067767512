import React, { ReactElement, useRef, useState } from 'react'
import { Button, Input, Svg } from 'ui'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import CloseIcon from 'images/menu-close.svg'
import SearchIcon from 'images/search-icon-bold.svg'

interface SearchBar {
  name: string
  label?: string
  searchText: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> & React.SyntheticEvent<HTMLInputElement, InputEvent>,
  ) => void
  onPaste?: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  clearSearch: () => void
  sx?: ThemeUIStyleObject
}

export default function MobileSearchInput({
  name,
  searchText,
  onChange,
  onPaste,
  clearSearch,
  sx,
  label,
}: SearchBar): ReactElement {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [hasFocus, setHasFocus] = useState(false)

  return (
    <Flex
      sx={{ display: ['flex', 'none'], position: 'relative', width: '100%', my: '0.8rem', ...sx }}
    >
      <Svg
        svg={SearchIcon}
        fill="aliceBlue"
        sx={{
          position: 'absolute',
          left: '1.2rem',
          margin: '0 auto',
          alignSelf: 'center',
          flexShrink: 0,
          opacity: searchText || hasFocus ? 1 : 0.6,
        }}
        descriptiveText="Search icon"
      />
      <Input
        variant="newSearch"
        ref={searchInputRef}
        data-testid={`search-${name}`}
        id={`search-${name}`}
        label={label}
        name={`search-${name}`}
        labelColor="aliceBlue"
        containerStyle={{
          '& > input': {
            pl: '4.2rem',
            height: '4rem',
            borderRadius: '0.8rem',
          },
        }}
        placeholder="Search"
        aria-label="search"
        value={searchText}
        onChange={onChange}
        onPaste={onPaste}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        autoComplete="off"
      />
      {searchText && (
        <Button
          data-testid="clear-search"
          ariaLabel="clear search button"
          variant="simple"
          onClick={() => {
            clearSearch()
          }}
          sx={{
            position: 'absolute',
            right: 0,
            margin: '0 auto',
            alignSelf: 'center',
            flexShrink: 0,
            opacity: 0.6,
            '&:hover': {
              opacity: 1,
              ':not([disabled])': {
                '& svg path': {
                  fill: 'aliceBlue',
                },
              },
            },
          }}
        >
          <Svg
            svg={CloseIcon}
            fill="aliceBlue"
            sx={{ width: '2rem', height: '2rem' }}
            descriptiveText="Clear search icon"
          />
        </Button>
      )}
    </Flex>
  )
}
