import React, { ReactElement } from 'react'
import HomepageDropdownBase, { DropdownCommonProps } from './HomepageDropdownBase'

export function FreeToolsDropdown({
  dropdownItems,
  children,
  withArrow,
  tippyprops,
  sxContainer,
}: DropdownCommonProps): ReactElement {
  return (
    <HomepageDropdownBase
      testId="free-tools-button"
      ariaLabel="Free Tools"
      variant="roundedSimple"
      sxContainer={{
        flexDirection: 'column',
        py: '2.4rem',
        width: ['calc(100vw - 1rem)', '38rem'],
        ...sxContainer,
      }}
      sxButton={{
        width: 'auto',
        minHeight: 'auto',
        flexWrap: 'nowrap',
        opacity: 1,
        svg: {
          opacity: 0.7,
        },
      }}
      sx={{
        ':not(.tippy-box)': {
          py: '0.8rem',
          px: '1.6rem',
          '@media screen and (max-width: 1400px)': {
            px: '0.8rem',
          },
        },
      }}
      {...{ dropdownItems, children, withArrow, tippyprops }}
    />
  )
}
