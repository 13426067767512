import React, { ReactElement, useMemo } from 'react'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { ResolverStatus } from 'store/api/user/user.interface'
import { getTimeSinceNow, getTimeSinceNowShortFormat } from 'utils/getTimeSinceNow'
import { useAppSelector } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { Svg } from 'ui'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import VerticalDivider from 'ui/VerticalDivider'
import SmallClientsIcon from 'images/analytics/small-client-to-device-icon.svg'
import CheckmarkIcon from 'images/checkmark.svg'
import GraphIcon from 'images/dashboard/navBar/device-graph-icon.svg'
import ParentDevice from 'components/Dashboard/Devices/DeviceListItem/ParentDevice'
import useGetColorMode from 'utils/useGetColorMode'
import LastUsedIndicator, {
  DeviceAnalyticsStatus,
} from 'components/Dashboard/CommonComponents/LastUsedIndicator'

export default function DeviceDropdownItem({
  pk = '',
  icon,
  text,
  stats,
  status,
  clientsCount,
  testId,
  lastActivity,
  isSelected,
  isGraphIconVisible,
  rightIcon,
  parentDeviceId,
  shouldUseAnalyticsDisabled = true,
}: {
  pk?: string
  icon?: string
  text: string
  stats?: StatLevel
  status?: ResolverStatus
  clientsCount?: number
  testId?: string
  isSelected?: boolean
  isGraphIconVisible?: boolean
  lastActivity?: number
  rightIcon?: ReactElement
  parentDeviceId?: string
  shouldUseAnalyticsDisabled?: boolean
}): ReactElement {
  const deviceStatus = useMemo<DeviceAnalyticsStatus>(() => {
    if (!lastActivity) {
      return DeviceAnalyticsStatus.UNAVAILABLE
    }
    const lastActivityTimestampMillis = lastActivity * 1000

    return Date.now() - lastActivityTimestampMillis < 300000
      ? DeviceAnalyticsStatus.ONLINE
      : DeviceAnalyticsStatus.OFFLINE
  }, [lastActivity])

  const timeSinceLastQuery = useMemo<string | undefined>(() => {
    return getTimeSinceNow(lastActivity)
  }, [lastActivity])

  const deviceStatusBadgeText = getTimeSinceNowShortFormat(lastActivity)

  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)
  const isDeviceConfigured = configuredDevice?.PK === pk

  const isDevicePending = status === 0

  const areAnalyticsDisabledOrNotConfigured =
    shouldUseAnalyticsDisabled && (isDevicePending || (stats === StatLevel.NO && !isDevicePending))

  const { isLightMode } = useGetColorMode()

  return (
    <Flex
      className={`${areAnalyticsDisabledOrNotConfigured ? 'not-configured' : ''}`}
      sx={{
        width: '100%',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      data-testid={testId}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Tippy
          onShow={(): void | false => {
            if (!lastActivity) {
              // returning false here stops the tooltip from showing
              return false
            }
          }}
          content={
            !!lastActivity ? (
              <Flex
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  textAlign: 'center',
                  p: '0.2rem',
                }}
              >
                Last query <br />
                {timeSinceLastQuery}
              </Flex>
            ) : (
              <></>
            )
          }
          theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
          arrow={false}
          maxWidth={148}
          offset={[0, 6]}
        >
          <Flex
            sx={{
              position: 'relative',
              width: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              mr: '0.8rem',
              flexShrink: 0,
            }}
          >
            <Svg
              className="device-icon"
              svg={DeviceIcons[icon ?? defaultIconName]}
              fill="aliceBlue"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
                mt: deviceStatus !== DeviceAnalyticsStatus.UNAVAILABLE ? '-0.7rem' : 0,
                opacity: areAnalyticsDisabledOrNotConfigured ? 0.3 : 1,
              }}
              descriptiveText={`${text} device icon`}
            />

            {deviceStatus !== DeviceAnalyticsStatus.UNAVAILABLE && (
              <LastUsedIndicator
                lastActiveTimeStamp={lastActivity}
                deviceStatus={deviceStatus}
                deviceStatusBadgeText={deviceStatusBadgeText}
                sx={{
                  top: '1.1rem',
                  height: '1.1rem',
                }}
              />
            )}
          </Flex>
        </Tippy>
        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'left',
          }}
        >
          <TextWithOverFlowAndTippyPopup
            variant="size14Weight600"
            content={text ?? ''}
            ariaLabel={text ?? ''}
            sxTooltipContent={{
              px: '0.8rem',
              py: '0.2rem',
            }}
            sxText={{
              maxWidth: '32rem',
              color: 'aliceBlue',
              opacity: areAnalyticsDisabledOrNotConfigured ? 0.3 : 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />

          <Flex sx={{ alignItems: 'center' }}>
            {isDeviceConfigured && (
              <Text variant="size12Weight400" sx={{ color: 'greenApple' }}>
                Current Device
              </Text>
            )}

            {(clientsCount || parentDeviceId) && isDeviceConfigured && (
              <VerticalDivider
                sx={{
                  color: 'blueYonder15',
                  width: '1px',
                  height: '0.8rem',
                  mx: '0.4rem',
                  flexShrink: 0,
                }}
              />
            )}

            {parentDeviceId && <ParentDevice parentDeviceId={parentDeviceId} />}

            {clientsCount && parentDeviceId && (
              <VerticalDivider
                sx={{
                  color: 'blueYonder15',
                  width: '1px',
                  height: '0.8rem',
                  mx: '0.4rem',
                  flexShrink: 0,
                }}
              />
            )}

            {!!clientsCount && (
              <Flex sx={{ alignItems: 'center' }}>
                <Svg
                  fill="aliceBlue"
                  svg={SmallClientsIcon}
                  sx={{ width: '1.4rem', height: '1.4rem', flexShrink: 0, mr: '0.4rem' }}
                  descriptiveText="Connected clients indicator"
                />
                <Text
                  className="clients-count"
                  variant="size12Weight400"
                  sx={{ color: 'aliceBlue', opacity: 0.6 }}
                >
                  {clientsCount} {`Client${clientsCount > 1 ? 's' : ''}`}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {isSelected && !isGraphIconVisible && (
        <Svg
          fill="aliceBlue"
          className="device-icon"
          svg={CheckmarkIcon}
          sx={{
            width: '1.6rem',
            height: '1.6rem',
            flexShrink: 0,
            justifySelf: 'flex-end',
          }}
          descriptiveText="Selected device indicator"
        />
      )}

      {isGraphIconVisible && (
        <Svg
          data-testid="device-graph"
          className="device-graph"
          svg={GraphIcon}
          fill="aliceBlue"
          sx={{
            visibility: 'hidden',
            width: '2.4rem',
            height: '2.4rem',
            flexShrink: 0,
            justifySelf: 'flex-end',
            ml: '0.4rem',
          }}
          descriptiveText="Device analytics graph"
        />
      )}
      {rightIcon}
    </Flex>
  )
}
