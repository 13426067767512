import React, { ReactElement, ReactNode } from 'react'
import { Flex, Spinner, Text } from 'theme-ui'
import CrownIcon from 'images/crown.svg'
import { Svg } from 'ui'
import { useAppSelector } from 'store/hooks'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { OrganizationNavRelativePath } from './OrganizationNavBarContent'
import NavBarAccordion, { NavBarAccordionType } from 'components/Dashboard/NavBar/NavBarAccordion'
import customUnescape from 'utils/customUnescape'

const isLowerCaseLetter = string => /^[a-z]*$/.test(string)

const getLineHeightAccordingToChar = char => {
  if ('jpqyg'.includes(char)) {
    return '2.8rem'
  }
  if ('bdfiklt'.includes(char)) {
    return '3.4rem'
  }

  return isLowerCaseLetter(char) ? '3.1rem' : '3.4rem'
}

export default function OrganizationsAccordion({
  children,
  name,
  isFetching,
  isSubOrg,
}: {
  children: ReactNode
  name?: string | number
  isFetching?: boolean
  isSubOrg?: boolean
}): ReactElement {
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const firstCharacter = `${name}`?.charAt(0)

  return (
    <NavBarAccordion
      type={NavBarAccordionType.ORGANIZATION}
      ariaLabelButtonName="organization"
      title={
        <Flex sx={{ width: '100%', flexDirection: 'column' }}>
          {isFetching ? (
            <Spinner
              color="aliceBlue"
              sx={{
                width: '2rem',
                height: '2rem',
              }}
            />
          ) : (
            <TextWithOverFlowAndTippyPopup
              sxText={{
                display: ['inline', 'none', isNavBarExpanded ? 'inline' : 'none'],
                maxWidth: 'none',
                flexShrink: 0,
                mr: ['1.2rem', '0.1rem'],
              }}
              variant="size15Weight600"
              content={customUnescape(name)}
              ariaLabel={customUnescape(name)}
            />
          )}
        </Flex>
      }
      titleIcon={
        !isFetching ? (
          <Flex
            sx={{
              borderRadius: '100%',
              backgroundColor: 'white6',
              border: '1px solid',
              borderColor: 'blueYonder30',
              width: '2.4rem',
              height: '2.4rem',
              flexShrink: 0,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              fontSize: '1.2rem',
              fontWeight: 'normal',
              lineHeight: getLineHeightAccordingToChar(firstCharacter),
            }}
          >
            <Text sx={{ textTransform: 'uppercase', color: 'aliceBlue60', fontWeight: 600 }}>
              {firstCharacter}
            </Text>
            {!isFetching && !isSubOrg && (
              <Svg
                className="crown-icon"
                data-testid="crown-icon"
                svg={CrownIcon}
                sx={{
                  position: 'absolute',
                  width: '0.6rem',
                  height: '0.6rem',
                  bottom: 0,
                  right: 0,
                }}
                descriptiveText="Crown icon indicating parent organization"
              />
            )}
          </Flex>
        ) : (
          <></>
        )
      }
      relativePath={OrganizationNavRelativePath}
    >
      {children}
    </NavBarAccordion>
  )
}
