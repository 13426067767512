import { ThemeUIStyleObject } from 'theme-ui'

export const month = '.react-datepicker__month'
export const week = '.react-datepicker__week'
export const day = '.react-datepicker__day'

export const bodyStyles = (background: string): ThemeUIStyleObject => ({
  [month]: {
    backgroundColor: background,
    m: 0,

    [week]: {
      display: 'flex',
      '& > div:not(disabled)': {
        border: '1px solid',
        borderColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        height: '3.2rem',
        fontSize: '1.2rem',
        color: 'aliceBlue',
        fontWeight: '700',
        '&:hover': {
          border: '1px solid',
          borderColor: 'plumpPurple',
          backgroundColor: 'transparent',
          borderRadius: '0.8rem',
          color: 'aliceBlue',
        },
      },

      [`${day}--today`]: {
        borderColor: 'plumpPurple',
        borderRadius: '0.8rem',
      },
      [`${day}--selected`]: {
        backgroundColor: 'plumpPurple',
        color: 'text',
        border: '1px solid',
        borderColor: 'plumpPurple',
        borderRadius: '0.8rem',
        '&:hover': {
          backgroundColor: 'transparent',
          borderRadius: '0.8rem',
          color: 'aliceBlue',
        },
      },
      [`${day}--keyboard-selected`]: {
        backgroundColor: 'transparent',
        border: '1px solid',
        borderRadius: '0.8rem',
        borderColor: 'plumpPurple',
      },
      [`${day}--disabled`]: {
        color: 'aliceBlue30',
        hover: {
          borderColor: 'transparent',
        },
      },
      [`${day}--in-range`]: {
        backgroundColor: 'plumpPurple',
        color: 'text',
      },
      [`${day}--in-selecting-range:not(.react-datepicker__day--selected)`]: {
        backgroundColor: 'plumpPurple',
        color: 'text',
        opacity: 0.5,
      },
    },
  },
})
