import React, { ReactElement } from 'react'
import { ThemeProvider, Theme } from 'theme-ui'
import base from './themeObject.base'

// to get the current mode and switch between modes use "const {colorMode, setColorMode} = useGetColorMode()" where needed
export default function CustomThemeProvider({
  theme,
  children,
}: {
  theme?: Theme
  children: ReactElement[] | ReactElement
}): React.ReactElement {
  return <ThemeProvider theme={theme || (base as Theme)}>{children}</ThemeProvider>
}
