import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex, Text } from 'theme-ui'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import CheckIcon from 'images/analytics/check.svg'
import { BadgeType } from './IpOrHostnameForm'
import { Setter } from 'utils'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'

export default function SpoofIpRuleDropdown({
  testId,
  types,
  selectedType,
  setSelectedType,
}: {
  testId
  types?: BadgeType[]
  selectedType: string
  setSelectedType?: Setter<BadgeType>
}): ReactElement {
  const hideRef = useRef<() => void>()

  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      types?.map(type => ({
        isSelected: type === selectedType,
        isBackgroundHighlighted: true,
        ariaLabel: `${type} option`,
        buttonContent: <Text sx={{ color: 'aliceBlue60' }}>{type}</Text>,
        children: (
          <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextWithOverFlowAndTippyPopup
              content={type}
              ariaLabel={type}
              sxText={{
                maxWidth: '32rem',
              }}
            />
            {type === selectedType && (
              <Svg
                svg={CheckIcon}
                fill="aliceBlue60"
                sx={{ flexShrink: 0 }}
                descriptiveText={`${type} type selected`}
              />
            )}
          </Flex>
        ),
        onClick: () => {
          setSelectedType?.(type !== BadgeType.HOSTNAME ? BadgeType.IPV4 : BadgeType.HOSTNAME)
          hideRef.current?.()
        },
      })) || [],
    [selectedType, setSelectedType, types],
  )

  return (
    <SimpleDropdown
      testId={`${testId}-dropdown`}
      ariaLabel={`${testId}-dropdown`}
      items={options}
      hideRef={hideRef}
      boundaryElementTestId="spoof-ip-rule-container"
      label="Type"
      sx={{
        width: '8.1rem',
        flexShrink: 0,
      }}
      sxDropdownContent={{
        width: '15rem',
      }}
      sxDropdown={{
        '&> div > div': {
          fontSize: selectedType === BadgeType.HOSTNAME ? '1rem' : '1.4rem',
          fontWeight: 400,
          color: 'aliceBlue30',
        },
      }}
      sxArrowIcon={{
        width: '1rem',
        height: '1rem',
      }}
    />
  )
}
