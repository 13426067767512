import React, { ReactElement, ReactNode } from 'react'
import { devicesApi } from 'store/api/devices'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'

export default function ParentDevice({
  parentDeviceId,
  children,
  sx,
}: {
  parentDeviceId: string
  children?: ReactNode
  sx?: ThemeUIStyleObject
}): ReactElement | null {
  const { data: devicesData } = devicesApi.endpoints.getDevices.useQueryState('')

  const parentDevice = devicesData?.devices?.find(device => device.PK === parentDeviceId)

  return parentDevice ? (
    <Flex sx={{ alignItems: 'center', flexShrink: 0, ...sx }}>
      <Svg
        data-testid="parent-device-icon"
        fill="aliceBlue60"
        svg={DeviceIcons[parentDevice?.icon ?? defaultIconName]}
        sx={{
          width: '1.4rem',
          height: '1.4rem',
          flexShrink: 0,
          mr: '0.4rem',
        }}
        descriptiveText={`${parentDevice?.name} parent device icon`}
      />
      <Text
        data-testid="parent-device-name"
        variant="size12Weight400"
        sx={{ color: 'aliceBlue', opacity: 0.6 }}
      >
        {parentDevice?.name}
      </Text>
      {children}
    </Flex>
  ) : null
}
