import React, { ReactElement, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, SimpleDropdown, Svg } from 'ui'
import CheckIcon from 'images/analytics/check.svg'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setGroupClientsBy } from 'store/multiprofile'

export enum GroupingType {
  NO = 'no',
  HOSTNAME = 'host',
  MAC = 'mac',
  IP = 'ip',
}

export const groupingData: Record<GroupingType, string> = {
  [GroupingType.NO]: 'No Grouping',
  [GroupingType.HOSTNAME]: 'By Hostname',
  [GroupingType.MAC]: 'By MAC',
  [GroupingType.IP]: 'By IP',
}

export default function GroupingDropdown(): ReactElement {
  const dispatch = useAppDispatch()
  const hideRef = useRef<() => void>()
  const groupClientsBy = useAppSelector(s => s.devices.groupClientsBy)

  const groupingOptions: SimpleActionMenuItemProps[] = Object.entries(groupingData).map(
    ([key, value]) => ({
      ariaLabel: `${value} option`,
      isSelected: groupClientsBy === key,
      isBackgroundHighlighted: true,
      onClick: () => {
        dispatch(setGroupClientsBy(key as GroupingType))
        hideRef.current?.()
      },
      buttonContent: <Text variant="size15Weight500">{value}</Text>,
      children: (
        <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text variant="size15Weight500">{value}</Text>
          {groupClientsBy === key && (
            <Svg
              svg={CheckIcon}
              fill="aliceBlue60"
              sx={{ flexShrink: 0 }}
              descriptiveText={`${value} is selected`}
            />
          )}
        </Flex>
      ),
      tabIndex: 0,
    }),
  )

  return (
    <SimpleDropdown
      testId="grouping-dropdown"
      ariaLabel="grouping dropdown"
      items={groupingOptions}
      hideRef={hideRef}
      boundaryElementTestId={'dashboard-header'}
      sxDropdownContent={{
        width: ['100%', '26.4rem'],
      }}
      sxDropdown={{
        p: 0,
        border: 'none',
        '& > div': {
          gap: '0.4rem',
        },
        backgroundColor: 'transparent',
      }}
      sxArrowIcon={{
        width: '1.2rem',
        height: '1.2rem',
      }}
    />
  )
}
