import React, { MutableRefObject, ReactChild, ReactElement, ReactNode, useState } from 'react'
import { Svg, Dropdown, IconMenu, CircleIconWrapper, SimpleActionMenuItemProps } from 'ui'
import MoreButtonIcon from 'images/dashboard/more-icon.svg'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useBoundaryElement from 'utils/useBoundaryElement'
import useGetColorMode from 'utils/useGetColorMode'
import { Placement } from 'tippy.js'

interface MoreButtonProps {
  options: SimpleActionMenuItemProps[]
  dataTestId?: string
  ariaLabel: string
  hideRef: MutableRefObject<(() => void) | undefined>
  dropdownContent?: ReactChild | ReactChild[]
  appendToBoundaryElement: string
  customContent?: ReactNode
  onHidden?: () => void
  shouldUseNewDropdownDesign?: boolean
  shouldHighlightOnHover?: boolean
  tabIndex?: number
  placement?: Placement
  sxDropdownContent?: ThemeUIStyleObject
}

export default function MoreButton({
  options,
  dataTestId,
  ariaLabel,
  hideRef,
  dropdownContent,
  appendToBoundaryElement,
  customContent,
  shouldUseNewDropdownDesign,
  shouldHighlightOnHover,
  onHidden,
  tabIndex,
  placement,
  sxDropdownContent,
}: MoreButtonProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const boundaryElement = useBoundaryElement(appendToBoundaryElement)
  const { isLightMode } = useGetColorMode()

  const isMobile = useBreakpointIndex() === 0

  return (
    <Dropdown
      variant="simple"
      ariaLabel={ariaLabel}
      data-testid={dataTestId}
      tabIndex={tabIndex || 0}
      sx={{
        p: 0,
      }}
      tippyprops={{
        appendTo: isMobile ? boundaryElement : 'parent',
        animation: 'fade',
        duration: 300,
        placement: placement || (isMobile ? 'bottom' : 'bottom-end'),
        offset: [0, 8],
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onHide: () => {
          setIsOpen(false)
        },
        onHidden: () => {
          onHidden?.()
        },
        onShow: () => {
          setIsOpen(true)
        },
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        maxWidth: '100%',
      }}
      dropdowncontent={
        dropdownContent || (
          <Flex
            sx={{
              width: ['100%', '26.4rem'],
              flexDirection: 'column',
              ...sxDropdownContent,
            }}
          >
            <IconMenu
              isOpen={isOpen}
              hideRef={hideRef}
              items={options}
              shouldHighlightOnHover={shouldHighlightOnHover}
              sxButton={{
                px: '0.8rem',
                color: 'aliceBlue',
                fontSize: '1.4rem',
                fontWeight: shouldUseNewDropdownDesign ? 500 : 700,
              }}
              sxContainer={{
                width: '100%',
                borderRadius: '1.2rem',
                p: '0.4rem',
                gap: '0.1rem',
              }}
            />
            {customContent}
          </Flex>
        )
      }
    >
      {/* Desktop and Tablet */}
      <Flex sx={{ display: ['none', 'flex'] }}>
        <CircleIconWrapper
          sx={{
            height: '3.8rem',
            width: '3.8rem',
            color: 'aliceBlue',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white6',
            svg: {
              opacity: isOpen ? 1 : 0.6,
            },
            '&:hover ': {
              backgroundColor: 'white6',
              svg: {
                opacity: 1,
              },
            },
          }}
          isActive={isOpen}
        >
          <Svg
            fill="aliceBlue"
            svg={MoreButtonIcon}
            sx={{
              width: '3.2rem',
              height: '3.2rem',
              flexShrink: 0,
            }}
            descriptiveText="More options icon"
          />
        </CircleIconWrapper>
      </Flex>
      {/* Mobile */}
      <Flex
        sx={{
          display: ['flex', 'none'],
          'svg > path': {
            opacity: isOpen ? 1 : 0.6,
          },
          '&:hover': {
            'svg > path': {
              opacity: 1,
            },
          },
        }}
      >
        <Svg
          svg={MoreButtonIcon}
          fill="aliceBlue"
          sx={{
            width: '3.2rem',
            height: '3.2rem',
            flexShrink: 0,
          }}
          descriptiveText="More options icon"
        />
      </Flex>
    </Dropdown>
  )
}
