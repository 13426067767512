import React, { ReactElement } from 'react'
import CheckListItem from './CheckListItem'
import { TopNavRelativePath } from 'components/Dashboard/NavBar/NavMenu'
import { Flex } from 'theme-ui'

interface ChecklistItemsProps {
  isProfileCreated: boolean
  isFilterEnabled: boolean
  isEndpointAdded: boolean
  isEndpointConfigured: boolean
  firstProfilePK: string | number | undefined
}

export function ChecklistItems({
  isProfileCreated,
  isFilterEnabled,
  isEndpointAdded,
  isEndpointConfigured,
  firstProfilePK,
}: ChecklistItemsProps): ReactElement {
  return (
    <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
      <CheckListItem
        isChecked={true}
        number={1}
        ariaLabel="Create your account"
        text="Create your account"
      />
      <CheckListItem
        isChecked={isProfileCreated}
        number={2}
        ariaLabel="Go to profile page and create a Profile"
        text="Create a Profile"
        navigateTo={`/dashboard/${TopNavRelativePath.PROFILES}`}
      />
      <CheckListItem
        isChecked={isFilterEnabled}
        isDisabled={!firstProfilePK}
        number={3}
        ariaLabel="Go to filter page and enable filtering"
        text="Enable a Filter"
        navigateTo={`/dashboard/${TopNavRelativePath.PROFILES}/${firstProfilePK}/filters`}
      />
      <CheckListItem
        isChecked={isEndpointAdded}
        isDisabled={!firstProfilePK}
        number={4}
        ariaLabel="Go to endpoint page and add an endpoint"
        text="Add an Endpoint"
        navigateTo={`/dashboard/${TopNavRelativePath.ENDPOINTS}`}
      />
      <CheckListItem
        isChecked={isEndpointConfigured}
        isDisabled={!firstProfilePK}
        number={5}
        ariaLabel="Go to endpoint page and configure your endpoint"
        text="Configure an Endpoint"
        navigateTo={`/dashboard/${TopNavRelativePath.ENDPOINTS}`}
      />
    </Flex>
  )
}
