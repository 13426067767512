import { ResolverStatus } from 'store/api/user/user.interface'
import React, { ReactElement, useEffect } from 'react'
import { Flex, Text } from 'theme-ui'
import useQueryString from 'utils/useQueryString'
import { ExternalLink, Svg } from 'ui'
import CheckmarkIcon from 'images/checkmark.svg'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import {
  setIsDeviceStatusVerified,
  startPollingDevices,
  stopPollingDevices,
} from 'store/multiprofile'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

const ConfiguredStatusBanner = ({
  configuredStatus,
}: {
  configuredStatus: ResolverStatus
}): ReactElement => {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const selectedDevicePk = qs.deviceId
  const isDeviceStatusEnabled = useAppSelector(s => s.devices.isDeviceStatusVerified)

  useEffect(() => {
    if (qs.freeResolverType || qs.resolverUid) {
      return
    }

    if (qs.helpPane !== SetupGuideIntermediateStates.DNS && qs.helpPane) {
      if (selectedDevicePk) {
        dispatch(setIsDeviceStatusVerified(false))
        // only poll devices if they are present and one is selected
        dispatch(startPollingDevices({ deviceId: selectedDevicePk }))
      }
    }
    return () => {
      dispatch(stopPollingDevices())
    }
  }, [dispatch, qs.freeResolverType, qs.helpPane, qs.resolverUid, selectedDevicePk])

  if (qs.freeResolverType || qs.resolverUid) {
    // here from free dns page. don't show banner
    return <></>
  }

  return (
    <>
      <Flex
        sx={{
          color: 'greenApple',
          width: 'auto',
          maxWidth: ['100%', '40rem'],
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          overflowWrap: 'break-word',
        }}
        tabIndex={0}
      >
        <Text variant="size12Weight400">
          {configuredStatus === ResolverStatus.UNVERIFIED &&
            !isDeviceStatusEnabled &&
            'Awaiting DNS Change...'}
        </Text>

        {configuredStatus === ResolverStatus.UNVERIFIED && isDeviceStatusEnabled && (
          <Text variant="size12Weight400" sx={{ mx: '0.8rem' }}>
            Partial success. Your browser is disregarding the DNS settings or you&apos;re not using
            this Endpoint.
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/browser-not-using-os-dns`}
              sx={{
                fontSize: '1.2rem',
                color: 'aliceBlue60',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                ml: '0.5rem',
              }}
            >
              Learn more
            </ExternalLink>
          </Text>
        )}

        {configuredStatus === ResolverStatus.VERIFIED && (
          <>
            <Svg
              svg={CheckmarkIcon}
              fill="greenApple"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                mr: '1.6rem',
              }}
              descriptiveText="Configuration successful checkmark"
            />
            Successfully Configured
          </>
        )}
      </Flex>
    </>
  )
}

export default ConfiguredStatusBanner
