import React, { ReactElement, ReactNode } from 'react'
import { Button, ButtonVariant } from 'ui/index'
import { navigate } from 'gatsby'
import { ThemeUIStyleObject } from 'theme-ui'
import { useLocation } from '@reach/router'

export function SmoothAnchorLink({
  testId,
  to,
  children,
  onClick,
  sx,
  tabIndex,
  ariaLabel,
  buttonVariant = 'transparent',
}: {
  to: string
  children: ReactNode
  onClick?: () => void
  sx?: ThemeUIStyleObject
  tabIndex?: number
  testId?: string
  ariaLabel: string
  buttonVariant?: ButtonVariant
}): ReactElement {
  const location = useLocation()

  return (
    <Button
      data-testid={testId}
      ariaLabel={ariaLabel}
      variant={buttonVariant}
      sx={{
        p: 0,
        m: 0,
        justifyContent: 'flex-start',
        ...sx,
      }}
      tabIndex={tabIndex}
      onClick={() => {
        onClick?.()
        navigate(to, { state: { prevPath: location.pathname } })
        const id = to.split('#')[1]

        if (id) {
          const element = document.getElementById(id)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }}
    >
      {children}
    </Button>
  )
}
