import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import { getMonth } from 'date-fns'
import { Button } from 'ui/Buttons'
import ArrowLeft from 'images/analytics/dateTimePicker/arrow-left.svg'
import ArrowRight from 'images/analytics/dateTimePicker/arrow-right.svg'
import { Svg } from 'ui/Svg'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps): ReactElement {
  return (
    <Flex
      data-testid="custom-header"
      sx={{
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '1.2rem',
      }}
    >
      <Button
        variant="simple"
        ariaLabel="previous month"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        sx={{
          opacity: prevMonthButtonDisabled ? 0 : 1,
          p: 0,
        }}
      >
        <Svg
          svg={ArrowLeft}
          descriptiveText="Previous month icon"
          sx={{ width: '2rem', height: '2rem' }}
        />
      </Button>

      <Text
        variant="size12Weight700"
        sx={{
          color: 'aliceBlue',
        }}
      >
        {months[getMonth(date)]}
      </Text>

      <Button
        variant="simple"
        ariaLabel="next month"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        sx={{
          opacity: nextMonthButtonDisabled ? 0 : 1,
          p: 0,
        }}
      >
        <Svg
          svg={ArrowRight}
          descriptiveText="Next month icon"
          sx={{ width: '2rem', height: '2rem' }}
        />
      </Button>
    </Flex>
  )
}
