import { Button, ButtonProps, Svg } from 'ui'
import React, { ReactElement } from 'react'
import { ThemeUIStyleObject, Text } from 'theme-ui'

interface TrayTabButtonProps extends ButtonProps {
  text: string
  selected: boolean
  onClick: () => void
  sx?: ThemeUIStyleObject
  inputRef?: React.MutableRefObject<HTMLInputElement | null>
  icon?: React.ElementType
  textSx?: ThemeUIStyleObject
}
export default function DialogTabButton({
  text,
  selected,
  onClick,
  sx,
  icon,
  inputRef,
  textSx,
  ...attrs
}: TrayTabButtonProps): ReactElement {
  return (
    <Button
      onClick={(): void => {
        onClick()
        inputRef?.current?.focus()
      }}
      variant={selected ? 'transparent' : 'simple'}
      sx={{
        border: 'none',
        borderBottom: ({ colors }): string =>
          `1px solid ${selected ? colors?.primary : 'transparent'}`,
        backgroundColor: 'transparent',
        marginBottom: '-1px',
        color: selected ? 'aliceBlue' : 'aliceBlue60',
        gap: '0.8rem',
        ...sx,
      }}
      {...attrs}
    >
      {icon && (
        <Svg
          svg={icon}
          fill={selected ? 'aliceBlue' : 'aliceBlue60'}
          sx={{ width: '2.4rem', height: '2.4rem' }}
          descriptiveText={`${text} Tab Icon`}
        />
      )}
      <Text variant="size15Weight600" sx={{ ...textSx }}>
        {text}
      </Text>
    </Button>
  )
}
