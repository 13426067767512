import { useColorMode } from 'theme-ui'
import { ColorMode } from 'store/dashboard/dashboard'
import { useEffect } from 'react'

export default function useGetColorMode(): {
  isLightMode: boolean
  colorMode: ColorMode
  setColorMode: (colorMode: ColorMode) => void
} {
  const [themeUIColorMode, setThemeUIColorMode] = useColorMode()
  const mediaQuery =
    typeof window !== 'undefined' ? window.matchMedia('(prefers-color-scheme: dark)') : null

  useEffect(() => {
    return () => mediaQuery?.removeEventListener('change', () => {})
  }, [mediaQuery])

  const setColorMode = (colorMode: ColorMode) => {
    if (colorMode === ColorMode.SYSTEM) {
      setThemeUIColorMode(mediaQuery?.matches ? ColorMode.DARK : ColorMode.LIGHT)
      mediaQuery?.addEventListener('change', () => {
        setThemeUIColorMode(mediaQuery?.matches ? ColorMode.DARK : ColorMode.LIGHT)
      })
    } else {
      setThemeUIColorMode(colorMode)
    }
  }

  return {
    isLightMode: themeUIColorMode === ColorMode.LIGHT,
    colorMode: themeUIColorMode as ColorMode,
    setColorMode,
  }
}
