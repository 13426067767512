import React, { ReactElement } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import useGetColorMode from 'utils/useGetColorMode'
import { Flex, Text } from '@theme-ui/components'
import { Input } from 'ui/Inputs'

enum TimeRange {
  MINUTES = 'minutes',
  HOURS = 'hours',
}

export default function CustomTimeInput({
  isDropdown,
  date,
  onChange,
  dayValue,
  sx,
}: {
  isDropdown?: boolean
  date?: Date | null
  onChange?: (date: Date | null) => void
  dayValue?: string
  sx?: ThemeUIStyleObject
}): ReactElement {
  const hours = date?.getHours() || 0
  const minutes = date?.getMinutes() || 0
  const { isLightMode } = useGetColorMode()

  const onChangeHandler = (value, type) => {
    if (value.length > 2) {
      return
    }

    if (type === TimeRange.HOURS && +value < 24) {
      date?.setHours(+value)
    } else if (type === TimeRange.MINUTES && +value < 60) {
      date?.setMinutes(+value)
    } else {
      return
    }

    if (date) {
      onChange?.(date)
    }
  }

  return (
    <Flex
      sx={{
        fontFamily: 'body',
        width: '100%',
        justifyContent: 'center',
        ...(isLightMode
          ? {
              backgroundColor: 'raisinBlack',
              borderRadius: '0 0 0.8rem 0.8rem',
            }
          : {}),
        ...sx,
      }}
    >
      {dayValue && (
        <Text
          variant="size12Weight700"
          sx={{
            display: 'inline-flex',
            width: '6rem',
            color: 'aliceBlue60',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {dayValue}
        </Text>
      )}
      <Flex
        sx={{
          width: 'fit-content',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: date ? 'blueYonder40' : 'transparent',
        }}
      >
        <Input
          disabled={!date}
          value={hours < 10 ? `0${hours}` : hours}
          containerStyle={{ width: 'fit-content', flexGrow: 'auto' }}
          onChange={e => {
            onChangeHandler(e.target.value.replace('0', ''), TimeRange.HOURS)
          }}
          sx={inputStyles(isDropdown)}
        />
        <Flex
          sx={{
            px: '2.4rem',
            color: 'aliceBlue',
            fontSize: '1.5rem',
            alignItems: 'center',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: date ? 'blueYonder40' : 'transparent',
          }}
        >
          :
        </Flex>
        <Input
          disabled={!date}
          value={minutes < 10 ? `0${minutes}` : minutes}
          containerStyle={{ width: 'fit-content', flexGrow: 'auto' }}
          onChange={e => {
            onChangeHandler(e.target.value.replace('0', ''), TimeRange.MINUTES)
          }}
          sx={inputStyles(isDropdown)}
        />
      </Flex>
    </Flex>
  )
}

export const inputStyles = (isDropdown?: boolean): ThemeUIStyleObject => {
  return {
    display: 'flex',
    maxWidth: '6.8rem !important',
    height: '2.5rem',
    fontSize: '1.2rem',
    border: 'solid 1px',
    borderColor: 'transparent',
    px: '2.4rem',
    py: 0,
    borderRadius: 0,
    color: 'aliceBlue60',
    backgroundColor: isDropdown ? 'raisinBlack' : 'blue',
  }
}
