import React, { ReactElement, ReactNode } from 'react'
import { Flex } from '@theme-ui/components'
import { headerStyles } from 'ui/DateTimePicker/CustomStyles/headerStyles'
import { bodyStyles } from 'ui/DateTimePicker/CustomStyles/bodyStyles'
import { timeInputStyles } from 'ui/DateTimePicker/CustomStyles/timeInputStyles'

export const datePickerClass = '.react-datepicker'

export default function CustomContainer({
  className,
  children,
  isDropdown,
}: {
  className?: string
  children: ReactNode
  isDropdown?: boolean
}): ReactElement {
  const background = isDropdown ? 'raisinBlack' : 'blue'

  return (
    <Flex
      sx={{
        fontFamily: 'Plus Jakarta Sans Variable, sans-serif',
        mt: isDropdown ? 0 : '1.2rem',
        width: ['100%', '40.3rem'],
        padding: '16px',
        background: isDropdown ? 'raisinBlack' : 'blue',
        color: 'aliceBlue',
        border: '1px solid',
        borderRadius: isDropdown ? '1.6rem' : '0.8rem 0.8rem 0 0',
        p: 0,
        borderColor: isDropdown ? 'transparent' : 'blueYonder40',
        [datePickerClass]: {
          display: 'flex',
          width: '100%',
          backgroundColor: background,
          border: 'none',
          borderRadius: isDropdown ? '0.8rem' : '0.8rem 0.8rem 0 0',
        },
        ...headerStyles(background),
        ...bodyStyles(background),
        ...timeInputStyles(background),
      }}
    >
      <Flex className={className} sx={{ flexDirection: 'column' }}>
        <Flex
          style={{
            position: 'relative',
            backgroundColor: isDropdown ? 'raisinBlack' : 'blue',
            flexDirection: 'column',
            borderRadius: isDropdown ? '0.8rem' : '0.8rem 0.8rem 0 0',
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
