import AllIcon from 'images/serviceCategories/all-services-icon.svg'
import AudioIcon from 'images/serviceCategories/audio-icon.svg'
import VideoIcon from 'images/serviceCategories/video-icon.svg'
import SocialIcon from 'images/serviceCategories/social-icon.svg'
import ToolsIcon from 'images/serviceCategories/tools-icon.svg'
import GamingIcon from 'images/serviceCategories/gaming-icon.svg'
import ShopIcon from 'images/serviceCategories/shop-icon.svg'
import VendorsIcon from 'images/serviceCategories/vendors-icon.svg'
import RecreationIcon from 'images/serviceCategories/recreation-icon.svg'
import NewsIcon from 'images/serviceCategories/news-icon.svg'
import HostingIcon from 'images/serviceCategories/hosting-icon.svg'
import FinanceIcon from 'images/serviceCategories/finance-icon.svg'
import CareerIcon from 'images/serviceCategories/career-icon.svg'

export default {
  all: AllIcon,
  audio: AudioIcon,
  video: VideoIcon,
  gaming: GamingIcon,
  social: SocialIcon,
  tools: ToolsIcon,
  shop: ShopIcon,
  vendors: VendorsIcon,
  recreation: RecreationIcon,
  news: NewsIcon,
  hosting: HostingIcon,
  finance: FinanceIcon,
  career: CareerIcon,
}
