import React, { ReactElement, useCallback } from 'react'
import { Flex } from 'theme-ui'
import ServicesOptionsMenu from 'components/Dashboard/Profiles/Services/ServicesOptionsMenu'
import { ServicesSubHeaderProps } from 'components/Dashboard/Profiles/Services/ServicesSubHeader'
import SearchBar from 'ui/SearchBar'

export default function ServicesSubHeaderActions({
  categoryId,
  searchText,
  setSearchText,
}: ServicesSubHeaderProps): ReactElement {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
    },
    [setSearchText],
  )

  const onClearSearch = useCallback(() => {
    setSearchText('')
  }, [setSearchText])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        height: ['3.2rem', 'auto'],
        flexGrow: 0,
        gap: '1.2rem',
      }}
    >
      <Flex sx={{ display: ['none', 'flex'] }}>
        <SearchBar
          name="services"
          searchText={searchText}
          onChange={onChange}
          clearSearch={onClearSearch}
        />
      </Flex>
      {categoryId && <ServicesOptionsMenu categoryId={categoryId} />}
    </Flex>
  )
}
