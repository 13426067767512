import React, { ReactElement } from 'react'
import ProvisionElement from './ProvisionElement'
import { Provision } from 'store/api/provision'
import NoResults from 'components/Dashboard/CommonComponents/NoResults'
import { Table, TableBody, TableHeader } from 'ui/Table'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

const tableHeaders = [
  'Created On',
  'Endpoint Type',
  'Link to Profile',
  'Status',
  'Expires on',
  'Limit',
  '',
]
export default function ProvisionTable({ provisions }: { provisions: Provision[] }): ReactElement {
  const isMobileOrTablet = useBreakpointIndex() < 2

  return (
    <Flex
      sx={{
        color: 'aliceBlue',
        flexDirection: 'column',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: ['transparent', 'blueYonder15'],
        borderRadius: [0, '1.2rem'],
      }}
    >
      {!provisions?.length ? (
        <NoResults message="No Invoices!" />
      ) : isMobileOrTablet ? (
        provisions?.map(provision => (
          <ProvisionElement
            key={`${provision.PK}`}
            provision={provision}
            tableHeaders={tableHeaders}
          />
        ))
      ) : (
        <Table>
          <TableHeader
            names={tableHeaders}
            sx={{
              ':first-of-type': { flex: '0 1 20rem' },
              ':nth-of-type(3)': { mr: '0.8rem' },
              ':nth-of-type(5)': { flex: '0 1 20rem' },
              ':nth-of-type(6)': { flex: '0 1 10rem' },
              ':last-of-type': { flex: '0 1 2.4rem' },
            }}
          />
          <TableBody>
            {provisions?.map(provision => (
              <ProvisionElement
                key={`${provision.PK}`}
                provision={provision}
                tableHeaders={tableHeaders}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Flex>
  )
}
