import React, { PropsWithChildren, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui/index'
import { createPortal } from 'react-dom'
import ModalDialog from 'ui/NewModalDialog/ModalDialog.base'
import WarningIcon from 'images/dashboard/warning-icon.svg'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'

export interface ConfirmDeletionDialogProps {
  element: Element | null
  title: string
  testId?: string
  onClose: (event?: React.MouseEvent<HTMLButtonElement>) => void
  onDelete: (event?: React.MouseEvent<HTMLButtonElement>) => void
  isOpen?: boolean
  notificationText?: string
  isRequestInFlight?: boolean
}

export default function ConfirmDeletionDialog({
  element,
  title,
  testId,
  onClose,
  isOpen = true,
  notificationText,
  isRequestInFlight,
  onDelete,
}: ConfirmDeletionDialogProps): ReactElement | null {
  if (!element) {
    return null
  }

  return createPortal(
    <ModalDialog
      title={title}
      onCloseClick={onClose}
      dismiss={onClose}
      dataTestId={testId}
      isOpen={isOpen}
      footer={
        <Flex sx={{ width: '100%', justifyContent: 'flex-end', gap: '0.8rem' }}>
          <Button ariaLabel="cancel button" variant="newTertiary" onClick={onClose}>
            Cancel
          </Button>
          <ButtonWithLoadingState
            ariaLabel="delete button"
            isLoading={isRequestInFlight}
            variant="newPrimary"
            sxSpinner={{
              width: '1.6rem',
              height: '1.6rem',
            }}
            sx={{
              minWidth: '8rem',
            }}
            onClick={onDelete}
          >
            Delete
          </ButtonWithLoadingState>
        </Flex>
      }
    >
      <DialogContent>{notificationText}</DialogContent>
    </ModalDialog>,
    element,
  )
}

function DialogContent({ children }: PropsWithChildren): ReactElement {
  return (
    <Flex
      sx={{
        width: ['100%', '42rem'],
        px: '2.4rem',
        py: '4rem',
        flexDirection: 'column',
        gap: '1.2rem',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Svg
        svg={WarningIcon}
        sx={{ width: '4.8rem', height: '4.8rem', '& path': { opacity: 1 } }}
        descriptiveText="Warning icon"
      />
      <Text variant="size16Weight400" sx={{ color: 'aliceBlue60', lineHeight: '140%' }}>
        {children}
      </Text>
    </Flex>
  )
}
