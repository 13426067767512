import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { ApiResponse } from 'store/api/http'
import {
  DeleteUserArgs,
  GoogleAuthorizationUrlResponse,
  GoogleSsoUserDataResponse,
  LoginOktaDataResponse,
  LoginOktaRequestArgs,
  LoginRequestArgs,
  PreAuthDataResponse,
  PutUserArgs,
  ResetPwdArgs,
  SSOProvider,
  UserDataResponse,
  UsersRegisterArgs,
} from './user.interface'
import { setSessionUpdate } from 'store/session'

const googleRedirectURI =
  typeof window !== 'undefined'
    ? `${window.location.origin}/sso/callback?provider=${SSOProvider.GOOGLE}`
    : ''

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUnImpersonatedUser: builder.query({
      query: () => ({
        url: '/users',
      }),
      transformResponse: (response: UserDataResponse) => {
        return response.body
      },
    }),
    // use useGetUser custom hook instead of useGetUserQuery
    getUser: builder.query({
      query: () => getQueryArgs('/users'),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(setSessionUpdate(data))
        } catch {}
      },
      providesTags: ['User'],
    }),
    putUser: builder.mutation({
      query: (body: PutUserArgs) => getQueryArgs('/users', 'PUT', body),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              Object.assign(draft, data.body)
            }),
          )
          dispatch(setSessionUpdate(data.body))
        } catch {}
      },
    }),
    deleteUser: builder.mutation({
      query: (body: DeleteUserArgs) => getQueryArgs('/users', 'DELETE', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response
      },
    }),
    userLogin: builder.mutation({
      query: (body: LoginRequestArgs) => getQueryArgs('/users/login', 'POST', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
    }),
    userLoginSSO: builder.mutation({
      query: (body: LoginOktaRequestArgs) => getQueryArgs('/users/login', 'POST', body),
      transformResponse: (response: LoginOktaDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
    }),
    preAuthUserLogin: builder.mutation({
      query: (body: LoginRequestArgs) => getQueryArgs('/preauth/login', 'POST', body),
      transformResponse: (response: PreAuthDataResponse) => response.body,
    }),
    preAuthUserRegister: builder.mutation({
      query: (body: UsersRegisterArgs) => getQueryArgs('/preauth/register', 'POST', body),
      transformResponse: (response: PreAuthDataResponse) => response.body,
    }),
    userRegister: builder.mutation({
      query: (body: UsersRegisterArgs) => getQueryArgs('/users/register', 'POST', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          await new Promise(r => setTimeout(r, 1000))
          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              Object.assign(draft, data)
            }),
          )
        } catch {}
      },
    }),
    postConfirmEmailResend: builder.mutation({
      query: () => getQueryArgs('/users/confirm_email/resend', 'POST'),
      transformResponse: (response: ApiResponse<[]>) => response,
    }),
    resetPassword: builder.mutation({
      query: (body: ResetPwdArgs) => getQueryArgs('/users/forgot_pass/change', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => {
        return response.body
      },
      transformErrorResponse,
    }),
    forgotPassword: builder.mutation({
      query: (body: { email: string }) => getQueryArgs('/users/forgot_pass', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response.body,
    }),
    confirmEmail: builder.mutation({
      query: (body: { hash: string }) => getQueryArgs('/users/confirm_email/', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              draft.email_status = 1
            }),
          )
        } catch {}
      },
    }),
    unsubscribeEmail: builder.mutation({
      query: (body: { hash: string }) => getQueryArgs('/users/unsubscribe_email', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response,
    }),
    getOktaCallback: builder.query({
      query: (params: { state: string; code?: string }) =>
        getQueryArgs(`/okta/callback?${new URLSearchParams(params).toString()}`, 'GET'),
      transformResponse: (response: UserDataResponse) => {
        return response.body
      },
      transformErrorResponse,
    }),
    getGoogleAuthorizationUrl: builder.query({
      query: () => {
        const state = Math.random().toString(36).substring(2, 15)
        const queryParams = new URLSearchParams()
        queryParams.set('state', state)
        queryParams.set('redirect_uri', googleRedirectURI)
        return getQueryArgs(`/sso/google/authorization?${queryParams.toString()}`, 'GET')
      },
      transformResponse: (response: GoogleAuthorizationUrlResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
    }),
    getGoogleCallback: builder.query({
      query: (params: { state: string; code?: string }) => {
        return getQueryArgs(
          `/sso/google/callback?${new URLSearchParams({
            ...params,
            redirect_uri: googleRedirectURI,
          }).toString()}`,
          'GET',
        )
      },
      transformResponse: (response: GoogleSsoUserDataResponse) => {
        throwErrorFromResponseData(response)
        return response.body
      },
      transformErrorResponse,
    }),
  }),
})

export const {
  useGetUnImpersonatedUserQuery,
  useGetUserQuery,
  usePutUserMutation,
  useDeleteUserMutation,
  useConfirmEmailMutation,
  useUnsubscribeEmailMutation,
  useForgotPasswordMutation,
  usePostConfirmEmailResendMutation,
  useResetPasswordMutation,
  useUserLoginSSOMutation,
  useGetOktaCallbackQuery,
  useLazyGetGoogleAuthorizationUrlQuery,
} = userApi
