import { ThemeUIStyleObject } from 'theme-ui'
import BackIconSvg from 'images/back-icon-big.svg'
import { Svg } from 'ui'
import React, { ReactElement } from 'react'

const BackIcon = ({ sx, fill }: { sx?: ThemeUIStyleObject; fill?: string }): ReactElement => {
  return (
    <Svg
      svg={BackIconSvg}
      fill={fill}
      sx={{
        pointerEvents: 'none',
        ...sx,
      }}
      descriptiveText="Back icon"
    />
  )
}

export default BackIcon
