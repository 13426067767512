import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import foregroundStyle from './foregroundStyle'

export function WarningMessagePlaceholder(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: ['12.2rem', '6.8rem'],
        alignItems: ['flex-start', 'center'],
        backgroundColor: 'transparent',
        borderRadius: '16px',
        border: '2px solid',
        borderColor: 'darkItemBG',
        mb: '2.4rem',
        p: ['1.6rem', '2.4rem'],
      }}
    >
      <Box
        sx={{
          ...foregroundStyle,
          width: '3.2rem',
          height: '3.2rem',
          flexShrink: 0,
        }}
      />
      <Flex sx={{ width: '100%', flexDirection: 'column' }}>
        <Box
          sx={{
            ...foregroundStyle,
            width: ['20rem', '50rem'],
            height: ['2.4rem', '2.8rem'],
            ml: '2.4rem',
          }}
        />
        <Box
          sx={{
            display: ['block', 'none'],
            ...foregroundStyle,
            width: ['20rem', '50rem'],
            height: ['2.4rem', '3.2rem'],
            ml: '2.4rem',
            mt: '0.6rem',
          }}
        />
      </Flex>
    </Flex>
  )
}
